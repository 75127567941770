import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromLayout from './core/reducers/layout/layout.reducer';
import * as fromRouter from './routing/reducer/router.reducer';
import * as fromNotifications from './core/reducers/notification/notification.reducer';
import { RightOffCanvasState } from './core/enum/right-off-canvas-state.enum';
import { Breakpoint } from './core/enum/breakpoint.enum';


export interface StateInterface {
  router: fromRouter.State;
  layout: fromLayout.StateInterface;
  notifications: fromNotifications.State;
}

export const reducers: ActionReducerMap<StateInterface> = {
  router: fromRouter.reducer,
  layout: fromLayout.reducer,
  notifications: fromNotifications.reducer
};

/**
 * Router Reducer
 */
export const getRouterState = createFeatureSelector<fromRouter.State>('router');

export const getRouterUrl = createSelector(getRouterState, fromRouter.getUrl);
export const getRouterParams = createSelector(getRouterState, fromRouter.getParams);
export const getRouterQueryParams = createSelector(getRouterState, fromRouter.getQueryParams);
export const getRouterTitle = createSelector(getRouterState, fromRouter.getTitle);

export const getRouterPath = createSelector(getRouterUrl, (url) => {
  const urlParser = document.createElement('a');
  urlParser.href = url;
  return urlParser.pathname;
});

/**
 * Layout Reducers
 */
export const getLayoutState = createFeatureSelector<fromLayout.StateInterface>('layout');

export const getLayoutRightOffCanvasMode = createSelector(getLayoutState, fromLayout.getRightOffCanvasMode);
export const getLayoutBreakpoint = createSelector(getLayoutState, fromLayout.getBreakpoint);

export const getNotificationsState = createFeatureSelector<fromNotifications.State>('notifications');
export const getNotifications = createSelector(getNotificationsState, fromNotifications.getNotifications);

export const getRightOffCanvasIsOpen = createSelector(
  getLayoutRightOffCanvasMode,
  getRouterUrl,
  (rightOffCanvasMode, routerUrl) => rightOffCanvasMode !== RightOffCanvasState.hidden
           && routerUrl
           && (routerUrl.startsWith('/products/') && !routerUrl.startsWith('/products/404'))
           || (routerUrl.startsWith('/categories/') && !routerUrl.startsWith('/categories/404')));

export const getListElementsPerRow = createSelector(getLayoutRightOffCanvasMode, getLayoutBreakpoint, (canvasMode, breakpoint) => {
  if (canvasMode === RightOffCanvasState.hidden) {
    switch (breakpoint) {
      case Breakpoint.bpMinXxxl:
      case Breakpoint.bpMinXxl:
      case Breakpoint.bpMinXl:
      case Breakpoint.bpMinL:
        return 6;
      case Breakpoint.bpMinM:
        return 4;
      case Breakpoint.bpMinS:
        return 3;
      default:
        return 2;
    }
  } else {
    switch (breakpoint) {
      case Breakpoint.bpMinXxxl:
      case Breakpoint.bpMinXxl:
        return 6;
      case Breakpoint.bpMinXl:
        return 4;
      case Breakpoint.bpMinL:
        return 3;
      default:
        return 2;
    }
  }
});

export const getSearchbarVisible = createSelector(getRouterUrl, (_routerUrl) => !![
  '/categories',
  '/products',
  '/suppliers',
  '/search'
].find(path => _routerUrl.startsWith(path)));
