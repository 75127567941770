import * as productActions from '../../../actions/product.actions';


/**
 * tempBasicFilterValues reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 * @returns the new state
 */
export const tempSettingFilterValues = (state: {[filterId: string]: FilterValue}, action: productActions.Actions): {[filterId: string]: FilterValue} => {
  switch (action.type) {
    case productActions.UPDATE_TEMP_SETTING_FILTER_VALUES:
      return action.payload;
    case productActions.FETCH_MULTI_SUCCEEDED:
      const { settings } = action.payload.queryConfig;

      if (settings && settings.length > 0) {
        const result = {};

        settings.forEach(basicFilterRequestValue => {
          result[basicFilterRequestValue.id] = basicFilterRequestValue.value;
        });

        return result;
      }

      return undefined;
    default:
      return state;
  }
};
