/**
 * Deep copies the given array, date, object or primitive
 *
 * @param input - array, date, object or primitive
 * @returns deepcopy
 */
export const deepCopy = (input: any) => {
  if (input instanceof Array) {
    return input.map((value) => deepCopy(value));
  }

  if (input instanceof Date) {
    return new Date(input.getTime());
  }

  if (input instanceof Object) {
    const newObj = Object.create(Object.getPrototypeOf(input), {});
    Object.assign(newObj, {...input});

    Object.getOwnPropertyNames(newObj).forEach((property) => {
      if (newObj[property] instanceof Array || newObj[property] instanceof Object) {
        newObj[property] = deepCopy(newObj[property]);
      }
    });

    return newObj;
  }

  return input;
};
