import {
  ProductPropertySet,
  ProductPropertySetBackendInterface
} from './';
import { generateProductPropertyFromBackendInput } from '../model-property';


/**
 * Generates a product property set based on api input
 *
 * @param input
 * @returns
 */
export const generateProductPropertySetFromBackendInput = (input: ProductPropertySetBackendInterface): ProductPropertySet => {
  try {
    return {
      name: input.Name,
      description: input.Description,
      uuid: input.Uuid,
      properties: (input.Properties || []).map(generateProductPropertyFromBackendInput)
    };
  } catch (e) {
    return null;
  }
};
