<header class="no-shadow">
  <a *ngIf="hasAnalyticsCompany$ | async"
     class="logo"
     [routerLink]="['/']"
     data-gtm-event-category="Navigation"
     data-gtm-event-action="Navigate Supplier Analytics"
     data-gtm-event-label="Header - Logo">
    <p1-lib-icons-icon-wrapper name="iconPlanOneLogo"></p1-lib-icons-icon-wrapper>
  </a>
  <div *ngIf="featureFlags.burgerMenu"
       class="menu hide@print">
    <button tabindex="-1"
            class="menu__trigger"
            title="Menu"
            i18n-title="title menu trigger // page: every page @@header.component_button_title"
            [ngClass]="{'menu__trigger--active': navigationOpen}"
            (click)="!ignoreNavClick && toggleNavigation(!navigationOpen)"
            (mouseenter)="startNavTimer()"
            (mouseleave)="resetNavTimer()">
      <span class="lines"></span>
      <span class="trigger__text"
            i18n="menu trigger text // page: all @@header.component_menu">Menu
      </span>
    </button>
  </div>

  <a *ngIf="!featureFlags.catalogConfig && (hasAnalyticsCompany$ | async) !== true"
     class="logo"
     [href]="wordpressPages.landingPage"
     data-gtm-event-category="Navigation"
     data-gtm-event-action="Navigate Landingpage"
     data-gtm-event-label="Header - Logo">
    <p1-lib-icons-icon-wrapper name="iconPlanOneLogo"></p1-lib-icons-icon-wrapper>
  </a>

  <a *ngIf="featureFlags.catalogConfig"
     class="logo logo--catalog"
     [style.width.px]="catalogIdentifier === 'bim' ? 225 : null"
     [href]="[(catalogLinkLogoUrl$ | async) ? (catalogLinkLogoUrl$ | async) : '/']">
    <ng-container *ngIf="(catalogLogoUrl$ | async) as logoUrl else companyName">
      <img [src]="logoUrl"
           [style.max-width.px]="catalogIdentifier === 'bim' ? 225 : null"
           [alt]="catalogName$ | async">
    </ng-container>
    <ng-template #companyName>
      {{ catalogName$ | async }}
    </ng-template>
  </a>
  <div class="hide@print user-wrapper">
    <button *ngIf="featureFlags.news && showNewsBell"
            class="news-trigger"
            (click)="toggleNews()"
            data-gtm-event-category="News"
            data-gtm-event-action="Open News Dialog"
            data-gtm-event-label="Header - News">
      <p1-lib-ui-elements-counter-badge *ngIf="unreadNews && unreadNews.length > 0"
                                        [count]="unreadNews.length"
                                        class="news-trigger__badge"></p1-lib-ui-elements-counter-badge>
      <i class="icon news-trigger__icon">
        <p1-lib-icons-icon-wrapper name="iconBell"></p1-lib-icons-icon-wrapper>
      </i>
    </button>
    <p1-qa-state-menu></p1-qa-state-menu>
    <p1-comparison-menu *ngIf="featureFlags.productComparison"></p1-comparison-menu>
    <p1-watchlist-menu *ngIf="featureFlags.myWatchlists"></p1-watchlist-menu>
    <p1-supplier-menu *ngIf="(hasAnalyticsCompany$ | async) && featureFlags.login === 'full'"></p1-supplier-menu>
    <p1-profile-menu *ngIf="featureFlags.login === 'full'"></p1-profile-menu>
  </div>
</header>
