<div *ngIf="featureFlags.myWatchlists"
     class="watchlist-menu">
  <a class="watchlist-menu__cta"
     title="My watchlists"
     (click)="blurLink($event)"
     [ngClass]="{'watchlist-menu__cta--unauthenticated': (isAuthenticated$ | async) !== true}"
     [routerLink]="(isAuthenticated$ | async) ? ['/myWatchlists'] : ['/auth/login']"
     [queryParams]="(isAuthenticated$ | async) ? null : { reason: 'needsAuthForWatchlists', from: '/myWatchlists' }"
     data-gtm-event-category="Navigation"
     data-gtm-event-action="Navigate my watchlists"
     data-gtm-event-label="Header - My watchlists button"
     i18n-title="my watchlists title text // page: all @@watchlist-menu.component_my_watchlists_label_text">
    <i class="icon icon--default">
      <p1-lib-icons-icon-wrapper name="iconStar"></p1-lib-icons-icon-wrapper>
    </i>
    <i *ngIf="(isAuthenticated$ | async) !== true"
       class="icon icon--lock">
      <p1-lib-icons-icon-wrapper name="iconLock"></p1-lib-icons-icon-wrapper>
    </i>
  </a>
</div>
