import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as rootReducer from '../../app.reducers';
import { user } from './user/user.reducer';
import { User } from '../../user/model';
import { keycloakAccessToken } from './keycloak-access-token/keycloak-access-token.reducer';
import { SupplierAnalyticsRoles } from '../../supplier-analytics/enums/supplier-analytics-roles.enum';


/**
 * Interface for auth-state
 */
export interface AuthStateInterface {
  keycloakAccessToken?: string;
  user: User;
}

export interface StateInterface extends rootReducer.StateInterface {
  auth: AuthStateInterface;
}

export const reducers = {
  keycloakAccessToken,
  user
};

export const getAuthState = createFeatureSelector<AuthStateInterface>('auth');

export const getKeycloakAccessToken = createSelector(getAuthState, (state: AuthStateInterface) => state.keycloakAccessToken);
export const getUser = createSelector(getAuthState, (state: AuthStateInterface) => state.user);

export const getHasAnalyticsCompany = createSelector(getAuthState,
  (state: AuthStateInterface) => state.user && state.user.companyUuid ? !!state.user.companyUuid && !!state.user.supplierAreaAccess : false);

export const getAnalyticsRoles = createSelector(getAuthState, (state: AuthStateInterface) => {
  if (!state.user || !state.user.realmAccessRoles) {
    return;
  }
  const analyticsRoles = [
    SupplierAnalyticsRoles.PortfolioDashboard,
    SupplierAnalyticsRoles.CategoryDashboard,
    SupplierAnalyticsRoles.ProductsInQA,
    SupplierAnalyticsRoles.PortfolioBenchmark,
    SupplierAnalyticsRoles.ProductDetailAnalysis,
    SupplierAnalyticsRoles.CompetitiveAnalysis,
    SupplierAnalyticsRoles.PerformanceDashboard,
    SupplierAnalyticsRoles.PerformancePerProduct,
    SupplierAnalyticsRoles.DemandDataDashboard,
    SupplierAnalyticsRoles.Leads
  ];
  const roles = analyticsRoles.filter(analyticsRole => state.user.realmAccessRoles.includes(analyticsRole));
  return roles;
});

export const getIsAuthenticated = createSelector(getKeycloakAccessToken, getUser, (_accessToken, _user) => !!(_accessToken && _user));

