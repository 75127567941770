import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers } from './reducer/auth.reducer';
import { KeycloakEffects } from './effects/keycloak.effects';
import { environment } from '../../environments/environment';


@NgModule({
  imports: [
    StoreModule.forFeature('auth', reducers),
    environment.featureFlags.login !== 'none' ? EffectsModule.forFeature([KeycloakEffects]) : []
  ]
})
export class AuthStoreModule {}
