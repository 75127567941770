import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { WindowRef } from '@p1/libs/browser-api-wrapper';

import { environment } from '../../../environments/environment';


@Injectable()
/**
 * Intercepts api requests to add a disable logging header
 * The api uses this header to ignore requests from bots
 */
export class AnalyticsExcludeInterceptor implements HttpInterceptor {

  constructor(private _windowRef: WindowRef) {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiRequest: boolean = req.url.indexOf(environment.api_url) > -1;

    try {
      if (isApiRequest && this._windowRef.nativeWindow && this._windowRef.nativeWindow.localStorage) {
        if (this._windowRef.nativeWindow.localStorage.getItem('analytics-exclude') === 'true') {
          req = req.clone({ setHeaders: {'Analytics-Exclude': 'true'} });
        }
      }
    } catch (e) { }

    return next.handle(req);
  }
}
