import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { TreeCategory } from '@p1/libs/catalog-service';

import { Product } from '../../product/model';


@Component({
  selector: 'p1-comparison-menu-product-tile',
  templateUrl: './comparison-menu-product-tile.component.html',
  styleUrls: ['./comparison-menu-product-tile.component.scss']
})
export class ComparisonMenuProductTileComponent {

  @Input()
  product: Product;

  @Input()
  productTreeCategoryMapping: {[uuid: string]: TreeCategory};

  @Output()
  removeProduct: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  getCategoryNameForProduct(product) {
    if (this.productTreeCategoryMapping?.[product?.uuid]) {
      return this.productTreeCategoryMapping[product.uuid].name;
    } else {
      return product.category?.name ?? '';
    }
  }
}
