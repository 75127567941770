import * as productActions from '../../../actions/product.actions';


/**
 * tempOrderingValue reducer
 *
 * @param state the current state
 * @param action an action that may result in a new state
 * @returns the new state
 */
export const tempOrderingValue = (state: string[], action: productActions.Actions): string[] => {
  switch (action.type) {
    case productActions.FETCH_MULTI_SUCCEEDED:
      return action.payload.queryConfig ? action.payload.queryConfig.ordering : undefined;
    case productActions.UPDATE_TEMP_ORDERING_VALUE:
      return action.payload;
    default:
      return state;
  }
};
