import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType
} from '@ngrx/effects';
import {
  Action,
  select,
  Store
} from '@ngrx/store';
import {
  combineLatest,
  defer,
  Observable,
  of
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators';

import { AnalyticsEventsService } from '@p1/libs/analyticsevents';

import * as rootReducer from '../../app.reducers';
import { Breakpoint } from '../../core/enum/breakpoint.enum';
import * as layoutActions from '../../core/actions/layout.actions';
import { RightOffCanvasState } from '../../core/enum/right-off-canvas-state.enum';
import * as productActions from '../../product/actions/product.actions';
import * as productReducer from '../../product/reducer';
import { ProductService } from '../../product/service/product.service';
import * as routerActions from '../../routing/actions/router.actions';
import * as productComparisonActions from '../actions/product-comparison.actions';
import { ProductComparison } from '../model/product-comparison';
import { ProductComparisonService } from '../service/product-comparison.service';
import * as productComparisonReducer from '../reducer/product-comparison.reducer';


@Injectable()
export class ProductComparisonEffects {

  /**
   * Dispatches an ProductComparisonAddItemsAction action to add similar products to comparison.
   * If similar products are not yet loaded, fetch them.
   *
   * @returns {Observable<Action>}
   */
  fetchSimilarProductsForComparison$: Observable<Action> = createEffect(
    () => this._actions$.pipe(
      ofType(productComparisonActions.fetchSimilarProductsForComparisonTriggered),
      map(action => action.product),
      mergeMap((product) => {
        if (Array.isArray(product.similarProducts) && product.similarProducts.length > 0) {
          return product.similarProducts.slice(0, 3).map(uuid => productComparisonActions.addProductToProductComparison({ uuid }));
        }

        return this._productService.getSimilarProducts(product.uuid).pipe(
          mergeMap(similarProducts => [
            new productActions.FetchSimilarProductsSucceededAction({
              uuid: product.uuid,
              similarProducts
            }),
            ...similarProducts.slice(0, 3).map(similarProduct => productComparisonActions.addProductToProductComparison({ uuid: similarProduct.uuid })),
          ]),
          catchError(error => of(new productActions.FetchSimilarProductsFailedAction(error)))
        );
      })
    )
  );

  /**
   * Dispatches an ProductComparisonAddItemsAction action to add similar products to comparison.
   * If similar products are not yet loaded, fetch them.
   *
   * @returns {Observable<Action>}
   */
  createProductComparisonFromProductUuids$: Observable<Action> = createEffect(
    () => this._actions$.pipe(
      ofType(productComparisonActions.createProductComparisonFromProductsTriggered),
      map(action => action.context),
      withLatestFrom(
        this._store.pipe(select(rootReducer.getLayoutBreakpoint)),
        this._store.pipe(select(productComparisonReducer.getComparisonIsSynced)),
        this._store.pipe(select(productComparisonReducer.getComparisonServerState))
      ),
      mergeMap(([context, breakpoint, comparisonIsSynched, comparisonServerState]) => {
        const returnActions = [];
        if (breakpoint <= Breakpoint.bpMinS) {
          returnActions.push(layoutActions.toggleRightOffCanvas({ rightOffCanvasMode: RightOffCanvasState.hidden }));
        }

        if (!comparisonIsSynched || !comparisonServerState) {
          returnActions.push(productComparisonActions.saveProductComparisonTriggered({ comparisonContext: 'comparison-preview' }));
        }

        returnActions.push(new routerActions.GoAction({
          path: ['products', 'comparison']
        }));

        returnActions.push(productComparisonActions.createProductComparisonFromProductsSuccess({ context }));

        return returnActions;
      }
      )
    )
  );

  /**
   * Saves the comparison after one or more products have been removed and there is more than 1 product left while the
   * user is on the comparison page
   *
   * @returns {Observable<Action>}
   */
  removeProductFromProductComparison$: Observable<Action> = createEffect(
    () => this._actions$.pipe(
      ofType(productComparisonActions.removeProductFromProductComparison),
      withLatestFrom(
        this._store.pipe(select(productComparisonReducer.getComparisonProductUuids)),
        this._store.pipe(
          select(rootReducer.getRouterUrl),
          map(path => path.startsWith('/products/comparison'))
        )
      ),
      filter(([_, comparisonProductUuids, comparisonPageActive]) =>
        comparisonProductUuids !== null && Array.isArray(comparisonProductUuids) && comparisonProductUuids.length > 0 && comparisonPageActive
      ),
      mapTo(productComparisonActions.saveProductComparisonTriggered({}))
    )
  );

  /**
   * Fetches product comparison from server and dispatches either succeeded or failed action afterwards
   *
   * @returns {Observable<Action>}
   */
  loadProductComparison$: Observable<Action> = createEffect(
    () => this._actions$.pipe(
      ofType(productComparisonActions.loadProductComparisonTriggered),
      map(action => action.comparisonUuid),
      mergeMap(comparisonUuid => this._productComparisonService.getComparison(comparisonUuid).pipe(
        map((productComparison) => productComparisonActions.loadProductComparisonSuccess({ productComparison })),
        catchError(error => of(productComparisonActions.loadProductComparisonFailure({error})))
      ))
    )
  );

  /**
   * Restores the query config that is stored with the comparison if the queryUuid != the current queryUuid
   *
   * @returns {Observable<Action>}
   */
  loadProductComparisonSuccess$: Observable<Action> = createEffect(
    () => this._actions$.pipe(
      ofType(productComparisonActions.loadProductComparisonSuccess),
      map(action => action.productComparison),
      withLatestFrom(this._store.pipe(
        select(productReducer.getQueryUuid)
      )),
      filter(([comparisonServerState, queryUuid]) => comparisonServerState.queryUuid !== queryUuid),
      map(([comparisonServerState]) => new productActions.FetchMultiAction({
        count: 24,
        offset: 0,
        restore: true,
        loadInBackground: true,
        queryUuid: comparisonServerState.queryUuid
      }))
    )
  );

  /**
   * Saves a product comparison
   *
   * @returns {Observable<Action>}
   */
  saveProductComparison$: Observable<Action> = createEffect(
    () => this._actions$.pipe(
      ofType(productComparisonActions.saveProductComparisonTriggered),
      withLatestFrom(
        this._store.pipe(
          select(productComparisonReducer.getComparisonProductUuids)
        ),
        this._store.pipe(
          select(productComparisonReducer.getComparisonServerState)
        ),
        this._store.pipe(
          select(productReducer.getQueryUuid)
        )
      ),
      mergeMap(([payload, comparisonProductUuids, comparisonServerState, queryUuid]) => {
        const newComparison: ProductComparison = {
          productUuids: comparisonProductUuids !== null && Array.isArray(comparisonProductUuids) ? comparisonProductUuids : [],
          queryUuid
        };

        if (comparisonServerState && comparisonServerState.uuid) {
          newComparison.previousComparisonUuid = comparisonServerState.uuid;
        }

        return this._productComparisonService.postComparison(newComparison).pipe(
          map((productComparison: ProductComparison) => productComparisonActions.saveProductComparisonSuccess({
            productComparison,
            comparisonContext: payload && payload.comparisonContext ? payload.comparisonContext : undefined
          })),
          catchError(error => of(productComparisonActions.saveProductComparisonFailure({ error })))
        );
      })
    )
  );

  /**
   * Ensures that detail information for comparison products are already in the store or will get fetched
   *
   * @returns {Observable<Action>}
   */
  getDataForComparisonProducts$: Observable<Action> = createEffect(
    () => combineLatest([
      defer(() => this._store.pipe(
        select(productComparisonReducer.getComparisonProductUuids)
      )),
      this._store.pipe(
        select(productReducer.getQueryUuid)
      )
    ]).pipe(
      map(([comparisonProductUuids]) => comparisonProductUuids),
      withLatestFrom(
        this._store.pipe(
          select(productReducer.getProductsWithDetailsLoaded)
        ),
        this._store.pipe(
          select(productReducer.getCurrentScores)
        ),
        this._store.pipe(
          select(productReducer.getQueryConfigHasPropertyFilters)
        )
      ),
      mergeMap(([comparisonProductUuids = [], productItems = {}, productItemScores = {}, queryConfigHasPropertyFilters = false]) => {
        const fetchActions = [];

        if (comparisonProductUuids !== null && Array.isArray(comparisonProductUuids) && comparisonProductUuids.length > 0) {
          comparisonProductUuids.forEach(productUuid => {
            if (!productItems.hasOwnProperty(productUuid)) {
              fetchActions.push(new productActions.FetchSingleAction({ uuid: productUuid }));
            }

            if (queryConfigHasPropertyFilters && !productItemScores.hasOwnProperty(productUuid)) {
              fetchActions.push(new productActions.FetchItemScoreAction(productUuid));
            }
          });
        }

        return fetchActions;
      })
    )
  );

  /**
   * Saves a product comparison analytics event
   *
   * @returns Observable<Action>
   */
  saveProductComparisonSuccess$: Observable<Action> = createEffect(
    () => this._actions$.pipe(
      ofType(productComparisonActions.saveProductComparisonSuccess),
      map(action => {
        this._analyticsEventService.startProductComparison(action.productComparison.uuid,
          action.productComparison.productUuids.length,
          action.comparisonContext ? action.comparisonContext : 'default');
        return productComparisonActions.productComparisonAnalyticsEventSaveSuccess();
      })
    )
  );

  constructor(
    private _actions$: Actions<productActions.Actions|productComparisonActions.Actions>,
    private _store: Store<productComparisonReducer.State | productReducer.State>,
    private _productService: ProductService,
    private _productComparisonService: ProductComparisonService,
    private _analyticsEventService: AnalyticsEventsService
  ) {}
}
