import {
  createReducer,
  on
} from '@ngrx/store';

import * as layoutActions from '../../actions/layout.actions';
import * as productActions from '../../../product/actions/product.actions';
import { RightOffCanvasState } from '../../enum/right-off-canvas-state.enum';
import { Breakpoint } from '../../enum/breakpoint.enum';


export interface StateInterface {
  rightOffCanvasMode: RightOffCanvasState;
  breakpoint: Breakpoint;
}

export const initialState: StateInterface = {
  rightOffCanvasMode: RightOffCanvasState.filter,
  breakpoint: null
};

const layoutReducer = createReducer(
  initialState,
  on(layoutActions.breakpointChangeDetected, (state, { breakpoint }) => ({...state, breakpoint })),
  on(layoutActions.toggleRightOffCanvas, (state, { rightOffCanvasMode }) => ({...state, rightOffCanvasMode})),
  on(productActions.openedProductDetailPage, state => ({...state, rightOffCanvasMode: RightOffCanvasState.hidden})),
  on(productActions.searchWithTempFilterValues, state => {
    if (state.breakpoint >= Breakpoint.bpMinM) {
      return {...state, rightOffCanvasMode: RightOffCanvasState.filter};
    } else {
      return state;
    }
  })
);

export const reducer = (state: StateInterface, action: any) => layoutReducer(state, action);

export const getRightOffCanvasMode = (state: StateInterface) => state.rightOffCanvasMode;
export const getBreakpoint = (state: StateInterface) => state.breakpoint;
