import {
  FileMetaBackendInterface,
  generateFileMetaFromBackendInput
} from '@p1/libs/file';

import {
  CatalogConfig,
  CatalogConfigHost,
  CatalogConfigLinkTile
} from './catalog-config';
import { CatalogConfigBackendInterface } from './catalog-config-backend.interface';


export const generateCatalogConfigFromBackendInput = (input: CatalogConfigBackendInterface, locale: string = 'en-GB'): CatalogConfig => {
  const catalogConfig: CatalogConfig = {
    name: input.name,
    uuid: input.uuid
  };

  if (input.treeUuid) {
    catalogConfig.treeUuid = input.treeUuid;
  }

  if (input.localizedConfigs) {
    const currentLocaleConfig = input.localizedConfigs.find(config => config.locale === locale);
    if (currentLocaleConfig) {
      [
        'homeHeadline',
        'pageTitleSuffix',
        'searchPlaceholder',
        'watchlistShareSubject',
        'watchlistShareContent',
        'supplierShareSubject',
        'supplierShareContent',
        'productShareSubject',
        'productShareContent',
        'comparisonShareSubject',
        'comparisonShareContent'
      ].forEach(localizedStringConfigKey => {
        catalogConfig[localizedStringConfigKey] = currentLocaleConfig.configs.find(config => config.key === localizedStringConfigKey)?.value as string;
      });

      const rollingSearchPlaceholder: string = currentLocaleConfig.configs.find(config => config.key === 'rollingSearchPlaceholder' &&
                                                                                          config.type === 'string')?.value as string;
      catalogConfig.rollingSearchPlaceholder = rollingSearchPlaceholder?.split(/\r?\n/);
      catalogConfig.linkLogoUrl = currentLocaleConfig.configs.find(config => config.key === 'logoUrl')?.value as string;

    }
    const hosts: CatalogConfigHost[] = [];
    const cookieDomains: CatalogConfigHost[] = [];
    input.localizedConfigs.forEach((config) => {
      if (config.configs.find(innerConfig => innerConfig.key === 'domain')) {
        hosts.push({
          locale: config.locale,
          domain: config.configs.find(innerConfig => innerConfig.key === 'domain')?.value as string
        });
      }

      if (config.configs.find(innerConfig => innerConfig.key === 'cookieDomain')) {
        cookieDomains.push({
          locale: config.locale,
          domain: config.configs.find(innerConfig => innerConfig.key === 'cookieDomain')?.value as string
        });
      }
    });
    catalogConfig.hosts = hosts;
    catalogConfig.cookieDomains = cookieDomains;
  }

  if (input.configs) {
    catalogConfig.image = generateFileMetaFromBackendInput(
      input.configs.find(config => config.key === 'cover' && config.type === 'file')?.value as FileMetaBackendInterface);
    catalogConfig.logo = generateFileMetaFromBackendInput(
      input.configs.find(config => config.key === 'logo' && config.type === 'file')?.value as FileMetaBackendInterface);
  }

  if (input.tileConfigs) {
    const linkTiles: CatalogConfigLinkTile[] = [];

    if (input.tileConfigs.find(config => config.locale === locale)?.configs) {
      input.tileConfigs.find(config => config.locale === locale).configs.forEach((tile) => {
        linkTiles.push({
          ad: tile.ad,
          link: tile.link,
          openInNewTab: tile.openInNewTab,
          name: tile.name,
          imageUuid: tile.image
        });
      });
    }

    catalogConfig.linkTiles = linkTiles;
  }

  return catalogConfig;
};
