/**
 * Can compare arrays of primitives
 *
 * @param array1
 * @param array2
 * @returns
 */
export const compareArrays = (array1: any[], array2: any[]) => {
  if (!array1 || !array2) {
    return false;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = array1.length - 1; i >= 0; i--) {
    if (array1[i] instanceof Array && array2[i] instanceof Array) {
      if (!compareArrays(array1[i], array2[i])) {
        return false;
      }
    } else if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
};
