import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

import { UiElementsModule } from '@p1/libs/ui-elements';
import { IconsRegistry } from '@p1/libs/icons/src/lib/registry/icons.registry';
import {
  iconArrowLeft,
  iconArrowRight,
  iconCrossCircledFilled,
  iconSearch,
  IconsModule
} from '@p1/libs/icons';
import { FileService } from '@p1/libs/file';

import { ImgFallbackDirective } from './common/directives/img-fallback/img-fallback.directive';
import { CollapsibleComponent } from './common/components/collapsible/collapsible.component';
import { TileComponent } from './common/components/tile/tile.component';
import { PaginationComponent } from './common/components/pagination/pagination.component';
import { ObjectFilterPipe } from './filter/pipes/object/object-filter.pipe';
import { TextHighlightPipe } from './common/pipes/text-highlight.pipe';
import { P1DateFormatPipe } from './common/pipes/p1-date-format.pipe';
import { InputTextComponent } from './forms/components/input-text/input-text.component';
import { MsDatalinkFallbackDirective } from './common/directives/ms-datalink-fallback/ms-datalink-fallback.directive';
import { EntityOrderingComponent } from './common/components/entity-ordering/entity-ordering.component';
import { SafeHtmlPipe } from './common/pipes/safe-html.pipe';
import { ExcludeIfPipe } from './common/pipes/exclude-if/exclude-if.pipe';
import { TilePlaceholderComponent } from './common/components/tile-placeholder/tile-placeholder.component';
import { EncodeUriPipe } from './common/pipes/encode-uri.pipe';
import { NlToBRPipe } from './common/pipes/nl-to-br.pipe';
import { StripNlPipe } from './common/pipes/strip-nl.pipe';
import { JsonLdBreadcrumbComponent } from './json-ld/components/json-ld-breadcrumb/json-ld-breadcrumb.component';
import { JsonLdScriptComponent } from './json-ld/components/json-ld-script/json-ld-script.component';
import { JsonLdSupplierComponent } from './json-ld/components/json-ld-supplier/json-ld-supplier.component';
import { ExternalContentFeedbackDialogComponent } from './common/components/external-content-feedback-dialog/external-content-feedback-dialog.component';
import { CategoryTileComponent } from './common/components/category-tile/category-tile.component';
import { NotFoundPageComponent } from './common/components/404/not-found-page.component';
import { LinkTileComponent } from './common/components/link-tile/link-tile.component';
import { IconCheckboxComponent } from './forms/components/icon-checkbox/icon-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    IconsModule,
    UiElementsModule
  ],
  providers: [
    FileService
  ],
  declarations: [
    CollapsibleComponent,
    EncodeUriPipe,
    EntityOrderingComponent,
    ExcludeIfPipe,
    ImgFallbackDirective,
    InputTextComponent,
    JsonLdBreadcrumbComponent,
    JsonLdScriptComponent,
    JsonLdSupplierComponent,
    MsDatalinkFallbackDirective,
    NlToBRPipe,
    NotFoundPageComponent,
    ObjectFilterPipe,
    P1DateFormatPipe,
    PaginationComponent,
    SafeHtmlPipe,
    StripNlPipe,
    TextHighlightPipe,
    TileComponent,
    TilePlaceholderComponent,
    ExternalContentFeedbackDialogComponent,
    CategoryTileComponent,
    LinkTileComponent,
    IconCheckboxComponent
  ],
  exports: [
    CollapsibleComponent,
    EncodeUriPipe,
    EntityOrderingComponent,
    ExcludeIfPipe,
    IconsModule,
    ImgFallbackDirective,
    InputTextComponent,
    JsonLdBreadcrumbComponent,
    JsonLdScriptComponent,
    JsonLdSupplierComponent,
    MsDatalinkFallbackDirective,
    NotFoundPageComponent,
    ObjectFilterPipe,
    P1DateFormatPipe,
    PaginationComponent,
    SafeHtmlPipe,
    TextHighlightPipe,
    TileComponent,
    TilePlaceholderComponent,
    UiElementsModule,
    ExternalContentFeedbackDialogComponent,
    CategoryTileComponent,
    LinkTileComponent,
    IconCheckboxComponent
  ]
})
export class SharedModule {
  constructor(private _iconsRegistry: IconsRegistry) {
    this._iconsRegistry.registerIcons([
      iconArrowLeft,
      iconArrowRight,
      iconCrossCircledFilled,
      iconSearch
    ]);
  }
}
