import { Injectable } from '@angular/core';
import {
  combineLatest,
  Observable
} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  select,
  Store
} from '@ngrx/store';

import * as authReducer from '../../auth/reducer/auth.reducer';
import { NavigationSection } from './navigation-section.interface';
import { environment } from '../../../environments/environment';
import { SupplierAnalyticsRoles } from '../../supplier-analytics/enums/supplier-analytics-roles.enum';


@Injectable({
  providedIn: 'root'
})
export class SupplierAnalyticsNavigationBarItemsService {

  supplierAnalyticsNavigationSection$: Observable<NavigationSection>;
  hasSupplierAnalytics: boolean;


  private _saDashboardNavItem = {
    title: $localize`:|supplier analytics navigation item dashboard @@navigation-bar.component_user-nav-item-dashboard:Dashboard`,
    name: 'dashboard',
    routerLink: ['/company', 'analytics', 'dashboard']
  };

  private _saPortfolioDashboardNavItem = {
    title: $localize`:|supplier analytics navigation item portfolio dashboard @@navigation-bar.component_user-nav-item-portfolio-dashboard:Portfolio dashboard`,
    routerLink: ['/company', 'analytics', 'portfolio', 'dashboard']
  };

  private _saPortfolioCategoryOverviewNavItem = {
    title: $localize`:|supplier analytics navigation item category overview @@navigation-bar.component_user-nav-item-category-overview:Category overview`,
    routerLink: ['/company', 'analytics', 'portfolio', 'categories']
  };

  private _saPortfolioInQaNavItem = {
    title: $localize`:|supplier analytics navigation item products for approval @@navigation-bar.component_user-nav-item-products-for-approval:Products for approval`,
    routerLink: ['/company', 'analytics', 'portfolio', 'qa']
  };

  private _saPortfolioBenchmarkNavItem = {
    title: $localize`:|supplier analytics navigation item portfolio benchmark @@navigation-bar.component_user-nav-item-portfolio-benchmark:Portfolio benchmark`,
    routerLink: ['/company', 'analytics', 'portfolio', 'benchmark']
  };

  private _saPortfolioProductDetailsNavItem = {
    title: $localize`:|supplier analytics navigation item product detail analysis @@navigation-bar.component_user-nav-item-product-detail-analysis:Product detail analysis`,
    routerLink: ['/company', 'analytics', 'portfolio', 'product']
  };

  private _saPortfolioNavItem = {
    title: $localize`:|supplier analytics navigation item my portfolio @@navigation-bar.component_user-nav-item-my-portfolio:My Portfolio`,
    name: 'portfolio',
    routerLink: ['/company', 'analytics', 'portfolio'],
    secondLevelItems: []
  };

  private _saEnterpriseBenchmarkNavItem = {
    title: $localize`:|supplier analytics navigation item competitive analysis @@navigation-bar.component_user-nav-item-competitive-analysis:Competitive analysis`,
    name: 'enterprise benchmark',
    routerLink: ['/company', 'analytics', 'benchmark']
  };

  private _saPerformanceSupplierNavItem = {
    title: $localize`:|supplier analytics navigation item performance dashboard @@navigation-bar.component_user-nav-item-performance-dashboard:Performance dashboard`,
    routerLink: ['/company', 'analytics', 'performance', 'supplier']
  };

  private _saPerformanceProductNavItem = {
    title: $localize`:|supplier analytics navigation item product performance @@navigation-bar.component_user-nav-item-product-performance:Product performance`,
    routerLink: ['/company', 'analytics', 'performance', 'product']
  };

  private _saPerformanceNavItem = {
    title: $localize`:|supplier analytics navigation item performance @@navigation-bar.component_user-nav-item-performance:Performance`,
    name: 'performance',
    routerLink: ['/company', 'analytics', 'performance'],
    secondLevelItems: []
  };

  private _saDemandDataNavItem = {
    title: $localize`:|supplier analytics navigation item demand data analysis @@navigation-bar.component_user-nav-item-demand-data-analysis:Demand data analysis`,
    name: 'enterprise benchmark',
    routerLink: ['/company', 'analytics', 'demand']
  };

  private _saLeadsNavItem = {
    title: $localize`:|supplier analytics navigation item leads @@navigation-bar.component_user-nav-item-leads:Leads`,
    name: 'leads',
    routerLink: ['/company', 'analytics', 'leads']
  };

  constructor(
    private _store: Store<authReducer.StateInterface>
  ) {
    /**
     * Generates the UserNavigationSection
     */
    this.supplierAnalyticsNavigationSection$ = combineLatest([
      this._store.pipe(
        select(authReducer.getUser),
        map(user => user ? user.name : null)
      ),
      this._store.pipe(
        select(authReducer.getAnalyticsRoles)
      )
    ]).pipe(
      map(([userName, analyticsRoles]) => {
        const supplierAnalyticsNavigationSection: NavigationSection = {
          title: userName,
          firstLevelItems: []
        };

        if (analyticsRoles && environment.featureFlags.supplierAnalytics) {
          supplierAnalyticsNavigationSection.firstLevelItems.push(
            this._saDashboardNavItem
          );
          if (analyticsRoles.includes(SupplierAnalyticsRoles.PortfolioDashboard)) {
            this._saPortfolioNavItem.secondLevelItems = [];
            supplierAnalyticsNavigationSection.firstLevelItems.push(
              this._saPortfolioNavItem
            );
            this._saPortfolioNavItem.secondLevelItems.push(
              this._saPortfolioDashboardNavItem
            );
          }
          if (analyticsRoles.includes(SupplierAnalyticsRoles.CategoryDashboard)) {
            this._saPortfolioNavItem.secondLevelItems.push(
              this._saPortfolioCategoryOverviewNavItem
            );
          }
          if (analyticsRoles.includes(SupplierAnalyticsRoles.ProductsInQA)) {
            this._saPortfolioNavItem.secondLevelItems.push(
              this._saPortfolioInQaNavItem
            );
          }
          if (analyticsRoles.includes(SupplierAnalyticsRoles.PortfolioBenchmark)) {
            this._saPortfolioNavItem.secondLevelItems.push(
              this._saPortfolioBenchmarkNavItem
            );
          }
          if (analyticsRoles.includes(SupplierAnalyticsRoles.ProductDetailAnalysis)) {
            this._saPortfolioNavItem.secondLevelItems.push(
              this._saPortfolioProductDetailsNavItem
            );
          }
          if (analyticsRoles.includes(SupplierAnalyticsRoles.CompetitiveAnalysis)) {
            supplierAnalyticsNavigationSection.firstLevelItems.push(
              this._saEnterpriseBenchmarkNavItem
            );
          }
          if (analyticsRoles.includes(SupplierAnalyticsRoles.PerformanceDashboard)) {
            this._saPerformanceNavItem.secondLevelItems = [];
            supplierAnalyticsNavigationSection.firstLevelItems.push(
              this._saPerformanceNavItem
            );
            this._saPerformanceNavItem.secondLevelItems.push(
              this._saPerformanceSupplierNavItem
            );
          }
          if (analyticsRoles.includes(SupplierAnalyticsRoles.PerformancePerProduct)) {
            this._saPerformanceNavItem.secondLevelItems.push(
              this._saPerformanceProductNavItem
            );
          }
          if (analyticsRoles.includes(SupplierAnalyticsRoles.DemandDataDashboard)) {
            supplierAnalyticsNavigationSection.firstLevelItems.push(
              this._saDemandDataNavItem
            );
          }

          supplierAnalyticsNavigationSection.firstLevelItems.push(
            this._saLeadsNavItem
          );
        }

        return supplierAnalyticsNavigationSection;
      })
    );

    this._store.pipe(
      select(authReducer.getUser),
      map(user => user && user.companyUuid && user.supplierAreaAccess ? user.supplierAreaAccess : false)
    ).subscribe(hasAnalytics => {
      this.hasSupplierAnalytics = hasAnalytics;
    });
  }
}
