import {
  Inject,
  Injectable,
  LOCALE_ID
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  select,
  Store
} from '@ngrx/store';

import { environment } from '../../../environments/environment';
import * as categoryTreeReducer from '../../category-tree/reducer';
import { ProductComparison } from '../model/product-comparison';
import { ProductComparisonBackendInterface } from '../model/product-comparison.backend-interface';
import { generateProductComparisonFromBackendInput } from '../model/product-comparison.generators';


/**
 * Contains server actions for the product comparison
 */
@Injectable()
export class ProductComparisonService {

  private _treeUuid: string;

  constructor(private _httpClient: HttpClient, @Inject(LOCALE_ID) private _locale: string, private _store: Store<categoryTreeReducer.State>
  ) {
    this._store.pipe(
      select(categoryTreeReducer.getCategoryTreeUuid)
    ).subscribe(treeUuid => this._treeUuid = treeUuid);
  }


  /**
   * Fetches a comparison object with the given uuid
   *
   * @param uuid - the comparison uuid
   * @returns
   */
  getComparison(uuid: string) {
    let url = `${environment.api_url}products/comparisons/${uuid}?locale=${this._locale}`;

    if (this._treeUuid) {
      url += `&treeUuid=${ this._treeUuid }`;
    }

    if (environment.catalogIdentifier) {
      url += `&catalogIdentifier=${ environment.catalogIdentifier }`;
    }

    return this._httpClient.get<{comparison: ProductComparisonBackendInterface}>(
      url
    ).pipe(
      map(response => response.comparison ? generateProductComparisonFromBackendInput(response.comparison) : undefined)
    );
  }

  /**
   * Posts the given comparison
   *
   * @param comparison
   * @returns
   */
  postComparison(comparison: ProductComparison): Observable<ProductComparison> {
    const beComparison = {
      uuid: comparison.uuid,
      previous_comparison_uuid: comparison.previousComparisonUuid,
      product_uuids: comparison.productUuids,
      query_uuid: comparison.queryUuid
    };

    let url = `${environment.api_url}products/comparisons?locale=${this._locale}`;

    if (this._treeUuid) {
      url += `&treeUuid=${ this._treeUuid }`;
    }

    if (environment.catalogIdentifier) {
      url += `&catalogIdentifier=${ environment.catalogIdentifier }`;
    }

    return this._httpClient.post<{comparison: ProductComparisonBackendInterface}>(
      url,
      beComparison
    ).pipe(
      map(response => response.comparison ? generateProductComparisonFromBackendInput(response.comparison) : undefined)
    );
  }
}
