import {
  Inject,
  Injectable
} from '@angular/core';

import {
  COOKIEHANDLING_SERVICE,
  CookiehandlingServiceInterface
} from '@p1/libs/cookiehandling';


@Injectable()
export class NewsCookieService {

  private visitedAmountName = 'p1_news_visited_amount';
  private newsReadName = 'p1_news_read_ids';

  constructor(@Inject(COOKIEHANDLING_SERVICE) private _cookiehandlingService: CookiehandlingServiceInterface
  ) {}

  getVisitCount = () => Number(this._cookiehandlingService.read(this.visitedAmountName));

  getNewsReadIds = (): number[] => {
    const readNewsIds = this._cookiehandlingService.read(this.newsReadName);

    let convertedReadNewsIds = [];

    if (readNewsIds) {
      convertedReadNewsIds = readNewsIds.split(',').map(id => Number(id));
    }

    return convertedReadNewsIds;
  };

  increaseVisitCount = () => {
    this._cookiehandlingService.write(this.visitedAmountName, String(Number(this.getVisitCount()) + 1));
  };

  addNewsReadId = (newsId: number) => {
    const newsReadIds = this.getNewsReadIds();
    newsReadIds.push(newsId);
    const uniqueIds = new Set(newsReadIds);
    this._cookiehandlingService.write(this.newsReadName, Array.from(uniqueIds).join(','));
  };
}
