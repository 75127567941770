import * as productActions from '../../../actions/product.actions';


export const defaultOrderingValueRange: { label: string; value: string }[] = [
  {
    label: $localize`:|product ordering random @@ordering-value-range_random:Random`,
    value: 'product_random'
  },
  {
    label: $localize`:|product ordering newest @@ordering-value-range_newest:Newest`,
    value: 'product_newest desc'
  },
  {
    label: $localize`:|product ordering oldest @@ordering-value-range_oldest:Oldest`,
    value: 'product_newest'
  },
  {
    label: $localize`:|product ordering alphabetical @@ordering-value-range_alphabetical:Products A-Z`,
    value: 'product_name'
  },
  {
    label: $localize`:|product ordering alphabetical reverse @@ordering-value-range_alphabetical-reverse:Products Z-A`,
    value: 'product_name desc'
  },
  {
    label: $localize`:|product ordering alphabetical supplier @@ordering-value-range_alphabetical-supplier:Supplier A-Z`,
    value: 'supplier_name'
  },
  {
    label: $localize`:|product ordering alphabetical supplier reverse @@ordering-value-range_alphabetical-supplier-reverse:Supplier Z-A`,
    value: 'supplier_name desc'
  }
];


/**
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 * @returns the new state
 */
export const orderingValueRange = (
  state: { label: string; value: string }[] = defaultOrderingValueRange,
  action: productActions.Actions
): { label: string; value: string }[] => {
  switch (action.type) {
    default:
      return state;
  }
};
