import * as productActions from '../../../actions/product.actions';


/**
 * tempBasicFilterValues reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 * @returns the new state
 */
export const tempBasicFilterValues = (state: {[filterId: string]: FilterValue}, action: productActions.Actions): {[filterId: string]: FilterValue} => {
  switch (action.type) {
    case productActions.UPDATE_TEMP_BASIC_FILTER_VALUES:
      return action.payload;
    case productActions.FETCH_MULTI_SUCCEEDED:
      if (action.payload.queryConfig) {
        const { basic_filters } = action.payload.queryConfig;

        if (basic_filters && basic_filters.length > 0) {
          const result = {};

          basic_filters.forEach(basicFilterRequestValue => {
            result[basicFilterRequestValue.id] = basicFilterRequestValue.value;
          });

          return result;
        }
      }

      return undefined;
    default:
      return state;
  }
};
