<p1-lib-ui-elements-modal
  [closeable]="true"
  [contentIsScrollable]="false"
  [autoFocus]="false"
  [leftBottom]="true"
  [context]="'SchuecoStahlContactModal'"
  (closeModal)="closeDialog.emit()">
  <ng-container modal-header>
    <h2 class="gr-12" i18n="Unanswered Questions Modal // page: all @@user-idle-dialog.component_modal-header">Any unanswered questions?</h2>
  </ng-container>
  <ng-container modal-content>
    <div class="container contact-dialog-content">
      <div class="row">
        <p class="gr-12" i18n="Unanswerd questions modal content text // page: all @@user-idle-dialog.component_modal-content-text">
          Do you have further questions about a product? You are welcome to contact us and we will discuss your request.</p>
        <div class="gr-12">
          <div class="row spacing-down--20">
            <div class="gr-5 gr-4@s">
              <label>
                <span i18n="Unanswerd questions modal email // page: all @@user-idle-dialog.component_modal-content-email">Email:</span>
              </label>
            </div>
            <div class="gr-7 gr-8@s">
              <a href="mailto:stahl_one@schueco.com">stahl_one@schueco.com</a>
            </div>
          </div>
        </div>
        <div class="gr-12">
          <div class="row spacing-down--20">
            <div class="gr-5 gr-4@s">
              <label>
                <span i18n="Unanswerd questions modal hone // page: all @@user-idle-dialog.component_modal-content-phone">Phone:</span>
              </label>
            </div>
            <div class="gr-7 gr-8@s">
              <a href="tel:+49 521 7831251">+49 521 7831251</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</p1-lib-ui-elements-modal>
