import { AllClusterSearchResult } from './all-cluster-search-result';
import { AllClusterSearchResultBackendInterface } from './all-cluster-search-result.backend-interface';
import { generateProductSearchResultFromBackendInput } from '../product-search-result';
import { generateSupplierSearchResultFromBackendInput } from '../supplier-search-result';
import { generateCategorySearchResultFromBackendInput } from '../category-search-result';


export const generateAllClusterSearchResultFromBackendInput = (input: AllClusterSearchResultBackendInterface): AllClusterSearchResult => {
  const result: AllClusterSearchResult = {};

  if (input.Products) {
    result.products = generateProductSearchResultFromBackendInput(input.Products, 'products');
  }
  if (input.Supplier) {
    result.suppliers = generateSupplierSearchResultFromBackendInput(input.Supplier, 'suppliers');
  }
  if (input.Categories) {
    result.categories = generateCategorySearchResultFromBackendInput(input.Categories, 'categories');
  }

  if (input.TotalCounts) {
    result.totalCounts = {};

    const { Categories, Supplier, Products, ProductsPerCategory } = input.TotalCounts;
    if (Products !== undefined) {
      result.totalCounts.products = Products;
    }
    if (Categories !== undefined) {
      result.totalCounts.categories = Categories;
    }
    if (Supplier !== undefined) {
      result.totalCounts.suppliers = Supplier;
    }
    if (ProductsPerCategory) {
      result.totalCounts.productsPerCategory = ProductsPerCategory;
    }
  }

  return result;
};
