import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers } from './reducer';
import { CategoryTreeEffects } from './effects/category-tree.effects';


@NgModule({
  imports: [
    StoreModule.forFeature('categoryTree', reducers ),
    EffectsModule.forFeature([
      CategoryTreeEffects
    ])
  ],
  providers: []
})
export class CategoryTreeStoreModule {}
