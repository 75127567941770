import {
  Action,
  createAction,
  props
} from '@ngrx/store';

import {
  CategorySearchResult,
  ProductSearchResult,
  SupplierSearchResult
} from '../model-result';


export const searchResultPageOpened = createAction(
  '[SEARCHPAGE] Result page opened',
  props<{searchTerm: string}>()
);

export const categoryDetailSearchResultsLoaded = createAction(
  '[SEARCHAPI] Category detail results loaded',
  props<{
    categories: CategorySearchResult[];
    totalCounts: {
      categories?: number;
      productsPerCategory?: {
        [uuid: string]: number;
      };
    };
  }>()
);

export const supplierDetailSearchResultsLoaded = createAction(
  '[SEARCHAPI] Supplier detail results loaded',
  props<{suppliers: SupplierSearchResult[]; totalCounts: {suppliers?: number}}>()
);


/**
 * Fetches searchResults for searchBar for all cluster
 *
 * @type {string}
 */
export const FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS = '[SEARCH_BAR_ALL_CLUSTER_SEARCH] Fetch all cluster';
export class FetchSearchBarAllClusterSearchResultsAction implements Action {
  readonly type = FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS;

  constructor(
    public payload: {
      searchTerm: string;
      supplierUuids?: string[];
      categoryUuids?: string[];
      analyticsCall?: boolean;
    }
  ) {}
}

/**
 * Contains fetched searchResults for searchBar
 *
 * @type {string}
 */
export const FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS_SUCCEEDED = '[SEARCH_BAR_ALL_CLUSTER_SEARCH] Fetch all cluster succeeded';
export class FetchSearchBarAllClusterSearchResultsSucceededAction implements Action {
  readonly type = FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS_SUCCEEDED;

  constructor(
    public payload: {
      products?: ProductSearchResult[];
      suppliers?: SupplierSearchResult[];
      categories?: CategorySearchResult[];
      totalCounts?: {
        products?: number;
        suppliers?: number;
        categories?: number;
      };
    }
  ) {}
}

/**
 * Contains an error that occured while fetching searchResults for searchBar
 *
 * @type {string}
 */
export const FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS_FAILED = '[SEARCH_BAR_ALL_CLUSTER_SEARCH] Fetch all cluster failed';
export class FetchSearchBarAllClusterSearchResultsFailedAction implements Action {
  readonly type = FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS_FAILED;

  constructor(public payload: any) {}
}

/**
 * Clears current searchResults for searchBar from state
 */
export const CLEAR_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS = '[SEARCH_BAR_ALL_CLUSTER_SEARCH] Clear all results';
export class ClearSearchBarAllClusterSearchResultsAction implements Action {
  readonly type = CLEAR_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS;
}


export type Actions = FetchSearchBarAllClusterSearchResultsAction
  | FetchSearchBarAllClusterSearchResultsSucceededAction
  | FetchSearchBarAllClusterSearchResultsFailedAction
  | ReturnType<typeof searchResultPageOpened>
  | ReturnType<typeof categoryDetailSearchResultsLoaded>
  | ReturnType<typeof supplierDetailSearchResultsLoaded>
  | ClearSearchBarAllClusterSearchResultsAction;
