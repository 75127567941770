export enum SupplierAnalyticsRoles {
  PortfolioDashboard    = 'portfolioDashboard',
  CategoryDashboard     = 'categoryDashboard',
  ProductsInQA          = 'productsInQA',
  PortfolioBenchmark    = 'portfolioBenchmark',
  ProductDetailAnalysis = 'productDetailAnalysis',
  CompetitiveAnalysis   = 'competitiveAnalysis',
  PerformanceDashboard  = 'performanceDashboard',
  PerformancePerProduct = 'performancePerProduct',
  DemandDataDashboard   = 'demandDataDashboard',
  Leads                 = 'leads'
}
