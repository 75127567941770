import * as productActions from '../../../actions/product.actions';


/**
 * fetchFilterInProgress reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const fetchFilterInProgress = (state: boolean = false, action: productActions.Actions): boolean => {
  switch (action.type) {
    case productActions.FETCH_FILTER:
      return true;
    case productActions.FETCH_FILTER_FAILED:
    case productActions.FETCH_FILTER_SUCCEEDED:
      return false;
    default:
      return state;
  }
};
