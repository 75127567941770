import { FileContainer } from './file-container';
import { FileContainerBackendInterface } from './file-container.backend-interface';


/**
 * Generates a FileContainer based on api input
 *
 * @param input
 * @returns
 */
export const generateFileContainerFromBackendInput = (input: FileContainerBackendInterface): FileContainer => {
  try {
    const fileContainer: FileContainer = {
      content: input.Content,
      fileSize: input.FileSize,
      lastUpdated: input.LastUpdated,
      name: input.Name,
      contentType: input.ContentType,
      uuid: input.Uuid,
      fileId: input.FileId
    };

    if (input.ContentAlternatives) {
      fileContainer.contentAlternatives = input.ContentAlternatives;
    }

    if (input.Options) {
      fileContainer.options = input.Options;
    }

    if (input.OriginalSource) {
      fileContainer.originalSource = input.OriginalSource;
    }

    return fileContainer;
  } catch (e) {
    return null;
  }
};
