<div class="comparison-menu">
  <div class="comparison-menu__trigger-area">
    <button p1Button
            class="comparison-menu__trigger"
            title="Comparison"
            [ngClass]="{ 'is-active' : isMenuOpen }"
            (click)="toggleMenu()"
            i18n-title="title for comparison dropdown // page: every page @@comparison-menu.component_button_title">
      <i class="icon">
        <p1-lib-icons-icon-wrapper name="iconComparison"></p1-lib-icons-icon-wrapper>
      </i>
    </button>
    <p1-lib-ui-elements-counter-badge
      *ngIf="(products$ | async)"
      [count]="(products$ | async)?.length">
    </p1-lib-ui-elements-counter-badge>
  </div>
  <div [@comparisonContent]="isMenuOpen ? 'is-open' : 'is-closed'"
       [ngClass]="{'comparison-menu__dropdown--align-right': boxIsOutOfViewport}"
       class="comparison-menu__dropdown"
       #comparisonMenuDropdown>

    <div class="comparison-menu__inner">
      <div *ngIf="(products$ | async) === false || (products$ | async) === null || (products$ | async) === undefined"
           class="product-area--empty">
        <div class="empty__container">
          <div class="empty__illustration spacing-down--base">
            <p1-lib-icons-icon-wrapper name="illustrationComparison"></p1-lib-icons-icon-wrapper>
          </div>
          <p class="empty__message">
            <ng-container i18n="message, if no products in comparison menu // page: every page @@comparison-menu.component_no-products-to-compare">
              To add products to a comparison you can use the
              <b>compare-button</b>
              <span class="empty__icon">
                <i class="icon">
                  <p1-lib-icons-icon-wrapper name="iconComparison"></p1-lib-icons-icon-wrapper>
                </i>
              </span>
              on each product tile.
            </ng-container>
            <ng-container *ngIf="featureFlags.similarProducts"
                          i18n="Add similar products to comparison hint, if no products in comparison menu // page: every page @@comparison-menu.component_add-similar-products-hint-empty">
              Once you have added one product you can fill the product comparison with three similar products.
            </ng-container>
          </p>
        </div>
      </div>

      <div *ngIf="products$ | async"
           class="product-area">
        <ul class="list--without-dots product-list">
          <li *ngFor="let product of products$ | async;
                  let i = index"
              class="product-list__item">

            <p1-comparison-menu-product-tile
              [product]="product"
              [productTreeCategoryMapping]="productTreeCategoryMapping$ | async"
              (removeProduct)="onRemoveProduct($event)">
            </p1-comparison-menu-product-tile>
          </li>
        </ul>

        <ng-container *ngIf="(products$ | async)?.length === 1">
          <div *ngFor="let product of products$ | async | slice:0:1;">
            <div class="product-area--similar">
              <div *ngIf="(products$ | async).length <= 1"
                   class="empty__container">
                <div class="empty__illustration"
                     [ngClass]="{'spacing-down--base': featureFlags.similarProducts}">
                  <p1-lib-icons-icon-wrapper name="illustrationComparisonSimilar"></p1-lib-icons-icon-wrapper>
                </div>
                <ng-container *ngIf="featureFlags.similarProducts">
                  <p class="empty__message"
                     i18n="message, if a product is on list and similar products are available // page: every page @@comparison-menu.component_add-similar-products-hint">
                    Fill the product comparison with three similar products.
                  </p>
                  <button p1Button
                          buttonType="secondary"
                          (click)="addSimilarProductsToComparison(product)"
                          data-gtm-event-category="ProductComparison"
                          [attr.data-gtm-event-action]="'Add similar products to product id - ID ' + product.uuid"
                          data-gtm-event-label="Header - Compare - Add similar products"
                          i18n="load similar products btn in comparison-menu // page: every page @@comparison-menu.component_add-similar-products-button">
                    Add similar products
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="products$ | async"
           class="cta-area">
        <div class="cta-area__reset">
          <div class="cta-area__reset--btn-wrapper">
            <button p1Button
                    [buttonType]="['link-style', 'small']"
                    class="cta-area__button-reset"
                    (click)="clearProducts()"
                    data-gtm-event-category="ProductComparison"
                    data-gtm-event-action="Remove all products"
                    data-gtm-event-label="Header - Compare - Remove all products"
                    i18n="remove all products button in  comparison-menu // page: every page @@comparison-menu.component_remove-all-products-button">
              <i class="icon">
                <p1-lib-icons-icon-wrapper name="iconBin"></p1-lib-icons-icon-wrapper>
              </i>
              Remove all products
            </button>
          </div>
        </div>
        <button p1Button
                class="cta-area__button-compare"
                (click)="compareProducts()"
                data-gtm-event-category="ProductComparison"
                [attr.data-gtm-event-action]="'Start compare - Productcount ' + (products$ | async)?.length"
                data-gtm-event-label="Header - Compare - Start compare"
                i18n="compare-button in comparison-menu // page: product list, product-detail @@comparison-canvas-tab.component_compare-button">
          Compare products
        </button>
      </div>
    </div>
  </div>
</div>
