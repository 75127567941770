export const uncaughtErrorMessage = {
  headline  : $localize`:|uncaught error headline @@custom-error-messages.constant_uncaught-error-headline:Oops…`,
  text      : $localize`:|uncaught error text @@custom-error-messages.constant_uncaught-error-text:unfortunately something went wrong. But there is a simple solution: reload the page and everything should work just fine.<br><br>Thanks`,
  buttonText: $localize`:|uncaught error button @@custom-error-messages.constant_uncaught-error-button:Reload the page`,
  contact   : $localize`:|uncaught error contact @@custom-error-messages.constant_uncaught-error-contact:If the error keeps occuring, please send us an e-mail at <a href="mailto:support@plan.one" style="white-space: nowrap">support@plan.one</a> or just call us <a href="tel:+49521999970" style="white-space: nowrap">+49 521 9999 70</a>.`
};

export const serverErrorMessage = {
  headline  : $localize`:|server error headline @@custom-error-messages.constant_server-error-headline:We make our service even better`,
  text      : $localize`:|server error text @@custom-error-messages.constant_server-error-text:We will be back in few minutes with even more construction products.`,
  buttonText: $localize`:|server error button @@custom-error-messages.constant_server-error-button:Try again`,
  contact   : $localize`:|server error contact @@custom-error-messages.constant_server-error-contact:If we are not available longer, please send us an e-mail at <a href="mailto:support@plan.one" style="white-space: nowrap">support@plan.one</a> or just call us <a href="tel:+49521999970" style="white-space: nowrap">+49 521 9999 70</a>.`
};
