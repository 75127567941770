import {
  Component
} from '@angular/core';
import {
  select,
  Store
} from '@ngrx/store';
import {
  Observable
} from 'rxjs';

import * as authReducer from '../../auth/reducer/auth.reducer';
import { environment } from '../../../environments/environment';

/**
 * component representing starting point for watchlist
 */
@Component({
  selector: 'p1-watchlist-menu',
  templateUrl: './watchlist-menu.component.html',
  styleUrls: ['./watchlist-menu.component.scss']
})

export class WatchlistMenuComponent {

  isAuthenticated$: Observable<boolean>;
  featureFlags = environment.featureFlags;

  constructor(
    private _store: Store<authReducer.StateInterface>
  ) {
    this.isAuthenticated$ = this._store.pipe(
      select(authReducer.getIsAuthenticated)
    );
  }

  blurLink(event) {
    const target = event.target || event.srcElement;

    target.blur();
  }
}
