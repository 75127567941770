import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';

import { reducers } from './reducer';
import { ProductEffects } from './effects/product.effects';
import { ProductService } from './service/product.service';
import { ProductGeneratorService } from './service/product.generator-service';
import { CategoryTreeStoreModule } from '../category-tree/category-tree.store.module';


@NgModule({
  imports: [
    CategoryTreeStoreModule,
    StoreModule.forFeature('product', reducers),
    EffectsModule.forFeature([ProductEffects])
  ],
  declarations: [],
  providers: [
    ProductService,
    ProductGeneratorService
  ],
  exports: []
})
export class ProductStoreModule {}
