import {
  Inject,
  Injectable
} from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl
} from '@angular/platform-browser';

import { WindowRef } from '@p1/libs/browser-api-wrapper';

import {
  FILE_SERVICE_CONFIG,
  FileConfigInterface
} from '../config/file-config.interface';
import { ImageMode } from '../enum/image-mode.enum';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private _domSanitizer: DomSanitizer,
    private _windowRef: WindowRef,
    @Inject(FILE_SERVICE_CONFIG) private _config: FileConfigInterface
  ) { }

  /**
   * Creates a valid file url
   *
   * @param uuid - the image id
   * @returns - file url
   */
  getFileUrl(uuid: string): string {
    if (!uuid) {
      return;
    }
    return this._config.fileApiUrl + 'files/' + uuid;
  }

  /**
   * Creates a valid image url
   *
   * @param uuid - the image id
   * @param width - image width
   * @param height - image height
   * @param mode - CROP | PAD
   * @param backgroundColor
   * @param quality
   * @returns - image url
   */
  getImageUrl(uuid: string, width: number, height: number, mode: ImageMode, backgroundColor?: string, quality?: number): string {
    if (!uuid) {
      return;
    }

    let url = `${ this._config.fileApiUrl }images/${ uuid }`;

    const queryParams = {
      quality: quality ?? 80,
      background: 'white',
      mode,
      width,
      height
    };

    if (this._windowRef.nativeWindow.devicePixelRatio > 1) {
      queryParams.width *= 2;
      queryParams.height *= 2;
    }

    if (backgroundColor) {
      queryParams.background = backgroundColor;
    }

    for (const param in queryParams) {
      if (queryParams.hasOwnProperty(param) && queryParams[param]) {
        url += `${ url.indexOf('?') > -1 ? '&' : '?' }${ param }=${ encodeURIComponent(queryParams[param]) }`;
      }
    }

    return url;
  }

  /**
   * disables Angular's built-in sanitization for the YouTube embed URL generated with YouTube ID passed in.
   *
   * @param id  - YouTube Video ID
   * @returns  - YouTube embed URL with disabled Angular's built-in sanitization
   */
  getYouTubeEmbedUrl(id: string): SafeResourceUrl {
    return this._domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/' + id + '?rel=0');
  }

  /**
   * Returns url for a placeholder image for the given youtube video id
   *
   * @param id - YouTube Video ID
   * @returns
   */
  getYoutubePlaceholderImageUrl(id: string): string {
    return `https://i1.ytimg.com/vi/${ id }/default.jpg`;
  }

}
