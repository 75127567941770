import {
  Component,
  Input,
  TemplateRef
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable
} from 'rxjs';
import {
  filter,
  map
} from 'rxjs/operators';

import { PortalOutletName } from '../../enums/portal-outlet-name.enum';
import { PortalService } from '../../services/portal/portal.service';


@Component({
  selector: 'p1-lib-ui-elements-portal-outlet',
  templateUrl: './portal-outlet.component.html',
  styleUrls: ['./portal-outlet.component.scss']
})
export class PortalOutletComponent {
  @Input()
  set portalName(portalName: PortalOutletName) {
    this._portalName$.next(portalName);
  }

  portalContent$: Observable<TemplateRef<any>>;

  private _portalName$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private _portalService: PortalService) {
    this.portalContent$ = combineLatest([
      this._portalService.portalContent$,
      this._portalName$
    ]).pipe(
      filter(([message, name]) => message && name && message.targetOutlet === name),
      map(([message]) => message.templateRef)
    );
  }
}
