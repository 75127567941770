<div *ngIf="product"
     class="item"
     [ngClass]="{'item--loading': !product.name}">
  <a [routerLink]="['/products', product.urlPathSegment]">
    <div class="item__image-wrapper">
      <img p1Img
           [fileId]="product.primaryImage?.fileId"
           [fallback]="'assets/images/placeholder-image/placeholder-product--small.svg'"
           width="60"
           height="60"
           loading="lazy">
    </div>
    <div class="item__info-wrapper">
      <div class="item__category">
        {{ getCategoryNameForProduct(product) }}
      </div>
      <div class="item__name">
        {{ product.name }}
      </div>
      <div class="item__supplier">
        {{ product.supplier?.name }}
      </div>
    </div>
  </a>

  <button p1Button
          (click)="removeProduct.emit(product.uuid)"
          class="item__remove-button"
          data-gtm-event-category="ProductComparison"
          [attr.data-gtm-event-action]="'Remove product compare - ID ' + product.uuid"
          data-gtm-event-label="Header - Compare - Remove compare">
    <i class="icon">
      <p1-lib-icons-icon-wrapper name="iconBin"></p1-lib-icons-icon-wrapper>
    </i>
  </button>
</div>
