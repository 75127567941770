import { Injectable } from '@angular/core';
import {
  fromEvent,
  merge,
  timer,
  Observable,
  Subject,
  Subscription
} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserIdleService {
  idle: Observable<any> = new Observable();
  timer: Subscription = new Subscription();
  timeOutMilliSeconds: number = 1000;
  idleSubscription: Subscription = new Subscription();
  expired: Subject<boolean> = new Subject<boolean>();

  startWatching(timeOutSeconds: number): Observable<any> {
    this.idle = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
    );
    this.timeOutMilliSeconds = timeOutSeconds * 1000;
    this.idleSubscription = this.idle.subscribe((_res) => {
      this.resetTimer();
    });
    this.startTimer();
    return this.expired;
  }

  startTimer() {
    this.timer = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe((_res) => {
      this.expired.next(true);
    });
  }

  resetTimer() {
    this.timer.unsubscribe();
    this.startTimer();
  }

  public stopTimer() {
    this.timer.unsubscribe();
    this.idleSubscription.unsubscribe();
  }
}
