import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FormControl,
  Validators
} from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subject,
  timer
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import {
  select,
  Store
} from '@ngrx/store';

import { AnalyticsEventsService } from '@p1/libs/analyticsevents';

import * as searchBarReducer from '../reducer/searchBarReducer';
import * as catalogConfigReducer from '../../catalog-config/reducers';
import * as allClusterSearchActions from '../actions/search.actions';
import {
  searchBarPlaceholderAlternatives,
  searchBarPlaceholderDefault,
  searchClusterNames
} from '../search-cluster-names.constant';
import {
  AllClusterSearchResult,
  CategorySearchResult,
  ProductSearchResult,
  SupplierSearchResult
} from '../model-result';
import { SearchCluster } from '../search-cluster.enum';
import { Breakpoint } from '../../core/enum/breakpoint.enum';
import * as rootReducer from '../../app.reducers';
import { GoogleTagManagerService } from '../../core/google-tag-manager/service/google-tag-manager.service';
import { environment } from '../../../environments/environment';
import * as routerActions from '../../routing/actions/router.actions';


/**
 * Represents a search bar
 */
@Component({
  selector: 'p1-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})


export class SearchBarComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('searchField', { static: true })
  searchField: ElementRef<HTMLInputElement>;

  @Input()
  set searchTerm(searchTerm: string) {
    this.searchTermFormControl.patchValue(searchTerm);
  }

  get searchTerm() {
    return this.searchTermFormControl.value;
  }

  @Input()
  largeMode: boolean = false;

  @Input()
  inlineMode: boolean = false;

  @Input()
  searchPage: boolean = false;

  @Input()
  set disabled(disabled: boolean) {
    if (disabled) {
      this.searchTermFormControl.disable();
    } else {
      this.searchTermFormControl.enable();
    }
  }

  @Input()
  searchTermMinLength: number = 1;

  @Output()
  fieldFocused: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchTermFormControl = new FormControl('', [Validators.required, Validators.minLength(this.searchTermMinLength)]);
  searchBarResults$: Observable<Pick<AllClusterSearchResult, 'products' | 'suppliers' | 'categories'>>;
  searchBarResultsLength$: Observable<number>;
  searchBarSearchInProgress$: Observable<boolean>;
  mobileBreakpoint$: Observable<boolean>;
  searchFieldFocused: boolean = false;
  searchFieldPlaceholder$: Observable<string>;
  showResultWrapper: boolean = false;
  pageInfo: string;
  featureFlags = environment.featureFlags;
  clusterCounts$: Observable<Record<string, unknown>>;
  searchPlaceholder$: Observable<string>;
  searchBarLargeModeStream$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchBarFocusedStream$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  rollingPlaceholderText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  searchBarResultsMaxPreviewElements: number = 5;
  availableSearchClusters = searchClusterNames;

  private _unsubscribe: Subject<void> = new Subject();

  constructor(
    private _store: Store<searchBarReducer.State>,
    private _analyticsEventService: AnalyticsEventsService,
    private _googleTagManager: GoogleTagManagerService
  ) {
    this.searchBarSearchInProgress$ = this._store.pipe(select(searchBarReducer.getSearchBarSearchInProgress));
    this.clusterCounts$ = this._store.pipe(select(searchBarReducer.getSearchBarTotalCountsSearchResults));
    this.searchBarResults$ = this._store.pipe(select(searchBarReducer.getSearchBarAllSearchResults));
    this.searchBarResultsLength$ = this._store.pipe(select(searchBarReducer.getSearchBarAllSearchResultsLength));
    this.searchPlaceholder$ = this._store.pipe(select(catalogConfigReducer.getCatalogConfigSearchPlaceholder));
    this.mobileBreakpoint$ = this._store.pipe(
      select(rootReducer.getLayoutBreakpoint),
      map(currentBreakpoint => currentBreakpoint < Breakpoint.bpMinM)
    );
  }

  /**
   * Subscribes to searchTerm changes and triggers search actions
   */
  ngOnInit() {
    timer(0, 10000).pipe(
      withLatestFrom(this._store.pipe(select(catalogConfigReducer.getCatalogConfigRollingSearchPlaceholder))),
      map(([value, rollingPlaceholder]) => {
        if (environment.featureFlags.catalogConfig && Array.isArray(rollingPlaceholder)) {
          return rollingPlaceholder[value % rollingPlaceholder.length];
        } else {
          return searchBarPlaceholderAlternatives[value % searchBarPlaceholderAlternatives.length];
        }
      }),
      takeUntil(this._unsubscribe)
    ).subscribe(placeholder => {
      this.rollingPlaceholderText$.next(placeholder);
    });

    this.searchFieldPlaceholder$ = combineLatest([
      this.mobileBreakpoint$,
      this.searchBarFocusedStream$,
      this.searchBarLargeModeStream$,
      this.searchPlaceholder$
    ]).pipe(
      switchMap(([mobile, focused, largeMode, searchPlaceholder]) => {
        if (mobile || (!largeMode && !focused)) {
          return this.rollingPlaceholderText$;
        } else {
          return environment.featureFlags.catalogConfig && searchPlaceholder ? of(searchPlaceholder) : of(searchBarPlaceholderDefault);
        }
      })
    );


    this.searchTermFormControl.valueChanges.pipe(
      startWith(this.searchTerm),
      distinctUntilChanged(),
      takeUntil(this._unsubscribe)
    ).subscribe((searchTerm) => {
      if (this.searchTermFormControl.valid && searchTerm.length > this.searchTermMinLength) {
        this.getSearchResults(searchTerm);
      } else {
        this.clearSearchResults();
      }
    });

    this.pageInfo = this.largeMode ? 'startpage' : 'subpage';

    this.searchBarResults$.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe(results => {
      const supplierUuids = results.suppliers?.map(supplier => supplier.uuid).slice(0, this.searchBarResultsMaxPreviewElements);
      const productUuids = results.products?.map(product => product.uuid).slice(0, this.searchBarResultsMaxPreviewElements);
      const categoryUuids = results.categories?.map(category => category.uuid).slice(0, this.searchBarResultsMaxPreviewElements);

      if (supplierUuids && supplierUuids.length > 0) {
        this._analyticsEventService.trackSearchBarResultsImpressions('suppliers', supplierUuids, this.searchTerm);
      }
      if (productUuids && productUuids.length > 0) {
        this._analyticsEventService.trackSearchBarResultsImpressions('products', productUuids, this.searchTerm);
      }
      if (categoryUuids && categoryUuids.length > 0) {
        this._analyticsEventService.trackSearchBarResultsImpressions('categories', categoryUuids, this.searchTerm);
      }
    });
  }

  /**
   * Patch value of search bar if available clusters are changing
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.searchTermFormControl.pristine) {
      this.showResultWrapper = false;
    }

    if (changes['searchTerm'] &&
        changes['searchTerm'].previousValue &&
        changes['searchTerm'].previousValue !== '' &&
        changes['searchTerm'].currentValue === '') {
      this.closeSearchPreview();
    }

    if (changes['largeMode']) {
      this.searchBarLargeModeStream$.next(changes['largeMode'].currentValue);
    }
  }

  /**
   * Unsubscribes from observables
   */
  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  getSearchResults(searchTerm: string) {
    const searchParams = {
      searchTerm
    };

    if (searchParams) {
      this._store.dispatch(new allClusterSearchActions.FetchSearchBarAllClusterSearchResultsAction(searchParams));
      this._analyticsEventService.textSearch('SearchBar', searchTerm, SearchCluster.all, this.pageInfo);
    }
  }

  clearSearchResults() {
    this._store.dispatch(new allClusterSearchActions.ClearSearchBarAllClusterSearchResultsAction());
  }

  clearSearchTerm() {
    this.searchTermFormControl.patchValue('');
  }

  focusSearchField() {
    if (this.searchField) {
      this.fieldFocused.emit(true);
      this.searchFieldFocused = true;
      this.searchField.nativeElement.focus();
      this.searchBarFocusedStream$.next(true);
    }
  }

  /**
   * Resets searchTerm and clears searchResults
   */
  onSearchResultClick(entity: ProductSearchResult | SupplierSearchResult | CategorySearchResult) {
    this._analyticsEventService.trackSearchBarResult(this.searchTermFormControl.value, SearchCluster.all, entity.name, entity.uuid);
    this._googleTagManager.pushEvent({
      category: 'SearchBar',
      action: `Click search result - cluster ${ SearchCluster.all } - term ${ this.searchTermFormControl.value } - entity ${ entity.name }`,
      label: `SearchBar - ${ this.pageInfo }`
    });
    this.clearSearchTerm();
    this.closeSearchPreview();
    this._store.dispatch(new allClusterSearchActions.ClearSearchBarAllClusterSearchResultsAction());
  }

  /**
   * Clears Searchterm
   */
  onClearSearchClick() {
    this.clearSearchTerm();
    this.focusSearchField();
  }

  /**
   * Resets active state of focused search
   */
  onShowMoreClick() {
    this._analyticsEventService.trackSearchBarShowMoreLink(this.searchTermFormControl.value, SearchCluster.all);
    this._googleTagManager.pushEvent({
      category: 'SearchBar',
      action: `Click show more - cluster ${ SearchCluster.all } - term ${ this.searchTermFormControl.value }`,
      label: `SearchBar - ${ this.pageInfo }`
    });

    this.closeSearchPreview();
  }

  onSearchFieldFocus(event: Event) {
    if (event.type === 'focus') {
      this.dispatchCustomEventOnFocus();
    }
    this.reportSearchFieldNewFocus();
    this.showResultWrapper = true;
  }

  getSearchClusterLabelByKey(input) {
    return this.availableSearchClusters[input];
  }

  reportSearchFieldNewFocus() {
    if (!this.searchFieldFocused) {
      this.fieldFocused.emit(true);
      this.searchFieldFocused = true;
      this.searchBarFocusedStream$.next(true);

      this.trackSearchFieldFocus();
    }
  }

  dispatchCustomEventOnFocus() {
    const searchFocused = document.createEvent('Event');
    searchFocused.initEvent('searchFocused', true, true);
    window.dispatchEvent(searchFocused);
  }

  removeCustomEventOnFocus() {
    this.searchField.nativeElement.addEventListener('click', function removeCustomEvent() {
      this.removeEventListener('searchFocused', removeCustomEvent);
    });
  }

  closeSearchPreview() {
    this.fieldFocused.emit(false);
    this.searchFieldFocused = false;
    this.searchBarFocusedStream$.next(false);
    this.showResultWrapper = false;
    this.searchField.nativeElement.blur();

    if (!this.searchPage && this.searchTermFormControl.value !== '') {
      this.searchTermFormControl.patchValue('');
    }

    this.removeCustomEventOnFocus();
  }

  onSearchBarEnter() {
    if (this.searchTerm) {
      this.trackPressEnterEvent();
      this.submitSearchForm();
    }
  }

  onSearchBarSubmitClick() {
    if (this.searchTerm) {
      this.trackSubmitButtonClick();
      this.submitSearchForm();
    } else {
      this.focusSearchField();
    }
  }

  submitSearchForm() {
    const params: Record<string, string> = {
      searchTerm: this.searchTermFormControl.value
    };

    this._store.dispatch(new routerActions.GoAction({
      path: ['search'],
      queryParams: params
    }));
    this.closeSearchPreview();
  }

  trackPressEnterEvent() {
    this._analyticsEventService.trackSearchBarPressEnter(this.searchTermFormControl.value, SearchCluster.all);
    this._googleTagManager.pushEvent({
      category: 'SearchBar',
      action: `Press enter - cluster ${ SearchCluster.all } - term ${ this.searchTermFormControl.value }`,
      label: `SearchBar - ${ this.pageInfo }`
    });
  }

  trackSubmitButtonClick() {
    this._analyticsEventService.trackSearchBarSubmitButton(this.searchTermFormControl.value, SearchCluster.all);
    this._googleTagManager.pushEvent({
      category: 'SearchBar',
      action: `Click submit button - cluster ${ SearchCluster.all } - term ${ this.searchTermFormControl.value }`,
      label: `SearchBar - ${ this.pageInfo }`
    });
  }

  trackSearchFieldFocus() {
    this._googleTagManager.pushEvent({
      category: 'SearchBar',
      action: 'Click on SearchBar',
      label: `SearchBar - ${ this.pageInfo }`
    });
  }


  identifyCluster(index: number, cluster: AllClusterSearchResult) {
    return cluster ? cluster['key'] : index;
  }

  identifyResult(index: number, result: ProductSearchResult | SupplierSearchResult | CategorySearchResult) {
    return result ? result.uuid : index;
  }

  identifyCounter(index: number, counter: Record<string, unknown>) {
    return counter ? counter['key'] : index;
  }
}
