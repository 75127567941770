import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CatalogConfigEffects } from './effects/catalog-config-effects';
import * as fromCatalogConfig from './reducers/index';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([CatalogConfigEffects]),
    StoreModule.forFeature(fromCatalogConfig.CatalogConfigFeatureKey, fromCatalogConfig.reducer)
  ]
})
export class CatalogConfigStoreModule {}
