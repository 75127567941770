import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { UiElementsModule } from '@p1/libs/ui-elements';

import { SchuecoStahlContactDialogComponent } from './schueco-stahl-contact-dialog/schueco-stahl-contact-dialog.component';
import { SchuecoStahlScrollSpyComponent } from './schueco-stahl-scroll-spy/schueco-stahl-scroll-spy.component';


@NgModule({
  declarations: [
    SchuecoStahlContactDialogComponent,
    SchuecoStahlScrollSpyComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    UiElementsModule
  ],
  exports: [
    SchuecoStahlContactDialogComponent,
    SchuecoStahlScrollSpyComponent
  ]
})
export class SchuecoStahlContactModule {}
