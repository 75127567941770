import { ProductScore } from './product-score';
import { FilterScore } from '../../shared/filter/filter-score.enum';


export const numberOfComponents = (score: ProductScore) => {
  if (score) {
    return score.filterScores.size;
  } else {
    return 0;
  }
};

export const numberOfMatchingComponents = (score: ProductScore) => {
  if (score) {
    return Array.from(score.filterScores.values()).filter(value => value === FilterScore.Full).length;
  } else {
    return 0;
  }
};

export const percentageOfComponentsMatching = (score: ProductScore) => {
  if (score && numberOfComponents(score) > 0) {
    return 100 * numberOfMatchingComponents(score) / numberOfComponents(score);
  } else {
    return 0;
  }
};

export const allComponentsMatching = (score: ProductScore) => {
  if (score) {
    return Array.from(score.filterScores.values()).every(value => value === FilterScore.Full);
  } else {
    return false;
  }
};

export const allFilterUuidsWithFullScore = (score: ProductScore) => allFilterUuidsWithFilterScore(score, FilterScore.Full);

export const allFilterUuidsWithPartialScore = (score: ProductScore) => allFilterUuidsWithFilterScore(score, FilterScore.Partial);

export const allFilterUuidsWithNoInfo = (score: ProductScore) => allFilterUuidsWithFilterScore(score, FilterScore.NoInfo);

const allFilterUuidsWithFilterScore = (score: ProductScore, filterScoreToCheck: FilterScore) => {
  if (score) {
    return Array.from(score.filterScores.entries())
      .filter(([_, filterScore]) => filterScore === filterScoreToCheck)
      .map(([uuid]) => uuid);
  } else {
    return undefined;
  }
};
