import { FileMeta } from './file-meta';
import { FileMetaBackendInterface } from './file-meta.backend-interface';


/**
 * Generates a FileMeta based on api input
 *
 * @param input
 * @returns
 */
export const generateFileMetaFromBackendInput = (input: FileMetaBackendInterface): FileMeta => {
  try {
    return {
      uuid: input.id,
      name: input.name
    };
  } catch (e) {
    return null;
  }
};
