import { BrowserModule } from '@angular/platform-browser';
import {
  LOCALE_ID,
  NgModule
} from '@angular/core';
import {
  APP_BASE_HREF,
  PlatformLocation,
  registerLocaleData
} from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en-GB';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SHARE_BUTTONS_CONFIG } from 'ngx-sharebuttons';

import {
  BrowserCookiehandlingService,
  COOKIEHANDLING_CONFIG,
  COOKIEHANDLING_SERVICE
} from '@p1/libs/cookiehandling';
import {
  ConsoleLoggerService,
  LOGGING_SERVICE,
  SentryLoggerService
} from '@p1/libs/logging';
import { EMBED_MESSAGING_CONFIG } from '@p1/libs/embed-messaging';
import { EXTERNAL_ADDITIONAL_CONTENT_CONFIG } from '@p1/libs/external-additional-content';
import { SCHUECO_PRODUCT_VALIDATION_CONFIG } from '@p1/libs/schueco-product-validation/';
import { ROMAKOWSKI_CONFIGURATOR_LINK_CONFIG } from '@p1/libs/romakowski-configurator-link';
import { FILE_SERVICE_CONFIG } from '@p1/libs/file';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { reducers } from './app.reducers';
import { CoreModule } from './core/core.module';
import { ProductStoreModule } from './product/product.store.module';
import { AuthStoreModule } from './auth/auth.store.module';
import { SharedModule } from './shared/shared.module';
import { CustomSerializer } from './routing/reducer/router.reducer';
import { CatalogConfigStoreModule } from './catalog-config/catalog-config-store.module';
import { AppRoutingModule } from './routing/app.routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PRODUCTSPECIFICATION_CONFIG } from '@p1/libs/productspecifications';
import { CATALOG_SERVICE_CONFIG } from '@p1/libs/catalog-service';
import { NewsModule } from './news/news.module';
import { SchuecoStahlContactModule } from '@p1/libs/schueco-stahl-contact/src/lib/schueco-stahl-contact.module';


registerLocaleData(localeDE, 'de-DE');
registerLocaleData(localeEN, 'en-GB');


@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'p1Frontend' }),
    CoreModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true
      }
    }),
    EffectsModule.forRoot([]),
    AuthStoreModule,
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),
    ProductStoreModule,
    environment.featureFlags.catalogConfig ? CatalogConfigStoreModule : [],
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AppRoutingModule, // The order of route configuration matters. Must be the last!!!
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    NewsModule,
    SchuecoStahlContactModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    environment.production ? null : {
      provide: LOCALE_ID,
      useValue: 'en-GB'
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    {
      provide: LOGGING_SERVICE,
      useFactory: () => {
        if (environment.environmentName === 'prod') {
          return new SentryLoggerService({
            dsn: environment.sentryDsn,
            environment: environment.environmentName
          });
        } else {
          return new ConsoleLoggerService();
        }
      }
    },
    {
      provide: COOKIEHANDLING_CONFIG,
      useValue: {
        production: environment.production,
        defaultDomain: environment.host
      }
    },
    {
      provide: COOKIEHANDLING_SERVICE,
      useClass: BrowserCookiehandlingService
    },
    {
      provide: EMBED_MESSAGING_CONFIG,
      useValue: {
        parentOrigins: environment.allowedParentOrigins
      }
    },
    {
      provide: EXTERNAL_ADDITIONAL_CONTENT_CONFIG,
      useValue: {
        apiUrl: environment.external_content_api_url
      }
    },
    {
      provide: PRODUCTSPECIFICATION_CONFIG,
      useValue: {
        apiUrl: environment.api_url
      }
    },
    {
      provide: CATALOG_SERVICE_CONFIG,
      useValue: {
        catalogApiUrl: environment.api_catalog_url
      }
    },
    {
      provide: ROMAKOWSKI_CONFIGURATOR_LINK_CONFIG,
      useValue: {
        bimGeneratorUrl: {
          'en-GB': 'https://www.romakowski.com/en/insulation-systems/bim/bim-generator',
          'de-DE': 'https://www.romakowski.com/de/daemmsysteme/bim/bim-generator'
        },
        solutionFinderUrl: {
          'en-GB': 'https://www.romakowski.com/en/insulation-systems/bim/bim-solution-finder',
          'de-DE': 'https://www.romakowski.com/de/daemmsysteme/bim/bim-solution-finder'
        },
        supplierUuid: '2f5606ea9fa8458c8daa5e54e93e5649'
      }
    },
    {
      provide: SHARE_BUTTONS_CONFIG,
      useValue: {
        prop: {
          email: {
            text: $localize`:|@@share-buttons-config_email-text:Email`
          },
          copy: {
            text: $localize`:|@@share-button-config_copy-text:Copy link`,
            data: {
              text: $localize`:|@@share-button-config_copy-text:Copy link`,
              successText: $localize`:|@@share-button-config_copy-text:link copied`
            }
          }
        }
      }
    },
    {
      provide: SCHUECO_PRODUCT_VALIDATION_CONFIG,
      useValue: {
        apiUrl: environment.schueco_product_validation_api_url
      }
    },
    {
      provide: FILE_SERVICE_CONFIG,
      useValue: {
        fileApiUrl: environment.api_files_url
      }
    }
  ].filter(provide => !!provide),
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
