import {
  FileContainer,
  FileContainerBackendInterface,
  generateFileContainerFromBackendInput
} from '@p1/libs/file';

export interface ProductspecificationPreview {
  uuid: string;
  name: string;
  category: string;
  supplier: string;
  image: FileContainer;
}

export interface ProductspecificationPreviewBackend {
  uuid: string;
  name: string;
  category: string;
  supplier: string;
  image: FileContainerBackendInterface;
}

export const generateProductspecificationPreviewFromBackendInterface = (preview: ProductspecificationPreviewBackend): ProductspecificationPreview => ({
  uuid: preview.uuid,
  name: preview.name,
  category: preview.category,
  supplier: preview.supplier,
  image: generateFileContainerFromBackendInput(preview.image)
});
