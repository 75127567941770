import { Action } from '@ngrx/store';

import { ActionWithPayload } from '../../../shared/ngrx/action-with-payload.class';
import { Dialog } from '../model/dialog';


/**
 * Shows a modal with the given config
 */
export const OPEN_DIALOG = '[DIALOG] Open dialog';
export class OpenDialogAction implements ActionWithPayload<Dialog> {
  readonly type = OPEN_DIALOG;


  constructor(public payload: Dialog) {}
}

/**
 * Removes the dialog with the given uuid from state and closes it
 */
export const CLOSE_DIALOG = '[DIALOG] Close dialog';
export class CloseDialogAction implements Action {
  readonly type = CLOSE_DIALOG;
}

export type Actions = OpenDialogAction | CloseDialogAction;
