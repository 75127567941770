<a *ngIf="(breakpoint >= Breakpoint.bpMinM || !category.childNodes) && !showAll"
   class="cm__link"
   [ngClass]="{
    'cm__link--hidden': activeChild
   }"
   [routerLink]="['/categories/' + category.pathedSlug]"
   data-gtm-event-category="Categorytree"
   [attr.data-gtm-event-action]="'Click Category - Name ' + category.name"
   data-gtm-event-label="Navigation - CategorytreeMenu">
  {{ category.name }}
  <i *ngIf="category.childNodes"
     class="icon">
    <p1-lib-icons-icon-wrapper name="iconArrowRight"></p1-lib-icons-icon-wrapper>
  </i>
</a>

<a *ngIf="(breakpoint <= Breakpoint.bpMinS && category.childNodes) && !showAll"
   class="cm__link"
   (click)="selectCategory(category)"
   [ngClass]="{
    'cm__link--hidden': activeChild
   }">
  {{ category.name }}
  <i *ngIf="category.childNodes"
     class="icon">
    <p1-lib-icons-icon-wrapper name="iconArrowRight"></p1-lib-icons-icon-wrapper>
  </i>
</a>

<a *ngIf="showAll"
   class="cm__link cm__link--all"
   [routerLink]="['/categories/' + category.pathedSlug]"
   [ngClass]="{
      'cm__link--hidden': activeChild
   }"
   data-gtm-event-category="Categorytree"
   [attr.data-gtm-event-action]="'Click Category - Name ' + category.name"
   data-gtm-event-label="Navigation - CategorytreeMenu">
  <span class="cm__link__inner"
        i18n="show all products from category // page: category tree menu @@category-tree-link.component">Show all in {{ category.name }}&nbsp;
  </span>
</a>
