import { UserAuthenticatedGuard } from '../guards/user-authenticated/user-authenticated.guard';
import { AppRoutes } from './app-routes.interface';
import { InDevelopGuard } from '../guards/in-develop/in-develop.guard';
import { MyWatchlistsFeatureGuard } from '../guards/active-features/my-watchlists-feature.guard';
import { NotFoundPageComponent } from '../../shared/common/components/404/not-found-page.component';
import { NavBarCategory } from '../enum/nav-bar-category.enum';
import { SupplierAnalyticsFeatureGuard } from '../guards/active-features/supplier-analytics-feature.guard';
import { TreeUuidResolver } from '../../category-tree/resolver/tree-uuid-resolver';
import { SupplierFeatureGuard } from '../guards/active-features/supplier-feature.guard';
import { environment } from '../../../environments/environment';
import { routeTitle } from './route-title-names.constant';
import { ProductValidationFeatureGuard } from '../guards/active-features/product-validation-feature.guard';


/**
 * Contains all app routes. Used for angular router and custom navigation menu component.
 *
 * all lazy loaded modules (loadChildren), could not be loaded in the app.module or core.module
 * only the first page, after loading the url, must be imported in the core.module and has to redirected to the spezific URL
 */

const conditionalRoutes: AppRoutes = [];
if (environment.featureFlags.productValidationDialog === true) {
  conditionalRoutes.push({
    canActivate: [ProductValidationFeatureGuard],
    canLoad: [ProductValidationFeatureGuard],
    path: 'product-validation',
    loadChildren: () => import('../../product-validation/product-validation.module').then(m => m.ProductValidationModule),
    showInNavigation: false,
    title: routeTitle.validation,
    navBarCategory: NavBarCategory.app
  });
}

if (environment.featureFlags.productSpecification === true) {
  conditionalRoutes.push({
    path: 'productspecification',
    loadChildren: () => import('../../product-specification/product-specification.module').then(m => m.ProductSpecificationModule),
    showInNavigation: false,
    title: routeTitle.productSpecification,
    navBarCategory: NavBarCategory.app
  });
}

export const appRoutes: AppRoutes = [
  {
    path: '',
    resolve: { treeUuid: TreeUuidResolver },
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    children: [
      {
        path: '',
        pathMatch: 'full',
        showInNavigation: true,
        loadChildren: () => import('../../home/home.module').then(m => m.HomeModule),
        title: routeTitle.home,
        hierarchy: 1,
        navBarCategory: NavBarCategory.app
      },
      {
        path: 'auth',
        loadChildren: () => import('../../auth/auth.module').then(m => m.AuthModule),
        navBarCategory: NavBarCategory.app
      },
      {
        path: 'products',
        loadChildren: () => import('../../product/product.module').then(m => m.ProductModule),
        showInNavigation: false,
        title: routeTitle.products,
        hierarchy: 2,
        navBarCategory: NavBarCategory.app
      },
      {
        canActivate: [SupplierFeatureGuard],
        canLoad: [SupplierFeatureGuard],
        path: 'suppliers',
        loadChildren: () => import('../../supplier/supplier.module').then(m => m.SupplierModule),
        showInNavigation: environment.featureFlags.supplierList,
        title: routeTitle.supplier,
        hierarchy: 3,
        navBarCategory: NavBarCategory.app
      },
      {
        path: 'categories',
        loadChildren: () => import('../../category-tree/category-tree.module').then(m => m.CategoryTreeModule),
        title: routeTitle.categories,
        hierarchy: 5,
        showInNavigation: false,
        navBarCategory: NavBarCategory.app
      },
      {
        path: 'dev-only',
        loadChildren: () => import('../../dev-only/dev-only.module').then(m => m.DevOnlyModule),
        showInNavigation: true,
        title: routeTitle.devOnly,
        hierarchy: 15,
        canActivate: [InDevelopGuard],
        developOnly: true
      },
      {
        canActivate: [UserAuthenticatedGuard],
        canLoad: [UserAuthenticatedGuard],
        path: 'userAccount',
        loadChildren: () => import('../../user/user.module').then(m => m.UserModule),
        showInNavigation: false,
        title: routeTitle.userAccount,
        navBarCategory: NavBarCategory.user
      },
      {
        canActivate: [MyWatchlistsFeatureGuard, UserAuthenticatedGuard],
        canLoad: [MyWatchlistsFeatureGuard, UserAuthenticatedGuard],
        path: 'myWatchlists',
        loadChildren: () => import('../../my-watchlists/my-watchlists.module').then(m => m.MyWatchlistsModule),
        title: routeTitle.watchlists,
        navBarCategory: NavBarCategory.user
      },
      {
        path: 'watchlistSnapshots',
        loadChildren: () => import('../../watchlist-snapshots/watchlist-snapshots.module').then(m => m.WatchlistSnapshotsModule),
        title: routeTitle.watchlists
      },
      {
        canLoad: [UserAuthenticatedGuard],
        path: 'company',
        pathMatch: 'full',
        redirectTo: 'company/analytics'
      },
      {
        canActivate: [SupplierAnalyticsFeatureGuard, UserAuthenticatedGuard],
        canLoad: [SupplierAnalyticsFeatureGuard, UserAuthenticatedGuard],
        path: 'company/analytics',
        loadChildren: () => import('../../supplier-analytics/supplier-analytics.module').then(m => m.SupplierAnalyticsModule),
        title: routeTitle.supplierAnalytics,
        navBarCategory: NavBarCategory.supplierAnalytics
      },
      {
        canActivate: [UserAuthenticatedGuard],
        canLoad: [UserAuthenticatedGuard],
        path: 'supplier-self-services',
        loadChildren: () => import('../../supplier-self-services/supplier-self-services.module').then(m => m.SupplierSelfServicesModule),
        title: routeTitle.supplierSelfServices,
        navBarCategory: NavBarCategory.app
      },
      {
        path: 'search',
        loadChildren: () => import('../../search/search.module').then(m => m.SearchModule),
        title: routeTitle.search
      },
      ...conditionalRoutes,
      {
        path: '404',
        component: NotFoundPageComponent,
        title: routeTitle.pageNotFound,
        navBarCategory: NavBarCategory.app
      },
      {
        path: '**',
        redirectTo: '404'
      },
    ]
  }
];
