import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WindowRef } from '@p1/libs/browser-api-wrapper';

import { QaState } from '../qa-state';
import { QaStateHandlingService } from '../qa-state-handling-service/qa-state-handling.service';


@Component({
  selector: 'p1-qa-state-menu',
  templateUrl: './qa-state-menu.component.html',
  styleUrls: ['./qa-state-menu.component.scss'],
  animations: [
    trigger('qastatemenuContent', [
      state('is-open', style({
        height: '*',
        visibility: 'visible'
      })),
      state('*', style({
        height: '0px',
        visibility: 'hidden'
      })),
      transition('* <=> *', animate('.3s ease-in-out'))
    ])
  ]
})
export class QaStateMenuComponent {

  isOpen: boolean = false;

  availableProductQaStates$: Observable<{key: QaState; label: string}[]>;
  selectedProductQaState$: Observable<QaState>;
  selectedProductQaStateLabel$: Observable<string>;

  private _ignoreToggle: boolean = false;
  private _ignoreToggleTimeout;

  constructor(
    private elementRef: ElementRef,
    private _windowRef: WindowRef,
    private _qaStateHandlingService: QaStateHandlingService
  ) {
    this.availableProductQaStates$ = this._qaStateHandlingService.availableQaStates$.pipe(
      map(availableStates => {
        if (availableStates?.length > 0) {
          return [null].concat(availableStates).map(availableState => ({
            key: availableState,
            label: this._getLabelForQaState(availableState)
          }));
        }
      })
    );
    this.selectedProductQaState$ = this._qaStateHandlingService.selectedQaState$;
    this.selectedProductQaStateLabel$ = this.selectedProductQaState$.pipe(
      map(key => this._getLabelForQaState(key))
    );
  }

  /**
   * listener for click on document to close overlay if clicked outside
   */
  @HostListener('document:click', ['$event'])
  onMouseUp(event) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.toggleMenu();
    }
  }

  /**
   * Catches events within the component
   */
  @HostListener('click', ['$event'])
  catchEvent(event) {
    if (event.target['tagName'] && event.target.tagName === 'A') {
      this.toggleMenu();
    }
  }

  /**
   * Close search if user clicked on overlay or outside component
   */
  @HostListener('window:click', ['$event.target'])
  @HostListener('window:touchstart', ['$event.target'])
  onWindowClick() {
    if (this.isOpen) {
      this._windowRef.nativeWindow.addEventListener('searchFocused', () => {
        this.isOpen = false;
      });
    }
  }

  /**
   * toggles the menu
   * Starts a timeout and blocks toggleing for 500ms to prevent unintended closing with double click
   */
  toggleMenu() {
    if (this._ignoreToggle) {
      return;
    }

    this.isOpen = !this.isOpen;
    this._ignoreToggle = true;

    clearTimeout(this._ignoreToggleTimeout);

    this._ignoreToggleTimeout = setTimeout(() => {
      this._ignoreToggle = false;
    }, 500);
  }

  selectProductQaState(selectedQaState) {
    this._qaStateHandlingService.selectQaState(selectedQaState);
    this._windowRef.nativeWindow.location.reload();
  }


  private _getLabelForQaState(stateKey: QaState) {
    switch (stateKey) {
      case QaState.integrated:
        return $localize`:|integrated product view @@navigation-bar.component_product_view_integrated:Integrated`;
      case QaState.internalQa:
        return $localize`:|integrated product view @@navigation-bar.component_product_view_internalqa:Internal QA`;
      case QaState.externalQa:
        return $localize`:|integrated product view @@navigation-bar.component_product_view_qa:QA`;
      default:
        return $localize`:|live product view @@navigation-bar.component_product_view_live:Live`;
    }
  }
}
