import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges, Output,
  SimpleChanges
} from '@angular/core';

import { NewsCategoriesEnum } from '../enum/news-categories.enum';

@Component({
  selector: 'p1-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnChanges {

  @Input()
  news: any;

  @Input()
  readNews: string[];

  @Output()
  addReadNews: EventEmitter<number> = new EventEmitter<number>();

  newsCategory = NewsCategoriesEnum;

  constructor(
    private _element: ElementRef,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['news']) {
      this._element.nativeElement.scrollTo(0, 0);
    }
  }
}
