import {
  Compiler,
  Inject,
  Injectable,
  Injector,
  NgModuleFactory
} from '@angular/core';
import {
  APP_BASE_HREF,
  DOCUMENT
} from '@angular/common';
import {
  Actions,
  Effect,
  ofType
} from '@ngrx/effects';
import {
  Action,
  select,
  Store
} from '@ngrx/store';
import {
  EMPTY,
  Observable
} from 'rxjs';
import {
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { KeycloakAdapterService } from '@p1/libs/keycloak-adapter';

import * as keycloakActions from '../action/keycloak.actions';
import * as authReducer from '../reducer/auth.reducer';
import { getUser } from '../reducer/auth.reducer';
import * as routerActions from '../../routing/actions/router.actions';


export const lazyModules = {
  supplierAnalytics: {
    loadChildren: () => import('src/app/supplier-analytics/supplier-analytics.module').then(m => m.SupplierAnalyticsModule)
  }
};

@Injectable()
/**
 * Contains side effects that are executed on several keycloak actions
 */
export class KeycloakEffects {

  @Effect({ dispatch: false })
  /**
   * Calls login function of the keycloak adapter, which will redirect to the keycloak login page.
   * Because of the resulting redirect there is no meaningful action to dispatch as a result.
   * Keycloak will again redirect to the given redirectURI on successful authentication.
   */
  keycloakLogin$: Observable<Action> = this._actions.pipe(
    ofType(keycloakActions.KEYCLOAK_LOGIN),
    map((action: keycloakActions.KeycloakLoginAction) => action.payload),
    switchMap((payload) => {
      const redirectURI = this.buildAppURL(payload.fromPath);
      const reason = payload.reason ? payload.reason : undefined;
      this._keycloakService.doLogin(redirectURI, reason, payload.replaceUrl);
      return EMPTY;
    })
  );

  @Effect({ dispatch: false })
  keycloakRegistration$ = this._actions.pipe(
    ofType(keycloakActions.KEYCLOAK_REGISTRATION),
    map((action: keycloakActions.KeycloakRegistrationAction) => action.payload),
    switchMap((payload) => {
      const redirectURI = this.buildAppURL(payload.fromPath);
      this._keycloakService.doRegistration(redirectURI, payload.replaceUrl);
      return EMPTY;
    })
  );

  @Effect({ dispatch: false })
  keycloakLoginSucceeded$ = this._actions.pipe(
    ofType(keycloakActions.KEYCLOAK_LOGIN_SUCCEEDED),
    withLatestFrom(this._store.pipe(select(getUser))),
    tap(([_, user]) => {

      if (user.supplierAreaAccess) {
        lazyModules.supplierAnalytics.loadChildren()
          .then((moduleOrFactory: any) => {
            if (moduleOrFactory instanceof NgModuleFactory) {
              return moduleOrFactory;
            } else {
              return this._compiler.compileModuleAsync(moduleOrFactory);
            }
          })
          .then((factory) => {
            factory.create(this._injector);
          });
      }
    })
  );

  @Effect({ dispatch: false })
  /**
   * Calls logout function of the keycloak adapter, which will redirect to the given redirectURI
   * resulting in a reload of the app if the redirectURI is inside the app. Therefore, there is no
   * meaningful action to dispatch as a result.
   * If the redirect is supposed to go to /auth/login, the keycloak login page uri is used instead
   * (with a redirect to the start page after successful login) in order to prevent the extra step
   * of reloading the app on /auth/login just to immediately redirect to keycloak login page.
   */
  keycloakLogout$ = this._actions.pipe(
    ofType(keycloakActions.KEYCLOAK_LOGOUT),
    map((action: keycloakActions.KeycloakLogoutAction) => action.payload),
    tap((redirectPath: string) => {
      let redirectURI = this.buildAppURL(redirectPath);
      if (redirectPath === '/auth/login') {
        const appRootUrl = this.buildAppURL('/');
        redirectURI = this._keycloakService.createLoginUrlWithRedirect(appRootUrl);
      }
      this._keycloakService.doLogout(redirectURI);
    })
  );

  @Effect()
  keycloakProfile$ = this._actions.pipe(
    ofType(keycloakActions.KEYCLOAK_PROFILE),
    switchMap(action => {
      this._keycloakService.navigateToProfilePage(action.payload.replaceUrl);
      return EMPTY;
    })
  );

  @Effect()
  keycloakEditPassword$ = this._actions.pipe(
    ofType(keycloakActions.KEYCLOAK_EDIT_PASSWORD),
    switchMap(action => {
      this._keycloakService.navigateToEditPasswordPage(action.payload.replaceUrl);
      return EMPTY;
    })
  );

  constructor(
    private _actions: Actions<keycloakActions.Actions | routerActions.Actions>,
    private _keycloakService: KeycloakAdapterService,
    private _store: Store<authReducer.StateInterface>,
    private _compiler: Compiler,
    private _injector: Injector,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(APP_BASE_HREF) private _baseHref: string) {}

  private buildAppURL(relativePath) {
    return this._document.location.origin
           + this._baseHref.slice(0, -1)
           + (relativePath ? relativePath : '');
  }
}
