export enum NavigationBarState {
  /**
   * Default navigation-bar state, no visible second level navigation
   */
  default,
  /**
   * Second level navigation for the currently active first level is visible
   */
  activeFirstLevel,
  /**
   * Second level navigation for the currently hovered first level is visible on top of the currently active first level
   */
  hoverOverActiveFirstLevel,
  /**
   * No second level navigation is visible, even if the currently active first level has a second level
   */
  emptyHoverOverActiveFirstLevel,
  /**
   * Second level navigation for the currently hovered first level is visible as overlay
   */
  hoverFirstLevel
}
