<p1-lib-ui-elements-modal
  [closeable]="true"
  [useDefaultSpacings]="false"
  [useDefaultGap]="false"
  [fullscreen]="true"
  [useSmallestMaxWidth]="true"
  [contentIsScrollable]="false"
  [autoFocus]="false"
  [context]="'News'"
  (closeModal)="abortNewsDialog.emit()">
  <ng-container modal-header>
    <h2>Neues auf Plan.One</h2>
  </ng-container>
  <ng-container modal-content>
    <div class="news-dialog">
      <p1-news-list class="news-dialog__list"
                    [news]="newsArticlesToDisplay$ | async"
                    [readNews]="getReadNews()"
                    (addReadNews)="readNews.emit($event)"></p1-news-list>
      <p1-news-navigation class="news-dialog__navigation"
                          (newsCategoryIdSelected)="selectNewsCategory($event)"
                          [selectedNewsCategoryId]="selectedNewsCategory$ | async"
                          [unreadNewsInCategories]="unreadNewsInCategories"
                          (addReadNews)="readNews.emit()"></p1-news-navigation>
    </div>
  </ng-container>
</p1-lib-ui-elements-modal>
