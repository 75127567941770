import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import { StateInterface } from '../../app.reducers';
import {
  AllClusterSearchResult,
  CategorySearchResult,
  ProductSearchResult,
  SupplierSearchResult
} from '../model-result';
import { searchBarProductSearchResults } from './search-bar/product-search-results/product-search-results.reducer';
import { searchBarSupplierSearchResults } from './search-bar/supplier-search-results/supplier-search-results.reducer';
import { searchBarCategorySearchResults } from './search-bar/category-search-results/category-search-results.reducer';
import { searchBarSearchInProgress } from './search-bar/search-in-progress/search-in-progress.reducer';
import { searchBarTotalCountsSearchResults } from './search-bar/totalCounts-search-results/totalCounts-search-results.reducer';
import { environment } from '../../../environments/environment';


export interface SearchBarState {
  searchBarProductSearchResults: ProductSearchResult[];
  searchBarSupplierSearchResults: SupplierSearchResult[];
  searchBarCategorySearchResults: CategorySearchResult[];
  searchBarSearchInProgress: boolean;
  searchBarTotalCountsSearchResults: {
    products?: number;
    suppliers?: number;
    categories?: number;
  };
}

export interface State extends StateInterface {
  searchBar: SearchBarState;
}

export const reducers = {
  searchBarProductSearchResults,
  searchBarSupplierSearchResults,
  searchBarCategorySearchResults,
  searchBarSearchInProgress,
  searchBarTotalCountsSearchResults
};

/* SEARCH BAR */
export const getSearchBarState = createFeatureSelector<SearchBarState>('searchBar');
export const getSearchBarSearchInProgress = createSelector(getSearchBarState, (state: SearchBarState) => state.searchBarSearchInProgress);
export const getSearchBarProductSearchResults = createSelector(getSearchBarState, (state: SearchBarState) => state.searchBarProductSearchResults);
export const getSearchBarSupplierSearchResults = createSelector(getSearchBarState, (state: SearchBarState) => state.searchBarSupplierSearchResults);
export const getSearchBarCategorySearchResults = createSelector(getSearchBarState, (state: SearchBarState) => state.searchBarCategorySearchResults);
export const getSearchBarTotalCountsSearchResults = createSelector(getSearchBarState, (state: SearchBarState) => state.searchBarTotalCountsSearchResults);
export const getSearchBarAllSearchResults = createSelector(
  getSearchBarProductSearchResults, getSearchBarSupplierSearchResults, getSearchBarCategorySearchResults,
  (_products, _supplier, _categories) => {
    const result: Pick<AllClusterSearchResult, 'products' | 'suppliers' | 'categories' > = {};

    if (_products) {
      result.products = _products;
    }

    if (_supplier && environment.featureFlags.supplierList) {
      result.suppliers = _supplier;
    }

    if (_categories) {
      result.categories = _categories;
    }
    return result;
  });

export const getSearchBarAllSearchResultsLength = createSelector(
  getSearchBarAllSearchResults, _results => {
    let resultLength = 0;

    for (const key in _results) {
      if (_results.hasOwnProperty(key)) {
        resultLength = resultLength + (Array.isArray(_results[key]) ? _results[key].length : 0);
      }
    }

    return resultLength;
  });
