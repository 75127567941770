import {
  Injectable,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { CookiehandlingServiceInterface } from '../cookiehandling-service.interface';
import {
  COOKIEHANDLING_CONFIG,
  CookiehandlingConfigInterface
} from '../config/cookiehandling-config.interface';


@Injectable({
  providedIn: 'root'
})
export class BrowserCookiehandlingService implements CookiehandlingServiceInterface {
  private _domain?: string;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(COOKIEHANDLING_CONFIG) private _config: CookiehandlingConfigInterface
  ) {}

  public getDomain(): string {
    return this._domain;
  }

  public setDomain(domain: string) {
    this._domain = domain;
  }

  public read(name: string): string {
    const rawcookies = this._document.cookie.split('; ');
    const cookieEntry = rawcookies.map(cookieentry => cookieentry.split('=')).find(entry => entry[0] === name);
    return Array.isArray(cookieEntry) ? cookieEntry[1] : null;
  }

  public write(name: string, value: string, expires?: Date, domain?: string, path?: string) {
    /* istanbul ignore else  */
    if (this._config && this._config.production) {
      const cookiedomain = domain ?? this._domain ?? this._config.defaultDomain;
      const isLocalhost = cookiedomain.includes('localhost');

      this._document.cookie = `${ name }=${ value ? value : '' }${cookiedomain && !isLocalhost ? ';secure;domain=' + cookiedomain : ''}`
                              + `;path=${path ? path : '/'}${expires ? ';expires=' + expires.toUTCString() : ''}`;
    } else {
      this._document.cookie = `${ name }=${ value ? value : ''}${domain ? ';domain=' + domain : ''};path=${path ? path : '/'}`
                              + `${expires ? ';expires=' + expires.toUTCString() : ''}`;
    }
  }
}
