import { ProductProperty } from './product-property';
import { PropertyType } from './property-type.enum';
import {
  combineValueAndUnit,
  singleFilterValueToString
} from '../../shared/filter/model';
import { uniqueArray } from '../../shared/common/helper/unique-array/unique-array';


/**
 * Returns the given product properties value as human readable string
 *
 * @param productProperty
 * @returns
 */
export const getProductPropertyValueAsString = (productProperty: ProductProperty): string => {
  let result;

  switch (productProperty.type) {
    case PropertyType.IfcPropertySingleValue:
      result = singleFilterValueToString(productProperty.nominalValue);
      break;
    case PropertyType.IfcPropertyListValue:
      result = uniqueArray<string | number>(productProperty.listValues).join(', ');
      break;
    case PropertyType.IfcPropertyBoundedValue:
      if (productProperty.lowerBoundValue === productProperty.upperBoundValue) {
        result = singleFilterValueToString(productProperty.lowerBoundValue);
        break;
      }
      result = `${ productProperty.lowerBoundValue } - ${ productProperty.upperBoundValue }`;
      break;
  }

  return combineValueAndUnit(result, productProperty.unit);
};
