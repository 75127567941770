import { NewsBackendInterface } from './news.backend-interface';
import { News } from './news';


export const generateNewsFromBackendInput = (input: NewsBackendInterface): News => {
  try {
    return {
      id: input.id,
      title: input.title.rendered,
      date: input.date,
      content: input.content.rendered,
      categories: input.categories
    };
  } catch (e) {
    return null;
  }
};
