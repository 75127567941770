import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFeatureGuard } from './base-feature.guard';
import { environment } from '../../../../environments/environment';
import { State } from '../../reducer/router.reducer';


@Injectable()
export class SupplierAnalyticsFeatureGuard extends BaseFeatureGuard {
  constructor(store: Store<State>) {
    super(store, environment.featureFlags.supplierAnalytics);
  }
}
