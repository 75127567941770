import {Inject, Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  COOKIEHANDLING_SERVICE,
  CookiehandlingServiceInterface
} from '@p1/libs/cookiehandling';

import { environment } from '../../../environments/environment';


@Injectable()
/**
 * Intercepts api requests to add app context identifier
 */
export class ClientIdInterceptor implements HttpInterceptor {

  private _clientIdCookie: string = 'p1_client_id';

  constructor(
    @Inject(COOKIEHANDLING_SERVICE) private _cookiehandlingService: CookiehandlingServiceInterface,
  ) {}

  /**
   * Returns the value of the explainScreenSeenCookie
   */
  getClientIdCookie() {
    return (this._cookiehandlingService.read(this._clientIdCookie));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiRequest: boolean = req.url.indexOf(environment.api_url) > -1;
    const clientIdCookie: string = this.getClientIdCookie();

    if (isApiRequest && clientIdCookie) {
      req = req.clone({ setHeaders: {'P1-Client-Id': clientIdCookie} });
    }

    return next.handle(req);
  }

}
