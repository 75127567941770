import { NgModule } from '@angular/core';

import { IconWrapperComponent } from './icon-wrapper/icon-wrapper.component';


@NgModule({
  imports: [],
  declarations: [
    IconWrapperComponent
  ],
  exports: [
    IconWrapperComponent
  ]
})
export class IconsModule {}
