import {
  createSelector,
  createFeatureSelector
} from '@ngrx/store';

import * as rootReducer from '../../app.reducers';
import { AnalyticsCompany } from '../model';
import { analyticsCompany } from './analytics-company/analytics-company.reducer';
import { leads } from './leads/leads.reducer';
import { Lead } from '../model/leads/lead.interface';


export interface AnalyticsCompanyState {
  analyticsCompany: AnalyticsCompany;
  leads: Lead[];
}

export interface State extends rootReducer.StateInterface {
  analyticsCompany: AnalyticsCompanyState;
}

export const reducers = {
  analyticsCompany,
  leads
};

export const getAnalyticsCompanyState = createFeatureSelector<AnalyticsCompanyState>('analyticsCompany');

export const getAnalyticsCompany = createSelector(getAnalyticsCompanyState, (state: AnalyticsCompanyState) => !!state && state.analyticsCompany);
export const getLeads = createSelector(getAnalyticsCompanyState, (state: AnalyticsCompanyState) => state.leads);

export const getSelectedCategoryOfCompany = createSelector(getAnalyticsCompany, (_analyticsCompany, props) =>
  _analyticsCompany.productCategories && props ? _analyticsCompany.productCategories.find(category => category.uuid === props.uuid) : undefined);

export const getAnalyticsCompanyCategories = createSelector(getAnalyticsCompany, (_analyticsCompany) =>
  _analyticsCompany ? _analyticsCompany.productCategories : undefined);

export const getNumberOfProcessedLeads = createSelector(getLeads, (_leads) =>
  _leads ? _leads.filter(lead => lead.processed === true).length : undefined);
