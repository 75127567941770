import {
  Component
} from '@angular/core';


@Component({
  selector: 'p1-lib-ui-elements-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss']
})
export class LoadingAnimationComponent {

  constructor() { }
}
