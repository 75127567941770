import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { TreeCategory } from '@p1/libs/catalog-service';

import { Breakpoint } from '../../../core/enum/breakpoint.enum';


@Component({
  selector: 'p1-category-tree-link',
  templateUrl: './category-tree-link.component.html',
  styleUrls: ['./category-tree-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryTreeLinkComponent {
  @Input()
  breakpoint: Breakpoint;

  @Input()
  category: TreeCategory;

  @Input()
  activeChild: boolean;

  @Input()
  showAll: boolean = false;

  @Output()
  categorySelected: EventEmitter<TreeCategory> = new EventEmitter<TreeCategory>();

  selectedCategory: TreeCategory;
  Breakpoint = Breakpoint;

  selectCategory(categorySelected) {
    this.categorySelected.emit(categorySelected);
  }
}
