import * as categoryTreeActions from '../../actions/category-tree.actions';


/**
 * treeUuid reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const treeUuid = (state: string = undefined, action: categoryTreeActions.Actions): string => {
  switch (action.type) {
    case categoryTreeActions.CATEGORY_TREE_UUID_REQUESTED:
    case categoryTreeActions.FETCH_CATEGORY_TREE_SUMMARY_SUCCEEDED:
      if (action.payload && action.payload.treeUuid) {
        return action.payload.treeUuid;
      } else {
        return undefined;
      }
    default:
      return state;
  }
};
