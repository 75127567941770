import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  select,
  Store
} from '@ngrx/store';
import { map } from 'rxjs/operators';

import { TreeCategory } from '@p1/libs/catalog-service';

import * as categoryTreeReducer from '../reducer/index';
import { Breakpoint } from '../../core/enum/breakpoint.enum';
import * as rootReducer from '../../app.reducers';


@Component({
  selector: 'p1-category-tree-menu',
  templateUrl: './category-tree-menu.component.html',
  styleUrls: ['./category-tree-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryTreeMenuComponent {

  @Input()
  positionLeft: number;

  @Input()
  positionTop: number;

  Breakpoint = Breakpoint;
  childIsActive: boolean;
  selectedCategory: TreeCategory;
  breakpoint$: Observable<Breakpoint>;
  categoryRootLineUuids$: Observable<string[]>;
  categoryTree$: Observable<TreeCategory[]>;


  /**
   * Create the Category tree menu.
   *
   * @constructor
   * @param _store
   */
  constructor(
    private _store: Store<categoryTreeReducer.State>
  ) {
    this.breakpoint$ = this._store.pipe(
      select(rootReducer.getLayoutBreakpoint)
    );

    this.categoryTree$ = this._store.pipe(
      select(categoryTreeReducer.getCategoryTreeAllLevels),
      map(categories => categories ? categories : null)
    );

    this.categoryRootLineUuids$ = this._store.pipe(
      select(categoryTreeReducer.getCurrentCategoryTreeRootLine),
      map(rootline => {
        if (Array.isArray(rootline)) {
          return rootline.map(node => node.uuid);
        } else {
          return [];
        }
      })
    );
  }

  setActiveCategory(category) {
    if (this.selectedCategory === category) {
      this.selectedCategory = null;
    } else {
      this.selectedCategory = category;
    }
  }

  selectCategory(categorySelected) {
    this.childIsActive = categorySelected;
  }
}
