import { FilterType } from './filter-type.enum';
import { Filter } from './filter';
import { FilterHelperService } from '../helper-service/filter-helper.service';
import { FilterValueType } from '../filter-value-type.enum';


export const prefixUnits = ['Klasse', 'Class'];
export const booleanLabelTrue = $localize`:|boolean label true title @@filter.helpers_boolean-label-true:Yes`;
export const booleanLabelFalse = $localize`:|boolean label false title @@filter.helpers_boolean-label-false:No`;

/**
 * pre or postfix a given value with the given unit
 *
 * @param value
 * @param unit
 */
export const combineValueAndUnit = (value, unit) => {
  if (value && unit) {
    if (prefixUnits.indexOf(unit) >= 0) {
      return `${ unit } ${ value }`;
    } else {
      return `${ value } ${ unit }`;
    }
  } else {
    return value;
  }
};

/**
 * convert number, boolean or string to a display string
 *
 * @param value
 */
export const singleFilterValueToString = (value: number | boolean | string) => {
  if (value === true) {
    return booleanLabelTrue;
  } else if (value === false) {
    return booleanLabelFalse;
  } else if (value != null) {
    return value.toString();
  }
  return '';
};

/**
 * Checks if the given single value (number or string) exists in the filters value range
 *
 * @param filter
 * @param value
 * @returns
 */
export const checkSingleValueInRange = (filter: Filter, value: string | number): boolean => {
  switch (filter.type) {
    case FilterType.AliasSelect:
      return !!(filter.valueRange || []).find(val => val.value === value);
    case FilterType.Select:
      return !!(filter.valueRange || []).find(val => val === value);
    case FilterType.RangeMulti:
      return value >= filter.rangeInfo.min && value <= filter.rangeInfo.max;
    default:
      return false;
  }
};

/**
 * Returns the default value for the given filter
 *
 * @param filter
 * @returns
 */
export const getDefaultValue = (filter: Filter): FilterValue => {
  if (filter.type === FilterType.Boolean) {
    return undefined;
  }
  return undefined;
};

/**
 * Returns the given filter value as human readable string
 *
 * @param filterValue
 * @param unit
 * @param propertyFilter
 * @returns
 */
export const getFilterValueAsString = (filterValue: FilterValue, unit?: string, propertyFilter?: Filter): string => {
  if (propertyFilter?.type === FilterType.AliasSelect) {
    if (Array.isArray(filterValue)) {
      filterValue = propertyFilter.valueRange
        .filter(propertyFilterItem => filterValue.includes(propertyFilterItem.value))
        .map(propertyFilterItem => propertyFilterItem.displayName);
    } else {
      filterValue = propertyFilter.valueRange
        .filter(propertyFilterItem => propertyFilterItem.value === filterValue)
        .map(propertyFilterItem => propertyFilterItem.displayName);
    }
  }

  const filterValueType = FilterHelperService.getFilterValueType(filterValue);
  let result;

  switch (filterValueType) {
    case FilterValueType.SingleValue:
      result = singleFilterValueToString(filterValue);
      break;
    case FilterValueType.ListValue:
      result = filterValue.join(', ');
      break;
    case FilterValueType.BoundedValue:
      result = `${ filterValue.lower } - ${ filterValue.upper }`;
      break;
  }

  return combineValueAndUnit(result, unit);
};
