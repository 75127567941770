import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import {
  IconsModule
} from '@p1/libs/icons';
import { IconsRegistry } from '@p1/libs/icons/src/lib/registry/icons.registry';
import {
  iconArrowRight,
  iconEdit,
  iconEditDismiss,
  iconEditDone,
  iconEditPen,
  iconCheckCircled,
  iconCrossCircled,
  iconHome,
  iconBin,
  iconCross,
  iconReload,
  iconPlus
} from '@p1/libs/icons/src/lib/registry/icons';

import { TableComponent } from './components/table/table.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionGroupComponent } from './components/accordion-group/accordion-group.component';
import { BadgeAdComponent } from './components/badge-ad/badge-ad.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonDirective } from './directives/button/button.directive';
import { ButtonLoadingComponent } from './components/button-loading/button-loading.component';
import { ImageTileComponent } from './components/image-tile/image-tile.component';
import { ImgDirective } from './directives/img/img.directive';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { MessageComponent } from './components/message/message.component';
import { ModalComponent } from './components/modal/modal.component';
import { PictureComponent } from './components/picture/picture.component';
import { PortalComponent } from './components/portal/portal.component';
import { PortalOutletComponent } from './components/portal-outlet/portal-outlet.component';
import { MaterialModule } from './material.module';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { TileBasicComponent } from './components/tile-basic/tile-basic.component';
import { CounterBadgeComponent } from './components/counter-badge/counter-badge.component';
import { FileUploadEntityComponent } from './components/file-upload-entity/file-upload-entity.component';
import { NotificationComponent } from './components/notification/notification.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    AccordionComponent,
    AccordionGroupComponent,
    BadgeAdComponent,
    BadgeComponent,
    BreadcrumbComponent,
    ButtonDirective,
    ButtonLoadingComponent,
    ImageTileComponent,
    ImgDirective,
    LoadingAnimationComponent,
    MessageComponent,
    ModalComponent,
    PictureComponent,
    PortalComponent,
    PortalOutletComponent,
    TableComponent,
    FileUploadComponent,
    TileBasicComponent,
    CounterBadgeComponent,
    FileUploadEntityComponent,
    NotificationComponent
  ],
  exports: [
    AccordionComponent,
    AccordionGroupComponent,
    BadgeAdComponent,
    BadgeComponent,
    BreadcrumbComponent,
    ButtonDirective,
    ButtonLoadingComponent,
    ImageTileComponent,
    ImgDirective,
    LoadingAnimationComponent,
    MessageComponent,
    ModalComponent,
    PictureComponent,
    PortalComponent,
    PortalOutletComponent,
    TableComponent,
    FileUploadComponent,
    FileUploadEntityComponent,
    TileBasicComponent,
    CounterBadgeComponent,
    NotificationComponent
  ]
})
export class UiElementsModule {
  constructor(private _iconsRegistry: IconsRegistry) {
    this._iconsRegistry.registerIcons([
      iconArrowRight,
      iconEdit,
      iconEditDismiss,
      iconEditDone,
      iconEditPen,
      iconHome,
      iconCheckCircled,
      iconCrossCircled,
      iconBin,
      iconCross,
      iconReload,
      iconPlus
    ]);
  }
}
