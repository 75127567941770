/**
 * Determine if an element is in the viewport
 *
 * @param elem the element
 * @return returns true if element is in the viewport
 */
export const isInViewport = (elem: HTMLElement): boolean => {
  const distance = elem.getBoundingClientRect();
  return (
    distance.top >= 0 &&
    distance.left >= 0 &&
    distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
