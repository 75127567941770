import {
  Component,
  OnInit,
  OnChanges,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  LOCALE_ID,
  Inject
} from '@angular/core';
import { Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

import { environment } from '../../../environments/environment';
import { AppRoute } from '../../routing/routes/app-routes.interface';
import { getWordpressPages } from '../../routing/wordpress-pages';
import {externalPages} from '../../routing/external-pages';


@Component({
  selector: 'p1-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('navigationContent', [
      state('is-open', style({
        height: '*',
        visibility: 'visible'
      })),
      state('*', style({
        height: '0px',
        visibility: 'hidden'
      })),
      transition('* <=> *', animate('.3s ease-in-out'))
    ])
  ]
})
/**
 * Component for side-navigation-menu
 */
export class NavigationComponent implements OnInit, OnChanges {

  @Input()
  /**
   * Determines if the navigation-menu is open (visible) or closed (hidden)
   */
  open: boolean = false;

  @Output()
  /**
   * Called everytime the open-state changes
   */
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  featureFlags = environment.featureFlags;
  wordpressPages: {[key: string]: string};
  appRoutes: AppRoute[]; // Filtered appRoutes that will be used in the navigation-menu
  routerConfig: AppRoute[]; // stored all router.modules (also from feature modules) from the app

  currentSubnav: string;
  locale: string;
  referenceProjectsLink: string =  externalPages.referenceProjects;

  private _closingAreaEnterTimeout: any; // Timeout property-filter-container

  constructor(router: Router, @Inject(LOCALE_ID) locale: string) {
    this.locale = locale;
    this.routerConfig = router.config;
    this.wordpressPages = getWordpressPages(locale);
  }


  /**
   * Sets appRoutes based on application appRoutes constant, filtered by showInNavigation-property
   * and sort the array to the hierarchy for the right display-order in navigation
   */
  ngOnInit() {
    if (this.routerConfig && this.routerConfig[0]) {
      this.appRoutes = this.routerConfig[0].children.filter(router => router.showInNavigation && router.hierarchy && !(environment.environmentName === 'prod' && router.developOnly)).sort((obj1, obj2) => obj1.hierarchy - obj2.hierarchy);
    }
  }


  /**
   * If the open state changes reset the open subnavigation
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['open']) {
      this.currentSubnav = undefined;
    }
  }

  /**
   * Emits toggle-event with explicit false state to close the menu
   */
  closeNavigation() {
    this.toggle.emit(false);
  }

  /**
   * Starts a timeout to close the menu after the mouse spent 300ms in the closing area
   */
  startClosingTimer() {
    this._closingAreaEnterTimeout = setTimeout(() => {
      this.toggle.emit(false);
    }, 300);
  }

  /**
   * Clears Timeout for closing the menu after mouse leaves the closing area
   */
  resetClosingTimer() {
    clearTimeout(this._closingAreaEnterTimeout);
    this._closingAreaEnterTimeout = null;
  }


  toggleSubNav(id: string) {
    this.currentSubnav = this.currentSubnav === id ? undefined : id;
  }
}
