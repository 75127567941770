import {
  Inject,
  Injectable,
  LOCALE_ID
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import {
  LoggerServiceInterface,
  LOGGING_SERVICE
} from '@p1/libs/logging';

import { environment } from '../../../environments/environment';
import { generateCatalogConfigFromBackendInput } from '../model/catalog-config.generator';
import { CatalogConfig } from '../model/catalog-config';
import { CatalogConfigBackendInterface } from '../model/catalog-config-backend.interface';


@Injectable({
  providedIn: 'root'
})
export class CatalogConfigService {

  constructor(private _httpClient: HttpClient, @Inject(LOGGING_SERVICE) private _logger: LoggerServiceInterface, @Inject(LOCALE_ID) private _locale: string) { }

  loadCatalogConfig() {
    return this._httpClient.get<CatalogConfig>(`${ environment.api_catalog_url }catalogs`, {params: {
      catalogIdentifier: environment.catalogIdentifier
    }
    })
      .pipe(
        map(response => {
          if (response && Array.isArray(response['catalogs']) && response['catalogs'][0]['catalogIdentifier'] === environment.catalogIdentifier) {
            return this.generateCatalogConfig(response['catalogs'][0], this._locale);
          } else {
            return null;
          }
        })
      );
  }

  generateCatalogConfig(config: CatalogConfigBackendInterface, locale: string) {
    try {
      return generateCatalogConfigFromBackendInput(config, locale);
    } catch (e) {
      this._logger.error(e);
      return null;
    }
  }
}
