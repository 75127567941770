<div class="navigation-wrapper"
 [ngClass]="{
      'navigation-wrapper--active-first-level': (navigationBarState$ | async) === NavigationBarState.activeFirstLevel,
      'navigation-wrapper--hover-over-active-first-level': (navigationBarState$ | async) === NavigationBarState.hoverOverActiveFirstLevel,
      'navigation-wrapper--empty-hover-over-active-first-level': (navigationBarState$ | async) === NavigationBarState.emptyHoverOverActiveFirstLevel,
      'navigation-wrapper--hover-first-level': (navigationBarState$ | async) === NavigationBarState.hoverFirstLevel,
      'navigation-wrapper--has-second-level': secondLevel,
      'navigation-wrapper--no-header-plugin': featureFlags.embedLayout
   }">
  <div class="navigation-bar"
       [ngClass]="{
            'search-is-active': searchExpanded && (searchEnabled$ | async)
        }"
       #navigationBar>
    <nav class="navigation"
         [ngClass]="{
            'search-is-active': searchExpanded  && (searchEnabled$ | async),
            'search-is-open': !searchExpanded  && (searchEnabled$ | async) && (searchPage$ | async)
         }">
      <div class="navigation-icons">
        <button class="navigation-icons__link navigation-icons__link--back button--link-style"
           title="Back to previous page"
           (click)="back($event)"
           i18n-title="step back in navigation-bar // page: all pages @@navigation-bar.component_back">
          <i class="navigation-icons__icon icon">
            <p1-lib-icons-icon-wrapper name="iconArrowLeft"></p1-lib-icons-icon-wrapper>
          </i>
        </button>

        <a class="navigation-icons__link navigation-icons__link--home"
           [ngClass]="{'navigation-icons__link--home-active': (home$ | async) && !showCategoryDropdown}"
           [title]="(homeRouterLink$ | async)?.title"
           [routerLink]="(homeRouterLink$ | async)?.url">
          <i class="navigation-icons__icon icon">
            <p1-lib-icons-icon-wrapper name="iconHome"></p1-lib-icons-icon-wrapper>
          </i>
        </a>
      </div>

      <div class="navigation__first-level">
        <button *ngIf="showLeftScrollTrigger$ | async"
             class="navigation__scrollable-indicator navigation__scrollable-indicator--left button--link-style"
             (click)="scrollLeft()">
          <i class="icon">
            <p1-lib-icons-icon-wrapper name="iconArrowLeft"></p1-lib-icons-icon-wrapper>
          </i>
        </button>

        <div *ngIf="visibleNavigationSection$ | async as navigationSection"
             class="navigation__scroll-container"
             (scroll)="emitFirstLevelScrollStream($event)">
          <ul class="navigation__list">
            <li *ngIf="navigationCategoryIsApp"
                class="navigation__listitem navigation__listitem--categorys"
                [ngClass]="{
                  'navigation__listitem--active': showCategoryDropdown
                  }"
                #categoryListItem>
              <a class="navigation__link"
                 (mouseenter)="setFirstLevelHover('categorys')"
                 (mouseleave)="setFirstLevelHover(undefined)"
                 (click)="toggleCategoryDropdown()">
                <span class="navigation__linktext"
                      i18n="navigation item for category-menu // page: all @@navigation-bar.component_category-menu">
                  Categories
                </span>
              </a>
            </li>
            <li *ngFor="let firstLevelItem of navigationSection.firstLevelItems"
                class="navigation__listitem"
                [ngClass]="{
                    'navigation__listitem--hover': (hoverFirstLevelItem$ | async)?.name === firstLevelItem.name,
                    'navigation__listitem--active': isLinkActive(firstLevelItem.routerLink) && !showCategoryDropdown
                    }">
              <a class="navigation__link"
                 [routerLink]="firstLevelItem.routerLink"
                 [queryParams]="firstLevelItem.queryParams"
                 (mouseenter)="setFirstLevelHover(firstLevelItem.name)"
                 (mouseleave)="setFirstLevelHover(undefined)">
                <span class="navigation__linktext">
                  {{ firstLevelItem.title }}
                </span>
              </a>
            </li>
            <li *ngIf="navigationCategoryIsApp && featureFlags.referenceProjects"
                class="navigation__listitem"
                [ngClass]="{
                  'navigation__listitem--hover': (hoverFirstLevelItem$ | async)?.name === 'refProjects'
                  }">
              <a class="navigation__link"
                 (mouseenter)="setFirstLevelHover('refProjects')"
                 (mouseleave)="setFirstLevelHover(undefined)"
                 [href]="referenceProjectsLink"
                 target="_blank"
                 data-gtm-event-category="ReferenceProjects"
                 data-gtm-event-action="Click reference projects"
                 data-gtm-event-label="Navigation bar - Link">
                <span class="navigation__linktext"
                      i18n="reference projects navigation item // page: all @@navigation-bar.component_ref-projects-nav-item">
                  Reference projects
                </span>
              </a>
            </li>
          </ul>
        </div>

        <button *ngIf="showRightScrollTrigger$ | async"
             class="navigation__scrollable-indicator navigation__scrollable-indicator--right button--link-style"
             (click)="scrollRight()">
          <i class="icon">
            <p1-lib-icons-icon-wrapper name="iconArrowRight"></p1-lib-icons-icon-wrapper>
          </i>
        </button>
      </div>

      <p1-search-bar
        *ngIf="(searchEnabled$ | async)"
        [ngClass]="{
          'search-is-active': searchExpanded && (searchEnabled$ | async),
          'search-is-open': !searchExpanded  && (searchEnabled$ | async) && (searchPage$ | async)
        }"
        [inlineMode]="true"
        [largeMode]="false"
        [searchTerm]="searchTermValue"
        [searchPage]="searchPage$ | async"
        (fieldFocused)="toggleSearch($event)"
        #searchBar>
      </p1-search-bar>
      <p1-comparison-menu *ngIf="featureFlags.productComparison && featureFlags.embedLayout"></p1-comparison-menu>
    </nav>

    <nav *ngIf="focusFirstLevelItem$ | async as firstLevelItem"
         class="navigation__second-level"
         (mouseenter)="setFirstLevelHover(firstLevelItem.name)"
         (mouseleave)="setFirstLevelHover(undefined)">
      <div class="navigation__scroll-container"
           (scroll)="emitFirstLevelScrollStream($event)">
        <ul class="navigation__list">
          <li *ngFor="let secondLevelItem of firstLevelItem.secondLevelItems"
              class="navigation__listitem"
              routerLinkActive="navigation__listitem--active">
            <a class="navigation__link"
               [routerLink]="secondLevelItem.routerLink">
              <span class="navigation__linktext">
                {{ secondLevelItem.title }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <p1-category-tree-menu *ngIf="showCategoryDropdown"
                           [positionLeft]="categoryDropdownLeft"
                           [positionTop]="categoryDropdownTop"
                           #categoryTreeMenu>
    </p1-category-tree-menu>
  </div>
</div>

<div *ngIf="searchExpanded && (searchEnabled$ | async)"
     class="backdrop"></div>
