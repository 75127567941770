export enum Breakpoint {
  bpMin     = 1,
  bpMinXs   = 2,
  bpMinS    = 3,
  bpMinM    = 4,
  bpMinL    = 5,
  bpMinXl   = 6,
  bpMinXxl  = 7,
  bpMinXxxl = 8
}
