import { combineReducers } from '@ngrx/store';
import {
  Params,
  RouterStateSnapshot
} from '@angular/router';
import {
  RouterStateSerializer,
  ROUTER_NAVIGATION,
  RouterAction
} from '@ngrx/router-store';

import * as routerActions from '../actions/router.actions';


export interface State {
  url: string;
  params: Params;
  queryParams: Params;
  title: string;
}

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const _url = routerState.url;
    const _queryParams = routerState.root.queryParams;
    const _params = route.params;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {
      url: _url,
      params: _params,
      queryParams: _queryParams
    };
  }
}

const url = (state: string = window.location.pathname + window.location.search, action: RouterAction<RouterStateUrl>): string => {
  switch (action.type) {
    case ROUTER_NAVIGATION:
      return action.payload.routerState.url;
    default:
      return state;
  }
};

const params = (state: Params, action: any): Params => {
  switch (action.type) {
    case ROUTER_NAVIGATION:
      return action.payload.routerState.params;
    default:
      return state;
  }
};

const queryParams = (state: Params, action: any): Params => {
  switch (action.type) {
    case ROUTER_NAVIGATION:
      return action.payload.routerState.queryParams;
    default:
      return state;
  }
};

const title = (state: string = 'Plan.One', action: routerActions.Actions): string => {
  switch (action.type) {
    case routerActions.SET_TITLE:
      return action.payload;
    default:
      return state;
  }
};

export const reducer = (state: State, action: any) => combineReducers({
  url,
  params,
  queryParams,
  title
})(state, action);

export const getUrl = (state: State) => state.url;
export const getParams = (state: State) => state.params;
export const getQueryParams = (state: State) => state.queryParams;
export const getTitle = (state: State) => state.title;
