import { Injectable } from '@angular/core';
import {
  Actions,
  Effect,
  ofType
} from '@ngrx/effects';
import {
  select,
  Store
} from '@ngrx/store';
import { defer } from 'rxjs';
import {
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import * as authReducer from '../../../auth/reducer/auth.reducer';
import { GoogleTagManagerService } from '../service/google-tag-manager.service';
import * as keycloakActions from '../../../auth/action/keycloak.actions';
import * as categoryTreeActions from '../../../category-tree/actions/category-tree.actions';
import * as productActions from '../../../product/actions/product.actions';
import * as productComparisonActions from '../../../product-comparison/actions/product-comparison.actions';
import * as searchActions from '../../../search/actions/search.actions';
import { GoogleTagManagerEvent } from '../service/google-tag-manager-event';
import { environment } from '../../../../environments/environment';
import * as productReducer from '../../../product/reducer';


/**
 * Contains side effects for Google Tag Manager
 */
@Injectable()
export class GoogleTagManagerEffects {

  /**
   * Pushes isAuthenticatedUser flag to Google Tag Manager
   * Dispatches push variable succeeded action
   */
  @Effect({ dispatch: false })
  loginSucceeded$ = this._actions.pipe(
    ofType(keycloakActions.KEYCLOAK_LOGIN_SUCCEEDED),
    tap(() => {
      this._googleTagManagerService.pushVariable({ isAuthenticatedUser: true });
    })
  );

  /**
   * Pushes isAuthenticatedUser flag to Google Tag Manager if access token is given
   */
  @Effect({ dispatch: false })
  accessTokenProvided$ = defer(() => this._store.pipe(
    select(authReducer.getKeycloakAccessToken),
    startWith(),
    first(),
    filter(accessToken => !!accessToken),
    tap(() => {
      this._googleTagManagerService.pushVariable({ isAuthenticatedUser: true });
    })
  ));

  @Effect({ dispatch: false })
  appContextIdentifier$ = this._actions.pipe(
    ofType(categoryTreeActions.CATEGORY_TREE_UUID_REQUESTED, categoryTreeActions.FETCH_CATEGORY_TREE_SUMMARY_SUCCEEDED),
    tap(action => {
      let catalogIdentifier = environment.catalogIdentifier || '';
      if (action.payload.treeUuid) {
        catalogIdentifier += ' ' + action.payload.treeUuid;
      }

      catalogIdentifier = catalogIdentifier.trim();

      if (catalogIdentifier !== '') {
        this._googleTagManagerService.pushVariable({
          appContextIdentifier: catalogIdentifier
        });
      }
    })
  );

  /**
   * Push userId to Google Tag Manager after user uuid is available
   */
  @Effect({ dispatch: false })
  updateUser$ = this._actions.pipe(
    ofType(keycloakActions.KEYCLOAK_LOGIN_SUCCEEDED),
    withLatestFrom(this._store.select(authReducer.getUser)),
    tap(([_, user]) => {
      this._googleTagManagerService.pushVariable({
        userId: user.uuid,
        excludeFromAnalytics: user.excludeFromAnalytics
      });
    })
  );

  /**
   * Pushes product comparison from preview related event to Google Tag Manager
   */
  @Effect({ dispatch: false })
  productComparisonPreviewClicked$ = this._actions.pipe(
    ofType(productComparisonActions.createProductComparisonFromProductsSuccess),
    map(action => action.context),
    tap(context => {
      const event: GoogleTagManagerEvent = {
        category: context,
        action: 'Open productcomparison from preview',
        label: 'ComparisonPreview - Open comparison - Button'
      };
      this._googleTagManagerService.pushEvent(event);
    })
  );

  @Effect({dispatch: false})
  emptySearchPageShown$ = this._actions.pipe(
    ofType(searchActions.searchResultPageOpened),
    switchMap(searchAction => this._actions.pipe(
      ofType(searchActions.categoryDetailSearchResultsLoaded),
      filter(result => !result.totalCounts || result.totalCounts.categories === 0),
      tap(_ => {
        this._googleTagManagerService.pushEvent({
          category: 'SearchResults',
          action: `Called empty search results page with search term - ${ searchAction.searchTerm }`,
          label: 'SearchResults - Page'
        });
      })
    ))
  );

  @Effect({dispatch: false})
  externalAdditionalContentClicked$ = this._actions.pipe(
    ofType(productActions.USER_CLICKED_EXTERNAL_LINK),
    tap(action => {
      this._googleTagManagerService.pushEvent({
        category: 'ProductDetailpage',
        action: `External content product ${action.payload.contentSection} - ID ${action.payload.productUuid} - Link ${action.payload.link.url}`,
        label: `External content ${action.payload.contentSection} - Source ${action.payload.source.key}`
      });
    })
  );

  @Effect({ dispatch: false })
  externalContendFeedbackSend$ = this._actions.pipe(
    ofType(productActions.USER_SEND_EXTERNAL_FEEDBACK),
    withLatestFrom(this._store.select(productReducer.getSelectedItemUuid)),
    tap(([action, productUuid]) => {
      this._googleTagManagerService.pushEvent({
        category: 'ProductDetailpage',
        action: 'Send external content feedback - Category ' + action.payload.category
                + ' - Url ' + action.payload.url
                + ' - Useful ' + action.payload.useful
                + ' - Feedback ' + action.payload.feedback
                + ' - ID ' + productUuid,
        label: 'ProductExternalContent - Feedback Dialog - Send Button'
      });
    })
  );

  @Effect({dispatch: false})
  init$ = defer(() => {
    if (environment.catalogIdentifier) {
      this._googleTagManagerService.pushVariable({appContextIdentifier: environment.catalogIdentifier});
    }
  });

  /**
   * @param _actions
   * @param _googleTagManagerService
   * @param _store
   */
  constructor(
    private _actions: Actions<
      keycloakActions.Actions
      |categoryTreeActions.Actions
      |productActions.Actions
      |searchActions.Actions>,
    private _googleTagManagerService: GoogleTagManagerService,
    private _store: Store<authReducer.StateInterface>
  ) {}
}
