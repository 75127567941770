import { LocationBackendInterface } from './';
import { Location } from './';


/**
 * Generates a Location based on api input
 *
 * @param input
 * @returns
 */
export const generateLocationFromBackendInput = (input: LocationBackendInterface): Location => ({
  uuid: input.Uuid,
  address: input.Address,
  road: input.Road,
  houseNumber: input.HouseNumber,
  city: input.City,
  postalCode: input.PostalCode,
  country: input.Country,
  state: input.State,
  longitude: input.Longitude,
  latitude: input.Latitude
});
