import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';
import {
  select,
  Store
} from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as authReducer from '../../auth/reducer/auth.reducer';
import * as rootReducer from '../../app.reducers';
import { User } from '../../user/model';
import { environment } from '../../../environments/environment';


/**
 * component representing profile menu dropdown in header
 */
@Component({
  selector: 'p1-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  animations: [
    trigger('profilemenuContent', [
      state('is-open', style({
        height: '*',
        visibility: 'visible'
      })),
      state('*', style({
        height: '0px',
        visibility: 'hidden'
      })),
      transition('* <=> *', animate('.3s ease-in-out'))
    ])
  ]
})
export class ProfileMenuComponent {

  user$: Observable<User>;
  hasSupplierSelfServices$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  featureFlags = environment.featureFlags;
  currentUrl$: Observable<string>;
  isOpen: boolean = false;

  private _ignoreToggle: boolean = false;
  private _ignoreToggleTimeout;

  constructor(
    private elementRef: ElementRef,
    private _store: Store<authReducer.StateInterface>,
    private _router: Router
  ) {
    this.user$ = this._store.pipe(
      select(authReducer.getUser)
    );

    this.hasSupplierSelfServices$ = this.user$.pipe(
      map(user => (user?.realmAccessRoles?.includes('supplierSelfServices') === true))
    );

    this.isAuthenticated$ = this._store.pipe(
      select(authReducer.getIsAuthenticated)
    );

    this.currentUrl$ = this._store.pipe(
      select(rootReducer.getRouterUrl)
    );
  }

  /**
   * listener for click on document to close overlay if clicked outside
   */
  @HostListener('document:click', ['$event'])
  onMouseUp(event) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.toggleMenu();
    }
  }

  /**
   * Catches events within the component
   */
  @HostListener('click', ['$event'])
  catchEvent(event) {
    if (event.target['tagName'] && event.target.tagName === 'A') {
      this.toggleMenu();
    }
  }

  /**
   * Close search if user clicked on overlay or outside component
   */
  @HostListener('window:click', ['$event.target'])
  @HostListener('window:touchstart', ['$event.target'])
  onWindowClick() {
    if (this.isOpen) {
      window.addEventListener('searchFocused', () => {
        this.isOpen = false;
      });
    }
  }

  /**
   * toggles the menu
   * Starts a timeout and blocks toggleing for 500ms to prevent unintended closing with double click
   */
  toggleMenu() {
    if (this._ignoreToggle) {
      return;
    }

    this.isOpen = !this.isOpen;
    this._ignoreToggle = true;

    clearTimeout(this._ignoreToggleTimeout);

    this._ignoreToggleTimeout = setTimeout(() => {
      this._ignoreToggle = false;
    }, 500);
  }

  /**
   * dispatching logout action
   */
  logout() {
    this._router.navigate(['auth', 'logout'], {queryParams: {redirectTo: '/auth/login'}});
  }
}
