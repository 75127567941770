import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve
} from '@angular/router';
import {
  select,
  Store
} from '@ngrx/store';
import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  take,
  filter
} from 'rxjs/operators';

import { StateInterface } from '../../app.reducers';
import * as categoryTreeActions from '../actions/category-tree.actions';
import * as catalogConfigReducer from '../../catalog-config/reducers';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TreeUuidResolver implements Resolve<any> {


  constructor(private _store: Store<StateInterface>) {}

  /**
   * Dispatches SelectItem Action of Product
   *
   * @param route
   * @returns
   */
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route && route.queryParamMap && route.queryParamMap.has('treeUuid')) {
      return this._loadTreeByUuid(route.queryParamMap.get('treeUuid'));
    } else if (environment.featureFlags.catalogConfig) {
      this._store.pipe(
        select(catalogConfigReducer.getCatalogConfig),
        filter(catalogConfig => !!catalogConfig),
        take(1),
        map(catalogConfig => catalogConfig.treeUuid)
      ).subscribe(treeUuid => {
        if (treeUuid) {
          return this._loadTreeByUuid(treeUuid);
        }
      });
    } else {
      return this._loadTreeByUuid('1692656f6590492f903660a60feb3f21');
    }
  }

  private _loadTreeByUuid(treeUuid: string) {
    this._store.dispatch(new categoryTreeActions.CategoryTreeUuidRequestedAction({ treeUuid }));
    return of(true);
  }
}
