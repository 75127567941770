import { Action } from '@ngrx/store';

import { TreeCategory } from '@p1/libs/catalog-service';

export const CATEGORY_TREE_UUID_REQUESTED = '[CATEGORY_TREE] Uuid requested via URL';
export class CategoryTreeUuidRequestedAction implements Action {
  readonly type = CATEGORY_TREE_UUID_REQUESTED;

  constructor(public payload: {treeUuid: string}) {}
}

/**
 * Fetch category tree summary succeeded, payload contains category tree summary
 */
export const FETCH_CATEGORY_TREE_SUMMARY_SUCCEEDED = '[CATEGORY_TREE] Fetch Category Tree Summary Succeeded';
export class FetchCategoryTreeSummarySucceededAction implements Action {
  readonly type = FETCH_CATEGORY_TREE_SUMMARY_SUCCEEDED;


  constructor(public payload: {treeNodes: TreeCategory[]; treeUuid: string}) {}
}

/**
 * Fetch category tree summary failed, payload may contain additional information
 */
export const FETCH_CATEGORY_TREE_SUMMARY_FAILED = '[CATEGORY_TREE] Fetch Category Tree Summary Failed';
export class FetchCategoryTreeSummaryFailedAction implements Action {
  readonly type = FETCH_CATEGORY_TREE_SUMMARY_FAILED;


  constructor(public payload: any) {}
}

export type Actions = CategoryTreeUuidRequestedAction
  | FetchCategoryTreeSummarySucceededAction
  | FetchCategoryTreeSummaryFailedAction;
