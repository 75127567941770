import * as productActions from '../../../actions/product.actions';


/**
 * TempProptertyFilterIdsWithoutValues reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 * @returns the new state
 */
export const tempPropertyFilterIdsWithoutValues = (state: string[], action: productActions.Actions): string[] => {
  switch (action.type) {
    case productActions.UPDATE_TEMP_PROPERTY_FILTER_VALUES:
      const tempPropertyFilterValues = action.payload;
      const result: string[] = [];

      if (tempPropertyFilterValues) {
        Object.keys(tempPropertyFilterValues).forEach((key) => {
          if (tempPropertyFilterValues[key] == null) {
            result.push(key);
          }
        });
      }

      return result;
    case productActions.ADD_PROPERTY_FILTERS_WITHOUT_VALUE:
      return action.payload;
    case productActions.REMOVE_ACTIVE_PROPERTY_FILTERS:
      const filterToRemove: string[] = action.payload;
      if (Array.isArray(filterToRemove) && Array.isArray(state)) {
        return state.filter(id => !filterToRemove.includes(id));
      }
      return state;
    case productActions.REMOVE_ALL_ACTIVE_PROPERTY_FILTERS:
      return undefined;
    default:
      return state;
  }
};
