import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';


@Injectable()
/**
 * Intercepts api requests to fix the locale param
 * Angular uses locales with '-', but our api uses '_'
 */
export class LocaleInterceptor implements HttpInterceptor {


  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiRequest: boolean = req.url.indexOf(environment.api_url) > -1;

    if (isApiRequest && req.urlWithParams.indexOf('locale=') > -1) {

      // We use en_GB as fallback for nl-NL because most of our APIs do not support nl_NL by now
      // When some endpoints support the new locale, we should extend the condition here to enable nl_NL on selected endpoints
      let nlReplacementLocale = 'en_GB';
      if (req.urlWithParams.indexOf(`${environment.api_url}categorytree`) > -1) {
        nlReplacementLocale = 'nl_NL';
      }

      const newUrl = req.urlWithParams
        .replace('locale=en-GB', 'locale=en_GB')
        .replace('locale=nl-NL', `locale=${nlReplacementLocale}`)
        .replace('locale=de-DE', 'locale=de_DE');
      req = req.clone({ url: newUrl });
    }

    return next.handle(req);
  }
}
