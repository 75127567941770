import { FilterRequestValue } from './filter-request-value';


export const normalizeFilterRequestValue = (input: FilterRequestValue) => {
  const normalizedRequestValue: FilterRequestValue = {
    id: input.id,
    value: null
  };

  if (input.name) {
    normalizedRequestValue.name = input.name;
  }

  if (input.value instanceof Array) {
    const val = input.value;
    normalizedRequestValue.value = val.length <= 1 ? val[0] : val;
  } else if (input.value instanceof Object) {
    // TODO: Refactor handling of advertised filter to settings section
    if (input.id === 'product_advertised') {
      normalizedRequestValue.value = {
        count: input.value.count,
        slot: input.value.slot
      };
    } else {
      normalizedRequestValue.value = {
        lower: input.value.lower,
        upper: input.value.upper
      };
    }

  } else {
    normalizedRequestValue.value = input.value;
  }

  return normalizedRequestValue;
};
