import * as productActions from '../../../actions/product.actions';
import { Cluster } from '../../../../shared/cluster/model';


/**
 * propertyClusters reducer
 *
 * @param state
 * @param action
 * @returns
 */
export const propertyClusters = (state: Cluster[], action: productActions.Actions): Cluster[] => {
  switch (action.type) {
    case productActions.FETCH_FILTER_SUCCEEDED:
      return action.payload.propertyClusters;
    default:
      return state;
  }
};
