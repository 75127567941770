import { ProductSearchResultBackendInterface } from './product-search-result.backend-interface';
import { ProductSearchResult } from './product-search-result';
import { generateProductFromBackendInput } from '../../../product/model/product.generators';
import { generateSearchResultFromBackendInput } from '../search-result';


export const generateProductSearchResultFromBackendInput = (input: ProductSearchResultBackendInterface[], urlTarget?: string): ProductSearchResult[] => {
  const productSearchResult: ProductSearchResult[] =
          input.map(product => {
            const generatedProduct = generateProductFromBackendInput(product);
            return {
              ...generateSearchResultFromBackendInput(product,
                urlTarget + '/' + generatedProduct.urlPathSegment),
              ...generatedProduct };
          });
  return productSearchResult;
};
