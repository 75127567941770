import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconsRegistry } from '@p1/libs/icons/src/lib/registry/icons.registry';
import {
  iconMailOutline
} from '@p1/libs/icons/src/lib/registry/icons';

import { SharedModule } from '../shared/shared.module';
import { NewsDialogComponent } from './news-dialog/news-dialog.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsNavigationComponent } from './news-navigation/news-navigation.component';
import { NewsStoreModule } from './news.store.module';
import { NewsCookieService } from './service/news-cookie.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NewsStoreModule
  ],
  declarations: [
    NewsDialogComponent,
    NewsListComponent,
    NewsNavigationComponent
  ],
  exports: [
    NewsDialogComponent,
    NewsListComponent,
    NewsNavigationComponent
  ],
  providers: [
    NewsCookieService
  ]
})
export class NewsModule {
  constructor(private _iconsRegistry: IconsRegistry) {
    this._iconsRegistry.registerIcons([
      iconMailOutline
    ]);
  }
}
