import {
  createAction,
  props
} from '@ngrx/store';

import { Breakpoint } from '../enum/breakpoint.enum';
import { RightOffCanvasState } from '../enum/right-off-canvas-state.enum';

export const toggleRightOffCanvas = createAction(
  '[LAYOUT] Toggle right off canvas',
  props<{rightOffCanvasMode: RightOffCanvasState}>()
);

export const breakpointChangeDetected = createAction(
  '[LAYOUT] Breakpoint change detected',
  props<{breakpoint: Breakpoint}>()
);
