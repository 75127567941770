import {
  TreeCategory,
  TreeCategoryBackendInterface
} from '@p1/libs/catalog-service';


export const treeCategoryMockFull: TreeCategory = {
  uuid: '123abc',
  name: 'Category',
  advertised: true,
  hideInSearch: true,
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
  image: {uuid: 'testid', name:'testname'},
  parentUuid: '456def',
  pathedSlug: 'go/to/category'
};

export const treeCategoryBackendMockFull: TreeCategoryBackendInterface = {
  uuid: '123abc',
  name: 'Category',
  active: true,
  advertised_category: true,
  hide_in_search: true,
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
  image: {id: 'testid', name: 'testname'},
  parent_category: '456def',
  pathed_slugs: ['go/to/category']
};
