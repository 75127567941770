import { Injectable } from '@angular/core';
import {
  Observable
} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  select,
  Store
} from '@ngrx/store';

import * as authReducer from '../../auth/reducer/auth.reducer';
import { NavigationSection } from './navigation-section.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserNavigationBarItemsService {

  userNavigationSection$: Observable<NavigationSection>;
  featureFlags = environment.featureFlags;

  private _userDataNavItem = {
    title: $localize`:|user navigation item edit data @@navigation-bar.component_user-edit-data-nav-item:Edit data`,
    routerLink: ['/userAccount', 'profile', 'data']
  };

  private _userAccessNavItem = {
    title: $localize`:|user navigation item edit password @@navigation-bar.component_user-edit-password-nav-item:Edit password`,
    routerLink: ['/userAccount', 'profile', 'access']
  };

  private _userAccountNavItem = {
    title: $localize`:|user navigation item my profile @@navigation-bar.component_user-my-profile-nav-item:My profile`,
    routerLink: ['/userAccount', 'profile'],
    name: 'userAccount',
    secondLevelItems: [
      this._userDataNavItem,
      this._userAccessNavItem
    ]
  };

  private _watchlistsNavItem = {
    title: $localize`:|user navigation item my watchlists @@navigation-bar.component_user-my-watchlists-nav-item:My watchlists`,
    routerLink: ['/myWatchlists'],
    name: 'myWatchlists'
  };

  constructor(
    private _store: Store<authReducer.StateInterface>
  ) {

    /**
     * Generates the UserNavigationSection
     */
    this.userNavigationSection$ = this._store.pipe(
      select(authReducer.getUser),
      map(user => {
        const userNavigationSection: NavigationSection = {
          title: user.name,
          firstLevelItems: []
        };

        userNavigationSection.firstLevelItems.push(this._userAccountNavItem);

        if (this.featureFlags.myWatchlists) {
          userNavigationSection.firstLevelItems.push(this._watchlistsNavItem);
        }

        return userNavigationSection;
      })
    );
  }
}
