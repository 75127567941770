import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import {
  IconsModule,
  IconsRegistry,
  iconAdvancedTechnicalProperties,
  iconBin,
  iconCheckCircled,
  iconCircle,
  iconCommonProperties,
  iconComparison,
  iconConstructionalFireProtection,
  iconControlAndDrive,
  iconCross,
  iconCrossCircled,
  iconCurvedArrow,
  iconDesignProperties,
  iconDimensions,
  iconDoorFitting,
  iconDoorFrame,
  iconDoorPanel,
  iconDownload,
  iconEnvironmentIndicator,
  iconFileCsv,
  iconFilter,
  iconFilterPlaceholder,
  iconGateFrame,
  iconGatePanel,
  iconMagnifierChecked,
  iconMagnifierCross,
  iconPackingWarranty,
  iconQuestionCircle,
  iconTechnicalProperties,
  iconVdi,
  illustrationProductTileStack
} from '@p1/libs/icons';

import { ProductListComponent } from './list/product-list.component';
import { ProductService } from './service/product.service';
import { HighlightFilterDirective } from './directive-highlight-filter/highlight-filter.directive';
import { SharedModule } from '../shared/shared.module';
import { ProductScoreFractionComponent } from './product-score-fraction/product-score-fraction.component';
import { ProductScoreBarComponent } from './product-score-bar/product-score-bar.component';
import { ProductScoreComponent } from './product-score/product-score.component';
import { ProductFilterScoresComponent } from './product-filter-scores/product-filter-scores.component';
import { FilterScoreIndicatorComponent } from './filter-score-indicator/filter-score-indicator.component';
import { ProductPropertyComponent } from './product-property/product-property.component';
import { WatchlistDropdownLoaderModule } from '../my-watchlists/dropdown-loader/watchlist-dropdown-loader.module';
import { FilterClusterIconsComponent } from './filter-cluster-icons/filter-cluster-icons.component';
import { InstructionPopupComponent } from './instruction-popup/instruction-popup.component';
import { environment } from '../../environments/environment';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    IconsModule,
    environment.featureFlags.myWatchlists ? WatchlistDropdownLoaderModule : []
  ],
  declarations: [
    ProductListComponent,
    ProductScoreComponent,
    ProductScoreFractionComponent,
    ProductScoreBarComponent,
    ProductPropertyComponent,
    ProductFilterScoresComponent,
    HighlightFilterDirective,
    FilterScoreIndicatorComponent,
    FilterClusterIconsComponent,
    InstructionPopupComponent
  ],
  providers: [
    ProductService
  ],
  exports: [
    ProductListComponent,
    ProductScoreComponent,
    ProductScoreFractionComponent,
    ProductScoreBarComponent,
    ProductPropertyComponent,
    ProductFilterScoresComponent,
    HighlightFilterDirective,
    FilterScoreIndicatorComponent,
    FilterClusterIconsComponent,
    InstructionPopupComponent
  ]
})
export class ProductSharedModule {
  constructor(private _iconsRegistry: IconsRegistry) {
    this._iconsRegistry.registerIcons([
      iconAdvancedTechnicalProperties,
      iconBin,
      iconCheckCircled,
      iconCircle,
      iconCommonProperties,
      iconComparison,
      iconConstructionalFireProtection,
      iconControlAndDrive,
      iconCross,
      iconCrossCircled,
      iconCurvedArrow,
      iconDesignProperties,
      iconDimensions,
      iconDoorFitting,
      iconDoorFrame,
      iconDoorPanel,
      iconDownload,
      iconEnvironmentIndicator,
      iconFileCsv,
      iconFilter,
      iconFilterPlaceholder,
      iconGateFrame,
      iconGatePanel,
      iconMagnifierChecked,
      iconMagnifierCross,
      iconPackingWarranty,
      iconQuestionCircle,
      iconTechnicalProperties,
      iconVdi,
      illustrationProductTileStack
    ]);
  }
}
