import * as productActions from '../../../actions/product.actions';


/**
 * searchResults reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const searchResultIdentifiers = (state: {uuid: string; advertised: boolean}[],
                                        action: productActions.Actions): {uuid: string; advertised: boolean}[] => {
  switch (action.type) {
    case productActions.FETCH_MULTI_SUCCEEDED:
      if (action.payload && Array.isArray(action.payload.items)) {
        return action.payload.items.map(item => ({
          uuid: item.uuid,
          advertised: !!item.advertised
        }));
      }
      return undefined;
    default:
      return state;
  }
};
