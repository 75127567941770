import { Action } from '@ngrx/store';

import { ActionWithPayload } from '../../shared/ngrx/action-with-payload.class';
import { AnalyticsCompany } from '../model';
import { Lead } from '../model/leads/lead.interface';


export const FETCH_ANALYTICS_COMPANY = '[ANALYTICS_COMPANY] Fetch analytics company';
export class FetchAnalyticsCompanyAction implements Action {
  readonly type = FETCH_ANALYTICS_COMPANY;
}

export const FETCH_ANALYTICS_COMPANY_SUCCEEDED = '[ANALYTICS_COMPANY] Fetch analytics company succeeded';
export class FetchAnalyticsCompanySucceededAction implements ActionWithPayload<AnalyticsCompany> {
  readonly type = FETCH_ANALYTICS_COMPANY_SUCCEEDED;

  constructor(public payload: AnalyticsCompany) {}
}

export const FETCH_ANALYTICS_COMPANY_FAILED = '[ANALYTICS_COMPANY] Fetch analytics company failed';
export class FetchAnalyticsCompanyFailedAction implements ActionWithPayload<any> {
  readonly type = FETCH_ANALYTICS_COMPANY_FAILED;

  constructor(public payload: any) {}
}

export const PATCH_ANALYTICS_COMPANY_CATEGORY_STATUS = '[ANALYTICS_COMPANY] Patch analytics company category status';
export class PatchAnalyticsCompanyCategoryStatusAction implements Action {
  readonly type = PATCH_ANALYTICS_COMPANY_CATEGORY_STATUS;

  constructor(public payload: string) {}
}

export const PATCH_ANALYTICS_COMPANY_CATEGORY_STATUS_SUCCEEDED = '[ANALYTICS_COMPANY] Patch analytics company category status succeeded';
export class PatchAnalyticsCompanyCategoryStatusSucceededAction implements ActionWithPayload<AnalyticsCompany> {
  readonly type = PATCH_ANALYTICS_COMPANY_CATEGORY_STATUS_SUCCEEDED;

  constructor(public payload: AnalyticsCompany) {}
}

export const PATCH_ANALYTICS_COMPANY_CATEGORY_STATUS_FAILED = '[ANALYTICS_COMPANY] Patch analytics company category status failed';
export class PatchAnalyticsCompanyCategoryStatusFailedAction implements ActionWithPayload<any> {
  readonly type = PATCH_ANALYTICS_COMPANY_CATEGORY_STATUS_FAILED;

  constructor(public payload: any) {}
}

export const UPDATE_ANALYTICS_COMPANY = '[ANALYTICS_COMPANY] Update the analytics company';
export class UpdateAnalyticsCompanyAction implements ActionWithPayload<AnalyticsCompany> {
  readonly type = UPDATE_ANALYTICS_COMPANY;

  constructor(public payload: AnalyticsCompany) {}
}

export const FETCH_LEADS = '[FETCH_LEADS] Fetch leads';
export class FetchLeadsAction implements Action {
  readonly  type = FETCH_LEADS;

  constructor(public payload: string) {}
}

export const FETCH_LEADS_SUCCEEDED = '[FETCH_LEADS] Fetch leads succeeded';
export class FetchLeadsSucceededAction implements Action {
  readonly  type = FETCH_LEADS_SUCCEEDED;

  constructor(public payload: Lead[]) {}
}

export const FETCH_LEADS_FAILED = '[FETCH_LEADS] Fetch leads failed';
export class FetchLeadsFailedAction implements Action {
  readonly  type = FETCH_LEADS_FAILED;

  constructor(public payload: string) {}
}

export const PATCH_LEAD_STATUS = '[PATCH_LEAD] Patch lead status';
export class PatchLeadStatusAction implements Action {
  readonly type = PATCH_LEAD_STATUS;

  constructor(public payload: {leadUuid; processed}) {}
}

export const PATCH_LEAD_STATUS_SUCCEEDED = '[PATCH_LEAD] Patch lead status succeeded';
export class PatchLeadStatusSucceededAction implements ActionWithPayload<Lead> {
  readonly type = PATCH_LEAD_STATUS_SUCCEEDED;

  constructor(public payload: Lead) {}
}

export const PATCH_LEAD_STATUS_FAILED = '[PATCH_LEAD] Patch lead status failed';
export class PatchLeadStatusFailedAction implements ActionWithPayload<any> {
  readonly type = PATCH_LEAD_STATUS_FAILED;

  constructor(public payload: any) {}
}

export const UPDATE_LEAD = '[PATCH_LEAD] Update changed lead';
export class UpdateLeadAction implements ActionWithPayload<Lead> {
  readonly type = UPDATE_LEAD;

  constructor(public payload: Lead) {}
}

export type Actions = FetchAnalyticsCompanyAction
  | FetchAnalyticsCompanySucceededAction
  | FetchAnalyticsCompanyFailedAction
  | PatchAnalyticsCompanyCategoryStatusAction
  | PatchAnalyticsCompanyCategoryStatusSucceededAction
  | PatchAnalyticsCompanyCategoryStatusFailedAction
  | UpdateAnalyticsCompanyAction
  | FetchAnalyticsCompanyFailedAction
  | FetchLeadsAction
  | FetchLeadsSucceededAction
  | FetchLeadsFailedAction
  | PatchLeadStatusAction
  | PatchLeadStatusSucceededAction
  | PatchLeadStatusFailedAction
  | UpdateLeadAction;
