import { generateFileContainerFromBackendInput } from '@p1/libs/file';

import {
  ProductDownloads,
  ProductDownloadsBackendInterface
} from './';


/**
 * Generates a ProductDownloads object based on api input
 *
 * @param input
 * @returns
 */
export const generateProductDownloadsFromBackendInput = (input: ProductDownloadsBackendInterface): ProductDownloads => {
  try {
    const productDownloads: ProductDownloads = {};

    if (input.Brochures) {
      productDownloads.brochures = input.Brochures.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (input.Certificates) {
      productDownloads.certificates = input.Certificates.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (input.CADData) {
      productDownloads.cadData = input.CADData.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (input.TenderText) {
      productDownloads.tenderText = input.TenderText.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (input.Textures) {
      productDownloads.textures = input.Textures.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (input.BIMModels) {
      productDownloads.bimModels = input.BIMModels.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (input.BIMModelCategories) {
      productDownloads.bimModelCategories = {};

      if (input.BIMModelCategories.Archicad) {
        productDownloads.bimModelCategories.archicad = input.BIMModelCategories.Archicad.map(generateFileContainerFromBackendInput).filter(item => !!item);
      }

      if (input.BIMModelCategories.Revit) {
        productDownloads.bimModelCategories.revit = input.BIMModelCategories.Revit.map(generateFileContainerFromBackendInput).filter(item => !!item);
      }

      if (input.BIMModelCategories.Other) {
        productDownloads.bimModelCategories.other = input.BIMModelCategories.Other.map(generateFileContainerFromBackendInput).filter(item => !!item);
      }
    }

    return productDownloads;
  } catch (e) {
    return e;
  }
};
