import {
  Inject,
  Injectable,
  Optional
} from '@angular/core';

import {
  LoggerServiceInterface,
  LOGGING_SERVICE
} from '@p1/libs/logging';

import { P1SvgGraphic } from '../model/p1-svg-graphic.interface';


@Injectable({
  providedIn: 'root'
})
export class IconsRegistry {

  private _registry = new Map<string, string>();

  constructor(
    @Optional() @Inject(LOGGING_SERVICE) private _loggingService: LoggerServiceInterface,
  ) {}

  registerIcons(icons: P1SvgGraphic[]): void {
    icons.forEach((icon: P1SvgGraphic) => this._registry.set(icon.name, icon.data));
  }

  getIcon(iconName: string): string | undefined {
    if (!this._registry.has(iconName)) {
      if (this._loggingService) {
        this._loggingService.warn(`Icon ${iconName} not found`);
      }
      return undefined;
    }
    return this._registry.get(iconName);
  }
}
