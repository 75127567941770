import { Injectable } from '@angular/core';

import {
  FilterRequestValue,
  normalizeFilterRequestValue
} from '../../common/models/filter-request-value';
import { FilterValueType } from '../filter-value-type.enum';


@Injectable()
/**
 * Helper Service for filters
 */
export class FilterHelperService {

  /**
   * Returns a url filter query string based on the input filter value map
   *
   * @param valueObject a map with filter values (key: filtername, value)
   * @returns a query string
   */
  static getQueryStringFromValueObject(valueObject: {[filterId: string]: FilterValue} = {}): string {
    return Object.keys(valueObject).map(key => {
      let valueString = '';
      if (valueObject[key] instanceof Array) {
        valueString = valueObject[key].map(v => typeof v === 'string' ? `"${ v }"` : v).join(',');
      } else if (valueObject[key] instanceof Object) {
        valueString = `<${ valueObject[key].lower }-${ valueObject[key].upper }>`;
      } else if (typeof valueObject[key] === 'boolean' || typeof valueObject[key] === 'number') {
        valueString = valueObject[key].toString();
      } else if (typeof valueObject[key] === 'string') {
        valueString = `"${ valueObject[key] }"`;
      }

      return `${ key }=${ valueString }`;
    }).join(';');
  }

  /**
   * Returns a value map based on the input filter query string
   *
   * @param queryString a filter query-string
   * @returns a filter value map
   */
  static getValueObjectFromQueryString(queryString: string = ''): {[filterId: string]: FilterValue} {
    const valueObject = {};
    const keyValuePairs = queryString.split(';');

    keyValuePairs.forEach((keyValuePair) => {
      const parts = keyValuePair.split('=');
      const key = parts[0];
      const valueString = parts[1];

      if (valueString === 'false' || valueString === 'true') {
        valueObject[key] = valueString === 'true';
      } else if (valueString.substr(0, 1) === '<' && valueString.substr(valueString.length - 1, 1) === '>') {
        const valueParts = valueString.substr(1, valueString.length - 2).split('-');
        valueObject[key] = {
          lower: Number(valueParts[0]),
          upper: Number(valueParts[1])
        };
      } else {
        const valueParts = valueString
          .split(',')
          .map(v => {
            // If the value should be a string value, just return it. Otherwise try to cast a number and return the result
            if (v.substr(0, 1) === '"' && v.substr(v.length - 1, 1) === '"') {
              return v.substr(1, v.length - 2);
            } else {
              return !isNaN(Number(v)) ? Number(v) : v;
            }
          });

        valueObject[key] = valueParts.length > 1 ? valueParts : valueParts[0];
      }
    });

    return valueObject;
  }

  /**
   * Returns a list of FilterRequestValues based on the input filter-value map
   *
   * @param values a map with filter-values (key: filtername, value)
   * @returns a list of FilterRequestValues
   */
  static getFilterRequestValuesFromValueObject(values: {[filterId: string]: FilterValue} = {}): FilterRequestValue[] {
    return Object.keys(values)
      .map(key => ({
        id: key,
        value: values[key]
      }))
      .filter(filterValue => filterValue.value != null)
      .map(normalizeFilterRequestValue);
  }

  /**
   * Returns a map with filter values based on input list of FilterRequestValues
   *
   * @param requestValues a list of either BasicFilterRequestValues or PropertyFilterRequestValues
   * @returns a filter value map
   */
  static getValueObjectFromRequestValues(requestValues: FilterRequestValue[] = []): {[filterId: string]: FilterValue} {
    const result = {};

    requestValues.forEach((requestValue) => {
      if (requestValue.id) {
        result[requestValue.id] = requestValue.value;
      }
    });

    return result;
  }

  static getFilterValueType(value: any): FilterValueType {
    if (value instanceof Array) {
      return FilterValueType.ListValue;
    }
    if (value instanceof Object) {
      return FilterValueType.BoundedValue;
    }
    if (value != null) {
      return FilterValueType.SingleValue;
    }
    return FilterValueType.None;
  }

  /**
   * Returns the given filter value in a form based on the given FilterValueType
   *
   * @param value
   * @param as
   * @returns
   */
  static getFilterValueAs(value: any, as: FilterValueType) {
    switch (as) {
      case FilterValueType.None:
        return undefined;
      case FilterValueType.ListValue:
        if (value instanceof Array && value.length > 0) {
          return value;
        }
        if (value instanceof Object) {
          return [value.lower, value.upper];
        }
        if (value != null) {
          return [value];
        }
        return undefined;
      case FilterValueType.SingleValue:
        if (value instanceof Array && value.length > 0) {
          return value[0];
        }
        if (value instanceof Object) {
          return value.lower;
        }
        if (value != null) {
          return value;
        }
        return undefined;
      case FilterValueType.BoundedValue:
        if (value instanceof Array && value.length > 0) {
          return {
            lower: value[0],
            upper: value[value.length - 1]
          };
        }
        if (value instanceof Object) {
          return {
            lower: value.lower,
            upper: value.upper
          };
        }
        if (value != null && !isNaN(Number(value))) {
          return {
            lower: Number(value),
            upper: Number(value)
          };
        }
        return undefined;
    }
  }
}
