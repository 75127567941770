import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';


@Injectable()
/**
 * Intercepts api requests to add app context identifier
 */
export class AppContextInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiRequest: boolean = req.url.indexOf(environment.api_url) > -1;

    if (isApiRequest && environment.catalogIdentifier) {
      req = req.clone({ setHeaders: {'App-Context-Identifier': environment.catalogIdentifier} });
    }

    return next.handle(req);
  }
}
