import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import {
  Actions,
  Effect,
  ofType
} from '@ngrx/effects';
import {
  NEVER,
  Observable,
  of
} from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  switchMap
} from 'rxjs/operators';

import * as searchActions from '../actions/search.actions';
import { SearchService } from '../service/search.service';
import { searchSettings } from '../search-settings.constant';
import { SearchCluster } from '../search-cluster.enum';


/**
 * Contains side effects for search
 */
@Injectable()
export class SearchEffects {

  /**
   * Fetches the categories with its products for the search result page
   */
  @Effect()
  fetchCategorySearch$: Observable<Action> = this._actions.pipe(
    ofType(searchActions.searchResultPageOpened),
    switchMap(searchParams => this._searchService.fetchDetailSearchResults({
      searchTerm: searchParams.searchTerm,
      limit: searchSettings.itemsPerPage,
      type: SearchCluster.category
    }).pipe(
      map(result => searchActions.categoryDetailSearchResultsLoaded({ categories: result.categories, totalCounts: result.totalCounts })),
      catchError(_ => NEVER)
    ))
  );

  /**
   * Fetches the suppliers for the search result page
   */
  @Effect()
  fetchSupplierSearch$: Observable<Action> = this._actions.pipe(
    ofType(searchActions.searchResultPageOpened),
    switchMap(searchParams => this._searchService.fetchDetailSearchResults({
      searchTerm: searchParams.searchTerm,
      limit: 6,
      type: SearchCluster.supplier
    }).pipe(
      map(result => searchActions.supplierDetailSearchResultsLoaded({ suppliers: result.suppliers, totalCounts: result.totalCounts })),
      catchError(_ => NEVER)
    ))
  );

  /**
   * Fetches searchResults for SearchBar using the given searchTerm
   * Returns FetchSearchBarAllClusterSearchResultsSucceededAction or FetchSearchBarAllClusterSearchResultsFailedAction
   */
  @Effect()
  fetchSearchBarSearch$: Observable<Action> = this._actions.pipe(
    ofType(searchActions.FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS),
    debounceTime(200),
    map(action => action.payload),
    switchMap(searchParams => this._searchService.fetchSearchBarSearchResults(searchParams).pipe(
      map((results) => new searchActions.FetchSearchBarAllClusterSearchResultsSucceededAction(results)),
      catchError(error => of(new searchActions.FetchSearchBarAllClusterSearchResultsFailedAction(error)))
    ))
  );

  constructor(
    private _actions: Actions<searchActions.Actions>,
    private _searchService: SearchService
  ) {}
}
