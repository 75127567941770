import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  ActionsSubject,
  select,
  Store
} from '@ngrx/store';
import {
  Observable,
  Subject
} from 'rxjs';
import {
  catchError,
  filter,
  first,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';

import { TreeCategory } from '@p1/libs/catalog-service';

import * as routerActions from '../../routing/actions/router.actions';
import * as productComparisonActions from '../actions/product-comparison.actions';
import * as rootReducer from '../../app.reducers';
import * as productComparisonReducer from '../../product-comparison/reducer/product-comparison.reducer';
import { Product } from '../../product/model';
import { Breakpoint } from '../../core/enum/breakpoint.enum';
import { toggleRightOffCanvas } from '../../core/actions/layout.actions';
import { RightOffCanvasState } from '../../core/enum/right-off-canvas-state.enum';
import { isInViewport } from '../../shared/common/helper/is-in-viewport/is-in-viewport';
import { environment } from '../../../environments/environment';
import * as productCategorytreeSelectors from '../../product/selector/product-categorytree.selectors';


@Component({
  selector: 'p1-comparison-menu',
  templateUrl: './comparison-menu.component.html',
  styleUrls: ['./comparison-menu.component.scss'],
  animations: [
    trigger('comparisonContent', [
      state('is-open', style({
        height: '*',
        visibility: 'visible'
      })),
      state('is-closed', style({
        height: '0px',
        visibility: 'hidden'
      })),
      transition('is-closed <=> is-open', animate('.3s ease-in-out'))
    ])
  ]
})
export class ComparisonMenuComponent implements OnDestroy {
  @ViewChild('comparisonMenuDropdown', {
    read: ElementRef,
    static: true
  })
  private _comparisonMenuDropdown: ElementRef;

  products$: Observable<Product[]>;
  productTreeCategoryMapping$: Observable<{[uuid: string]: TreeCategory}>;
  featureFlags = environment.featureFlags;
  isMenuOpen: boolean = false;
  boxIsOutOfViewport: boolean = false;

  private _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private _elementRef: ElementRef,
    private _store: Store<rootReducer.StateInterface>,
    private _actionsSubject: ActionsSubject
  ) {
    this.products$ = this._store.pipe(
      select(productComparisonReducer.getComparisonProducts),
      catchError(() => [])
    );


    this.productTreeCategoryMapping$ = this._store.pipe(
      select(productCategorytreeSelectors.getComparisonProductTreeCategoryMapping),
    );

    this._actionsSubject.pipe(
      filter(action => action.type === productComparisonActions.addProductToProductComparison.type),
      withLatestFrom(this._store.pipe(
        select(rootReducer.getLayoutBreakpoint)
      )),
      takeUntil(this._unsubscribe)
    ).subscribe(([_, breakpoint]) => {
      if (breakpoint > Breakpoint.bpMinS) {
        this.isMenuOpen = true;
        if (!isInViewport(this._comparisonMenuDropdown.nativeElement)) {
          this.boxIsOutOfViewport = true;
        }
      }
    });
  }

  /**
   * listener for click on document to close overlay if clicked outside
   */
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event?: Event) {
    if (this.isMenuOpen && !this._elementRef.nativeElement.contains(event.target)) {
      if (!(event.target['dataset'] && event.target['dataset'].hasOwnProperty('doNotCloseComparison'))) {
        this.toggleMenu(event);
      }
    }
  }

  /**
   * Unsubscribe from all observables when the component gets destroyed.
   */
  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  /**
   * toggles the menu
   */
  toggleMenu(event?: Event) {
    if (event) {
      this.blurDropdown(event);
    }

    if (!isInViewport(this._comparisonMenuDropdown.nativeElement)) {
      this.boxIsOutOfViewport = true;
    }

    this.isMenuOpen = !this.isMenuOpen;
  }

  blurDropdown(event) {
    const target = event.target || event.srcElement;

    if (target && target.localName === 'button') {
      target.blur();
    } else if (target && target.offsetParent) {
      target.offsetParent.blur();
    }
  }

  /**
   * Removes productUuid from the state compare product uuid list
   *
   * @param uuid
   */
  onRemoveProduct(uuid: string) {
    this._store.dispatch(productComparisonActions.removeProductFromProductComparison({ uuid }));
  }

  /**
   * Removes all compared products from the state
   */
  clearProducts() {
    this._store.dispatch(productComparisonActions.clearProductsFromProductComparison());
    this.isMenuOpen = false;
  }

  /**
   * add the product to the state for product comparision
   *
   * @param product - product to compare
   */
  addSimilarProductsToComparison(product: Product) {
    this._store.dispatch(productComparisonActions.fetchSimilarProductsForComparisonTriggered({ product }));
  }

  /**
   * Closes overlay if displayed in full-screen
   */
  compareProducts() {
    this._store.pipe(
      select(rootReducer.getLayoutBreakpoint),
      first(),
      withLatestFrom(this._store.pipe(
        select(productComparisonReducer.getComparisonIsSynced)
      ))
    ).subscribe(([breakpoint, comparisonIsSynced]) => {
      if (breakpoint <= Breakpoint.bpMinS) {
        this._store.dispatch(toggleRightOffCanvas({rightOffCanvasMode: RightOffCanvasState.hidden}));
      }

      if (!comparisonIsSynced) {
        this._store.dispatch(productComparisonActions.saveProductComparisonTriggered({ comparisonContext: 'default' }));
      }

      this._store.dispatch(new routerActions.GoAction({
        path: ['products', 'comparison']
      }));

      this.isMenuOpen = false;
    });
  }
}
