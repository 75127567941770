import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Action } from '@ngrx/store';

import { Notification } from '../../models/notification.interface';
import { NotificationType } from '../../enums/notification-type.enum';

@Component({
  selector: 'p1-lib-ui-elements-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnDestroy, AfterViewInit {

  @Input()
  notification: Notification;

  @Output()
  closeNotification: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  ctaClick: EventEmitter<Action> = new EventEmitter<Action>();

  @ViewChild('icon', {
    read: ViewContainerRef
  }) icon: ViewContainerRef;

  notificationType = NotificationType;

  private _timeoutSeconds: number = 5;
  private _timeout;


  constructor(
  ) {}

  /**
   * sets a timeout to automatically emit close output
   */
  useTimeout() {
    this._timeout = setTimeout(() => {
      this.closeNotification.emit();
    }, this._timeoutSeconds * 1000);
  }

  /**
   * Check new notification values and sets up icons.
   * Sets up the default icons if no icon is given, or creates the custom icon component.
   */
  ngAfterViewInit() {
    if (this.notification?.useTimeout) {
      this.useTimeout();
    }
  }

  /**
   * clear the timeout if component gets removed before timeout expires
   */
  ngOnDestroy() {
    clearTimeout(this._timeout);
  }
}
