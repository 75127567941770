import { Component } from '@angular/core';
import {
  Action,
  select,
  Store
} from '@ngrx/store';
import { Observable } from 'rxjs';

import { Notification } from '@p1/libs/ui-elements';

import * as rootReducer from '../../../app.reducers';
import { RemoveNotificationAction } from '../../actions/notification.actions';


@Component({
  selector: 'p1-notification-container',
  template: `
    <p1-lib-ui-elements-notification *ngFor="let notification of notifications$ | async"
                     [notification]="notification"
                     (closeNotification)="onClose(notification.uuid)"
                     (ctaClick)="dispatch($event, notification.uuid)">
    </p1-lib-ui-elements-notification>`,
  styleUrls: ['./notification-container.component.scss']
})
export class NotificationContainerComponent {

  notifications$: Observable<Notification[]>;


  /**
   * Creates the NotificationContainer Component
   *
   * @param _store - to get the state
   */
  constructor(
    private _store: Store<rootReducer.StateInterface>
  ) {
    this.notifications$ = this._store.pipe(
      select(rootReducer.getNotifications)
    );
  }

  dispatch(action: Action, uuid: string) {
    this._store.dispatch(action);
    this._store.dispatch(new RemoveNotificationAction(uuid));
  }

  onClose(uuid: string) {
    this._store.dispatch(new RemoveNotificationAction(uuid));
  }
}
