import {
  Inject,
  Injectable
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Action,
  select,
  Store
} from '@ngrx/store';
import {
  Actions,
  createEffect,
  Effect,
  ofType
} from '@ngrx/effects';
import {
  asyncScheduler,
  Observable,
  combineLatest,
  of
} from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import {
  LoggerServiceInterface,
  LOGGING_SERVICE
} from '@p1/libs/logging';
import { AnalyticsEventsService } from '@p1/libs/analyticsevents';
import { NotificationType } from '@p1/libs/ui-elements';

import { ProductService } from '../service/product.service';
import { FilterHelperService } from '../../shared/filter/helper-service/filter-helper.service';
import * as productActions from '../actions/product.actions';
import * as routerActions from '../../routing/actions/router.actions';
import * as authReducer from '../../auth/reducer/auth.reducer';
import * as categoryTreeReducer from '../../category-tree/reducer/index';
import * as productReducer from '../reducer/index';
import * as rootReducer from '../../app.reducers';
import * as productCategoryTreeSelector from '../selector/product-categorytree.selectors';
import { FilterValueType } from '../../shared/filter/filter-value-type.enum';
import { FilterRequestValue } from '../../shared/common/models/filter-request-value';
import {
  normalizeRequestPayload,
  RequestPayload
} from '../../shared/common/models/request-payload';
import { ApiErrorCode } from '../../shared/common/enum/api-error-code.enum';
import { productSearchSettings } from '../product-search-settings.constant';
import * as filterHelpers from '../../shared/filter/model/filter.helpers';
import { ProductQueryUserInteractionType } from '../../shared/common/models/request-payload/product-query-user-interaction-type.enum';
import { deepCopy } from '../../shared/common/helper/deep-copy/deep-copy';
import { AddNotificationAction } from '../../core/actions/notification.actions';
import { environment } from '../../../environments/environment';

@Injectable()
/**
 * Contains side effects for product actions
 */
export class ProductEffects {
  @Effect()
  /**
   * Sets queryParams that fit the new queryConfig if payload.navigate is true
   * Fetches new Filters
   *
   * @type {Observable<any>}
   */
  fetchMultiSucceeded$: Observable<Action> = this._actions.pipe(
    ofType(productActions.FETCH_MULTI_SUCCEEDED),
    map(action => action.payload),
    withLatestFrom(
      this._activatedRoute.queryParams,
      this._store.pipe((select(rootReducer.getRouterPath)))
    ),
    mergeMap(([payload, lastQueryParams, routerPath]) => {

      const returnActions: Action[] = [];

      if (!payload.loadInBackground) {
        let newPath = ['/products'];
        let queryParams: {
          [paramName: string]: string | number;
          queryUuid?: string;
          page?: number;
        } = {};

        if (payload.queryUuid && (!routerPath.includes('categories')
                                  || (routerPath.includes('categories') && (payload.queryConfig.property_filters.length > 0
                                                                            || payload.queryConfig.basic_filters
                                                                              .find(basicFilter => basicFilter.id === 'supplier_uuids')
                                                                            || payload.queryConfig.basic_filters
                                                                              .find(basicFilter => basicFilter.id === 'search_string'))))) {
          queryParams.queryUuid = payload.queryUuid;
        }

        if (payload.pagination && payload.pagination.page > 1) {
          queryParams.page = payload.pagination.page;
        }

        if (payload.unfilterableCategory && !routerPath.includes('categories')) {
          queryParams['unfilterable'] = 1;
        }

        if (routerPath.includes('categories')) {
          newPath = [decodeURIComponent(routerPath)];
        }

        const urlParamsToKeepOnNavigation = ['utm_source', 'utm_medium', 'utm_campaign', 'gclid', '_hsenc', '_hsmi'];
        if (lastQueryParams) {
          queryParams = {
            ...queryParams, ...urlParamsToKeepOnNavigation.reduce((queryParamsToKeep, paramName) => {
              if (lastQueryParams[paramName]) {
                queryParamsToKeep[paramName] = lastQueryParams[paramName];
              }
              return queryParamsToKeep;
            }, {})
          };
        }

        returnActions.push(new routerActions.GoAction({
          path: newPath,
          queryParams,
          extras: {
            replaceUrl: payload.replaceUrl
          }
        }));
      } else if (payload.addQueryUuidParam) {
        returnActions.push(new routerActions.GoAction({
          path: [],
          queryParams: { queryUuid: payload.queryUuid },
          extras: {
            replaceUrl: payload.replaceUrl
          }
        }));
      }

      if (payload.queryConfig) {
        const schemaCategoryFilter = this._getSchemaCategoryFilterFromQueryConfig(payload.queryConfig);
        const treeCategoryFilter = this._getTreeCategoryFilterFromQueryConfig(payload.queryConfig);
        const supplierFilter = this._getSupplierFilterFromQueryConfig(payload.queryConfig);
        const fetchFilterPayload = {};

        if (treeCategoryFilter && treeCategoryFilter.value) {
          fetchFilterPayload['tree_category'] = FilterHelperService.getFilterValueAs(treeCategoryFilter.value, FilterValueType.ListValue);
        } else if (schemaCategoryFilter && schemaCategoryFilter.value) {
          fetchFilterPayload['ifc_types'] = FilterHelperService.getFilterValueAs(schemaCategoryFilter.value, FilterValueType.ListValue);
        }

        if (supplierFilter && supplierFilter.value) {
          fetchFilterPayload['supplier_uuids'] = FilterHelperService.getFilterValueAs(supplierFilter.value, FilterValueType.ListValue);
        }

        returnActions.push(new productActions.FetchFilterAction(Object.keys(fetchFilterPayload).length > 0 ? fetchFilterPayload : undefined));
      }

      return returnActions;
    })
  );

  @Effect()
  /**
   * Starts a new search with all current temp filter values
   * This should be used if filter values are changed.
   * It resets the page to 1 if no page is given
   */
  searchWithTempFilterValues$: Observable<Action> = combineLatest([
    this._actions.pipe(ofType(productActions.searchWithTempFilterValues)),
    this._store.pipe(select(categoryTreeReducer.getCategoryTreeLeaves), filter((leaves) => leaves?.length > 0), take(1))]).pipe(
    withLatestFrom(
      this._store.pipe(select(productReducer.getTempBasicFilterValues)),
      this._store.pipe(select(productReducer.getTempPropertyFilterValues)),
      this._store.pipe(select(productReducer.getTempSettingFilterValues)),
      this._store.pipe(select(productReducer.getTempOrderingValue)),
    ),
    map(([[action, categoryTreeLeaves], basicFilters, propertyFilters, settingFilters, ordering]) => {
      const basicFilterValues = basicFilters ? FilterHelperService.getFilterRequestValuesFromValueObject(basicFilters) : undefined;
      const propertyFilterValues = propertyFilters ? FilterHelperService.getFilterRequestValuesFromValueObject(propertyFilters) : undefined;
      const settingFilterValues = settingFilters ? FilterHelperService.getFilterRequestValuesFromValueObject(settingFilters) : undefined;
      let unfilterableCategory = false;
      let context = action.context ? action.context : null;

      if (basicFilterValues) {
        const treeCategory = basicFilterValues.find(_filter => _filter.id === 'tree_category');
        if (treeCategory && categoryTreeLeaves && !categoryTreeLeaves.map(leave => leave.uuid).includes(treeCategory.value)) {
          unfilterableCategory = true;
        }

        if (!context || !context.userInteraction) {
          if (treeCategory && (!propertyFilterValues || propertyFilterValues.length === 0)) {
            context = {
              ...context,
              userInteraction: ProductQueryUserInteractionType.browseTreeCategory
            };
          } else if (treeCategory && propertyFilterValues && propertyFilterValues.length > 0) {
            context = {
              ...context,
              userInteraction: ProductQueryUserInteractionType.search
            };
          } else if (!treeCategory && basicFilterValues.find(_filter => _filter.id === 'supplier_uuids')) {
            context = {
              ...context,
              userInteraction: ProductQueryUserInteractionType.browseSupplier
            };
          }
        }
      }

      return new productActions.FetchMultiAction({
        queryConfig: normalizeRequestPayload({
          basic_filters: basicFilterValues,
          property_filters: propertyFilterValues,
          settings: settingFilterValues,
          context,
          ordering: ordering ?? [environment.featureFlags.defaultProductOrderBy]
        }),
        count: unfilterableCategory ? productSearchSettings.featuredProductsCount : productSearchSettings.itemsPerPage,
        offset: action.page ? this._offsetFromPage(action.page) : 0,
        replaceUrl: action.replaceUrl ?? false,
        unfilterableCategory
      });
    })
  );

  @Effect()
  /**
   * fires an action that holds all filterIds that have no value anymore
   */
  checkActivePropertyFilters$: Observable<Action> = this._actions.pipe(
    ofType(productActions.UPDATE_ACTIVE_PROPERTY_FILTERS),
    withLatestFrom(
      this._store.pipe(select(productReducer.getTempPropertyFilterValues))
    ),
    map(([{ payload }, _tempPropertyFilterValues]) => {
      const filterIdsWithoutValue: string[] = [];
      payload.forEach(propertyFilter => {
        if (filterHelpers.getDefaultValue(propertyFilter) === undefined && !_tempPropertyFilterValues.hasOwnProperty(propertyFilter.id)) {
          filterIdsWithoutValue.push(propertyFilter.id);
        }
      });
      return new productActions.AddPropertyFiltersWithoutValueAction(filterIdsWithoutValue);
    })
  );

  @Effect()
  /**
   * fires a search action if all filters are removed
   */
  startSearch$: Observable<Action> = this._actions.pipe(
    ofType(productActions.REMOVE_ACTIVE_PROPERTY_FILTERS),
    withLatestFrom(
      this._store.pipe(select(productReducer.getTempPropertyFilterValues)),
      this._store.pipe(select(productReducer.getTempPropertyFilterIdsWithoutValues))
    ),
    filter(([_, _tempPropertyFilterValues, _tempPropertyFilterIdsWithoutValues]) => (Object.keys(_tempPropertyFilterValues).length +
              (Array.isArray(_tempPropertyFilterIdsWithoutValues) ? _tempPropertyFilterIdsWithoutValues.length : 0)) === 0),
    map(() => productActions.searchWithTempFilterValues({}))
  );

  /**
   * navigate to another page of the current search
   */
  @Effect()
  paginateCurrentSearch$: Observable<Action> = this._actions.pipe(
    ofType(productActions.PAGINATE_CURRENT_SEARCH),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(
      select(productReducer.getQueryConfig)
    )),
    map(([payload, previousQueryConfig]) => new productActions.FetchMultiAction({
      queryConfig: previousQueryConfig,
      count: productSearchSettings.itemsPerPage,
      offset: this._offsetFromPage(payload.page),
      fromPaginate: true
    }))
  );



  @Effect()
  /**
   * Restore a Query with a queryUuid and an optional page and sorting
   */
  searchWithQueryUuid$: Observable<Action> = this._actions.pipe(
    ofType(
      productActions.openedSearchWithQueryUuid,
      productActions.openedProductDetailPageWithQueryUuid
    ),
    withLatestFrom(
      this._store.pipe(select(productReducer.getQueryUuid)),
      this._store.pipe(select(productReducer.getPaginationInfo)),
      this._store.pipe(select(rootReducer.getRouterQueryParams))
    ),
    filter(([action, previousUuid, previousPaginationInfo]) => {
      if (previousUuid && previousPaginationInfo) {
        // filter changes where uuid and page are the same as previous values
        return previousUuid !== action.queryUuid || (action.page || 1) !== (previousPaginationInfo.page || 1);
      } else {
        return true;
      }
    }),
    filter(([action, previousUuid]) =>
      // do not refecht search on navigation to detailpage if already loaded
      !(action.type === productActions.openedProductDetailPageWithQueryUuid.type && previousUuid && action.queryUuid === previousUuid)
    ),
    map(([action, previousUuid, previousPaginationInfo, queryParams]) => {
      if (previousUuid && previousPaginationInfo
          && previousUuid === action.queryUuid && (action.page || 1) !== (previousPaginationInfo.page || 1)) {

        // if only the page is changed, we have a restore
        return new productActions.PaginateCurrentSearchAction({ page: action.page });
      }
      const unfilterableCategory = !!(queryParams && queryParams['unfilterable']);
      return new productActions.FetchMultiAction({
        queryUuid: action.queryUuid,
        count: unfilterableCategory ? productSearchSettings.featuredProductsCount : productSearchSettings.itemsPerPage,
        restore: true,
        loadInBackground: action.type === productActions.openedProductDetailPageWithQueryUuid.type,
        addQueryUuidParam: action.type === productActions.openedProductDetailPageWithQueryUuid.type,
        offset: this._offsetFromPage(action.page),
        unfilterableCategory
      });
    })
  );

  @Effect()
  /**
   * Fetches a single product
   * Dispatches either succeeded or failed action afterwards
   */
  fetchSingle$: Observable<Action> = this._actions.pipe(
    ofType(productActions.FETCH_SINGLE),
    map(action => action.payload),
    mergeMap((payload) => this._productService.getSingle(payload.uuid).pipe(
      map(product => new productActions.FetchSingleSucceededAction(product)),
      catchError(error => of(new productActions.FetchSingleFailedAction(error)))
    )
    )
  );

  @Effect()
  /**
   * Fetches similar products
   * Dispatches either succeeded or failed action afterwards
   */
  fetchSimilarProducts$: Observable<Action> = this._actions.pipe(
    ofType(productActions.FETCH_SIMILAR_PRODUCTS),
    map(action => action.payload),
    mergeMap((payload) => this._productService.getSimilarProducts(
      payload.productUuid,
      payload.loadWithDetail,
      payload.limit,
      payload.offset,
      payload.treeCategoryUuid
    ).pipe(
      map((products) => new productActions.FetchSimilarProductsSucceededAction({
        uuid: payload.productUuid,
        similarProducts: products,
        offset: payload.offset,
        withDetails: !!payload.loadWithDetail
      })),
      catchError(error => of(new productActions.FetchSimilarProductsFailedAction(error)))
    )
    )
  );

  @Effect()
  /**
   * May dispatch an FetchSingleAction if the given uuid is not present in the items state
   * Also dispatches a fetchItemScore Action for the given product uuid
   */
  selectOrHoverItem$: Observable<Action> = this._actions.pipe(
    ofType(productActions.SET_HOVERED_ITEM_UUID),
    map(action => action.payload),
    filter(selectedItemUuid => !!selectedItemUuid),
    withLatestFrom(this._store.pipe(
      select(productReducer.getProductsWithDetailsLoaded)
    ), this._store.pipe(
      select(productReducer.getCurrentScores)
    ), this._store.pipe(
      select(productReducer.getQueryConfigHasPropertyFilters)
    )),
    mergeMap(([selectedItemUuid, productItems = {}, productItemScores, queryHasPropertyFilters]) => {
      const returnActions: Action[] = [];

      if (queryHasPropertyFilters && (!productItemScores || !productItemScores.hasOwnProperty(selectedItemUuid))) {
        returnActions.push(new productActions.FetchItemScoreAction(selectedItemUuid));
      }

      if (!productItems.hasOwnProperty(selectedItemUuid)) {
        returnActions.push(new productActions.FetchSingleAction({ uuid: selectedItemUuid }));
      }

      return returnActions;
    })
  );


  @Effect()
  /**
   * May dispatch an FetchItemScoreAction if query uuid is given and if an item is selected
   */
  setQueryUuid$: Observable<Action> = this._actions.pipe(
    ofType(productActions.SET_QUERY_UUID),
    map(action => action.payload),
    filter(queryUuid => !!queryUuid),
    withLatestFrom(this._store.pipe(
      select(productReducer.getSelectedItemUuid)
    )),
    filter(([_, selectedItemUuid]) => !!selectedItemUuid),
    map(([_, selectedItemUuid]) => new productActions.FetchItemScoreAction(selectedItemUuid))
  );

  @Effect()
  /**
   * Fetches the score for the given item
   */
  fetchItemScore$: Observable<Action> = this._actions.pipe(
    ofType(productActions.FETCH_ITEM_SCORE),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(
      select(productReducer.getQueryUuid),
      filter(queryUuid => !!queryUuid)
    )),
    mergeMap(([itemUuid, _queryUuid]) => this._productService.getItemScore(itemUuid, _queryUuid).pipe(
      map(score => new productActions.FetchItemScoreSucceededAction({
        itemUuid,
        queryUuid: _queryUuid,
        score
      })),
      catchError(error => of(new productActions.FetchItemScoreFailedAction(error)))
    )
    )
  );

  @Effect()
  /**
   * Fetches filters and dispatches succeeded or failed action afterwards
   */
  fetchFilter$: Observable<Action> = this._actions.pipe(
    ofType(productActions.FETCH_FILTER),
    map(action => action.payload),
    switchMap((payload) => this._productService.getFilters(payload).pipe(
      map(filters => new productActions.FetchFilterSucceededAction(filters)),
      catchError(error => of(new productActions.FetchFilterFailedAction(error)))
    )
    )
  );


  @Effect()
  /**
   * filters all property filters and fires an action to set all relevant filters as active
   */
  setRelevantTempPropertyFilters$: Observable<Action> = this._actions.pipe(
    ofType(productActions.FETCH_MULTI_SUCCEEDED),
    map(action => {
      if (action.payload.queryConfig && Array.isArray(action.payload.queryConfig.basic_filters)) {
        const treecategory = action.payload.queryConfig.basic_filters.find((_filter) => _filter.id === 'tree_category');
        if (treecategory) {
          return treecategory.value;
        }
        const category = action.payload.queryConfig.basic_filters.find((_filter) => _filter.id === 'product_categories');

        return category ? category.value : null;
      }
      return null;
    }),
    filter(categoryId => !!categoryId),
    switchMap(() => this._actions.pipe(
      ofType(productActions.FETCH_FILTER_SUCCEEDED),
      take(1),
      withLatestFrom(
        this._store.pipe(select(productReducer.getActivePropertyFilterIds))
      ),
      filter(([action, activeFilters]) => Array.isArray(action.payload.propertyFilters) && activeFilters.length === 0),
      map(([action, _]) => {
        const relevantPropertyFilters = action.payload.propertyFilters.filter(f => f.mainProperty && !f.hideInFilter);
        return new productActions.UpdateActivePropertyFiltersAction(relevantPropertyFilters);
      })
    ))
  );

  @Effect()
  restoreUrlparamsFromLastSearch: Observable<Action> = this._actions.pipe(
    ofType(productActions.RESTORE_URLPARAMS_FROM_LAST_SEARCH),
    withLatestFrom(
      this._store.pipe(select(productReducer.getQueryUuid)),
      this._store.pipe(select(productReducer.getPaginationInfo))
    ),
    map(([_, queryUuid, paginationInfo]) => {
      const params: {page?: number; queryUuid?: string} = {};
      if (queryUuid) {
        params.queryUuid = queryUuid;
      }
      if (paginationInfo && paginationInfo.page > 1) {
        params.page = paginationInfo.page;
      }
      return new routerActions.GoAction({
        path: ['/products'],
        queryParams: params
      });
    })
  );

  @Effect()
  fetchFeaturedProducts$: Observable<Action> = this._actions.pipe(
    ofType(productActions.FETCH_FEATURED_PRODUCTS),
    map(action => action.payload),
    switchMap(payload => this._productService.getMulti(payload.queryConfig,
      payload.count,
      payload.offset,
      payload.queryUuid,
      false,
      false,
      !!payload.fromPaginate
    ).pipe(
      map(searchResults => new productActions.FetchFeaturedProductsSucceededAction(searchResults)),
      catchError(error => of(new productActions.FetchFeaturedProductsFailedAction(error)))
    )
    )
  );


  @Effect()
  sendExternalFeedback$ = this._actions.pipe(
    ofType(productActions.USER_SEND_EXTERNAL_FEEDBACK),
    withLatestFrom(
      this._store.select(productReducer.getSelectedItemUuid),
      this._store.select(authReducer.getUser)
    ),
    tap(([action, selectedItemUuid, user]) => {
      this._analyticsService.trackExternalContentFeedback(
        selectedItemUuid,
        action.payload.url,
        action.payload.category,
        action.payload.useful,
        action.payload.feedback,
        user?.uuid
      );
    }),
    map(() => new AddNotificationAction({
      uuid: UUID(),
      content: $localize`:|external feedback send notification @@product-detail-page.component_external-content-feedback-send:Your feedback was sent.`,
      actions: undefined,
      type: NotificationType.SUCCESS,
      useTimeout: true
    })
    ),
    catchError(error => this._loggerService.error(error.message, error))
  );


  @Effect({dispatch: false})
  logExternalLinkClicked$ = this._actions.pipe(
    ofType(productActions.USER_CLICKED_EXTERNAL_LINK),
    tap(action => {
      try {
        this._analyticsService.trackExternalContentLink(action.payload.productUuid,
          action.payload.source?.key,
          action.payload.link?.url,
          action.payload.type,
          action.payload.contentSection);
      } catch (error) {
        this._loggerService.error(error.message, error);
      }
    })
  );

  fetchCurrentProductIfNotLoaded$ = createEffect(() => this._actions.pipe(
    ofType(
      productActions.openedProductDetailPage,
      productActions.openedProductDetailPageWithQueryUuid
    ),
    switchMap(action => of(action).pipe(
      withLatestFrom(this._store.pipe(
        select(productReducer.getProductWithDetailsLoaded, { uuidOrSlug: action.uuidOrSlug }),
        take(1)
      )),
      filter(([_, product]) => !product),
      switchMap(([_action, _]) => this._productService.getSingle(_action.uuidOrSlug).pipe(
        map(product => new productActions.FetchSingleSucceededAction(product)),
        catchError(error => of(new productActions.FetchSingleFailedAction(error)))
      ))
    ))
  ));

  /**
   * In order to display the rightOffCanvas on product detail pages without a previous search,
   * we fake the search by applying a search result that only contains the suitable tree category for the current product.
   * This leads to fetching the available filters and displaying everything in the rightOffCanvas.
   * This is a workaround to prevent firing a "real" search (fetchMulti) that would be tracked for analytics and generates unnecessary server load
   */
  fakeSearchInBackgroundForCurrentProductDetailPage$ = createEffect(() => this._actions.pipe(
    ofType(productActions.openedProductDetailPage),
    switchMap(action => this._waitForProductAndTreeCategory(action.uuidOrSlug).pipe(
      map(({category}) => new productActions.FetchMultiSucceededAction({
        loadInBackground: true,
        addQueryUuidParam: false,
        queryConfig: {
          basic_filters: [
            {
              id: 'tree_category',
              value: category.uuid
            }
          ]
        }
      }))
    ))
  ));

  fetchDetailSimilarProducts$ = createEffect(() => this._actions.pipe(
    filter(_ => environment.featureFlags.similarProducts),
    ofType(productActions.openedProductDetailPage, productActions.openedProductDetailPageWithQueryUuid),
    switchMap(action => this._waitForProductAndTreeCategory(action.uuidOrSlug).pipe(
      switchMap(({product, category}) => this._productService.getSimilarProducts(
        product.uuid,
        true,
        3,
        0,
        category.uuid
      ).pipe(
        map((products) => new productActions.FetchSimilarProductsSucceededAction({
          uuid: product.uuid,
          similarProducts: products,
          offset: 0,
          withDetails: true
        })),
        catchError(error => of(new productActions.FetchSimilarProductsFailedAction(error)))
      ))
    ))
  ));

  fetchSummarySimilarProducts$ = createEffect(() => this._actions.pipe(
    filter(_ => environment.featureFlags.similarProducts),
    ofType(productActions.openedProductDetailPage, productActions.openedProductDetailPageWithQueryUuid),
    switchMap(action => this._waitForProductAndTreeCategory(action.uuidOrSlug).pipe(
      switchMap(({product, category}) => this._productService.getSimilarProducts(
        product.uuid,
        false,
        6,
        3,
        category.uuid
      ).pipe(
        map((products) => new productActions.FetchSimilarProductsSucceededAction({
          uuid: product.uuid,
          similarProducts: products,
          offset: 3,
          withDetails: false
        })),
        catchError(error => of(new productActions.FetchSimilarProductsFailedAction(error)))
      ))
    ))
  ));

  fetchComplementaryProducts$ = createEffect(() => this._actions.pipe(
    ofType(productActions.openedProductDetailPage, productActions.openedProductDetailPageWithQueryUuid),
    switchMap(action => this._waitForProductAndTreeCategory(action.uuidOrSlug).pipe(
      switchMap(({ product }) => this._productService.getComplementaryProducts(
        product.uuid
      ).pipe(
        map((products) => productActions.fetchComplementaryProductsSucceeded({
          payload: {
            uuid: product.uuid,
            complementaryProducts: products
          }
        })),
        catchError(error => of(productActions.fetchComplementaryProductsFailed(error)))
      ))
    ))
  ));

  /**
   * @param _actions
   * @param _store
   * @param _productService used to fetch products
   * @param _activatedRoute
   * @param _analyticsService
   * @param _loggerService
   */
  constructor(
    private _actions: Actions<productActions.Actions>,
    private _store: Store<productReducer.State>,
    private _productService: ProductService,
    private _activatedRoute: ActivatedRoute,
    private _analyticsService: AnalyticsEventsService,
    @Inject(LOGGING_SERVICE) private _loggerService: LoggerServiceInterface
  ) {}

  @Effect()
  /**
   * Fetches multiple products, filtered by the actions payload.
   * Dispatches FetchFilterAction if necessary
   * Dispatches either succeeded or failed action afterwards
   * Dispatches SetQueryUuid Action on success
   * Dispatches FetchFilterAction if the FetchMulti Action had a restore param to restore filters
   */
  fetchMulti$ = ({ debounce = 300, scheduler = asyncScheduler } = {}) => this._actions.pipe(
    ofType(productActions.FETCH_MULTI),
    map(action => action.payload),
    withLatestFrom(
      this._store.pipe(
        select(productReducer.getQueryUuid),
        startWith()
      ),
      this._store.pipe(
        select(productReducer.getLastRestoredQueryUuid)
      )
    ),
    debounceTime(debounce, scheduler),
    switchMap(([payload, queryUuid, lastRestoredQueryUuid]) => {
      const queryConfig = deepCopy(payload.queryConfig);
      if (queryConfig
          && Array.isArray(queryConfig.basic_filters)
          && queryConfig.basic_filters.length > 0
          && queryConfig.basic_filters.find(_filter => _filter.id === 'tree_category')
          && !queryConfig.basic_filters.find(_filter => _filter.id === 'product_advertised')) {
        queryConfig.basic_filters.push({
          id: 'product_advertised',
          value: {
            count: 1,
            slot: 'category'
          }
        });
      }

      return this._productService.getMulti(
        queryConfig,
        payload.count,
        payload.offset,
        payload.queryUuid || queryUuid,
        payload.restore,
        lastRestoredQueryUuid ? lastRestoredQueryUuid === (payload.queryUuid || queryUuid) : false,
        !!payload.fromPaginate).pipe(
        mergeMap(searchResults => [
          new productActions.FetchMultiSucceededAction(Object.assign(searchResults,
            {
              loadInBackground: !!payload.loadInBackground,
              replaceUrl: !!payload.replaceUrl,
              unfilterableCategory: !!payload.unfilterableCategory
            })),
          new productActions.SetQueryUuidAction(searchResults.queryUuid)
        ]
        ),
        catchError(error => error.status === 400 && error.error && error.error.internal_code === ApiErrorCode.offsetTooLarge ?
          of(new productActions.FetchMultiAction(Object.assign(payload, { offset: 0 }))) :
          of(new productActions.FetchMultiFailedAction(error))
        )
      );
    })
  );

  /**
   * calculate the offset from the page
   *
   * @param page
   * @private
   */
  private _offsetFromPage(page: number) {
    return page ? (page - 1) * productSearchSettings.itemsPerPage : 0;
  }

  /**
   * Returns the schema category filter in the given query config
   *
   * @param queryConfig
   * @returns
   * @private
   */
  private _getSchemaCategoryFilterFromQueryConfig(queryConfig: RequestPayload) {
    return this._getBasicFilterFromQueryConfig(queryConfig, 'product_categories');
  }

  /**
   * Returns the tree category filter in the given query config
   *
   * @param queryConfig
   * @returns
   * @private
   */
  private _getTreeCategoryFilterFromQueryConfig(queryConfig: RequestPayload) {
    return this._getBasicFilterFromQueryConfig(queryConfig, 'tree_category');
  }

  /**
   * Returns the supplier_uuids in the given query config
   *
   * @param queryConfig
   * @returns
   * @private
   */
  private _getSupplierFilterFromQueryConfig(queryConfig: RequestPayload) {
    return this._getBasicFilterFromQueryConfig(queryConfig, 'supplier_uuids');
  }

  /**
   * Returns a specific basic filter from query config
   *
   * @param queryConfig
   * @param filterId - the identifier of the filter that should be returned
   * @returns
   * @private
   */
  private _getBasicFilterFromQueryConfig(queryConfig: RequestPayload, filterId: string): FilterRequestValue {
    if (queryConfig && queryConfig.basic_filters) {
      return queryConfig.basic_filters.find((_filter) => _filter.id === filterId);
    } else {
      return null;
    }
  }

  private _waitForProductAndTreeCategory(uuidOrSlug: string) {
    return this._store.pipe(
      select(productReducer.getProductByUuidOrSlug, { uuidOrSlug }),
      filter(product => !!product),
      take(1),
      switchMap(product => this._store.pipe(
        select(productCategoryTreeSelector.getTreeCategoryForGivenProduct, { product }),
        filter(category => !!category),
        take(1),
        map(category => ({product, category}))
      ))
    );
  }
}
