import {
  Inject,
  Injectable,
  LOCALE_ID
} from '@angular/core';
import {
  Actions,
  createEffect,
  ofType
} from '@ngrx/effects';
import {
  EMPTY,
  of
} from 'rxjs';
import {
  catchError,
  map,
  switchMap
} from 'rxjs/operators';

import {
  COOKIEHANDLING_SERVICE,
  CookiehandlingServiceInterface
} from '@p1/libs/cookiehandling';

import * as catalogConfigActions from '../actions/catalog-config.actions';
import { CatalogConfigService } from '../service/catalog-config.service';


@Injectable()
export class CatalogConfigEffects {


  // TODO: Test must still be written
  loadCatalogConfig$ = createEffect(() => this.actions$.pipe(
    ofType(catalogConfigActions.loadCatalogConfig),
    switchMap(_ => this._catalogConfigService.loadCatalogConfig().pipe(
      map(config => catalogConfigActions.loadCatalogConfigSuccess({ config })),
      catchError(error => of(catalogConfigActions.loadCatalogConfigFailure({ error })))
    ))
  ));

  loadCatalogConfigSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(catalogConfigActions.loadCatalogConfigSuccess),
    switchMap((catalogConfig) => {
      const cookieDomain = catalogConfig.config.cookieDomains.find(config => config.locale === this._locale);
      const catalogDomain = catalogConfig.config.hosts.find(config => config.locale === this._locale);
      const domain = cookieDomain?.domain ?? catalogDomain?.domain;
      this._cookiehandlingService.setDomain(domain);
      return EMPTY;
    })
  ),
  { dispatch: false }
  );

  init$ = createEffect(() => of(catalogConfigActions.loadCatalogConfig()));

  constructor(
    private actions$: Actions,
    private _catalogConfigService: CatalogConfigService,
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(COOKIEHANDLING_SERVICE) private _cookiehandlingService: CookiehandlingServiceInterface
  ) {}

}
