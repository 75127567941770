import * as productActions from '../../../actions/product.actions';


/**
 * queryConfig reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const lastRestoredQueryUuid = (state: string, action: productActions.Actions): string => {
  switch (action.type) {
    case productActions.FETCH_MULTI_SUCCEEDED:
      return action.payload.basedOnRestore ? action.payload.queryUuid : undefined;
    default:
      return state;
  }
};
