import {
  Inject,
  Injectable,
  LOCALE_ID
} from '@angular/core';
import {
  HttpClient,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BatchApiResponse } from './batch-api-response';
import {
  ExternalAdditionalContentConfigInterface,
  EXTERNAL_ADDITIONAL_CONTENT_CONFIG
} from './config/external-additional-content-config.interface';
import {
  ExternalSource
} from './external-source/external-source';


@Injectable({
  providedIn: 'root'
})
export class ExternalAdditionalContentService {

  constructor(
    private _httpClient: HttpClient,
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(EXTERNAL_ADDITIONAL_CONTENT_CONFIG) private _config: ExternalAdditionalContentConfigInterface
  ) { }

  getAdditionalContentForProduct(uuids: string[]): Observable<{[key: string]: ExternalSource[]}> {
    return this._httpClient.get<BatchApiResponse>(
      `${this._config.apiUrl}content`,
      {params: new HttpParams({fromObject: {uuids: uuids.join(','), locale: this._locale.split('-')[0]}})}
    ).pipe(
      map(apiResult => {
        const result: {[key: string]: ExternalSource[]} = {};
        Object.keys(apiResult).forEach((type) => {
          const sources = apiResult[type];
          result[type] = Object.keys(sources).map(sourceKey => {
            const source = sources[sourceKey];
            const resultSource: ExternalSource = {icon: source.icon, key: sourceKey, name: source.name, iconWidth: source.iconWidth};

            if (source.product) {
              resultSource.productData = source.product.data;
            }
            if (source.supplier) {
              resultSource.supplierData = source.supplier.data;
            }
            return resultSource;
          });
        });
        return result;
      })
    );
  }
}
