import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {
  Inject,
  Injectable
} from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  LoggerServiceInterface,
  LOGGING_SERVICE
} from '@p1/libs/logging';

import { ErrorModalCreatorService } from '../service-error-handling/error-modal-creator.service';
import { CustomErrorTypes } from '../service-error-handling/custom-error-types.enum';
import { environment } from '../../../environments/environment';


@Injectable()
/**
 * Intercepts responses and checks for server error status
 */
export class ServerErrorInterceptor implements HttpInterceptor {


  constructor(
    private _errorModalCreatorService: ErrorModalCreatorService,
    @Inject(LOGGING_SERVICE) private _loggerService: LoggerServiceInterface
  ) {}


  /**
   * @param req
   * @param next
   * @returns
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(null,
        err => {
          if (err instanceof HttpErrorResponse) {
            const checkStatusCodes =  [502, 503, 504];
            if (checkStatusCodes.includes(err.status) && err.url.includes(environment.api_url)) {
              this._loggerService.error(err.message, err.error);

              // show server api error
              this._errorModalCreatorService.showError(CustomErrorTypes.ServerError);
            }
          }
        }
      )
    );
  }
}
