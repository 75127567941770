import {
  Inject,
  Injectable,
  LOCALE_ID,
  NgZone
} from '@angular/core';
import {
  BehaviorSubject,
  from,
  of,
  Observable,
  Subject
} from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import jwtDecode from 'jwt-decode';

import {
  LoggerServiceInterface,
  LOGGING_SERVICE
} from '@p1/libs/logging';

import { KeycloakData } from './keycloak-data';
import { enterZone } from './utility/enter-zone';


declare let keycloak;
declare let keycloakAuthorization;
declare let keycloakAuthorizationInit;

@Injectable()
export class KeycloakAdapterService {

  public authSuccessData$: Observable<KeycloakData>;
  public updateBearerToken$: Observable<string>;

  private _authSuccessDataSource$: Subject<KeycloakData>;
  private _updateBearerTokenSource$: Subject<string>;

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(LOGGING_SERVICE) private _loggerService: LoggerServiceInterface,
    private _ngZone: NgZone
  ) {
    this._authSuccessDataSource$ = new BehaviorSubject<KeycloakData>(null);
    this.authSuccessData$ = this._authSuccessDataSource$.asObservable().pipe(enterZone(this._ngZone));
    this._updateBearerTokenSource$ = new Subject<string>();
    this.updateBearerToken$ = this._updateBearerTokenSource$.asObservable().pipe(enterZone(this._ngZone));
  }

  initKeycloak() {
    try {
      if (keycloak.token) {
        this.extractTokenData();
      } else {
        keycloak.onAuthSuccess = () => {
          this.extractTokenData();
        };
      }
    } catch (error) {
      this._loggerService.error(error);
    }
  }

  extractTokenData() {
    const data: KeycloakData = {
      bearerToken: keycloak.token,
      bearerTokenParsed: keycloak.tokenParsed,
      idToken: keycloak.idToken,
      idTokenParsed: keycloak.idTokenParsed,
      realmAccess: keycloak.realmAccess,
      resourceAccess: keycloak.resourceAccess ? keycloak.resourceAccess : {},
      refreshToken: keycloak.refreshToken,
      refreshTokenParsed: keycloak.refreshTokenParsed,
      subject: keycloak.subject
    };
    this._authSuccessDataSource$.next(data);
  }

  doLogin(redirectURI: string, loginReason?: string, replaceUrl?: boolean) {
    const url = keycloak.createLoginUrl({
      redirectUri: redirectURI,
      locale: this._locale
    });

    this.redirectToKeycloakUrl(loginReason ? url + `&reason=${ loginReason }` : url, replaceUrl);
  }

  doLogout(redirectURI: string) {
    keycloak.logout({ redirectUri: redirectURI });
  }

  doRegistration(redirectURI: string, replaceUrl?: boolean) {
    const url = keycloak.createRegisterUrl({
      redirectUri: redirectURI,
      locale: this._locale
    });

    this.redirectToKeycloakUrl(url, replaceUrl);
  }

  navigateToProfilePage(replaceUrl?: boolean) {
    const url = keycloak.createAccountUrl({ locale: this._locale });

    this.redirectToKeycloakUrl(url, replaceUrl);
  }

  navigateToEditPasswordPage(replaceUrl?: boolean) {
    let url = keycloak.createAccountUrl({ locale: this._locale });
    if (url) {
      url = url.replace(/\?/g, '/password?');
    }

    this.redirectToKeycloakUrl(url, replaceUrl);
  }

  createLoginUrlWithRedirect(redirectURI: string): string {
    return keycloak.createLoginUrl({
      redirectUri: redirectURI,
      locale: this._locale
    });
  }

  /**
   * Refreshes the bearer token and returns the token as observable. If the token is still valid
   * (in the next 5 second), the current token will be returned. If the token is not valid anymore,
   * the refresh token is used to request a fresh token which will then be returned. If the refresh
   * token is not valid anymore an error observable is returned.
   *
   * @param minValidity time in seconds that the current token is supposed to be valid before a token
   *                    refresh is executed. By default this is set to 5 seconds.
   */
  refreshBearerToken(minValidity = 5): Observable<string> {
    return from(keycloak.updateToken(minValidity)).pipe(tap((refreshed: boolean) => {
      if (refreshed) {
        this._updateBearerTokenSource$.next(keycloak.token);
      }
    }), map(() => keycloak.token));
  }

  refreshRpt(clientId, minValidity = 5) {
    if (!keycloakAuthorization) {
      return of(keycloak.token);
    }
    return from(keycloakAuthorizationInit).pipe(
      take(1),
      switchMap((initialRpt: string) => {
        if (!this.checkTokenExpired(jwtDecode<{exp: number}>(keycloakAuthorization.rpt ?? initialRpt), minValidity)) {
          return of(keycloakAuthorization.rpt ?? initialRpt);
        } else {
          return this.getRpt(clientId);
        }
      }),
      catchError((error) => {
        this._loggerService.error(error);
        return of(null);
      })
    );
  }

  getRpt(clientId) {
    return from(keycloakAuthorization.ready.then(() => {
      // the old token has to be reset in order to fetch a new one
      // otherwise keycloak-authz.js post the old token and the request gets rejected
      keycloakAuthorization.rpt = null;
      return keycloakAuthorization.entitlement(clientId);
    })).pipe(
      enterZone(this._ngZone),
      catchError(error => {
        this._loggerService.error(error);
        return of(null);
      })
    );
  }

  clearToken(): void {
    keycloak.clearToken();
  }

  redirectToKeycloakUrl(url: string, replaceUrl?: boolean) {

    // url may already contain ui_locales param by the keycloak.js adapter
    // but user preference is saved in keycloak user and has to be overwritten by kc_locale parameter
    // since we do not want to show our users a different language in keycloak than the app they come from
    // see: https://github.com/keycloak/keycloak-documentation/blob/master/server_development/topics/locale-selector.adoc
    if (url.includes('?')) {
      url += `&kc_locale=${this._locale}`;
    } else {
      url += `?kc_locale=${this._locale}`;
    }
    if (replaceUrl) {
      window.location.replace(url);
    } else {
      window.location.assign(url);
    }
  }

  checkTokenExpired(parsedToken: {exp: number}, minValidity = 5) {
    const expiresIn = parsedToken.exp - Math.ceil(new Date().getTime() / 1000) + (keycloak?.timeSkew ?? 0);
    return expiresIn - minValidity < 0;
  }
}
