import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { IconsRegistry } from '../registry/icons.registry';
import { p1Icons } from '../registry/icons';


@Component({
  selector: 'p1-lib-icons-icon-wrapper',
  templateUrl: './icon-wrapper.component.html',
  styleUrls: ['./icon-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconWrapperComponent {

  private _svgIcon: SVGElement;

  constructor(
    private _element: ElementRef,
    private _iconsRegistry: IconsRegistry,
    @Optional() @Inject(DOCUMENT) private document: any
  ) { }

  @Input()
  set name(iconName: p1Icons) {
    if (this._svgIcon) {
      this._element.nativeElement.removeChild(this._svgIcon);
    }
    const svgData = this._iconsRegistry.getIcon(iconName);
    this._svgIcon = this._svgElementFromString(svgData);
    this._element.nativeElement.appendChild(this._svgIcon);
  }

  private _svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }
}
