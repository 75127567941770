<p1-lib-ui-elements-modal *ngIf="currentDialog$ | async as currentDialog"
                          [closeable]="currentDialog.closable"
                          (closeModal)="closeDialog()">
  <ng-container *ngIf="currentDialog.title"
                modal-header>
    <h2>
      {{ currentDialog.title }}
    </h2>
  </ng-container>
  <ng-container modal-content>
    <p [innerHTML]="currentDialog.content"></p>
  </ng-container>
  <ng-container modal-actions>
    <div class="cta-area__row">
      <div *ngFor="let action of currentDialog.actions"
           class="cta-area__half">
        <p1-lib-ui-elements-button-loading [classes]="!action.isPrimary ? 'button--secondary' : ''"
                                           [loading]="loadingButtonLabel === action.label"
                                           (buttonClick)="dispatchAction(action)">
          {{ action.label }}
        </p1-lib-ui-elements-button-loading>
      </div>
      <div *ngIf="!currentDialog.actions || currentDialog.actions.length < 1"
           class="cta-area__full">
        <button p1Button
                (click)="closeDialog()"
                i18n="Default close button for the store dialog // page: not fixed @@dialog-container.component_button-close">
          Close
        </button>
      </div>
    </div>
  </ng-container>
</p1-lib-ui-elements-modal>
