import { InjectionToken } from '@angular/core';


export interface LoggerServiceInterface {
  // Todo: clarify if this can be fixed (POE-3704)
  log(message: string, payload?: Record<string, any> | number | string | boolean);

  // Todo: clarify if this can be fixed (POE-3704)
  warn(message: string, payload?: Record<string, any> | number | string | boolean);

  // Todo: clarify if this can be fixed (POE-3704)
  error(message: string, payload?: Record<string, any> | number | string | boolean);
}

export const LOGGING_SERVICE = new InjectionToken<LoggerServiceInterface>('LOGGING_SERVICE');
