import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as rootReducer from '../../app.reducers';
import { getRouterParams } from '../../app.reducers';
import { RequestPayload } from '../../shared/common/models/request-payload';
import { Product } from '../model';
import {
  Filter,
  getDefaultValue as getFilterDefaultValue
} from '../../shared/filter/model';
import { FilterType } from '../../shared/filter/model/filter-type.enum';
import { fetchFilterError } from './fetch/fetch-filter-error/fetch-filter-error.reducer';
import { fetchFilterInProgress } from './fetch/fetch-filter-in-progress/fetch-filter-in-progress.reducer';
import { propertyFilters } from './filters/property-filters/property-filters.reducer';
import { basicFilters } from './filters/basic-filters/basic-filters.reducer';
import { settingFilters } from './filters/setting-filters/setting-filters.reducer';
import { tempPropertyFilterValues } from './filters/temp-property-filter-values/temp-property-filter-values.reducer';
import { tempPropertyFilterIdsWithoutValues } from './filters/temp-property-filters-without-values/temp-property-filter-ids-without-values.reducer';
import { tempBasicFilterValues } from './filters/temp-basic-filter-values/temp-basic-filter-values.reducer';
import { tempSettingFilterValues } from './filters/temp-setting-filter-values/temp-setting-filter-values.reducer';
import { FilterHelperService } from '../../shared/filter/helper-service/filter-helper.service';
import { PaginationInfoInterface } from '../../shared/common/interfaces/pagination-info.interface';
import { queryConfig } from './products/query-config/query-config.reducer';
import { searchResultIdentifiers } from './products/search-result-identifiers/search-result-identifiers.reducer';
import { items } from './products/items/items.reducer';
import { fetchMultiInProgress } from './fetch/fetch-multi-in-progress/fetch-multi-in-progress.reducer';
import { fetchSingleInProgress } from './fetch/fetch-single-in-progress/fetch-single-in-progress.reducer';
import { hoveredItemUuid } from './products/hovered-item-uuid/hovered-item-uuid.reducer';
import { fetchMultiError } from './fetch/fetch-multi-error/fetch-multi-error.reducer';
import { fetchSingleError } from './fetch/fetch-single-error/fetch-single-error.reducer';
import { paginationInfo } from './products/pagination-info/pagination-info.reducer';
import { lastRestoredQueryUuid } from './products/based-on-restore/based-on-restore.reducer';
import { ProductProperty } from '../model-property/';
import { PropertyType } from '../model-property/property-type.enum';
import { queryUuid } from './products/query-uuid/query-uuid.reducer';
import { itemScores } from './products/item-scores/item-scores.reducer';
import { orderingValueRange } from './filters/ordering-value-range/ordering-value-range.reducer';
import { tempOrderingValue } from './filters/temp-ordering-value/temp-ordering-value.reducer';
import { allComponentsMatching } from '../model-score/product-score.helpers';
import { propertyClusters } from './filters/property-clusters/property-clusters.reducer';
import {
  Cluster,
  ClusterWithFilters
} from '../../shared/cluster/model';
import { deepCopy } from '../../shared/common/helper/deep-copy/deep-copy';
import { ItemScoresStateInterface } from './products/item-scores/item-scores-state.interface';
import { ProductItemsStateInterface } from './products/items/product-items-state.interface';
import { featuredProductIdentifiers } from './products/featured-product-identifiers/featured-product-identifiers.reducer';

/**
 * Ref-Project State Interface
 */
export interface ProductState {
  queryConfig: RequestPayload;
  queryUuid: string;
  searchResultIdentifiers: {uuid: string; advertised: boolean}[];
  featuredProductIdentifiers: {uuid: string; advertised: boolean}[];
  basicFilters: Filter[];
  propertyFilters: Filter[];
  propertyClusters: Cluster[];
  settingFilters: Filter[];
  orderingValueRange: {label: string; value: string}[];
  activePropertyFilterIds: string[];
  items: ProductItemsStateInterface;
  itemScores: ItemScoresStateInterface;
  fetchMultiInProgress: boolean;
  fetchSingleInProgress: boolean;
  hoveredItemUuid: string;
  fetchSingleError: any;
  fetchMultiError: any;
  fetchFilterInProgress: boolean;
  fetchFilterError: any;
  tempBasicFilterValues: {[filterId: string]: FilterValue};
  tempPropertyFilterValues: {[filterId: string]: FilterValue};
  tempPropertyFilterIdsWithoutValues: string[];
  tempSettingFilterValues: {[filterId: string]: FilterValue};
  tempOrderingValue: string[];
  paginationInfo: PaginationInfoInterface;
  lastRestoredQueryUuid: string;
}

export interface State extends rootReducer.StateInterface {
  product: ProductState;
}

/**
 * Ref-Project State Reducer
 */
export const reducers = {
  queryConfig,
  queryUuid,
  searchResultIdentifiers,
  featuredProductIdentifiers,
  items,
  itemScores,
  fetchFilterInProgress,
  propertyFilters,
  basicFilters,
  propertyClusters,
  settingFilters,
  fetchFilterError,
  fetchMultiInProgress,
  fetchSingleInProgress,
  hoveredItemUuid,
  fetchMultiError,
  fetchSingleError,
  tempPropertyFilterValues,
  tempPropertyFilterIdsWithoutValues,
  tempBasicFilterValues,
  tempSettingFilterValues,
  paginationInfo,
  orderingValueRange,
  tempOrderingValue,
  lastRestoredQueryUuid
};

export const getProductState = createFeatureSelector<ProductState>('product');

/*
 * Selectors
 */
export const getQueryConfig = createSelector(getProductState, (state: ProductState) => state ? state.queryConfig : undefined);
export const getQueryUuid = createSelector(getProductState, (state: ProductState) => state.queryUuid);
export const getSearchResultIdentifiers = createSelector(getProductState, (state: ProductState) => state.searchResultIdentifiers);
export const getFeaturedProductIdentifiers = createSelector(getProductState, (state: ProductState) => state.featuredProductIdentifiers);
export const getPropertyFilters = createSelector(getProductState, (state: ProductState) => state.propertyFilters);
export const getPropertyClusters = createSelector(getProductState, (state: ProductState) => state.propertyClusters);
export const getBasicFilters = createSelector(getProductState, (state: ProductState) => state.basicFilters);
export const getSettingFilters = createSelector(getProductState, (state: ProductState) => state.settingFilters);
export const getItems = createSelector(getProductState, (state: ProductState) => state.items);
export const getItemScores = createSelector(getProductState, (state: ProductState) => state.itemScores);
export const getFetchFiltersInProgress = createSelector(getProductState, (state: ProductState) => state.fetchFilterInProgress);
export const getFetchFiltersError = createSelector(getProductState, (state: ProductState) => state.fetchFilterError);
export const getFetchMultiInProgress = createSelector(getProductState, (state: ProductState) => state.fetchMultiInProgress);
export const getFetchSingleInProgress = createSelector(getProductState, (state: ProductState) => state.fetchSingleInProgress);
export const getHoveredItemUuid = createSelector(getProductState, (state: ProductState) => state.hoveredItemUuid);
export const getFetchMultiError = createSelector(getProductState, (state: ProductState) => state.fetchMultiError);
export const getFetchSingleError = createSelector(getProductState, (state: ProductState) => state.fetchSingleError);
export const getTempPropertyFilterValues = createSelector(getProductState, (state: ProductState) => state.tempPropertyFilterValues);
export const getTempPropertyFilterIdsWithoutValues = createSelector(getProductState, (state: ProductState) => state.tempPropertyFilterIdsWithoutValues);
export const getTempBasicFilterValues = createSelector(getProductState, (state: ProductState) => state.tempBasicFilterValues);
export const getTempSettingFilterValues = createSelector(getProductState, (state: ProductState) => state.tempSettingFilterValues);
export const getPaginationInfo = createSelector(getProductState, (state: ProductState) => state.paginationInfo);
export const getOrderingValueRange = createSelector(getProductState, (state: ProductState) => state.orderingValueRange);
export const getTempOrderingValue = createSelector(getProductState, (state: ProductState) => state.tempOrderingValue);
export const getLastRestoredQueryUuid = createSelector(getProductState, (state: ProductState) => state.lastRestoredQueryUuid);

export const getSelectedItemUuidOrSlug = createSelector(getRouterParams, (_routerParams) => _routerParams && _routerParams.selectedProductUuidOrSlug);

/**
 * Returns the current selected Item
 *
 * @type {OutputSelector<State, string, (res: any) => string>}
 */
export const getSelectedItem = createSelector(getItems, getSelectedItemUuidOrSlug, (_items, _selectedItemUuidOrSlug) => {
  if (_items && _selectedItemUuidOrSlug) {
    return _items[_selectedItemUuidOrSlug] || Object.keys(_items)
      .map(uuid => _items[uuid])
      .find(product => Array.isArray(product.slugs) && product.slugs.includes(_selectedItemUuidOrSlug));
  } else {
    return undefined;
  }
});

export const getSelectedItemUuid = createSelector(getSelectedItem, (_item) => _item ? _item.uuid : undefined);

export const getProductsWithDetailsLoaded = createSelector(getItems, _items => {
  if (_items) {
    return Object.keys(_items).reduce((withDetails, uuid) => {
      if (_items[uuid] && _items[uuid].detailsLoaded) {
        withDetails[uuid] = { ..._items[uuid] };
      }
      return withDetails;
    }, {});
  } else {
    return undefined;
  }
});

export const getProductWithDetailsLoaded = createSelector(getItems, (_items: ProductItemsStateInterface, props: {uuidOrSlug: string}) => {
  if (_items) {
    return Object.keys(_items).map(uuid => _items[uuid])
      .filter(product => product.detailsLoaded)
      .find(product => product.uuid === props.uuidOrSlug || Array.isArray(product.slugs) && product.slugs.includes(props.uuidOrSlug));
  } else {
    return undefined;
  }
});

export const getProductByUuid = createSelector(getItems, (_items, props: {uuid: string}) => {
  if (_items && props) {
    return _items[props.uuid];
  }
});

export const getProductByUuidOrSlug = createSelector(getItems, (_items: ProductItemsStateInterface, props: {uuidOrSlug: string}) => {
  if (_items && props?.uuidOrSlug) {
    return _items[props.uuidOrSlug] ?? Object.keys(_items).map(uuid => _items[uuid]).find(product => product.slugs?.includes(props.uuidOrSlug));
  }
});

/**
 * Returns a boolean value to know if tempBasicFilter contains more than one product category
 */
export const getTempBasicFilterHasCategories = createSelector(getTempBasicFilterValues, (filterValues): boolean => filterValues && filterValues.hasOwnProperty('product_categories'));

/**
 * Returns a boolean value to know if tempBasicFilter contains a supplier category
 */
export const getTempBasicFilterHasSupplierCategories = createSelector(getTempBasicFilterValues, (filterValues): boolean =>
  filterValues && filterValues.hasOwnProperty('supplier_categories'));

/**
 * Returns a boolean value to know if tempBasicFilter contains only one product category
 */
export const getTempBasicFilterHasCategory = createSelector(getTempBasicFilterValues, (filterValues): boolean => filterValues && filterValues['product_categories'] && typeof filterValues['product_categories'] === 'string');

/**
 * Returns the displayName and uuid of active supplier if ony one supplier is selected
 */
export const getActiveSupplierByTempBasicFilters = createSelector(getBasicFilters, getTempBasicFilterValues,
  (_basicFilters, _activeBasicFilterValues) => {
    if (_activeBasicFilterValues && Object.keys(_activeBasicFilterValues).length > 0 && Array.isArray(_basicFilters)) {
      const activeSupplierValues = _activeBasicFilterValues.hasOwnProperty('supplier_uuids') ? _activeBasicFilterValues['supplier_uuids'] : [];
      const suppliersFilters = _basicFilters.find(basicFilter => basicFilter.id === 'supplier_uuids');
      if (!Array.isArray(activeSupplierValues) && suppliersFilters?.type === FilterType.AliasSelect) {
        return suppliersFilters.valueRange.find(filter => filter.value === activeSupplierValues) as {displayName: string; value: string};
      }
    }
  });

/**
 * Returns the similar products to a product according to uuids
 */
export const getSimilarProducts = createSelector(getItems, getSelectedItem, (_items, _selectedItem) => getSimilarProductsOfProduct(_items, _selectedItem));

/**
 * Returns the similar products with details to a product according to uuids
 */
export const getSimilarProductsWithDetails = createSelector(getItems, getSelectedItem, (_items, _selectedItem) =>
  getSimilarProductsWithDetailsLoadedOfProduct(_items, _selectedItem));

/**
 * Returns the similar products with details by a given product uuid
 */
export const getSimilarProductsWithDetailsByUuid = createSelector(getItems, (_items, props: {uuid: string}) => {
  if (props && _items && _items[props.uuid] && Array.isArray(_items[props.uuid].similarProducts)) {
    return getSimilarProductsWithDetailsLoadedOfProduct(_items, _items[props.uuid]);
  }
  return [];
});

/**
 * Returns the complementary products to a product according to uuids
 */
export const getComplementaryProducts = createSelector(getItems, getSelectedItem, (_items, _selectedItem) =>
  getComplementaryProductsOfProduct(_items, _selectedItem));

export const getCurrentScores = createSelector(getItemScores, getQueryUuid, (_itemScores, _queryUuid) => {
  if (_itemScores && _queryUuid) {
    return _itemScores[_queryUuid];
  }
  return undefined;
});

export const getSelectedItemScore = createSelector(getCurrentScores, getSelectedItemUuid, (_itemScores, _itemUuid) => {
  if (_itemUuid && _itemScores) {
    return _itemScores[_itemUuid];
  }

  return undefined;
});

/**
 * Returns the current hovered Item
 *
 * @type {OutputSelector<State, string, (res: any) => string>}
 */
export const getHoveredItem = createSelector(getItems, getHoveredItemUuid, (_items, _hoveredItemUuid) => {
  if (_items && _hoveredItemUuid) {
    return _items[_hoveredItemUuid];
  } else {
    return undefined;
  }
});

export const getSearchResults = createSelector(getSearchResultIdentifiers, getItems, getCurrentScores, (_searchResultsIdentifiers, _items, _scores) => {
  if (Array.isArray(_searchResultsIdentifiers) && _items) {
    return _searchResultsIdentifiers.map(item => {
      const result: Product = {
        ..._items[item.uuid],
        advertised: item.advertised
      };
      if (_scores && _scores[item.uuid]) {
        result.score = _scores[item.uuid];
      }
      return result;
    });
  } else {
    return undefined;
  }
});

export const getFeaturedProducts =
               createSelector(getFeaturedProductIdentifiers, getItems, (_product, _items) => {
                 if (Array.isArray(_product) && _items) {
                   return _product.map(product => ({
                     ..._items[product.uuid],
                     advertised: product.advertised
                   }));
                 } else {
                   return undefined;
                 }
               });

/**
 * Returns all searchresults without a score
 *
 * @type {MemoizedSelector<object, Product[]>}
 */
export const getSearchResultsWithoutScore = createSelector(getSearchResults, (_searchResults) => {
  if (_searchResults) {
    return _searchResults.filter(product => !product.score);
  } else {
    return undefined;
  }
});

/**
 * Returns all searchresults with a full score
 *
 * @type {MemoizedSelector<object, Product[]>}
 */
export const getSearchResultsWithFullScore = createSelector(getSearchResults, (_searchResults) => {
  if (_searchResults) {
    return _searchResults.filter(product => product.score && allComponentsMatching(product.score));
  } else {
    return undefined;
  }
});


/**
 * Returns all searchresults with a partly score
 *
 * @type {MemoizedSelector<object, Product[]>}
 */
export const getSearchResultsWithoutFullScore = createSelector(getSearchResults, (_searchResults) => {
  if (_searchResults) {
    return _searchResults.filter(product => product.score && !allComponentsMatching(product.score));
  } else {
    return undefined;
  }
});

export const getQueryConfigHasPropertyFilters = createSelector(getQueryConfig, (_queryConfig) =>
  _queryConfig && _queryConfig.property_filters && _queryConfig.property_filters.length > 0);

/**
 * Returns a map of the current query configs property filters (like the tempPropertyFilterValues, but based on the
 * currently active search query)
 *
 * @type {MemoizedSelector<object, Map<FilterId, FilterValue> | undefined>}
 */
export const getQueryConfigPropertyFilterValues = createSelector(getQueryConfig, (_queryConfig = {} as RequestPayload) => {
  const { property_filters } = _queryConfig;

  if (property_filters && property_filters.length > 0) {
    return FilterHelperService.getValueObjectFromRequestValues(property_filters);
  }

  return undefined;
});

export const getPendingFilterModificationsExist = createSelector(getQueryConfig, getTempPropertyFilterValues, (_queryConfig, _filterValueMap) => {
  const currentQueryString = FilterHelperService.getQueryStringFromValueObject(FilterHelperService.getValueObjectFromRequestValues(
    _queryConfig && _queryConfig.property_filters ? _queryConfig.property_filters : []));
  const currentTempFilterValueString = _filterValueMap ? FilterHelperService.getQueryStringFromValueObject(_filterValueMap) : '';

  return currentQueryString !== currentTempFilterValueString;
});

export const getSelectedProductCategory = createSelector(getQueryConfig, (config) => {
  const categoryFilter = !config ? undefined : config.basic_filters.find((filter) => filter.id === 'product_categories');
  return categoryFilter ? categoryFilter.value : undefined;
});

export const getSelectedSupplierCategory = createSelector(getQueryConfig, (config) => {
  const categoryFilter = !config ? undefined : config.basic_filters.find((filter) => filter.id === 'supplier_categories');
  return categoryFilter ? categoryFilter.value : undefined;
});

export const getSelectedTreeCategoryUuid = createSelector(getQueryConfig, (config) => {
  const treeFilter = config ? config.basic_filters.find(filter => filter.id === 'tree_category') : undefined;
  return treeFilter ? treeFilter.value : undefined;
});

/**
 * Returns all options for the product_categories basic filter
 *
 * @type {MemoizedSelector<Object, {displayName: string; value: string}[]>}
 */
export const getProductBasicFilterCategories = createSelector(getBasicFilters, (_basicFilters = []): {displayName: string; value: string | number}[] => {
  const categoryFilter = _basicFilters.find((filter) => filter.id === 'product_categories');

  return categoryFilter && categoryFilter.type === FilterType.AliasSelect ? categoryFilter.valueRange : undefined;
});

/**
 * Returns the displayValues of active product_categories and supplier_uuid
 *
 * @type {}
 */
export const getActiveBasicFilterDisplayValues = createSelector(getBasicFilters, getTempBasicFilterValues,
  (_basicFilters, _activeBasicFilterValues) => {
    const result: {activeProductCategory: string; activeSuppliers: string[]} = {
      activeProductCategory: null,
      activeSuppliers: null
    };

    if (_activeBasicFilterValues && Object.keys(_activeBasicFilterValues).length > 0 && Array.isArray(_basicFilters)) {

      const activeProductCategoryValue = _activeBasicFilterValues.hasOwnProperty('product_categories') ? _activeBasicFilterValues['product_categories'] : [];
      const activeSupplierValue = _activeBasicFilterValues.hasOwnProperty('supplier_uuids') ? _activeBasicFilterValues['supplier_uuids'] : [];

      const productCategoryFilter = _basicFilters.find(basicFilter => basicFilter.id === 'product_categories');
      const suppliersFilters = _basicFilters.find(basicFilter => basicFilter.id === 'supplier_uuids');

      if (productCategoryFilter && productCategoryFilter.type === FilterType.AliasSelect) {
        const productCategoryValueRangeOption = productCategoryFilter.valueRange.find(filter => filter.value === activeProductCategoryValue);
        result.activeProductCategory = productCategoryValueRangeOption ? productCategoryValueRangeOption.displayName : null;
      }

      let activeSupplierValues: any[] = [];
      if (!Array.isArray(activeSupplierValue)) {
        activeSupplierValues = [activeSupplierValue];
      }
      result.activeSuppliers = activeSupplierValues.map(activSupplier => {
        if (suppliersFilters && suppliersFilters.type === FilterType.AliasSelect) {
          const valueRangeOption = suppliersFilters.valueRange.find(filter => filter.value === activSupplier);
          if (valueRangeOption) {
            return valueRangeOption.displayName;
          } else {
            return null;
          }
        }
      });
    }
    return result;
  });

/**
 *
 */
export const getActivePropertyFilterIds = createSelector(getTempPropertyFilterValues,
  (_filterValueObject): string[] => {
    if (_filterValueObject) {
      return Object.keys(_filterValueObject);
    } else {
      return [];
    }
  });

/**
 * Returns all active propertyFilters with values from the tempQueryConfig
 *
 * @type {OutputSelector<State, string[], (res: any) => string[]>}
 */
export const getActivePropertyFilters = createSelector(
  getPropertyFilters, getActivePropertyFilterIds, getTempPropertyFilterIdsWithoutValues,
  (_propertyFilters: Filter[], _activePropertyFilterIds: string[], _tempPropertyFilterIdsWithoutValues: string[]) => {
    if (Array.isArray(_activePropertyFilterIds)) {
      const _allFiltersInROCanvas = _activePropertyFilterIds.concat(_tempPropertyFilterIdsWithoutValues);
      const result = _propertyFilters ? _propertyFilters.filter(pf => _allFiltersInROCanvas.indexOf(pf.id) > -1) : ([] as Filter[]);
      return result.length > 0 ? result : undefined;
    } else {
      return undefined;
    }
  });

/**
 * Returns a map with filter-values based on the currenlty selected product
 *
 * @type {OutputSelector<State, any, (res1: (Filter[] | Array), res2: Product) => any>}
 */
export const getPropertyFilterMarkedValues = createSelector(getActivePropertyFilters, getSelectedItem, getHoveredItem,
  (_propertyFilters, _selectedItem, _hoveredItem): Map<string, any> => {
    const item = _selectedItem || _hoveredItem;
    if (_propertyFilters && item && Array.isArray(item.propertySets)) {
      const productProperties: ProductProperty[] = item.propertySets.reduce((a, b) => {
        a.push(...b.properties);
        return a;
      }, []);
      const result = new Map<string, any>();

      _propertyFilters.forEach(propertyFilter => {
        const selectedProductPropertyIndex = productProperties.findIndex(p => p.ifdguid === propertyFilter.id);

        if (selectedProductPropertyIndex < 0) {
          return;
        }

        const selectedProductProperty = productProperties[selectedProductPropertyIndex];

        switch (propertyFilter.type) {
          case FilterType.Boolean:
            result.set(propertyFilter.id, selectedProductProperty.filterNominalValue);
            break;
          case FilterType.RangeMulti:
            switch (selectedProductProperty.filterType) {
              case PropertyType.IfcPropertySingleValue:
                result.set(propertyFilter.id, selectedProductProperty.filterNominalValue);
                break;
              case PropertyType.IfcPropertyBoundedValue:
                result.set(propertyFilter.id, {
                  upper: selectedProductProperty.filterUpperBoundValue,
                  lower: selectedProductProperty.filterLowerBoundValue
                });
                break;
            }
            break;
          case FilterType.Select:
          case FilterType.AliasSelect:
            switch (selectedProductProperty.filterType) {
              case PropertyType.IfcPropertyListValue:
                result.set(propertyFilter.id, [...selectedProductProperty.filterListValues]);
                break;
              case PropertyType.IfcPropertySingleValue:
                result.set(propertyFilter.id, [selectedProductProperty.filterNominalValue]);
                break;
            }
            break;
        }

        productProperties.splice(selectedProductPropertyIndex, 1);
      });

      return result;
    }

    return undefined;
  }
);

/**
 * Returns all inactive PropertyFilters that may be activated by the user
 *
 * @type {OutputSelector<State, string[], (res: any) => string[]>}
 */
export const getInactivePropertyFilters = createSelector(
  getPropertyFilters, getActivePropertyFilterIds,
  (_propertyFilters, _activePropertyFilterIds) => {
    const result = _propertyFilters ? _propertyFilters.filter(pf => _activePropertyFilterIds.indexOf(pf.id) === -1) : [];
    return result.length > 0 ? result : undefined;
  });

export const getOnlyMainFiltersWithDefaultValueAreActive = createSelector(
  getPropertyFilters,
  getTempPropertyFilterValues,
  getTempPropertyFilterIdsWithoutValues,
  (_filters, _tempPropertyFilterValues, _idsWithoutValues) => {
    if (Array.isArray(_filters)) {
      const mainFilters = _filters.filter(filter => filter.mainProperty && !filter.hideInFilter);

      const mainFiltersWithDefaultValue = mainFilters.filter(filter => getFilterDefaultValue(filter) !== undefined);
      const mainFiltersWithoutDefaultValue = mainFilters.filter(filter => getFilterDefaultValue(filter) === undefined);

      if (_tempPropertyFilterValues) {
        if (!(mainFiltersWithDefaultValue.every(filter => _tempPropertyFilterValues[filter.id] === getFilterDefaultValue(filter))
              && mainFiltersWithDefaultValue.length === Object.keys(_tempPropertyFilterValues).length)) {
          return false;
        }

        if (mainFiltersWithoutDefaultValue && !_idsWithoutValues ) {
          return false;
        }

        if (!(mainFiltersWithoutDefaultValue.every(filter => _idsWithoutValues.includes(filter.id))
              && mainFiltersWithoutDefaultValue.length === _idsWithoutValues.length)) {
          return false;
        }
      }

      return true;
    }
  });

/**
 * Checks if at least one of the current temp property filter values is not the filters default value
 *
 * @type {MemoizedSelector<object, boolean | boolean>}
 */
export const getTempPropertyFilterValuesAreNotDefaultValues = createSelector(getTempPropertyFilterValues, getPropertyFilters,
  (_tempPropertyFilterValues, _propertyFilters) => {
    if (!_tempPropertyFilterValues || !_propertyFilters) {
      return false;
    }
    let result = false;

    Object.keys(_tempPropertyFilterValues).forEach((filterId) => {
      if (result) {
        return;
      }

      const propertyFilter = _propertyFilters.find(pf => pf.id === filterId);
      let filterValue;

      if (propertyFilter) {
        filterValue = _tempPropertyFilterValues[filterId];
        result = filterValue !== getFilterDefaultValue(propertyFilter);
      }
    });

    return result;
  });

/**
 * Returns a map with all propertyClusters and its propertyFilters
 *
 * @type {MemorizedSelector<object, ClusterWithFilters[]}
 */
export const getFilterPropertiesStructuredByClusters = createSelector(getPropertyClusters, getPropertyFilters,
  (_propertyClusters, _propertyFilters, props: {excludeHiddenFilters: boolean}) => {
    const excludeHiddenFilters = props && props.excludeHiddenFilters ? props.excludeHiddenFilters : false;
    if (Array.isArray(_propertyClusters) && Array.isArray(_propertyFilters)) {
      return _propertyClusters.map(cluster => {
        const clusterWithFilters: ClusterWithFilters = deepCopy(cluster);
        clusterWithFilters.filters =
          _propertyFilters.filter(_filter => _filter.clusterId === cluster.uuid && _filter.hideInFilter !== excludeHiddenFilters);
        return clusterWithFilters;
      });
    }
    return null;
  });

export const getActiveSupplier = createSelector(getActiveSupplierByTempBasicFilters, getItems, getSelectedSupplierCategory,
  (_tempBasicFilterValues, _supplierItems, _selectedSupplierCategory) => {
    if (_tempBasicFilterValues) {
      const result = {
        uuid: _tempBasicFilterValues.value,
        name: _tempBasicFilterValues.displayName,
        categoryName: null,
        urlPathSegment: _tempBasicFilterValues.value
      };

      if (Array.isArray(_supplierItems)) {
        const activeSupplier = _supplierItems.find(supplier => supplier.uuid === _tempBasicFilterValues.value);
        if (activeSupplier) {
          result.categoryName = _selectedSupplierCategory ?
              (activeSupplier.supplierCategories
                .find(supplierCategory => supplierCategory.uuid === _selectedSupplierCategory)).name : 'all';
          result.urlPathSegment = activeSupplier.urlPathSegment;
        }

      }

      return result;
    }

    return null;
  });

export const getActiveSchemaCategory = createSelector(getQueryConfig, getBasicFilters,
  (_queryConfig, _filters) => {
    const hasQueryFilters = _queryConfig && _queryConfig.basic_filters;
    if (hasQueryFilters) {
      const query = _queryConfig.basic_filters.find(f => f.id === 'product_categories');
      const basicFilter = (_filters ? _filters.filter(f => f.id === 'product_categories') : [])[0];
      const categoryFilterValueRange = basicFilter && basicFilter.type === FilterType.AliasSelect ? basicFilter.valueRange : null;

      if (query && categoryFilterValueRange) {
        return categoryFilterValueRange.find(category => category.value === query.value);
      }
    }

    return null;
  });

/**
  Helper function to get similar products of a product
 */
const getSimilarProductsOfProduct = (allProducts, selectedProduct): Product[] => {
  if (selectedProduct && Array.isArray(selectedProduct.similarProducts) && allProducts) {
    return selectedProduct.similarProducts.map(uuid => allProducts[uuid]).filter(product => !!product);
  }
};

/**
 Helper function to get complementary products of a product
 */
const getComplementaryProductsOfProduct = (allProducts, selectedProduct): Product[] => {
  if (selectedProduct && Array.isArray(selectedProduct.complementaryProducts) && allProducts) {
    return selectedProduct.complementaryProducts.map(uuid => allProducts[uuid]).filter(product => !!product);
  }
  return [];
};

/**
  Helper function to get similar products with details loaded of a product
 */
const getSimilarProductsWithDetailsLoadedOfProduct = (allProducts, selectedProduct): Product[] =>
  getSimilarProductsOfProduct(allProducts, selectedProduct)?.filter(product => product.detailsLoaded);
