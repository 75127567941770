<button p1Button
        [attr.class]="classes"
        [attr.type]="type"
        [attr.data-gtm-event-category]="gtmEventCategory"
        [attr.data-gtm-event-action]="gtmEventAction"
        [attr.data-gtm-event-label]="gtmEventLabel"
        [disabled]="disabled"
        [ngClass]="{'button--loading': loading}"
        (click)="emitClickEvent($event)">
  <p1-lib-ui-elements-loading-animation *ngIf="loading; else content"></p1-lib-ui-elements-loading-animation>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</button>
