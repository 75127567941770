import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { LoggerServiceInterface } from './logger-service.interface';
import { SentryLoggerConfig } from './sentry-logger.config';


@Injectable({
  providedIn: 'root'
})
export class SentryLoggerService implements LoggerServiceInterface {

  constructor(sentryLoggerConfig: SentryLoggerConfig) {
    Sentry.init({
      dsn: sentryLoggerConfig.dsn,
      release: 'webapp-v' + window['P1_Version'],
      environment: sentryLoggerConfig.environment
    });
  }

  public error(message: string, payload?: Record<string, unknown> | number | string | boolean) {
    if (payload && (payload instanceof Error || payload instanceof ErrorEvent)) {
      Sentry.captureException(payload);
      if (message) {
        Sentry.captureMessage(message);
      }
    } else {
      const suffix = payload != null ? ' ' + payload.toString() : '';
      Sentry.captureException(message + suffix);
    }
  }

  public log(message: string, payload?: Record<string, unknown> | number | string | boolean) {
    const suffix = payload != null ? ' ' + payload.toString() : '';
    Sentry.captureMessage(message + suffix);
  }

  public warn(message: string, payload?: Record<string, unknown> | number | string | boolean) {
    const suffix = payload != null ? ' ' + payload.toString() : '';
    Sentry.captureMessage(message + suffix);
  }
}
