import { InjectionToken } from '@angular/core';


export interface RomakowskiConfiguratorLinkConfigInterface {
  bimGeneratorUrl: {[language: string]: string};
  solutionFinderUrl: {[language: string]: string};
  supplierUuid: string;
}

export const ROMAKOWSKI_CONFIGURATOR_LINK_CONFIG = new InjectionToken<RomakowskiConfiguratorLinkConfigInterface>('ROMAKOWSKI_CONFIGURATOR_LINK_CONFIG');
