import {
  NavigationEnd,
  Router
} from '@angular/router';
import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  select,
  Store
} from '@ngrx/store';
import {
  Observable,
  Subject
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  APP_BASE_HREF,
  Location
} from '@angular/common';

import { environment } from '../../../environments/environment';
import { CatalogConfigHost } from '../../catalog-config/model/catalog-config';
import { getWordpressPages } from '../../routing/wordpress-pages';
import * as catalogConfigReducer from '../../catalog-config/reducers';
import { externalPages } from '../../routing/external-pages';

@Component({
  selector: 'p1-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input()
  hasRightOffCanvas: boolean;

  catalogHosts$: Observable<CatalogConfigHost[]>;
  path: string = this._baseHref.slice(0, -1);
  host: string = environment.host;
  featureFlags = environment.featureFlags;
  date = new Date();
  wordpressPages: {[key: string]: string};
  currentLanguage: string;
  referenceProjectsLink: string =  externalPages.referenceProjects;

  private _unsubscribe: Subject<void> = new Subject();

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    private _store: Store<catalogConfigReducer.State>,
    private _location: Location,
    private _router: Router,
    @Inject(APP_BASE_HREF) private _baseHref: string
  ) {
    this.wordpressPages = getWordpressPages(this._locale);
    this.currentLanguage = this._locale;

    this.catalogHosts$ = this._store.pipe(
      select(catalogConfigReducer.getCatalogConfigHosts)
    );
  }

  /**
   * OnInit Handler
   * Handles language switch path recognition
   */
  ngOnInit() {
    this.path = this._baseHref.slice(0, -1) + this._location.path();
    this._router.events.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.path = this._baseHref.slice(0, -1) + this._location.path();
      }
    });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  get currentYear() {
    return this.date.getFullYear();
  }
}
