import {
  ErrorHandler,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';

import {
  iconArrowLeft,
  iconArrowRight,
  iconAvatar,
  iconFacebook,
  iconGlobe,
  iconHammer,
  iconHome,
  iconInfoCircle,
  iconLinkedIn,
  iconLock,
  iconPlanOneLogo,
  iconPlanOneN,
  iconProblemTriangle,
  IconsModule,
  IconsRegistry,
  iconStar,
  iconSuccessCircle,
  iconXing,
  iconYoutube,
  iconInstagram,
  iconBell
} from '@p1/libs/icons/';
import { illustrationNotFound } from '@p1/libs/icons/src/lib/registry/illustrations';

import { ErrorModalCreatorService } from './service-error-handling/error-modal-creator.service';
import { CustomErrorHandler } from './service-error-handling/custom-error-handler.service';
import { GoogleTagManagerEffects } from './google-tag-manager/effects/google-tag-manager.effects';
import { GoogleTagManagerService } from './google-tag-manager/service/google-tag-manager.service';
import { SeoService } from './service-seo/seo.service';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { ResizeService } from './service-resize/resize.service';
import { AuthInterceptor } from './http-interceptors/auth-interceptor';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { NotificationContainerComponent } from './notification/container/notification-container.component';
import { DialogModule } from './dialog/dialog.module';
import { ServerErrorInterceptor } from './http-interceptors/server-error-interceptor';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { WatchlistMenuComponent } from './watchlist-menu/watchlist-menu.component';
import { AnalyticsExcludeInterceptor } from './http-interceptors/analytics-exclude-interceptor.service';
import { SupplierMenuComponent } from './supplier-menu/supplier-menu.component';
import { SearchSharedModule } from '../search/search.shared.module';
import { KeycloakAdapterModule } from '@p1/libs/keycloak-adapter';
import { AppContextInterceptor } from './http-interceptors/app-context-interceptor';
import { ClientIdInterceptor} from './http-interceptors/client-id-interceptor';
import { QaStateInterceptor } from './http-interceptors/qa-state-interceptor';
import { CategoryTreeSharedModule } from '../category-tree/category-tree.shared.module';
import { LocaleInterceptor } from './http-interceptors/locale-interceptor.service';
import { environment } from '../../environments/environment';
import { QaStateModule } from '../qa-state/qa-state.module';
import { ProductComparisonSharedModule } from '../product-comparison/product-comparison.shared.module';
import { DoNotTrackUserInterceptor } from './http-interceptors/do-not-track-user-interceptor.service';


@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    SharedModule,
    DialogModule,
    EffectsModule.forFeature([GoogleTagManagerEffects]),
    CategoryTreeSharedModule,
    SearchSharedModule,
    QaStateModule,
    environment.featureFlags.productComparison ? ProductComparisonSharedModule : [],
    environment.featureFlags.login !== 'none' ? KeycloakAdapterModule : [],
    IconsModule
  ],
  declarations: [
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    NotificationContainerComponent,
    ProfileMenuComponent,
    NavigationBarComponent,
    SupplierMenuComponent,
    WatchlistMenuComponent
  ],
  providers: [
    SeoService,
    ResizeService,
    ErrorModalCreatorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QaStateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AnalyticsExcludeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppContextInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientIdInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DoNotTrackUserInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    GoogleTagManagerService
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    NavigationComponent,
    NavigationBarComponent,
    FooterComponent,
    NotificationContainerComponent,
    DialogModule,
    CategoryTreeSharedModule
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule,
              private _iconsRegistry: IconsRegistry) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
    this._iconsRegistry.registerIcons([
      iconArrowLeft,
      iconArrowRight,
      iconAvatar,
      iconFacebook,
      iconGlobe,
      iconHammer,
      iconHome,
      iconInfoCircle,
      iconLinkedIn,
      iconLock,
      iconPlanOneLogo,
      iconPlanOneN,
      iconProblemTriangle,
      iconStar,
      iconSuccessCircle,
      iconXing,
      illustrationNotFound,
      iconInstagram,
      iconYoutube,
      iconBell
    ]);
  }
}
