import * as allClusterSearchActions from '../../../actions/search.actions';


/**
 * Sets the searchBarSearchInProgress boolean value
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const searchBarSearchInProgress = (state = false, action: allClusterSearchActions.Actions): boolean => {
  switch (action.type) {
    case allClusterSearchActions.FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS:
      return true;
    case allClusterSearchActions.FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS_SUCCEEDED:
    case allClusterSearchActions.FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS_FAILED:
      return false;
    default:
      return state;
  }
};
