export const routeTitle = {
  home: $localize`:|categories route title @@route-title-names_categories-title:Categories`,
  products: $localize`:|products route title @@route-title-names_products-title:Products`,
  supplier: $localize`:|suppliers route title @@route-title-names_suppliers-title:Suppliers`,
  categories: $localize`:|categories route title @@route-title-names_categories-title:Categories`,
  devOnly: $localize`:|dev only route title @@route-title-names_dev-only-title:Dev only`,
  userAccount: $localize`:|user account route title @@route-title-names_user-account-title:User Account`,
  watchlist: $localize`:|watchlist route title @@route-title-names_watchlist-title:Watchlist`,
  watchlists: $localize`:|watchlists route title @@route-title-names_watchlists-title:Watchlists`,
  myWatchlists: $localize`:|my watchlist route title @@route-title-names_my-watchlist-title:My watchlists`,
  mapping: $localize`:|mapping detail page route title @@route-title-names_mapping-detail-page-title:Mapping`,
  supplierAnalytics: $localize`:|supplier analytics route title @@route-title-names_supplier-analytics-title:Supplier Analytics`,
  supplierDashboard: $localize`:|supplier dashboard route title @@route-title-names_supplier-dashboard-title:Supplier Dashboard`,
  supplierBenchmark: $localize`:|supplier benchmark route title @@route-title-names_supplier-benchmark-title:Benchmark`,
  supplierDemandData: $localize`:|supplier demand data route title @@route-title-names_supplier-demand-data-title:Demand data`,
  supplierLeads: $localize`:|supplier leads route title @@route-title-names_supplier-leads-title:Leads`,
  supplierSelfServices: $localize`:|supplier self services route title @@route-title-names_supplier-self-services-title:Supplier self services`,
  search: $localize`:|search route title @@route-title-names_search-title:Search`,
  searchResults: $localize`:|search results route title @@route-title-names_search-results-title:Search results`,
  pageNotFound: $localize`:|not found page route title @@route-title-names_not-found-page-title:404 - Page not found`,
  comparison: $localize`:|comparison route title @@route-title-names_comparison-title:Comparison`,
  notFoundProduct: $localize`:|not found product route title @@route-title-names_not-found-product-title:Product not found`,
  notFoundSupplier: $localize`:|not found supplier route title @@route-title-names_not-found-supplier-title:Supplier not found`,
  notFoundWatchlist: $localize`:|not found watchlist route title @@route-title-names_not-found-watchlist-title:Watchlist not found`,
  validation: $localize`:|validation route title @@route-title-names_validation-page-title:Validate product`,
  productSpecification: $localize`:|product specification title @@route-title-names_product-specification:Product specification`,
  notFoundValidation: $localize`:|not found validation route title @@route-title-names_not-found-validation-title:Product validation not found`,
};
