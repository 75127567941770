import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { UserUnauthenticatedGuard } from './guards/user-unauthenticated/user-unauthenticated.guard';
import { appRoutes } from './routes/routes.constant';
import { UserAuthenticatedGuard } from './guards/user-authenticated/user-authenticated.guard';
import { RouterEffects } from './effects/router.effects';
import { InDevelopGuard } from './guards/in-develop/in-develop.guard';
import { MyWatchlistsFeatureGuard } from './guards/active-features/my-watchlists-feature.guard';
import { SupplierAnalyticsFeatureGuard } from './guards/active-features/supplier-analytics-feature.guard';
import { SupplierFeatureGuard } from './guards/active-features/supplier-feature.guard';
import { ProductComparisonFeatureGuard } from './guards/active-features/product-comparison-feature.guard';
import { CatalogConfigModule } from '../catalog-config/catalog-config.module';
import { ProductValidationFeatureGuard } from './guards/active-features/product-validation-feature.guard';


// all routes are defined in ./core/routes/routes.constant
@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'corrected'
      }
    ),
    CatalogConfigModule,
    EffectsModule.forFeature([RouterEffects])
  ],
  providers: [
    UserAuthenticatedGuard,
    UserUnauthenticatedGuard,
    InDevelopGuard,
    MyWatchlistsFeatureGuard,
    SupplierAnalyticsFeatureGuard,
    SupplierFeatureGuard,
    ProductComparisonFeatureGuard,
    ProductValidationFeatureGuard
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
