import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { IconsRegistry } from '@p1/libs/icons/src/lib/registry/icons.registry';
import {
  iconLock,
  iconStar
} from '@p1/libs/icons/src/lib/registry/icons';

import { WatchlistDropdownLoaderComponent } from './watchlist-dropdown-loader/watchlist-dropdown-loader.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    WatchlistDropdownLoaderComponent
  ],
  exports: [
    WatchlistDropdownLoaderComponent
  ],
  providers: []
})
export class WatchlistDropdownLoaderModule {
  constructor(private _iconsRegistry: IconsRegistry) {
    this._iconsRegistry.registerIcons([
      iconLock,
      iconStar
    ]);
  }
}
