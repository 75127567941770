import { ProductScore, ProductScoreBackendInterface } from './';
import { FilterScore } from '../../shared/filter/filter-score.enum';


/**
 * Generates a ProductScore based on api input
 *
 * @param input
 * @returns
 */
export const generateProductScoreFromBackendInput = (input: ProductScoreBackendInterface): ProductScore => {
  if (input && input.parameters_components && Object.keys(input.parameters_components).length > 0) {
    return {
      dataQuality:  input.data_quality,
      parameters:   input.parameters,
      filterScores: Object.keys(input.parameters_components).reduce((valuesMap, key) =>
        valuesMap.set(key, filterScoreFromScoreNumber(input.parameters_components[key])), new Map<string, FilterScore>()),
      total:        input.total_score
    };
  } else {
    return undefined;
  }
};

const filterScoreFromScoreNumber = (filterScore: number) => {
  switch (filterScore) {
    case 1:
      return FilterScore.Full;
    case 0:
      return FilterScore.Partial;
    case -1:
    default:
      return FilterScore.NoInfo;
  }
};
