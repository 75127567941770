import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as dialogActions from '../actions/dialog.actions';
import { Dialog } from '../model/dialog';
import * as rootReducer from '../../../app.reducers';


export interface DialogState {
  currentDialog: Dialog;
}

export interface State extends rootReducer.StateInterface {
  dialog: DialogState;
}

export const currentDialog = (state: Dialog, action: dialogActions.Actions): Dialog => {
  switch (action.type) {
    case dialogActions.OPEN_DIALOG:
      return action.payload;
    case dialogActions.CLOSE_DIALOG:
      return undefined;
    default:
      return state;
  }
};

export const reducers = {
  currentDialog
};

export const getDialogState = createFeatureSelector<DialogState>('dialog');

export const getCurrentDialog = createSelector(getDialogState, (state) => state.currentDialog);
