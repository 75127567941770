import { FormGroup } from '@angular/forms';

import { SchuecoProductValidationWatchlistPreviewResponseInterface } from '@p1/libs/schueco-product-validation/src/lib/model/schueco-product-validation-watchlist-preview-response.interface';
import { generateFileContainerFromBackendInput } from '@p1/libs/file';

import { SchuecoProductValidationRequestInterface } from './schueco-product-validation-request.interface';
import {
  SchuecoProductValidationResponseBackendInterface,
  SchuecoProductValidationWatchlistPreviewResponseBackendInterface
} from './schueco-product-validation-response.backend-interface';
import { SchuecoProductValidationResponseInterface } from './schueco-product-validation-response.interface';

export const generateSchuecoProductValidationResponseFromBackendInput = (
  validationBackendApiResponse: SchuecoProductValidationResponseBackendInterface
): SchuecoProductValidationResponseInterface => {

  if (!validationBackendApiResponse || !validationBackendApiResponse.validation_properties || !validationBackendApiResponse.validation_result) {
    return null;
  }

  const productValidationResult: SchuecoProductValidationResponseInterface = {
    productUuid: validationBackendApiResponse.uuid,
    valid: validationBackendApiResponse.valid,
    validationProperties: {
      totalWidth: validationBackendApiResponse.validation_properties.total_width,
      totalHeight: validationBackendApiResponse.validation_properties.total_height,
      openingType: validationBackendApiResponse.validation_properties.opening_type
    },
    validationResult: {
      ceData: {
        uValue: shortenValue(validationBackendApiResponse.validation_result.ce_data.uvalue),
        soundDampening: shortenValue(validationBackendApiResponse.validation_result.ce_data.sound_dampening),
        calculatedPrice: validationBackendApiResponse.validation_result.ce_data.price.calculated
      },
      valuesUsedInValidation: {
        totalWidth: validationBackendApiResponse.validation_result.values_used_in_validation.total_width,
        totalHeight: validationBackendApiResponse.validation_result.values_used_in_validation.total_height,
        openingType: validationBackendApiResponse.validation_result.values_used_in_validation.opening_type
      }
    }
  };

  if (validationBackendApiResponse.validation_result.ce_data.price.fixedprice) {
    productValidationResult.validationResult.ceData.fixedPrice = validationBackendApiResponse.validation_result.ce_data.price.fixedprice;
  }

  /** validationProperties **/
  /* Temporarily removed
  if (validationBackendApiResponse.validation_properties.burglar_resistance) {
    productValidationResult.validationProperties.burglarResistance = validationBackendApiResponse.validation_properties.burglar_resistance;
  }
  */

  if (validationBackendApiResponse.validation_properties.glazing) {
    productValidationResult.validationProperties.glazing = validationBackendApiResponse.validation_properties.glazing;
  }

  /** valuesUsedInValidation **/
  /* Temporarily removed
  if (validationBackendApiResponse.validation_result.values_used_in_validation.burglar_resistance) {
    productValidationResult.validationResult.valuesUsedInValidation.burglarResistance =
      validationBackendApiResponse.validation_result.values_used_in_validation.burglar_resistance;
  }
  */

  if (validationBackendApiResponse.validation_result.values_used_in_validation.glazing) {
    productValidationResult.validationResult.valuesUsedInValidation.glazing =
      validationBackendApiResponse.validation_result.values_used_in_validation.glazing;
  }

  /** messages **/
  if (
    validationBackendApiResponse.messages &&
    (validationBackendApiResponse.messages.error || validationBackendApiResponse.messages.info || validationBackendApiResponse.messages.warning)
  ) {
    productValidationResult.messages = {};

    /** error  **/
    if (validationBackendApiResponse.messages.error) {
      productValidationResult.messages.error = validationBackendApiResponse.messages.error;
    }

    /** info  **/
    if (validationBackendApiResponse.messages.info) {
      productValidationResult.messages.info = validationBackendApiResponse.messages.info;
    }

    /** warning  **/
    if (validationBackendApiResponse.messages.warning) {
      productValidationResult.messages.warning = validationBackendApiResponse.messages.warning;
    }
  }

  /** itemInfo **/
  if (validationBackendApiResponse.validation_result.item_info) {
    productValidationResult.validationResult.itemInfo = {};

    if (validationBackendApiResponse.validation_result.item_info.description) {
      productValidationResult.validationResult.itemInfo.description = validationBackendApiResponse.validation_result.item_info.description;
    }

    if (validationBackendApiResponse.validation_result.item_info.glass_structure) {
      productValidationResult.validationResult.itemInfo.glassStructure = validationBackendApiResponse.validation_result.item_info.glass_structure;
    }

    if (validationBackendApiResponse.validation_result.item_info.surface) {
      productValidationResult.validationResult.itemInfo.surface = validationBackendApiResponse.validation_result.item_info.surface;
    }
  }

  /** csv **/
  if (validationBackendApiResponse.validation_result.result_csv) {
    productValidationResult.validationResult.resultCsv = {};

    if (validationBackendApiResponse.validation_result.result_csv.content_type) {
      productValidationResult.validationResult.resultCsv.contentType = validationBackendApiResponse.validation_result.result_csv.content_type;
    }

    if (validationBackendApiResponse.validation_result.result_csv.file_id) {
      productValidationResult.validationResult.resultCsv.fileId = validationBackendApiResponse.validation_result.result_csv.file_id;
    }

    if (validationBackendApiResponse.validation_result.result_csv.file_size) {
      productValidationResult.validationResult.resultCsv.fileSize = validationBackendApiResponse.validation_result.result_csv.file_size;
    }
  }

  /** zip **/
  if (validationBackendApiResponse.validation_result.schuecal_project_zip) {
    productValidationResult.validationResult.schuecalProjectZip = {};

    if (validationBackendApiResponse.validation_result.schuecal_project_zip.content_type) {
      productValidationResult.validationResult.schuecalProjectZip.contentType =
        validationBackendApiResponse.validation_result.schuecal_project_zip.content_type;
    }

    if (validationBackendApiResponse.validation_result.schuecal_project_zip.file_id) {
      productValidationResult.validationResult.schuecalProjectZip.fileId = validationBackendApiResponse.validation_result.schuecal_project_zip.file_id;
    }

    if (validationBackendApiResponse.validation_result.schuecal_project_zip.file_size) {
      productValidationResult.validationResult.schuecalProjectZip.fileSize = validationBackendApiResponse.validation_result.schuecal_project_zip.file_size;
    }
  }

  /** meta data **/
  if (validationBackendApiResponse.validation_result.values_used_in_validation.timestamp ||
      validationBackendApiResponse.validation_result.values_used_in_validation.validation_file_name) {
    productValidationResult.metaData = {};

    if (validationBackendApiResponse.validation_result.values_used_in_validation.timestamp) {
      productValidationResult.metaData.timestamp = validationBackendApiResponse.validation_result.values_used_in_validation.timestamp * 1000;
    }

    if (validationBackendApiResponse.validation_result.values_used_in_validation.validation_file_name) {
      productValidationResult.metaData.validationFileName = validationBackendApiResponse.validation_result.values_used_in_validation.validation_file_name;
    }
  }

  return productValidationResult;
};

export const generateSchuecoProductValidationRequestFromFormGroup = (
  formGroup: FormGroup,
  productName: string,
  date: Date
): SchuecoProductValidationRequestInterface => {
  const payload: SchuecoProductValidationRequestInterface = {
    validation_properties: {
      timestamp: date.getTime() / 1000,
      validation_file_name: generateValidationFileName(date, productName),
      total_height: formGroup.controls['totalHeight'].value,
      total_width: formGroup.controls['totalWidth'].value,
      opening_type: formGroup.controls['openingType'].value.value
    }
  };

  /* Temporarily removed
  if (formGroup.controls['burglarResistance'].value !== null) {
    payload.validation_properties.burglar_resistance = formGroup.controls['burglarResistance'].value.value;
  }
  */

  if (formGroup.controls['glazing'].value !== null) {
    payload.validation_properties.glazing = formGroup.controls['glazing'].value.value;
  }

  return payload;
};

export const generateValidationFileName = (date, productName) => {
  const prodName = productName.split(/\s+/g).join('_');
  // @ts-ignore
  const downloadName = $localize`:|file download name@@validation-result-page.component_file-download-name:Configuration_for_`;
  return `${ downloadName }${ prodName }_${ date.getDate() < 10 ? '0' + date.getDate() : date.getDate() }${ (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) }${ date.getFullYear() }_${ date.getHours() < 10 ? '0' + date.getHours() : date.getHours() }${ date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() }`;
};

export const generateSchuecoProductValidationPreviewFromBackendInput = (
  productValidationPreview: SchuecoProductValidationWatchlistPreviewResponseBackendInterface): SchuecoProductValidationWatchlistPreviewResponseInterface => {

  const productValidationPreviewElement: SchuecoProductValidationWatchlistPreviewResponseInterface = {
    uuid: productValidationPreview.Uuid,
    name: productValidationPreview.Name,
    category: productValidationPreview.Category
  };
  if (productValidationPreview.Image) {
    productValidationPreviewElement.image = generateFileContainerFromBackendInput(productValidationPreview.Image);
  }
  return productValidationPreviewElement;
};

export const shortenValue = (value): number => {
  if (value === null || value === undefined) {
    return null;
  } else {
    // Check for length of decimal digits, because Math.ceil only makes sens here if its more than two decimalPlaces.
    // Otherwise it will round up a value like 1.1 to 1.11
    const decimalPlaces = value.toString().split('.')[1] ? value.toString().split('.')[1].length : 0;
    if (decimalPlaces > 2) {
      // Round up second decimal number
      return Math.ceil(value * 100) / 100;
    }
    return value;
  }
};
