import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';


@Injectable()
/**
 * Intercepts api requests to add a do not track header
 * The api uses this header to anonymize user actions
 */
export class DoNotTrackUserInterceptor implements HttpInterceptor {

  constructor() {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiRequest: boolean = req.url.indexOf(environment.api_url) > -1;

    if (isApiRequest && environment.featureFlags.doNotTrackUser) {
      req = req.clone({ setHeaders: {'Do-Not-Track-User': 'true'} });
    }

    return next.handle(req);
  }
}
