import { generateFileContainerFromBackendInput } from '@p1/libs/file';

import { generateProductCategoryFromBackendInput } from '../../product/model-category/product-category.generators';
import { generateLocationFromBackendInput } from '../../shared/common/models/location';
import { generateSupplierDownloadFromBackendInput } from '../model-downloads';
import { generateProductFromBackendInput } from '../../product/model/product.generators';
import { Supplier } from './supplier';
import { SupplierBackendInterface } from './supplier.backend-interface';
import { generateSupplierTreeCategoryFromBackendInput } from '../model-treecategory';
import { generateSupplierSchemaCategoriesWithProductsFromBackendInput } from '../model-schemacategories-with-products';


/**
 * Generates a Supplier object based on api input
 *
 * @param input
 * @returns
 */
export const generateSupplierFromBackendInterface = (input: SupplierBackendInterface): Supplier => {
  try {
    const supplier: Supplier = {
      uuid: input.Uuid,
      name: input.Name,
      fullName: input.FullName,
      urlPathSegment: Array.isArray(input.Slugs) && input.Slugs.length > 0 ? input.Slugs[0] : input.Uuid
    };

    if (input.Slugs) {
      supplier.slugs = input.Slugs;
    }

    if (input.Summary) {
      supplier.summary = input.Summary;
    }

    if (input.PostalAddress) {
      supplier.postalAddress = generateLocationFromBackendInput(input.PostalAddress);
    }

    if (Array.isArray(input.Websites)) {
      supplier.websiteUrls = input.Websites;
    }

    if (Array.isArray(input.EmailAddresses)) {
      supplier.emailAddresses = input.EmailAddresses;
    }

    if (input.Logo) {
      supplier.logo = generateFileContainerFromBackendInput(input.Logo);
    }

    if (input.Image) {
      supplier.primaryImage = generateFileContainerFromBackendInput(input.Image);
    }

    if (Array.isArray(input.Downloads)) {
      supplier.downloads = input.Downloads.map(generateSupplierDownloadFromBackendInput).filter(item => !!item);
    }

    if (Array.isArray(input.Videos)) {
      supplier.videos = input.Videos.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (Array.isArray(input.SupplierCategories)) {
      supplier.supplierCategories = input.SupplierCategories.map(generateProductCategoryFromBackendInput).filter(item => !!item);
    }

    if (input.TreeCategories) {
      supplier.treeCategories = generateSupplierTreeCategoryFromBackendInput(input.TreeCategories);
    }

    if (input.SchemaCategories) {
      supplier.schemaCategories = generateSupplierSchemaCategoriesWithProductsFromBackendInput(input.SchemaCategories);
    }

    if (Array.isArray(input.SupplierBIMProducts)) {
      supplier.supplierBimProducts = input.SupplierBIMProducts.map(generateProductFromBackendInput).filter(item => !!item);
    }

    // is only used for complementaryProducts on productDetailpage (and in product.generators.ts)
    if (Array.isArray(input.ProductCategories)) {
      supplier.complementaryProductCategories =
        input.ProductCategories.map(pc => {
          try {
            return {
              displayName: pc[0],
              value: pc[1]
            };
          } catch (e) {
            return null;
          }
        }).filter(item => !!item);
    }

    if (input.Advertised) {
      supplier.advertised = input.Advertised;
    }

    return supplier;
  } catch (e) {
    return null;
  }
};
