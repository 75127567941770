import {
  Action,
  createAction,
  props
} from '@ngrx/store';

import { ExternalSource } from '@p1/libs/external-additional-content';

import { RequestPayload } from '../../shared/common/models/request-payload';
import { Product } from '../model/';
import { SearchResponseInterface } from '../../shared/common/interfaces/search-response.interface';
import { Filter } from '../../shared/filter/model';
import { Cluster } from '../../shared/cluster/model';
import { ProductScore } from '../model-score';
import { ProductQueryUserInteractionType } from '../../shared/common/models/request-payload/product-query-user-interaction-type.enum';


/**
 * Start a Fetch for Products using the given request payload
 */
export const FETCH_MULTI = '[PRODUCTS] Fetch Multi';
export class FetchMultiAction implements Action {
  readonly type = FETCH_MULTI;

  constructor(public payload: {
    queryConfig?: RequestPayload;
    count?: number;
    offset?: number;
    loadInBackground?: boolean;
    addQueryUuidParam?: boolean;
    queryUuid?: string;
    replaceUrl?: boolean;
    restore?: boolean;
    fromPaginate?: boolean;
    unfilterableCategory?: boolean;
  }) {}
}

/**
 * Fetch for multiple Projects succeeded, payload contains result-list
 */
export const FETCH_MULTI_SUCCEEDED = '[PRODUCTS] Fetch Multi Succeeded';
export class FetchMultiSucceededAction implements Action {
  readonly type = FETCH_MULTI_SUCCEEDED;

  constructor(public payload: SearchResponseInterface<Product>) {}
}

/**
 * Fetch for multiple Projects failed, payload may contain additional information
 */
export const FETCH_MULTI_FAILED = '[PRODUCTS] Fetch Multi Failed';
export class FetchMultiFailedAction implements Action {
  readonly type = FETCH_MULTI_FAILED;

  constructor(public payload: any) {}
}

/**
 * Start a Fetch for a single Product (with more details)
 */
export const FETCH_SINGLE = '[PRODUCTS] Fetch Single';
export class FetchSingleAction implements Action {
  readonly type = FETCH_SINGLE;

  constructor(public payload: {
    uuid: string;
  }) {}
}

/**
 * Fetch for a single Project succeeded, payload contains result
 */
export const FETCH_SINGLE_SUCCEEDED = '[PRODUCTS] Fetch Single Succeeded';
export class FetchSingleSucceededAction implements Action {
  readonly type = FETCH_SINGLE_SUCCEEDED;

  constructor(public payload: Product) {}
}

/**
 * Fetch for a single Project failed, payload may contain additional information
 */
export const FETCH_SINGLE_FAILED = '[PRODUCTS] Fetch Single Failed';
export class FetchSingleFailedAction implements Action {
  readonly type = FETCH_SINGLE_FAILED;

  constructor(public payload: any) {}
}

/**
 * Fetch the score for a single product
 */
export const FETCH_ITEM_SCORE = '[PRODUCTS] Fetch item score';
export class FetchItemScoreAction implements Action {
  readonly type = FETCH_ITEM_SCORE;

  constructor(public payload: string) {}
}

/**
 * Fetch for a single Project succeeded, payload contains result
 */
export const FETCH_ITEM_SCORE_SUCCEEDED = '[PRODUCTS] Fetch item score Succeeded';
export class FetchItemScoreSucceededAction implements Action {
  readonly type = FETCH_ITEM_SCORE_SUCCEEDED;

  constructor(public payload: { itemUuid: string; queryUuid: string; score: ProductScore }) {}
}

/**
 * Fetch for a single Project failed, payload may contain additional information
 */
export const FETCH_ITEM_SCORE_FAILED = '[PRODUCTS] Fetch item score Failed';
export class FetchItemScoreFailedAction implements Action {
  readonly type = FETCH_ITEM_SCORE_FAILED;


  constructor(public payload: any) {}
}

/**
 * Add new active propertyFilter name
 */
export const ADD_ACTIVE_PROPERTY_FILTERS = '[PRODUCTS] Add active Property Filters';
export class AddActivePropertyFiltersAction implements Action {
  readonly type = ADD_ACTIVE_PROPERTY_FILTERS;

  constructor(public payload: string[]) {}
}

/**
 * Removes an active propertyFilter name
 */
export const REMOVE_ACTIVE_PROPERTY_FILTERS = '[PRODUCTS] Remove active Property Filters';
export class RemoveActivePropertyFiltersAction implements Action {
  readonly type = REMOVE_ACTIVE_PROPERTY_FILTERS;

  constructor(public payload: string[]) {}
}

/**
 * Removes all active propertyFilters
 */
export const REMOVE_ALL_ACTIVE_PROPERTY_FILTERS = '[PRODUCTS] Remove all active Property Filters';
export class RemoveAllActivePropertyFiltersAction implements Action {
  readonly type = REMOVE_ALL_ACTIVE_PROPERTY_FILTERS;
}

/**
 * Updates active propertyFilter
 */
export const UPDATE_ACTIVE_PROPERTY_FILTERS = '[PRODUCTS] Update active Property Filters';
export class UpdateActivePropertyFiltersAction implements Action {
  readonly type = UPDATE_ACTIVE_PROPERTY_FILTERS;

  constructor(public payload: Filter[]) {}
}

/**
 * Add property filters that have no value
 */
export const ADD_PROPERTY_FILTERS_WITHOUT_VALUE = '[PRODUCTS] Add property filters that have no value';
export class AddPropertyFiltersWithoutValueAction implements Action {
  readonly type = ADD_PROPERTY_FILTERS_WITHOUT_VALUE;

  constructor(public payload: string[]) {}
}

/**
 * Sets a new query uuid
 */
export const SET_QUERY_UUID = '[PRODUCT] Set query uuid';
export class SetQueryUuidAction implements Action {
  readonly type = SET_QUERY_UUID;

  constructor(public payload: string) {}
}

/**
 * Fetches new filters
 */
export const FETCH_FILTER = '[PRODUCTS] Fetch Filter';
export class FetchFilterAction implements Action {
  readonly type = FETCH_FILTER;

  // Todo: clarify if this can be fixed (POE-3704)
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(public payload: {} = undefined) {}
}

/**
 * Apply successfully fetched filters
 */
export const FETCH_FILTER_SUCCEEDED = '[PRODUCTS] Fetch Filter Succeeded';
export class FetchFilterSucceededAction implements Action {
  readonly type = FETCH_FILTER_SUCCEEDED;

  constructor(public payload: {
    basicFilters: Filter[];
    propertyFilters: Filter[];
    propertyClusters: Cluster[];
    settingFilters: Filter[];
  }) {}
}

/**
 * Fetch filter failed, save error
 */
export const FETCH_FILTER_FAILED = '[PRODUCTS] Fetch Filter Failed';
export class FetchFilterFailedAction implements Action {
  readonly type = FETCH_FILTER_FAILED;

  /**
   * @param payload an error object
   */
  constructor(public payload: any) {}
}

/**
 * Sets a new map with property-filter-values
 */
export const UPDATE_TEMP_PROPERTY_FILTER_VALUES = '[PRODUCT] Update temp property filter values';
export class UpdateTempPropertyFilterValuesAction implements Action {
  readonly type = UPDATE_TEMP_PROPERTY_FILTER_VALUES;

  /**
   * @param payload an object with property filter values
   */
  constructor(public payload: {[filterId: string]: FilterValue}) {}
}

/**
 * Sets a new map with basic-filter-values
 */
export const UPDATE_TEMP_BASIC_FILTER_VALUES = '[PRODUCT] Update temp basic filter values';
export class UpdateTempBasicFilterValuesAction implements Action {
  readonly type = UPDATE_TEMP_BASIC_FILTER_VALUES;

  /**
   * @param payload an object with basic filter values
   */
  constructor(public payload: {[filterId: string]: FilterValue}) {}
}

/**
 * Sets a new map with setting-filter-values
 */
export const UPDATE_TEMP_SETTING_FILTER_VALUES = '[PRODUCT] Update temp setting filter values';
export class UpdateTempSettingFilterValuesAction implements Action {
  readonly type = UPDATE_TEMP_SETTING_FILTER_VALUES;

  /**
   * @param payload an object with setting filter values
   */
  constructor(public payload: {[filterId: string]: FilterValue}) {}
}

/**
 * Sets the uuid for the currently hovered item in the product list
 */
export const SET_HOVERED_ITEM_UUID = '[PRODUCT] Set hovered item uuid';
export class SetHoveredItemUuidAction implements Action {
  readonly type = SET_HOVERED_ITEM_UUID;

  /**
   * @param payload Uuid of product to highlight filter values
   */
  constructor(public payload: string) {}
}

export const UPDATE_TEMP_ORDERING_VALUE = '[PRODUCT] Update temp ordering value';
export class UpdateTempOrderingValueAction implements Action {
  readonly type = UPDATE_TEMP_ORDERING_VALUE;

  constructor(public payload: string[]) {}
}

/**
 * Search with temp filter values
 */
export const searchWithTempFilterValues = createAction(
  '[PRODUCTS] Search with temp filter values',
  props<{page?: number; replaceUrl?: boolean; context?: {userInteraction: ProductQueryUserInteractionType}}>()
);

/**
 * Opened a product search page with a queryUuid
 */
export const openedSearchWithQueryUuid = createAction(
  '[PRODUCTS] Opened search with query uuid',
  props<{queryUuid: string; page?: number}>()
);

export const openedProductDetailPage = createAction(
  '[PRODUCTS] Opened product detail page',
  props<{uuidOrSlug: string}>()
);

export const openedProductDetailPageWithQueryUuid = createAction(
  '[PRODUCTS] Opened product detail page with query uuid',
  props<{queryUuid: string; uuidOrSlug: string; page?: number}>()
);

/**
 * Navigate to another page of the current search
 */
export const PAGINATE_CURRENT_SEARCH = '[PRODUCTS] Paginate current search';
export class PaginateCurrentSearchAction implements Action {
  readonly type = PAGINATE_CURRENT_SEARCH;

  constructor(public payload: {page: number}) {}
}

export const RESTORE_URLPARAMS_FROM_LAST_SEARCH = '[PRODUCTS] Restore url params from last search';
export class RestoreUrlparamsFromLastSearchAction implements Action {
  readonly type = RESTORE_URLPARAMS_FROM_LAST_SEARCH;
}

export const FETCH_SIMILAR_PRODUCTS = '[PRODUCTS] Fetch similar products';
export class FetchSimilarProductsAction implements Action {
  readonly type = FETCH_SIMILAR_PRODUCTS;

  constructor(public payload: {
    productUuid: string;
    loadWithDetail?: boolean;
    limit?: number;
    offset?: number;
    treeCategoryUuid?: string;
  }) {}
}

export const FETCH_SIMILAR_PRODUCTS_SUCCEEDED = '[PRODUCTS] Fetch similar products succeeded';
export class FetchSimilarProductsSucceededAction implements Action {
  readonly type = FETCH_SIMILAR_PRODUCTS_SUCCEEDED;

  constructor(public payload: {uuid: string; similarProducts: Product[]; offset?: number; withDetails?: boolean}) {}
}

export const FETCH_SIMILAR_PRODUCTS_FAILED = '[PRODUCTS] Fetch similar products failed';
export class FetchSimilarProductsFailedAction implements Action {
  readonly type = FETCH_SIMILAR_PRODUCTS_FAILED;

  constructor(public payload: any) {}
}

export const fetchComplementaryProducts = createAction(
  '[PRODUCTS] Fetch complementary products',
  props<{productUuid: string}>()
);

export const fetchComplementaryProductsSucceeded = createAction(
  '[PRODUCTS] Fetch complementary products succeeded',
  props<{payload: {uuid: string; complementaryProducts: Product[]}}>()
);

export const fetchComplementaryProductsFailed = createAction(
  '[PRODUCTS] Fetch complementary products failed',
  props<{payload: any}>()
);

/**
 * Start a Fetch for featured products using the given request payload
 */
export const FETCH_FEATURED_PRODUCTS = '[PRODUCTS] Fetch featured products';
export class FetchFeaturedProductsAction implements Action {
  readonly type = FETCH_FEATURED_PRODUCTS;

  constructor(public payload: {
    queryConfig?: RequestPayload;
    count?: number;
    offset?: number;
    loadInBackground?: boolean;
    addQueryUuidParam?: boolean;
    queryUuid?: string;
    replaceUrl?: boolean;
    restore?: boolean;
    fromPaginate?: boolean;
  }) {}
}

/**
 * Fetch for featured products succeeded, payload contains result-list
 */
export const FETCH_FEATURED_PRODUCTS_SUCCEEDED = '[PRODUCTS] Fetch featured products succeeded';
export class FetchFeaturedProductsSucceededAction implements Action {
  readonly type = FETCH_FEATURED_PRODUCTS_SUCCEEDED;

  constructor(public payload: SearchResponseInterface<Product>) {}
}

/**
 * Fetch for featured products failed, payload may contain additional information
 */
export const FETCH_FEATURED_PRODUCTS_FAILED = '[PRODUCTS] Fetch featured products failed';
export class FetchFeaturedProductsFailedAction implements Action {
  readonly type = FETCH_FEATURED_PRODUCTS_FAILED;

  constructor(public payload: any) {}
}

export const USER_CLICKED_EXTERNAL_LINK = '[PRODUCT EXTERNAL DOWNLOADS] User clicked external link';
export class UserClickedExternalLinkAction implements Action {
  readonly type = USER_CLICKED_EXTERNAL_LINK;

  constructor(public payload: {
    link: {url: string; name: string};
    source: ExternalSource;
    type: 'product'|'supplier';
    productUuid: string;
    contentSection: string;
  }) {}
}

export const USER_SEND_EXTERNAL_FEEDBACK = '[PRODUCT EXTERNAL DOWNLOADS] User send external feedback';
export class UserSendExternalFeedbackAction implements Action {
  readonly type = USER_SEND_EXTERNAL_FEEDBACK;

  constructor(public payload: {
    category: string;
    url: string;
    useful: boolean;
    feedback: string;
  }) {}
}

export type Actions = FetchMultiAction
  | FetchMultiSucceededAction
  | FetchMultiFailedAction
  | FetchSingleAction
  | FetchSingleSucceededAction
  | FetchSingleFailedAction
  | FetchFilterAction
  | FetchFilterSucceededAction
  | FetchFilterFailedAction
  | AddActivePropertyFiltersAction
  | RemoveActivePropertyFiltersAction
  | RemoveAllActivePropertyFiltersAction
  | UpdateActivePropertyFiltersAction
  | AddPropertyFiltersWithoutValueAction
  | UpdateTempPropertyFilterValuesAction
  | UpdateTempBasicFilterValuesAction
  | UpdateTempSettingFilterValuesAction
  | SetQueryUuidAction
  | FetchItemScoreAction
  | FetchItemScoreSucceededAction
  | FetchItemScoreFailedAction
  | SetHoveredItemUuidAction
  | UpdateTempOrderingValueAction
  | PaginateCurrentSearchAction
  | ReturnType<typeof searchWithTempFilterValues>
  | ReturnType<typeof openedSearchWithQueryUuid>
  | ReturnType<typeof openedProductDetailPage>
  | ReturnType<typeof openedProductDetailPageWithQueryUuid>
  | RestoreUrlparamsFromLastSearchAction
  | FetchSimilarProductsAction
  | FetchSimilarProductsSucceededAction
  | FetchSimilarProductsFailedAction
  | ReturnType<typeof fetchComplementaryProducts>
  | ReturnType<typeof fetchComplementaryProductsSucceeded>
  | ReturnType<typeof fetchComplementaryProductsFailed>
  | FetchFeaturedProductsAction
  | FetchFeaturedProductsSucceededAction
  | FetchFeaturedProductsFailedAction
  | UserClickedExternalLinkAction
  | UserSendExternalFeedbackAction;
