import {
  Actions,
  Effect
} from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  Action,
  select,
  Store
} from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  filter,
  mapTo,
  withLatestFrom
} from 'rxjs/operators';

import * as dialogReducer from '../reducer';
import * as dialogActions from '../actions/dialog.actions';


@Injectable()
export class DialogEffects {

  /**
   * Checks if any actions type is part of the closeOnActionTypes array of the currentDialog
   * Dispatches CloseDialogAction if true
   *
   * @type {Observable<CloseDialogAction>}
   */
  @Effect()
  anyAction$: Observable<Action> = this._actions.pipe(
    withLatestFrom(this._store.pipe(
      select(dialogReducer.getCurrentDialog)
    )),
    filter(([action, currentDialog]) => currentDialog && currentDialog.closeOnActionTypes && currentDialog.closeOnActionTypes.includes(action.type)),
    mapTo(new dialogActions.CloseDialogAction())
  );


  constructor(private _actions: Actions, private _store: Store<dialogReducer.State>) {}
}
