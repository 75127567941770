import {
  Inject,
  Injectable
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Observable,
  of
} from 'rxjs';
import {
  catchError,
  map,
  share
} from 'rxjs/operators';

import {
  LoggerServiceInterface,
  LOGGING_SERVICE
} from '@p1/libs/logging';

import {
  SCHUECO_PRODUCT_VALIDATION_CONFIG,
  SchuecoProductValidationConfigInterface
} from '../config/schueco-product-validation-config.interface';
import { SchuecoProductValidationResponseBackendInterface } from '../model/schueco-product-validation-response.backend-interface';
import { SchuecoProductValidationResponseInterface } from '../model/schueco-product-validation-response.interface';
import { SchuecoProductValidationRequestInterface } from '../model/schueco-product-validation-request.interface';
import { generateSchuecoProductValidationResponseFromBackendInput } from '../model/schueco-product-validation.generators';
import { ConfigOptionsInterface } from '../model/config-options.interface';
import { ConfigOptionsBackendInterface } from '../model/config-options.backend-interface';
import { generateConfigOptionsFromBackendInput } from '../model/config-options.generator';


@Injectable({
  providedIn: 'root'
})
export class SchuecoProductValidationService {

  fetchValidationResultApiUrl: string;
  triggerValidationApiUrl: string;
  checkProductForValidationApiUrl: string;
  getConfigOptionsUrl: string;

  constructor(
    private _httpClient: HttpClient,
    @Inject(LOGGING_SERVICE) private _logger: LoggerServiceInterface,
    @Inject(SCHUECO_PRODUCT_VALIDATION_CONFIG) private _config: SchuecoProductValidationConfigInterface
  ) {}


  triggerValidation(productUuid: string, inputData: SchuecoProductValidationRequestInterface): Observable<string> {
    this.triggerValidationApiUrl = `${ this._config.apiUrl }scheduler/product/${ productUuid }/configuration`;

    return this._httpClient.post<{task_id}>(
      this.triggerValidationApiUrl,
      inputData
    ).pipe(
      map(data => {
        if (data) {
          try {
            return data.task_id;
          } catch (e) {
            this._logger.error(e);
            return null;
          }
        } else {
          return null;
        }
      }),
      share(),
      catchError(error => {
        this._logger.error(error);
        return of(null);
      })
    );
  }

  fetchValidationResult(validationUuid: string): Observable<SchuecoProductValidationResponseInterface> {
    this.fetchValidationResultApiUrl = `${ this._config.apiUrl }scheduler/tasks/${ validationUuid }/result`;

    return this._httpClient.get<SchuecoProductValidationResponseBackendInterface>(
      this.fetchValidationResultApiUrl
    ).pipe(
      map(data => {
        if (data) {
          try {
            return generateSchuecoProductValidationResponseFromBackendInput(data);
          } catch (e) {
            this._logger.error(e);
            return null;
          }
        } else {
          return null;
        }
      }),
      share()
    );
  }

  checkIfValidatonIsPossible(productUuid: string): Observable<boolean> {
    this.checkProductForValidationApiUrl = `${ this._config.apiUrl }product/${ productUuid }/configurable`;

    return this._httpClient.get<{configurable: boolean}>(
      this.checkProductForValidationApiUrl
    ).pipe(
      map(data => data ? data.configurable : null)
    );
  }

  getConfigOptions(productUuid: string): Observable<ConfigOptionsInterface> {
    this.getConfigOptionsUrl = `${ this._config.apiUrl }product/${ productUuid }/config_options`;

    return this._httpClient.get<ConfigOptionsBackendInterface>(
      this.getConfigOptionsUrl
    ).pipe(
      map(data => {
        if (data) {
          try {
            return generateConfigOptionsFromBackendInput(data);
          } catch (e) {
            this._logger.error(e);
            return null;
          }
        }
        return null;
      })
    );
  }
}
