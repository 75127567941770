import * as allClusterSearchActions from '../../../actions/search.actions';


export const searchBarTotalCountsSearchResults = (state: Record<string, unknown>,
                                                  action: allClusterSearchActions.Actions): Record<string, unknown> => {
  switch (action.type) {
    case allClusterSearchActions.FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS_SUCCEEDED:
      return action.payload.totalCounts;
    case allClusterSearchActions.CLEAR_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS:
      return undefined;
    default:
      return state;
  }
};
