import * as categoryTreeActions from '../../actions/category-tree.actions';


/**
 * fetchCategoryTreeInProgress reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const fetchCategoryTreeInProgress = (state: boolean = false, action: categoryTreeActions.Actions): boolean => {
  switch (action.type) {
    case categoryTreeActions.CATEGORY_TREE_UUID_REQUESTED:
      return true;
    case categoryTreeActions.FETCH_CATEGORY_TREE_SUMMARY_FAILED:
    case categoryTreeActions.FETCH_CATEGORY_TREE_SUMMARY_SUCCEEDED:
      return false;
    default:
      return state;
  }
};
