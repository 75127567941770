import {
  createAction,
  props
} from '@ngrx/store';
import { ProductComparison } from '../model/product-comparison';
import { Product } from '../../product/model';


export const addProductToProductComparison = createAction(
  '[ProductComparison] Added product to product comparison',
  props<{uuid: string}>()
);

export const removeProductFromProductComparison = createAction(
  '[ProductComparison] Removed product from product comparison',
  props<{uuid: string}>()
);

export const clearProductsFromProductComparison = createAction(
  '[ProductComparison] Clear all products from product comparison'
);

export const loadProductComparisonTriggered = createAction(
  '[ProductComparison] Load product comparison triggered',
  props<{comparisonUuid: string}>()
);

export const loadProductComparisonSuccess = createAction(
  '[ProductComparison] Load product comparison Success',
  props<{productComparison: ProductComparison}>()
);

export const loadProductComparisonFailure = createAction(
  '[ProductComparison] Load product comparison Failure',
  props<{error: any}>()
);

export const saveProductComparisonTriggered = createAction(
  '[ProductComparison] Save product comparison triggered',
  props<{comparisonContext?: string}>()
);

export const saveProductComparisonSuccess = createAction(
  '[ProductComparison] Save product comparison Success',
  props<{productComparison: ProductComparison; comparisonContext?: string}>()
);

export const saveProductComparisonFailure = createAction(
  '[ProductComparison] Save product comparison Failure',
  props<{error: any}>()
);

export const createProductComparisonFromProductsTriggered = createAction(
  '[ProductComparison] Create product comparison from products triggered',
  props<{context: string; uuids: string[]}>()
);

export const createProductComparisonFromProductsSuccess = createAction(
  '[ProductComparison] Create product comparison from products Success',
  props<{context: string}>()
);

export const createProductComparisonFromProductsFailure = createAction(
  '[ProductComparison] Create product comparison from products Failure',
  props<{error: any}>()
);

export const productComparisonAnalyticsEventSaveSuccess = createAction(
  '[ProductComparison] Save product comparison analytics event Success'
);

export const fetchSimilarProductsForComparisonTriggered = createAction(
  '[ProductComparison] Fetch similar products for comparison triggered',
  props<{product: Product}>()
);

export type Actions = ReturnType<typeof addProductToProductComparison> |
                      ReturnType<typeof removeProductFromProductComparison> |
                      ReturnType<typeof clearProductsFromProductComparison> |
                      ReturnType<typeof loadProductComparisonTriggered> |
                      ReturnType<typeof loadProductComparisonSuccess> |
                      ReturnType<typeof loadProductComparisonFailure> |
                      ReturnType<typeof saveProductComparisonTriggered> |
                      ReturnType<typeof saveProductComparisonSuccess> |
                      ReturnType<typeof saveProductComparisonFailure> |
                      ReturnType<typeof createProductComparisonFromProductsTriggered> |
                      ReturnType<typeof createProductComparisonFromProductsSuccess> |
                      ReturnType<typeof createProductComparisonFromProductsFailure> |
                      ReturnType<typeof productComparisonAnalyticsEventSaveSuccess> |
                      ReturnType<typeof fetchSimilarProductsForComparisonTriggered>;
