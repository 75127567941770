import {
  Inject,
  Injectable,
  LOCALE_ID
} from '@angular/core';
import {
  HttpClient,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  CATALOG_SERVICE_CONFIG,
  CatalogServiceConfigInterface,
} from '@p1/libs/catalog-service';
import { map } from 'rxjs/operators';
import { TreeCategory } from '@p1/libs/catalog-service/src/lib/model/tree-category';
import { generateTreeCategoryFromBackendInput } from '@p1/libs/catalog-service/src/lib/model/tree-category.generator';
import { TreeCategoryBackendInterface } from '@p1/libs/catalog-service/src/lib/model/tree-category.backend-interface';


@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(
    private _httpClient: HttpClient,
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(CATALOG_SERVICE_CONFIG) private _config: CatalogServiceConfigInterface
  ) { }

  fetchAllCategoriesOfTree(treeUuid: string): Observable<TreeCategory[]> {
    const queryParameters = new HttpParams({ fromObject: { locale: this._getLanguageFromLocale(this._locale) } });
    return this._httpClient.get<{categories: TreeCategoryBackendInterface[]}>(`${ this._config.catalogApiUrl }category-trees/${ treeUuid }/categories`,
      { params: queryParameters }).pipe(
      map(response => Array.isArray(response?.categories) ? response.categories.filter(category => category.active)
        .map(generateTreeCategoryFromBackendInput) : null)
    );
  }

  private _getLanguageFromLocale(locale: string): string {
    return locale?.split('-', 1)[0];
  }

}
