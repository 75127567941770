<div *ngFor="let newsItem of news; last as isLast"
     class="news-item">
  <span class="news-item__date">{{ newsItem.date | date:'dd.MM.YYYY' }}</span>
  <div class="news-item__headline">
    <i *ngIf="!readNews.includes(newsItem.id)"
       class="news-item__indicator"></i>
    <h3 class="news-item__title">{{ newsItem.title }}</h3>
    <span class="news-item__category">
      <ng-container *ngIf="newsItem.categories.includes(newsCategory.functions)">
        Funktion
      </ng-container>
      <ng-container *ngIf="newsItem.categories.includes(newsCategory.suppliers)">
        Hersteller
      </ng-container>
      <ng-container *ngIf="newsItem.categories.includes(newsCategory.categories)">
        Kategorien
      </ng-container>
    </span>
  </div>
  <div class="news-item__content"
       [innerHTML]="newsItem.content"></div>
  <button p1Button
          buttonType="link-style"
          class="news-item__button"
          [disabled]="readNews.includes(newsItem.id)"
          (click)="addReadNews.emit(newsItem.id)"
          data-gtm-event-category="News"
          [attr.data-gtm-event-action]="'Click read news ' + newsItem.title"
          data-gtm-event-label="News Dialog - List - Read news"><i class="news-item__indicator"></i>Als gelesen markieren</button>
  <hr *ngIf="!isLast">
</div>
