import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router
} from '@angular/router';
import {
  select,
  Store
} from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as authReducer from '../../../auth/reducer/auth.reducer';


@Injectable()
export class UserUnauthenticatedGuard implements CanActivate {

  constructor(
    private _store: Store<authReducer.StateInterface>,
    private _router: Router
  ) {}

  canActivate(
    _,
    __): Observable<boolean> {
    return this._store.pipe(
      select(authReducer.getIsAuthenticated),
      map(isAuthenticated => {
        if (isAuthenticated) {
          this._router.navigate(['']);
        }
        return !isAuthenticated;
      })
    );
  }

}
