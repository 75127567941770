import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { QaStateHandlingService } from '../../qa-state/qa-state-handling-service/qa-state-handling.service';


@Injectable()
/**
 * Intercepts api requests to add app context identifier
 */
export class QaStateInterceptor implements HttpInterceptor {

  private _selectedQaState: string;

  constructor(
    private _qaStateHandlingService: QaStateHandlingService
  ) {
    this._qaStateHandlingService.selectedQaState$.subscribe(state => {
      this._selectedQaState = state;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isQaDependingApiRequest = req.url.startsWith(environment.api_url + 'products')
                                         || req.url.startsWith(environment.api_url + 'companies')
                                         || req.url.startsWith(environment.api_url + 'search');
    if (isQaDependingApiRequest && this._selectedQaState) {
      let newUrl = req.urlWithParams;
      if (!newUrl.includes('requested_qa_state')) {
        newUrl += (newUrl.includes('?') ? '&' : '?') + `qa_state=${this._selectedQaState}`;
      }
      req = req.clone({ url: newUrl });
    }

    return next.handle(req);
  }
}
