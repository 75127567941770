import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';
import { RouterNavigationAction } from '@ngrx/router-store';

import { ActionWithPayload } from '../../shared/ngrx/action-with-payload.class';


export const GO = '[Router] Go';
export class GoAction implements ActionWithPayload<{ path: any[]; query?: Record<string, unknown>; extras?: NavigationExtras }> {
  readonly type = GO;


  constructor(public payload: {
    path: any[];
    queryParams?: Record<string, unknown>;
    extras?: NavigationExtras;
  }) {}
}

export const BACK = '[Router] Back';
export class BackAction implements Action {
  readonly type = BACK;
}

export const FORWARD = '[Router] Forward';
export class ForwardAction implements Action {
  readonly type = FORWARD;
}

/**
 * Payload contains the new page title
 *
 * @type {string}
 */
export const SET_TITLE = '[ROUTER] Set Title';
export class SetTitleAction implements Action {
  readonly type = SET_TITLE;


  constructor(public payload: string) {}
}

export type Actions = SetTitleAction
  | GoAction
  | BackAction
  | ForwardAction
  | RouterNavigationAction;

export { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
