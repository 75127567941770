import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  iconBin,
  iconComparison,
  IconsModule,
  IconsRegistry,
  illustrationComparison,
  illustrationComparisonSimilar
} from '@p1/libs/icons';

import { environment } from '../../environments/environment';
import { WatchlistDropdownLoaderModule } from '../my-watchlists/dropdown-loader/watchlist-dropdown-loader.module';
import { ProductSharedModule } from '../product/product.shared.module';
import { SharedModule } from '../shared/shared.module';
import { ComparisonMenuComponent } from './comparison-menu/comparison-menu.component';
import { ComparisonMenuProductTileComponent } from './comparison-product-tile/comparison-menu-product-tile.component';
import { ProductComparisonPreviewComponent } from './product-comparison-preview/product-comparison-preview.component';
import { ProductComparisonStoreModule } from './product-comparison.store.module';
import { ProductComparisonTableComponent } from './product-comparison-table/product-comparison-table.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ProductSharedModule,
    ProductComparisonStoreModule,
    IconsModule,
    environment.featureFlags.myWatchlists ? WatchlistDropdownLoaderModule : []
  ],
  declarations: [
    ComparisonMenuComponent,
    ComparisonMenuProductTileComponent,
    ProductComparisonPreviewComponent,
    ProductComparisonTableComponent
  ],
  exports: [
    ComparisonMenuComponent,
    ComparisonMenuProductTileComponent,
    ProductComparisonPreviewComponent,
    ProductComparisonTableComponent
  ]
})
export class ProductComparisonSharedModule {
  constructor(private _iconsRegistry: IconsRegistry) {
    this._iconsRegistry.registerIcons([
      iconBin,
      iconComparison,
      illustrationComparison,
      illustrationComparisonSimilar
    ]);
  }
}
