/**
 * returns an array with removed duplicates
 *
 * @param input
 */
export const uniqueArray = <T>(input: T[]) => Array.from<T>(input
  .reduce((prev, curr) => {
    prev.add(curr);
    return prev;
  }, new Set<T>()));
