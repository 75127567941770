import { Injectable } from '@angular/core';
import {
  Actions,
  Effect,
  ofType
} from '@ngrx/effects';
import {
  Action
} from '@ngrx/store';
import {
  defer,
  Observable,
  of
} from 'rxjs';
import {
  catchError,
  map,
  switchMap
} from 'rxjs/operators';

import { NewsService } from '../service/news.service';
import * as newsActions from '../actions/news.actions';


/**
 * Contains base side effects for the blog posts of wordpress
 */
@Injectable()
export class NewsEffects {

  /**
   * Fetches the news of wordpress api
   *
   * @type {Observable<Action>}
   */
  @Effect()
  fetchNews$: Observable<Action> = this._actions.pipe(
    ofType(newsActions.fetchNews),
    switchMap(() => this._newsService.fetchNews().pipe(
      map((news) => newsActions.fetchNewsSucceeded({ news })),
      catchError(error => of(newsActions.fetchNewsFailed({ error }))))
    )
  );

  @Effect()
  init$: Observable<Action> = defer(() => of(newsActions.fetchNews()));

  /**
   * @param _actions
   * @param _newsService
   */
  constructor(
    private _actions: Actions<newsActions.Actions>,
    private _newsService: NewsService
  ) {}
}
