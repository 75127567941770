import { Injectable } from '@angular/core';
import {
  Action
} from '@ngrx/store';
import {
  Actions,
  Effect,
  ofType
} from '@ngrx/effects';
import {
  Observable,
  of
} from 'rxjs';
import {
  catchError,
  map,
  switchMap
} from 'rxjs/operators';

import * as categoryTreeActions from '../actions/category-tree.actions';
import * as productActions from '../../product/actions/product.actions';
import { CatalogService } from '@p1/libs/catalog-service';


@Injectable()
/**
 * Contains side effects for Supplier actions
 */
export class CategoryTreeEffects {

  @Effect()
  fetchAllCategoriesOfTree$: Observable<Action> = this._actions.pipe(
    ofType(categoryTreeActions.CATEGORY_TREE_UUID_REQUESTED),
    map(action => action.payload ? action.payload.treeUuid : null),
    switchMap(treeUuid => this._catalogService.fetchAllCategoriesOfTree(treeUuid).pipe(
      map(categories => new categoryTreeActions.FetchCategoryTreeSummarySucceededAction({treeNodes: categories, treeUuid})),
      catchError(error => of(new categoryTreeActions.FetchCategoryTreeSummaryFailedAction(error)))
    ))
  );


  /**
   * @param _actions
   * @param _catalogService
   */
  constructor(
    private _actions: Actions<categoryTreeActions.Actions | productActions.Actions>,
    private _catalogService: CatalogService) {}
}
