import {
  Inject,
  Injectable,
  Optional
} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';

import {
  LoggerServiceInterface,
  LOGGING_SERVICE
} from '@p1/libs/logging';
import { WindowRef } from '@p1/libs/browser-api-wrapper';

import { environment } from '../../../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})

/**
 * Class represents the AnalyticsEventsService
 */
export class AnalyticsEventsService {

  private _serviceUrl: string = `${ environment.api_url }events`;
  private _itemKey: string = 'p1_acquisition';
  private _additionalEventData: Record<string, unknown> = {};

  /**
   * Create the service
   *
   * @constructor
   * @param _httpClient {HttpClient}
   * @param _loggerService {LoggerServiceInterface}
   * @param _windowRef {WindowRef}
   */
  constructor(
    private _httpClient: HttpClient,
    @Optional() @Inject(LOGGING_SERVICE) private _loggerService: LoggerServiceInterface,
    private _windowRef: WindowRef
  ) {}

  /**
   * Accepts the productUuid and calls the `_postEvent` method with type, action, source and payload
   *
   * @param productUuid {string}
   */
  openProductDetailPage(productUuid: string) {
    this._postEvent('Product', 'openProductDetailPage', {
      uuid: productUuid
    });
  }

  /**
   * Accepts the supplierUuid and products of search bar results and calls the `_postEvent` method with type, action, source and payload
   *
   * @param supplierUuid
   */
  openSupplierDetailPage(supplierUuid: string, products?: {product: string; treeCategory: string}[]) {
    this._postEvent('Supplier', 'openSupplierDetailPage', {
      uuid: supplierUuid,
      products
    });
  }

  /**
   * Accepts the downloadUuid, productUuid and name and originalSource of the downloaded file
   * and calls the `_postEvent` method with type, action, source and payload.
   * The parameter originalSource is optional.
   *
   * @param downloadUuid {string}
   * @param productUuid {string}
   * @param name {string}
   * @param type {string}
   * @param originalSrc {string}
   */
  downloadProductInformation(downloadUuid: string, productUuid: string, fileId: string, name: string, type: string, originalSrc?: string) {
    this._postEvent('Product', 'downloadProductInfo', {
      productUuid,
      uuid: downloadUuid,
      fileId,
      name,
      type,
      originalSource: originalSrc
    });
  }

  trackExternalContentLink(productUuid: string, source: string, link: string, linkType: 'product' | 'supplier', contentSection: string) {
    this._postEvent('Product', 'clickExternalLink', {
      uuid: productUuid,
      source,
      link,
      linkType,
      type: contentSection
    });
  }

  trackExternalContentFeedback(productUuid: string, link: string, category: string, useful: boolean, feedback?: string, userUuid?: string) {
    this._postEvent('Product', 'sendExternalFeedback', {
      uuid: productUuid,
      link,
      category,
      useful,
      feedback,
      userUuid
    });
  }

  /**
   * Accepts the productUuid and calls the `_postEvent` method with type, action, source and payload
   *
   * @param productUuid {string}
   */
  searchSimilarProducts(productUuid: string) {
    this._postEvent('Product', 'searchSimilarProducts', {
      uuid: productUuid
    });
  }

  /**
   * Accepts the downloadUuid, productUuid and name and originalSource of the downloaded file
   * and calls the `_postEvent` method with type, action, source and payload.
   * The parameter originalSource is optional.
   *
   * @param downloadUuid {string} // fileId
   * @param productUuid {string}
   * @param name {string} // FileName
   * @param type {string} // 'ValidationResult'
   * @param fileType {string} // pdf / zip / csv
   */
  downloadProductValidationResult(downloadUuid: string, productUuid: string, name: string, type: string, fileType: string) {
    this._postEvent('product_validation', 'download_validation_result', {
      productUuid,
      uuid: downloadUuid,
      name,
      type,
      fileType
    });
  }

  /**
   * Accepts the searchType and searchTerm and calls the `_postEvent` method with type, action, source and payload.
   *
   * @param searchType {string}
   * @param searchTerm {string}
   * @param searchCluster {string}
   * @param pageInfo {string}
   * @param queryUuid {string}
   */
  textSearch(searchType: string, searchTerm: string, searchCluster?: string, pageInfo?: string, queryUuid?: string) {
    const payload = {
      searchTerm
    };

    if (searchCluster) {
      payload['searchCluster'] = searchCluster;
    }

    if (pageInfo) {
      payload['pageInfo'] = `${ pageInfo }page`;
    }

    if (queryUuid) {
      payload['searchUuid'] = queryUuid;
    }

    this._postEvent('text_search', searchType, payload);
  }

  /**
   * Accepts searchTerm and resultUuid of search result and calls the `_postEvent` method with type, action, source and payload.
   *
   * @param searchTerm {string}
   * @param resultName {string}
   * @param resultUuid {string}
   * @param resultType {string}
   */
  trackSearchBarResult(searchTerm: string, resultType: string, resultName: string, resultUuid: string) {
    this._postEvent('text_search', 'clickSearchResult', {
      searchTerm,
      resultType,
      resultName,
      resultUuid
    });
  }

  /**
   * Accepts searchTerm and resultUuid of search result and calls the `_postEvent` method with type, action, source and payload.
   *
   * @param searchTerm {string}
   * @param searchCluster {string}
   */
  trackSearchBarShowMoreLink(searchTerm: string, searchCluster: string) {
    this._postEvent('text_search', 'clickShowMoreLink', {
      searchTerm,
      searchCluster
    });
  }

  /**
   * Accepts searchCluster and resultUuids of search page results and calls the `_postEvent` method with type, action and payload.
   *
   * @param searchCluster {string}
   * @param searchUuids {string[]}
   */
  trackSearchPageResultsImpressions(searchCluster: string, searchUuids: string[], searchTerm: string) {
    this._postEvent('text_search', 'searchPageImpressions', {
      searchCluster,
      searchUuids,
      searchTerm
    });
  }

  /**
   * Accepts searchCluster and resultUuids of search bar results and calls the `_postEvent` method with type, action and payload.
   *
   * @param searchCluster {string}
   * @param searchUuids {string[]}
   */
  trackSearchBarResultsImpressions(searchCluster: string, searchUuids: string[], searchTerm?: string) {
    this._postEvent('text_search', 'searchBarImpressions', {
      searchCluster,
      searchUuids,
      searchTerm
    });
  }

  /**
   * Accepts searchTerm and resultUuid of search result and calls the `_postEvent` method with type, action, source and payload.
   *
   * @param searchTerm {string}
   * @param searchCluster {string}
   */
  trackSearchBarPressEnter(searchTerm: string, searchCluster: string) {
    this._postEvent('text_search', 'pressEnter', {
      searchTerm,
      searchCluster
    });
  }

  /**
   * Accepts searchTerm and resultUuid of search result and calls the `_postEvent` method with type, action, source and payload.
   *
   * @param searchTerm {string}
   * @param searchCluster {string}
   */
  trackSearchBarSubmitButton(searchTerm: string, searchCluster: string) {
    this._postEvent('text_search', 'submitButton', {
      searchTerm,
      searchCluster
    });
  }


  /**
   * Accepts itemType, itemUuid and searchTerm of clicked search result and calls the `_postEvent` method with type, action, source and payload.
   *
   * @param itemType {string}
   * @param itemUuid {string}
   * @param searchTerm {string}
   */
  trackSearchPageClicks(itemType: string, itemUuid: string, searchTerm: string) {
    this._postEvent('text_search', 'searchPageClicks', {
      itemType,
      itemUuid,
      searchTerm
    });
  }

  /**
   * Accepts categoryNameand searchTerm of clicked search show more result and calls the `_postEvent` method with type, action, source and payload.
   *
   * @param categoryName {string}
   * @param searchTerm {string}
   */
  trackSearchPageShowMoreClicks(categoryName: string, searchTerm: string) {
    this._postEvent('text_search', 'searchPageShowMoreClicks', {
      categoryName,
      searchTerm
    });
  }


  /**
   * Accepts the number of products in a comparison and calls the `_postEvent` method with type, action, source and payload.
   *
   * @param comparisonUuid {string}
   * @param productCount {number}
   * @param comparisonContext {string}
   */

  /* istanbul ignore next*/
  startProductComparison(comparisonUuid: string, productCount: number, comparisonContext: string = 'default') {
    this._postEvent('product_comparison', 'start_compare', {
      comparisonUuid,
      productCount,
      comparisonContext
    });
  }

  /**
   * Accepts the action, productUuid and queryUuid and calls the `_postEvent` method with type, action, source and payload
   *
   * @param action {string}
   * @param type {string}
   * @param uuid {string}
   * @param originalSource {string}
   * @param queryUuid {string}
   */
  trackAdTile(action: string, type: string, uuid: string = null, originalSource: string = null, queryUuid?: string) {
    const payload = {
      uuid,
      originalSource,
      type
    };

    if (queryUuid) {
      payload['queryUuid'] = queryUuid;
    }

    this._postEvent(type, action, payload);
  }

  getAcquisition() {
    if (this._windowRef.nativeWindow && this._windowRef.nativeWindow.sessionStorage) {
      const storedItem: string = this._windowRef.nativeWindow.sessionStorage.getItem(this._itemKey);
      return JSON.parse(storedItem);
    }
  }

  /**
   * Accepts the companyUuid and categoryUuid that the user selected to approve
   *
   * @param companyUuid
   * @param categoryUuid
   */
  trackSACategoryConfirmation(companyUuid: string, categoryUuid: string) {
    this._postEvent('supplier_approval', 'approved', {
      companyUuid,
      categoryUuid
    });
  }

  /**
   * Accepts the leadUuid and status of the lead the user changed
   *
   * @param leadUuid
   * @param status
   */
  trackLeadStatusUpdate(leadUuid: string, status: boolean) {
    this._postEvent('lead_status', 'update', {
      leadUuid,
      status
    });
  }

  /**
   * Accepts supplierUuids of displayed suppliers and calls the `_postEvent` method with type, action and payload.
   *
   * @param supplierUuids {string[]}
   */
  trackFeaturedSuppliersImpressions(supplierUuids: string[]) {
    this._postEvent('supplier', 'featured_suppliers', {
      supplierUuids
    });
  }

  addAdditionalEventData(additionalData: Record<string, unknown>) {
    this._additionalEventData = {...this._additionalEventData, ...additionalData};
  }

  /**
   * Composes `eventData` object and post it to the events endpoint
   *
   * @private
   * @param type {string}
   * @param action {string}
   * @param payload {object}
   */
  private _postEvent(type: string, action: string, payload: Record<string, unknown>) {
    const eventData = {
      type,
      action,
      payload
    };

    const acquisition = this.getAcquisition();
    if (acquisition) {
      eventData.payload = {
        ...payload,
        acquisition
      };
    }

    if (this._additionalEventData) {
      eventData.payload = {...this._additionalEventData, ...eventData.payload};
    }

    if (!environment.featureFlags.doNotTrackUser) {
      this._httpClient.post(this._serviceUrl, eventData).subscribe(
        () => {},
        (error: HttpErrorResponse) => {
          if (this._loggerService) {
            this._loggerService.error(error.message, error.error);
          }
        }
      );
    }
  }
}
