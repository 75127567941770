import * as allClusterSearchActions from '../../../actions/search.actions';
import { ProductSearchResult } from '../../../model-result';


export const searchBarProductSearchResults = (state: ProductSearchResult[],
                                              action: allClusterSearchActions.Actions): ProductSearchResult[] => {
  switch (action.type) {
    case allClusterSearchActions.FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS_SUCCEEDED:
      return action.payload.products;
    case allClusterSearchActions.CLEAR_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS:
      return undefined;
    default:
      return state;
  }
};
