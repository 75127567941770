import {
  select,
  Store
} from '@ngrx/store';
import {
  Actions,
  Effect,
  ofType
} from '@ngrx/effects';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import {
  map,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as routerActions from '../actions/router.actions';
import { StateInterface } from '../../app.reducers';
import { environment } from '../../../environments/environment';
import * as catalogConfigReducer from '../../catalog-config/reducers';


@Injectable()
/**
 * Side effects for router actions
 */
export class RouterEffects {

  @Effect({ dispatch: false })
  /**
   * Uses router to navigate to the given location
   *
   * @type {Observable<{path: any; queryParams: any; extras: any}>}
   */
  navigate$ = this._actions.pipe(
    ofType(routerActions.GO),
    map(action => action.payload),
    tap(({ path, queryParams, extras}) => this._router.navigate(path, { queryParams, ...extras }))
  );

  @Effect({ dispatch: false })
  /**
   * Uses browser APIs to navigate back
   *
   * @type {Observable<any>}
   */
  navigateBack$ = this._actions.pipe(
    ofType(routerActions.BACK),
    tap(() => this._location.back())
  );

  @Effect({ dispatch: false })
  /**
   * Uses browser APIs to navigate forward
   *
   * @type {Observable<any>}
   */
  navigateForward$ = this._actions.pipe(
    ofType(routerActions.FORWARD),
    tap(() => this._location.forward())
  );

  @Effect({dispatch: false})
  /**
   * Uses angular title Service to set page title
   */
  setTitle$ = this._actions.pipe(
    ofType(routerActions.SET_TITLE),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(select(catalogConfigReducer.getCatalogConfigPageTitleSuffix))),
    map(([title, pageTitleSuffix]) => {
      const suffix = environment.featureFlags.catalogConfig && pageTitleSuffix ? pageTitleSuffix : 'Plan.One';
      this._title.setTitle((title || '') + (title && suffix ? ' | ' : '') + (suffix || ''));
    })
  );


  constructor(
    private _actions: Actions<routerActions.Actions>,
    private _router: Router,
    private _title: Title,
    private _store: Store<StateInterface>,
    private _location: Location) {
    this._store.dispatch(new routerActions.GoAction({ path: [window.location.pathname + window.location.search] }));
  }

}
