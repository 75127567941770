import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
import { share } from 'rxjs/operators';

import { PortalOutletName } from '../../enums/portal-outlet-name.enum';
import { PortalMessageInterface } from '../../models/portal-message.interface';


@Injectable({
  providedIn: 'root'
})
/**
 * This service allows to connect portals and portal-outlets
 * It accepts references to template refs and offers an observable of this accumulated template refs for all portals
 */
export class PortalService {
  readonly portalContent$: Observable<PortalMessageInterface>;

  private _templateRef$: BehaviorSubject<PortalMessageInterface> = new BehaviorSubject<PortalMessageInterface>(null);

  constructor() {
    this.portalContent$ = this._templateRef$.asObservable().pipe(share());
  }

  projectContent(portalMessage: PortalMessageInterface) {
    this._templateRef$.next(portalMessage);
  }

  clearContent(portalOutletName: PortalOutletName) {
    this._templateRef$.next({targetOutlet: portalOutletName, templateRef: null});
  }
}
