import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy
} from '@angular/core';
import {
  select,
  Store
} from '@ngrx/store';
import {
  Observable,
  Subject
} from 'rxjs';
import {
  take,
  takeUntil
} from 'rxjs/operators';

import * as dialogReducer from '../reducer';
import { Dialog } from '../model/dialog';
import { DialogAction } from '../model/dialog-action';
import { CloseDialogAction } from '../actions/dialog.actions';


/**
 * Container component for a dialog
 */
@Component({
  selector: 'p1-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogContainerComponent implements OnDestroy {

  currentDialog$: Observable<Dialog>;
  loadingButtonLabel: string;

  private _unsubscribe: Subject<void> = new Subject<void>();


  constructor(private _store: Store<dialogReducer.State>) {
    this.currentDialog$ = this._store.pipe(
      select(dialogReducer.getCurrentDialog)
    );

    this.currentDialog$.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe(() => {
      this.loadingButtonLabel = undefined;
    });
  }


  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  /**
   * Dispatches the action configured in the given DialogAction
   * Sets loading state or dispatches closing action, depending on DialogAction config
   *
   * @param dialogAction
   */
  dispatchAction(dialogAction: DialogAction) {
    if (dialogAction.action) {
      this._store.dispatch(dialogAction.action);
    }

    if (dialogAction.showLoaderOnDispatch) {
      this.loadingButtonLabel = dialogAction.label;
    } else {
      this._store.dispatch(new CloseDialogAction());
    }
  }

  /**
   * Dispatches the closeAction (if configured)
   * Closes the dialog.
   */
  closeDialog() {
    this.currentDialog$.pipe(
      take(1),
      takeUntil(this._unsubscribe)
    ).subscribe(currentDialog => {
      if (currentDialog.closeAction) {
        this._store.dispatch(currentDialog.closeAction);
      }

      this._store.dispatch(new CloseDialogAction());
    });
  }
}
