import * as allClusterSearchActions from '../../../actions/search.actions';
import { SupplierSearchResult } from '../../../model-result';


export const searchBarSupplierSearchResults = (state: SupplierSearchResult[],
                                               action: allClusterSearchActions.Actions): SupplierSearchResult[] => {
  switch (action.type) {
    case allClusterSearchActions.FETCH_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS_SUCCEEDED:
      return action.payload.suppliers;
    case allClusterSearchActions.CLEAR_SEARCH_BAR_ALL_CLUSTER_SEARCH_RESULTS:
      return undefined;
    default:
      return state;
  }
};
