import { normalizeFilterRequestValue } from '../filter-request-value';
import { RequestPayload } from './request-payload';
import { FilterHelperService } from '../../../filter/helper-service/filter-helper.service';


export const normalizeRequestPayload = (input: RequestPayload) => {
  const requestPayload: RequestPayload = {
    basic_filters: [],
    property_filters: [],
    settings: [],
    ordering: []
  };

  if (Array.isArray(input.basic_filters) && input.basic_filters.length > 0) {
    requestPayload.basic_filters = input.basic_filters.map(normalizeFilterRequestValue);

    if (Array.isArray(input.property_filters) && input.property_filters.length > 0) {
      requestPayload.property_filters = input.property_filters.map(normalizeFilterRequestValue);
    }
  }

  if (Array.isArray(input.settings) && input.settings.length > 0) {
    requestPayload.settings = input.settings.map(normalizeFilterRequestValue);
  }

  if (input.context) {
    requestPayload.context = input.context;
  }

  if (Array.isArray(input.ordering)) {
    requestPayload.ordering = input.ordering;
  }

  return requestPayload;
};

export const generateRequestPayloadFromQueryString = (queryString: string) => {
  if (!queryString) {
    return {
      basic_filters: [],
      property_filters: [],
      settings: [],
      ordering: []
    };
  }
  const queryStringParts = queryString.split('|');
  const basicFiltersString = queryStringParts[0];
  const propertyFiltersString = queryStringParts[1];
  const settingFiltersString = queryStringParts[2];
  const orderingValueString = queryStringParts[3];
  const requestPayload: RequestPayload = {};

  if (basicFiltersString) {
    requestPayload.basic_filters = FilterHelperService.getFilterRequestValuesFromValueObject(
      FilterHelperService.getValueObjectFromQueryString(basicFiltersString));
  }

  if (propertyFiltersString) {
    requestPayload.property_filters = FilterHelperService.getFilterRequestValuesFromValueObject(
      FilterHelperService.getValueObjectFromQueryString(propertyFiltersString));
  }

  if (settingFiltersString) {
    requestPayload.settings = FilterHelperService.getFilterRequestValuesFromValueObject(
      FilterHelperService.getValueObjectFromQueryString(settingFiltersString));
  }

  if (orderingValueString) {
    requestPayload.ordering = orderingValueString.split(',');
  }

  return requestPayload;
};

export const getQueryStringFromRequestPayload = (requestPayload: RequestPayload) => {

  let queryString = '';

  if (Array.isArray(requestPayload.basic_filters) && requestPayload.basic_filters.length > 0) {
    queryString += FilterHelperService.getQueryStringFromValueObject(FilterHelperService.getValueObjectFromRequestValues(requestPayload.basic_filters));
  }

  queryString += '|';

  if (Array.isArray(requestPayload.property_filters) && requestPayload.property_filters.length > 0) {
    queryString += FilterHelperService.getQueryStringFromValueObject(FilterHelperService.getValueObjectFromRequestValues(requestPayload.property_filters));
  }

  queryString += '|';

  if (Array.isArray(requestPayload.settings) && requestPayload.settings.length > 0) {
    queryString += FilterHelperService.getQueryStringFromValueObject(FilterHelperService.getValueObjectFromRequestValues(requestPayload.settings));
  }

  queryString += '|';

  if (Array.isArray(requestPayload.ordering) && requestPayload.ordering.length > 0) {
    queryString += requestPayload.ordering.join(',');
  }

  return queryString;
};
