<div *ngIf="(availableProductQaStates$ | async) as availableProductQaStates" class="qa-state-menu">
  <div>
    <div class="qa-state-menu__cta"
         (click)="toggleMenu()"
         [ngClass]="{'qa-state-menu__cta--open': isOpen}">

        <i class="qa-state-menu__avatar icon">
          <p1-lib-icons-icon-wrapper name="iconTechnicalProperties"></p1-lib-icons-icon-wrapper>
        </i>
        <span class="qa-state-menu__text">
          {{selectedProductQaStateLabel$ | async}}
        </span>
    </div>
  </div>
  <div class="qa-state-menu__dropdown"
       [@qastatemenuContent]="isOpen ? 'is-open' : ''">
    <ul class="qa-state-menu__list list--without-dots">
      <li *ngFor="let state of availableProductQaStates" class="qa-state-menu__item">
        <a *ngIf="(selectedProductQaState$ | async) !== state.key; else activeState" class="qa-state-menu__link" (click)="selectProductQaState(state.key)">
          {{state.label}}
        </a>
        <ng-template #activeState>
          <span class="qa-state-menu__link qa-state-menu__link--active">{{state.label}}</span>
        </ng-template>
      </li>
    </ul>
  </div>
</div>

