import { TreeCategoryBackendInterface } from './tree-category.backend-interface';
import { TreeCategory } from './tree-category';


export const generateTreeCategoryFromBackendInput = (input: TreeCategoryBackendInterface): TreeCategory => {
  const result: TreeCategory = {
    uuid: input.uuid,
    name: input.name,
    description: input.description,
    hideInSearch: input.hide_in_search,
    pathedSlug: input.pathed_slugs ? input.pathed_slugs[0] : undefined,
    advertised: input.advertised_category
  };

  if (input.parent_category) {
    result.parentUuid = input.parent_category;
  }

  if (input.image?.id) {
    result.image = {
      uuid: input.image.id,
      name: input.image.name
    };
  }

  return result;
};
