import * as productActions from '../../../actions/product.actions';
import { PaginationInfoInterface } from '../../../../shared/common/interfaces/pagination-info.interface';


/**
 * paginationInfo reducer
 *
 * @param state
 * @param action
 * @returns
 */
export const paginationInfo = (state: PaginationInfoInterface, action: productActions.Actions): PaginationInfoInterface => {
  switch (action.type) {
    case productActions.FETCH_MULTI_SUCCEEDED:
      if (action.payload) {
        return action.payload.pagination;
      }
      return undefined;
    default:
      return state;
  }
};
