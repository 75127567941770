import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as productComparisonReducers from './reducer/product-comparison.reducer';
import { ProductComparisonEffects } from './effects/product-comparison.effects';
import { ProductComparisonService } from './service/product-comparison.service';


@NgModule({
  imports: [
    StoreModule.forFeature(productComparisonReducers.productComparisonFeatureKey, productComparisonReducers.reducers),
    EffectsModule.forFeature([ProductComparisonEffects])
  ],
  providers: [
    ProductComparisonService
  ]
})
export class ProductComparisonStoreModule {}

