import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers } from './reducer';
import { NewsEffects } from './effects/news.effects';
import { NewsService } from './service/news.service';


@NgModule({
  imports: [
    StoreModule.forFeature('news', reducers),
    EffectsModule.forFeature([NewsEffects])
  ],
  providers: [
    NewsService
  ]
})
export class NewsStoreModule {}
