import * as productActions from '../../../actions/product.actions';
import { Filter } from '../../../../shared/filter/model';
import * as filterHelpers from '../../../../shared/filter/model/filter.helpers';


/**
 * TempPropertyFilterValues reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 * @returns the new state
 */
export const tempPropertyFilterValues = (state: {[filterId: string]: FilterValue}, action: productActions.Actions): {[filterId: string]: FilterValue} => {
  switch (action.type) {
    case productActions.UPDATE_TEMP_PROPERTY_FILTER_VALUES:
      if (action.payload) {
        return Object.keys(action.payload)
          .filter(filterId => action.payload[filterId] != null)
          .reduce((allValues, filterId) => {
            allValues[filterId] = action.payload[filterId];
            return allValues;
          }, {});
      }

      return {};
    case productActions.FETCH_MULTI_SUCCEEDED:
      const { property_filters } = action.payload.queryConfig;

      if (property_filters && property_filters.length > 0) {
        return property_filters.reduce((allFilters, filter) => {
          if (filter.id) {
            allFilters[filter.id] = filter.value;
          }
          return allFilters;
        }, {});
      }

      return undefined;
    case productActions.REMOVE_ACTIVE_PROPERTY_FILTERS:
      if (action.payload && action.payload.length) {
        const newValues = {...state};
        action.payload.forEach(filterId => {
          delete newValues[filterId];
        });
        return newValues;
      }
      return state;
    case productActions.UPDATE_ACTIVE_PROPERTY_FILTERS:
      const selectedPropertyFilters: Filter[] = action.payload;
      const resultFilters = {};
      const activeFilters = state;

      selectedPropertyFilters.forEach(filter => {
        if (activeFilters && activeFilters.hasOwnProperty(filter.id)) {
          resultFilters[filter.id] = activeFilters[filter.id];
        } else {
          const defaultValue = filterHelpers.getDefaultValue(filter);
          if (defaultValue != null) {
            resultFilters[filter.id] = filterHelpers.getDefaultValue(filter);
          }
        }
      });

      return resultFilters;
    case productActions.REMOVE_ALL_ACTIVE_PROPERTY_FILTERS:
      return undefined;
    default:
      return state;
  }
};
