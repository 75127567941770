import { SupplierSearchResultBackendInterface } from './supplier-search-result.backend-interface';
import { SupplierSearchResult } from './supplier-search-result';
import { generateSearchResultFromBackendInput } from '../search-result';
import { generateSupplierFromBackendInterface } from '../../../supplier/model/supplier.generators';


export const generateSupplierSearchResultFromBackendInput = (input: SupplierSearchResultBackendInterface[], urlTarget?: string): SupplierSearchResult[] => {
  const supplierSearchResult: SupplierSearchResult[] =
          input.map(supplier => {
            const generatedSupplier = generateSupplierFromBackendInterface(supplier);
            return {
              ...generateSearchResultFromBackendInput(supplier,
                urlTarget + '/' + generatedSupplier.urlPathSegment),
              ...generatedSupplier };
          });
  return supplierSearchResult;
};
