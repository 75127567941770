import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import { StateInterface } from '../../app.reducers';
import { items } from './items/items.reducer';
import { treeUuid } from './tree-uuid/tree-uuid.reducer';
import { fetchCategoryTreeInProgress } from './fetch-category-tree-in-progress/fetch-category-tree-in-progress.reducer';
import { CategoryTreeNodeMapping } from '../model/category-tree-node-mapping';
import { TreeCategoryWithSubCategories } from '../model/tree-category-with-sub-categories';
import { getSelectedTreeCategoryUuid } from '../../product/reducer';
import { TreeCategory } from '@p1/libs/catalog-service';


/**
 * Category Tree State Interface
 */
export interface CategoryTreeState {
  items: CategoryTreeNodeMapping;
  treeUuid: string;
  fetchCategoryTreeInProgress: boolean;
}

export interface State extends StateInterface {
  categoryTree: CategoryTreeState;
}

/**
 * Category Tree Reducer
 */
export const reducers = {
  items,
  treeUuid,
  fetchCategoryTreeInProgress
};

/**
 * Returns the category tree state
 */
export const getCategoryTreeState = createFeatureSelector<CategoryTreeState>('categoryTree');

/**
 * returns all category tree items unsorted and unfiltered
 */
export const getCategoryTreeItems = createSelector(getCategoryTreeState, (state: CategoryTreeState) => {
  if (state) {
    return state.items;
  }
});

/**
 * returns status whether fetch category tree is in progress or not
 */
export const getFetchCategoryTreeInProgress = createSelector(getCategoryTreeState, (state: CategoryTreeState) => state.fetchCategoryTreeInProgress);

/**
 * Returns the category tree uuid
 */
export const getCategoryTreeUuid = createSelector(getCategoryTreeState, (state: CategoryTreeState) => {
  if (state) {
    return state.treeUuid;
  }
});

/**
 * returns all first level category tree items
 */
export const getCategoryTreeFirstLevel = createSelector(getCategoryTreeState, (state: CategoryTreeState) => {
  if (state) {
    return Object.keys(state.items)
      .map(key => state.items[key])
      .filter(category => category.parentUuid == null)
      .sort(sortCategoriesAdvertised);
  }
});


/**
 * returns all first level category tree items with subcategories
 */
export const getCategoryTreeFirstLevelwithSubcategories = createSelector(getCategoryTreeFirstLevel, getCategoryTreeItems, (_firstLevel, _items) => {
  if (_firstLevel) {
    return _firstLevel.map(item => addChildNodesToNode(_items, item));
  }
});

export const getCategoryTreeLeaves = createSelector(getCategoryTreeItems, _items => {
  if (_items) {
    const allItems = Object.keys(_items).map(key => _items[key]);
    return allItems.filter(category => allItems.find(child => child.parentUuid === category.uuid) == null);
  } else {
    return null;
  }
});

/**
 * returns current category tree item
 */
export const getCurrentCategoryTreeNode = createSelector(getSelectedTreeCategoryUuid, getCategoryTreeItems, (_uuid, _items) => {
  if (_items && _uuid) {
    return _items[_uuid];
  }
});

/**
 * returns current category tree item including subcategories
 */
export const getCurrentCategoryTreeNodeWithTwoLevelSubCategories = createSelector(getCurrentCategoryTreeNode,
  getCategoryTreeItems,
  (_node, _items): TreeCategoryWithSubCategories => {
    if (_node && _items) {
      const categoryTreeDetail: TreeCategoryWithSubCategories = {
        category: _items[_node.uuid]
      };
      const subCategories = findChildNodes(_items, _node)
        .map(treeNode => addChildNodesToNode(_items, treeNode, 1))
        .sort(sortCategoriesAdvertised);

      if (subCategories.length > 0) {
        categoryTreeDetail.subCategories = subCategories;
      }

      return categoryTreeDetail;
    }
    return undefined;
  });

export const getCurrentCategoryIsLeave = createSelector(getCurrentCategoryTreeNodeWithTwoLevelSubCategories,
  (_nodeWithSubcategories) => !!(_nodeWithSubcategories && !_nodeWithSubcategories.subCategories));

/**
 * returns strict path from root category to active category (breadcrumb)
 */
export const getCurrentCategoryTreeRootLine = createSelector(getSelectedTreeCategoryUuid, getCategoryTreeItems, (_currentUuid, _items) => {
  if (_currentUuid && _items && _items.hasOwnProperty(_currentUuid)) {
    return getAllParentsOfCategory(_currentUuid, _items);
  }
  return null;
});

/**
 * returns the whole category tree (items with subcategories)
 */
export const getCategoryTreeAllLevels = createSelector(getCategoryTreeItems, getCategoryTreeFirstLevel, (_items, _firstLevelItems) => {
  if (_items && _firstLevelItems) {
    return _firstLevelItems
      .map(item => addChildNodesToNode(_items, item))
      .filter(item => !item.advertisedCategory);
  }
  return null;
});

export const getAllParentsOfCategory = (uuid: string, categoryTreeNodes: CategoryTreeNodeMapping): TreeCategory[] => {
  if (uuid && categoryTreeNodes && categoryTreeNodes.hasOwnProperty(uuid)) {
    return getAllParentsOfCategory(categoryTreeNodes[uuid].parentUuid, categoryTreeNodes).concat([categoryTreeNodes[uuid]]);
  }
  return [];
};

const sortCategories = (left: TreeCategory, right: TreeCategory) => left.name.localeCompare(right.name, undefined, { numeric: true });

const sortCategoriesAdvertised = (left: TreeCategory, right: TreeCategory) =>
  (left.advertised === right.advertised) ? sortCategories(left, right) : left.advertised ? 1 : -1;

const addChildNodesToNode = (allItems: CategoryTreeNodeMapping, currentItem: TreeCategory, levels?: number) => {
  if (allItems && currentItem && (levels === undefined || levels > 0)) {
    const remainingLevels = levels === undefined ? undefined : levels - 1;
    const childNodes = findChildNodes(allItems, currentItem)
      .map(item => addChildNodesToNode(allItems, item, remainingLevels))
      .sort(sortCategoriesAdvertised);

    return childNodes.length > 0 ? {
      ...currentItem,
      childNodes
    } : currentItem;
  }
  return currentItem;
};

const findChildNodes = (allItems: CategoryTreeNodeMapping, currentItem: TreeCategory) => {
  if (allItems && currentItem) {
    return Object.keys(allItems).map(key => allItems[key]).filter(item => item.parentUuid === currentItem.uuid).sort(sortCategories);
  }
  return [];
};
