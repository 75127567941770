import { SupplierSchemaCategoriesWithProducts } from './supplier-schemacategories-with-products';
import { SupplierSchemaCategoriesWithProductsBackendInterface } from './supplier-schemacategories-with-products.backend-interface';
import { generateCategoryTreeNodeWithProductsFromBackendInterface } from '../../category-tree/model/category-tree-node-with-products.generator';


export const generateSupplierSchemaCategoriesWithProductsFromBackendInput =
               (input: SupplierSchemaCategoriesWithProductsBackendInterface): SupplierSchemaCategoriesWithProducts => {
                 const supplierSchemaCategoriesWithProducts: SupplierSchemaCategoriesWithProducts = {};

                 if (Array.isArray(input.Categories)) {
                   supplierSchemaCategoriesWithProducts.categories =
                     input.Categories.map(generateCategoryTreeNodeWithProductsFromBackendInterface).filter(item => !!item);
                 }

                 if (input.ProductsPerCategory) {
                   supplierSchemaCategoriesWithProducts.productsPerCategory = input.ProductsPerCategory;
                 }

                 if (input.TotalCounts) {
                   supplierSchemaCategoriesWithProducts.totalCounts = input.TotalCounts;
                 }

                 return supplierSchemaCategoriesWithProducts;
               };
