import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'p1-lib-schueco-stahl-contact',
  templateUrl: './schueco-stahl-contact-dialog.component.html',
  styleUrls: ['./schueco-stahl-contact-dialog.component.scss']
})
export class SchuecoStahlContactDialogComponent implements OnInit {

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
