<li *ngFor="let category of categoryTree; trackBy:identifyCategory;"
    class="cm__item"
    [ngClass]="{
      'cm__item--has-children': (category.childNodes),
      'cm__item--active': (selectedCategory == category),
      'cm__item--hidden': (selectedCategory != category && selectedCategory != null),
      'cm__item--selected': selecedCategoryUuids?.includes(category.uuid)
    }">
  <p1-category-tree-link
    [category]="category"
    [activeChild]="childIsActive"
    [breakpoint]="breakpoint"
    (categorySelected)="setActiveCategory($event)">
  </p1-category-tree-link>

  <ul *ngIf="category.childNodes"
      class="cm__list cm__list--sub"
      p1-category-tree-sublist
      (subCategorySelected)="selectSubCategory($event)"
      [selecedCategoryUuids]="selecedCategoryUuids"
      [breakpoint]="breakpoint"
      [categoryTree]="category.childNodes">
    <li class="cm__item"
        [ngClass]="{
          'cm__item--hidden': (selectedCategory != category && selectedCategory != null)
        }">
      <p1-category-tree-link
        [category]="category"
        [activeChild]="childIsActive"
        [breakpoint]="breakpoint"
        [showAll]="true">
      </p1-category-tree-link>
    </li>
  </ul>
</li>
<ng-content></ng-content>
