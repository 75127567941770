import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { IconsRegistry } from '@p1/libs/icons/src/lib/registry/icons.registry';
import { iconArrowRight } from '@p1/libs/icons/src/lib/registry/icons';

import { SharedModule } from '../shared/shared.module';
import { CategoryTreeStoreModule } from './category-tree.store.module';
import { CategoryTreeListComponent } from './category-tree-list/category-tree-list.component';
import { CategoryTreeBreadcrumbComponent } from './category-tree-breadcrumb/category-tree-breadcrumb.component';
import { CategoryTreeMenuComponent } from './category-tree-menu/category-tree-menu.component';
import { CategoryTreeSubListComponent } from './category-tree-menu/sublist/category-tree-sublist.component';
import { CategoryTreeLinkComponent } from './category-tree-menu/link/category-tree-link.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CategoryTreeStoreModule
  ],
  declarations: [
    CategoryTreeListComponent,
    CategoryTreeBreadcrumbComponent,
    CategoryTreeMenuComponent,
    CategoryTreeSubListComponent,
    CategoryTreeLinkComponent
  ],
  exports: [
    CategoryTreeListComponent,
    CategoryTreeBreadcrumbComponent,
    CategoryTreeMenuComponent,
    CategoryTreeSubListComponent,
    CategoryTreeLinkComponent
  ]
})
export class CategoryTreeSharedModule {
  constructor(private _iconsRegistry: IconsRegistry) {
    this._iconsRegistry.registerIcons([
      iconArrowRight
    ]);
  }
}
