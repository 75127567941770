<footer class="footer"
        [ngClass]="{'footer--has-roc': hasRightOffCanvas}">
  <div *ngIf="featureFlags.planoneSitemapFooter"
       class="footer__section footer__section--top">
    <div class="container">
      <div class="row">
        <div class="gr-12">
          <div class="row">
            <div class="gr-12 gr-4@m">
              <div class="row">
                <div class="gr-12 gr-3@m">
                  <p1-lib-icons-icon-wrapper name="iconPlanOneN"></p1-lib-icons-icon-wrapper>
                </div>
                <div class="gr-9">
                  <p i18n="Full address Plan.One // page: all @@footer.component_full-address">
                    Plan.One GmbH<br>
                    Kammerratsheide 36<br>
                    33609 Bielefeld<br>
                    <a href="mailto:info@plan.one"><b>info@plan.one</b></a>
                  </p>
                  <ul class="list--without-dots social-media-list">
                    <li class="social-media-list__item">
                      <a href="https://www.xing.com/companies/plan.one"
                        class="social-media-list__link social-media-list__link--xing">
                        <p1-lib-icons-icon-wrapper name="iconXing"></p1-lib-icons-icon-wrapper>
                      </a>
                    </li>
                    <li class="social-media-list__item">
                      <a href="https://www.linkedin.com/company/plan.one"
                         class="social-media-list__link social-media-list__link--linkedin">
                        <p1-lib-icons-icon-wrapper name="iconLinkedIn"></p1-lib-icons-icon-wrapper>
                      </a>
                    </li>
                    <li class="social-media-list__item">
                      <a href="https://www.facebook.com/planonegmbh"
                         class="social-media-list__link social-media-list__link--facebook">
                        <p1-lib-icons-icon-wrapper name="iconFacebook"></p1-lib-icons-icon-wrapper>
                      </a>
                    </li>
                    <li class="social-media-list__item">
                      <a href="https://www.youtube.com/channel/UCHonur3srcv12st5mif4tiA"
                         class="social-media-list__link social-media-list__link--youtube">
                        <p1-lib-icons-icon-wrapper name="iconYoutube"></p1-lib-icons-icon-wrapper>
                      </a>
                    </li>
                    <li class="social-media-list__item">
                      <a href="https://www.instagram.com/planonegmbh"
                         class="social-media-list__link social-media-list__link--instagram">
                        <p1-lib-icons-icon-wrapper name="iconInstagram"></p1-lib-icons-icon-wrapper>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="gr-12 gr-8@m">
              <div class="row">
                <div class="gr-12 push-3@m gr-3@m">
                  <p class="spacing-down--0"
                     i18n="Meta-data sub headline products // page: all @@footer.component_subheader-products">
                     <strong>Products</strong>
                  </p>
                  <ul class="list--without-dots footer-meta-links-list">
                    <li>
                      <a [routerLink]="['/']"
                           i18n="Link text categories // page: all @@footer.component_link-text-categories">
                        Categories
                      </a>
                    </li>
                    <li>
                      <a [routerLink]="['/suppliers']"
                         i18n="Link text suppliers // page: all @@footer.component_link-text-suppliers">
                      Suppliers
                      </a>
                    </li>
                    <li *ngIf="featureFlags.referenceProjects">
                      <a [href]="referenceProjectsLink"
                         i18n="Link text Reference projects // page: all @@footer.component_link-text-reference-projects"
                         target="_blank"
                         data-gtm-event-category="ReferenceProjects"
                         data-gtm-event-action="Click reference projects"
                         data-gtm-event-label="Footer - Link">
                        Reference projects
                      </a>
                    </li>
                  </ul>
                  <p>
                    <strong>
                      <a [href]="wordpressPages.career"
                         i18n="Link text career // page: all @@footer.component_link-text-career">
                        Career</a>
                    </strong>
                  </p>
                </div>
                <div class="gr-12 push-3@m gr-3@m">
                  <p class="spacing-down--0"
                     i18n="Meta-data subheadline services // page: all @@footer.component_subheader-services">
                    <strong>Services</strong>
                  </p>
                  <ul class="list--without-dots footer-meta-links-list">
                    <li>
                      <a [href]="wordpressPages.serviceSupplier"
                         i18n="Link text for suppliers // page: all @@footer.component_link-text-for-suppliers">
                      For Suppliers
                      </a>
                    </li>
                    <li>
                      <a [href]="wordpressPages.serviceArchitects"
                         i18n="Link text for architects // page: all @@footer.component_link-text-for-architects">
                      For Architects
                      </a>
                    </li>
                  </ul>
                  </div>
                <div class="gr-12 push-3@m gr-3@m">
                  <p class="spacing-down--0"
                     i18n="Meta-data subheadline media // page: all @@footer.component_subheader-media">
                    <strong>Media</strong>
                  </p>
                  <ul class="list--without-dots footer-meta-links-list">
                    <li>
                      <a [href]="wordpressPages.mediaCenter"
                         i18n="Link text media center // page: all @@footer.component_link-text-media-center">
                      Media Center
                      </a>
                    </li>
                    <li>
                      <a [href]="wordpressPages.blog"
                         i18n="Link text blog // page: all @@footer.component_link-text-blog">
                      Blog
                      </a>
                    </li>
                    <li *ngIf="currentLanguage === 'de-DE'">
                      <a [href]="wordpressPages.ebooks"
                         i18n="Link text eBooks // page: all @@footer.component_link-text-eBooks">
                      eBooks
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer__section footer__section--bottom">
    <div class="container">
      <div class="row">
        <div class="gr-12 footer-line"
             [ngClass]="{'footer-line--has-border': !featureFlags.planoneSitemapFooter}">
          <div *ngIf="featureFlags.languageSwitch"
               class="language-switch hide@print">
            <i class="language-switch__icon icon">
              <p1-lib-icons-icon-wrapper name="iconGlobe"></p1-lib-icons-icon-wrapper>
            </i>
            <ul class="language-switch__list">
              <ng-container *ngIf="featureFlags.catalogConfig">
                <li *ngFor="let catalogHost of catalogHosts$ | async"
                    class="language-switch__item">
                  <a class="language-switch__link"
                     href="//{{ catalogHost.domain + path }}"
                     [ngClass]="{'language-switch__link--active': currentLanguage === catalogHost.locale}"
                     data-gtm-event-category="Navigation"
                     [attr.data-gtm-event-action]="'Switch Language - ' + (catalogHost.locale | slice:0:2 | uppercase)"
                     data-gtm-event-label="Footer - LanguageSwitch">
                    {{ catalogHost.locale | slice:0:2 | uppercase }}
                  </a>
                </li>
              </ng-container>
              <ng-container *ngIf="!featureFlags.catalogConfig">
                <li class="language-switch__item">
                  <a class="language-switch__link"
                     href="//{{ host + path }}"
                     [ngClass]="{'language-switch__link--active': currentLanguage === 'de-DE'}"
                     data-gtm-event-category="Navigation"
                     data-gtm-event-action="Switch Language - DE"
                     data-gtm-event-label="Footer - LanguageSwitch">
                    DE
                  </a>
                </li>
                <li class="language-switch__item">
                  <a class="language-switch__link"
                     href="//en.{{ host + path }}"
                     [ngClass]="{'language-switch__link--active': currentLanguage === 'en-GB'}"
                     data-gtm-event-category="Navigation"
                     data-gtm-event-action="Switch Language - EN"
                     data-gtm-event-label="Footer - LanguageSwitch">
                    EN
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="footer-navigation hide@print"
               [ngClass]="{'footer-navigation--align-left': !featureFlags.languageSwitch}">
            <ul class="footer-navigation__list list--without-dots">
              <li class="footer-navigation__item">
                <a class="footer-navigation__link"
                   [href]="wordpressPages.imprint"
                   [target]="featureFlags.planoneLegalLinks ? '_self' : '_blank'"
                   i18n="imprint link // page: all @@footer.component_imprint">
                  Imprint
                </a>
              </li>
              <li class="footer-navigation__item">
                <a class="footer-navigation__link"
                   [href]="wordpressPages.termsAndConditions"
                   [target]="featureFlags.planoneLegalLinks ? '_self' : '_blank'"
                   i18n="terms and conditions link // page: all @@footer.component_terms-and-conditions">
                  Terms and Conditions
                </a>
              </li>
              <li class="footer-navigation__item">
                <a class="footer-navigation__link"
                   [href]="wordpressPages.termsOfService"
                   [target]="featureFlags.planoneLegalLinks ? '_self' : '_blank'"
                   i18n="terms of service link // page: all @@footer.component_terms">
                  Terms of Service
                </a>
              </li>
              <li class="footer-navigation__item">
                <a class="footer-navigation__link"
                   [href]="wordpressPages.privacy"
                   [target]="featureFlags.planoneLegalLinks ? '_self' : '_blank'"
                   i18n="privacy policy link // page: all @@footer.component_privacy">
                  Privacy Policy
                </a>
              </li>
              <li class="footer-navigation__item">
                <a class="footer-navigation__link"
                   [href]="wordpressPages.contact"
                   i18n="contact link // page: all @@footer.component_contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <small class="copyright"
                 i18n="copyright // page: all @@footer.component_copyright">
            Copyright © {{ currentYear }} Plan.One
          </small>
        </div>
      </div>
    </div>
  </div>
</footer>
