import {
  ErrorHandler,
  Inject,
  Injectable
} from '@angular/core';

import {
  LoggerServiceInterface,
  LOGGING_SERVICE
} from '@p1/libs/logging';

import { ErrorModalCreatorService } from './error-modal-creator.service';



@Injectable()
/**
 * Service for custom error handling (logging, displaying error messages etc.)
 */
export class CustomErrorHandler extends ErrorHandler {


  constructor(
    private _tempErrorModalCreatorService: ErrorModalCreatorService,
    @Inject(LOGGING_SERVICE) private _loggerService: LoggerServiceInterface
  ) {
    super();
  }


  /**
   * Expects an uncaught error anywhere in the application
   * Reports the error to sentry and shows the error message
   *
   * @param error - the catched error
   */
  handleError(error: any) {
    this._loggerService.error(null, error.originalError || error);
    this._tempErrorModalCreatorService.showError(error);
  }
}
