import { combineReducers } from '@ngrx/store';

import { Notification } from '@p1/libs/ui-elements';

import * as notificationActions from '../../actions/notification.actions';


export interface State {
  notifications: Notification[];
}


export const notifications = (state: Notification[] = [], action: notificationActions.Actions): Notification[] => {
  switch (action.type) {
    case notificationActions.ADD_NOTIFICATION:
      return [...state, action.payload];
    case notificationActions.REMOVE_NOTIFICATION:
      const i = state.findIndex(n => n.uuid === action.payload);
      const newState = [...state];
      newState.splice(i, 1);
      return newState;
    default:
      return state;
  }
};

export const reducer = (state: State, action: any) => combineReducers({
  notifications
})(state, action);

export const getNotifications = (state: State) => state.notifications;
