import {
  Injectable,
  NgZone
} from '@angular/core';
import jwtDecode from 'jwt-decode';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable
} from 'rxjs';
import { map } from 'rxjs/operators';

import { WindowRef } from '@p1/libs/browser-api-wrapper';
import { enterZone } from '@p1/libs/keycloak-adapter';
import { AnalyticsEventsService } from '@p1/libs/analyticsevents';

import { environment } from '../../../environments/environment';
import { QaState } from '../qa-state';
import { GoogleTagManagerService } from '../../core/google-tag-manager/service/google-tag-manager.service';


@Injectable({providedIn: 'root'})
export class QaStateHandlingService {
  private static _selectedQaStateKeyName = 'p1-selected-product-qa-state';

  availableQaStates$: Observable<QaState[]>;
  selectedQaState$: Observable<QaState>;

  private _availableQaStates$ = new BehaviorSubject<QaState[]>([]);
  private _selectedQaState$ = new BehaviorSubject<QaState>(null);

  constructor(
    private _window: WindowRef,
    private _analyticsEventsService: AnalyticsEventsService,
    private _tagManagerService: GoogleTagManagerService,
    private _ngZone: NgZone
  ) {
    this.availableQaStates$ = this._availableQaStates$.asObservable().pipe(enterZone(this._ngZone));
    this.selectedQaState$ = combineLatest([
      this.availableQaStates$,
      this._selectedQaState$.asObservable()
    ]).pipe(
      enterZone(this._ngZone),
      map(([possibleValues, selectedValue]) => {
        if (possibleValues.includes(selectedValue)) {
          return selectedValue;
        } else {
          return null;
        }
      })
    );

    this.selectedQaState$.subscribe(qaState => {
      this._pushQaStateToEventTracking(qaState);
    });

    if (environment.featureFlags.login !== 'none' && this._window.nativeWindow['keycloakAuthorizationInit']) {

      from(this._window.nativeWindow['keycloakAuthorizationInit'] as Promise<string>).subscribe((rpt) => {
        this._availableQaStates$.next(this._getPossibleQaStatesFromToken(jwtDecode(rpt)));
      });

      this._selectedQaState$.next(this._readSelectedQaState());
    }

  }

  selectQaState(selectedQaState: string) {
    if (this.isQaState(selectedQaState)) {
      try {
        this._window.nativeWindow.localStorage.setItem(QaStateHandlingService._selectedQaStateKeyName, selectedQaState);
      } finally {
        this._selectedQaState$.next(selectedQaState);
      }
    } else if (selectedQaState == null) {
      try {
        this._window.nativeWindow.localStorage.removeItem(QaStateHandlingService._selectedQaStateKeyName);
      } finally {
        this._selectedQaState$.next(null);
      }
    }
  }

  isQaState(state: string): state is QaState {
    return Object.values(QaState).includes(state as QaState);
  }

  private _pushQaStateToEventTracking(qaState: QaState) {
    if(qaState) {
      this._analyticsEventsService.addAdditionalEventData({qaState});
      this._tagManagerService.pushVariable({qaState});
    } else {
      this._analyticsEventsService.addAdditionalEventData({qaState: 'live'});
      this._tagManagerService.pushVariable({qaState: 'live'});
    }
  }

  private _readSelectedQaState() {
    try {
      const stateFromLocalStorage = this._window.nativeWindow.localStorage.getItem(QaStateHandlingService._selectedQaStateKeyName);
      if (this.isQaState(stateFromLocalStorage)) {
        return stateFromLocalStorage;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  private _getPossibleQaStatesFromToken(parsedToken: {realm_access: {roles: string[]}; authorization: {permissions: {rsname: string}[]}}) {
    const possibleQaStates = [];
    if (parsedToken?.realm_access?.roles?.includes('productsIntegratedRead')) {
      possibleQaStates.push(QaState.integrated);
    }

    if (parsedToken?.realm_access?.roles?.includes('productsQAInternalRead')) {
      possibleQaStates.push(QaState.internalQa);
    }

    if (parsedToken?.realm_access?.roles?.includes('productsQAExternalRead')
        || parsedToken?.authorization?.permissions?.find((value) => value.rsname.startsWith('QA-External-Products-'))
    ) {
      possibleQaStates.push(QaState.externalQa);
    }

    return possibleQaStates;
  }
}
