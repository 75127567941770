import { generateFileContainerFromBackendInput } from '@p1/libs/file';

import { SupplierDownloadBackendInterface } from './supplier-download.backend-interface';
import { SupplierDownload } from './supplier-download';

/**
 * Generates a SupplierDownload object based on api input
 *
 * @param input
 * @returns
 */
export const generateSupplierDownloadFromBackendInput = (input: SupplierDownloadBackendInterface): SupplierDownload => {
  try {
    const supplierDownloads: SupplierDownload = {
      name: input.Name,
      url: input.Url
    };

    if (input.Logo) {
      supplierDownloads.logo = generateFileContainerFromBackendInput(input.Logo);
    }

    if (input.FileSize) {
      supplierDownloads.fileSize = input.FileSize;
    }

    if (input.Description) {
      supplierDownloads.description = input.Description;
    }

    return supplierDownloads;
  } catch (e) {
    return null;
  }
};
