import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DialogContainerComponent } from './container/dialog-container.component';
import { reducers } from './reducer';
import { SharedModule } from '../../shared/shared.module';
import { DialogEffects } from './effects/dialog.effects';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('dialog', reducers ),
    EffectsModule.forFeature([DialogEffects])
  ],
  declarations: [
    DialogContainerComponent
  ],
  exports: [
    DialogContainerComponent
  ]
})
export class DialogModule {}
