import {
  Inject,
  Injectable,
  LOCALE_ID
} from '@angular/core';

import {
  ROMAKOWSKI_CONFIGURATOR_LINK_CONFIG,
  RomakowskiConfiguratorLinkConfigInterface
} from './config/romakowski-configurator-link-config.interface';
import { RomakowskiConfiguratorLinkModule } from './romakowski-configurator-link.module';


export interface ProductProperty {
  ifdguid: string;
  filterUpperBoundValue?: number;
  filterLowerBoundValue?: number;
  filterNominalValue?: number | string | boolean;
  filterListValues?: (string|number)[];
}

export interface FilterValue {
  id: string;
  value: string[] | string;
}

@Injectable({
  providedIn: RomakowskiConfiguratorLinkModule
})
export class RomakowskiConfiguratorLinkService {

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(ROMAKOWSKI_CONFIGURATOR_LINK_CONFIG) private _config: RomakowskiConfiguratorLinkConfigInterface
  ) { }

  generateConfiguratorUrl(product: {uuid: string; propertySets: {properties: ProductProperty[]}[]}, filters: FilterValue[] = null) {
    return `${ this._config.solutionFinderUrl[this._locale] }?${ this._generateConfiguratorArguments(product, filters).join('&') }`;
  }

  private _generateConfiguratorArguments(product: {uuid: string; propertySets: {properties: ProductProperty[]}[]}, filters: FilterValue[]) {

    const propertyBasedArguments = product.propertySets?.reduce((all: string[], currentPropertySet) => {
      const argumentsInSet = currentPropertySet.properties.reduce((argumentsInSetAcc, property) =>
        [...argumentsInSetAcc, this._mapPropertyToConfiguratorArgument(property, filters)], []);
      return [...all, ...argumentsInSet];
    }, []) ?? [];

    const uuidBasedArgument = this._mapUuidToArgument(product.uuid);

    return [uuidBasedArgument, ...propertyBasedArguments].filter(arg => arg !== '');

  }

  private _mapPropertyToConfiguratorArgument(property: ProductProperty, filters: FilterValue[] = null): string {
    switch (property.ifdguid) {
      case '614f0057a62f4b0e8aafbc8a9b3477a7':
        // Baustoffklasse - eu
        return this._convertNominalValueToArgument(
          property.filterNominalValue,
          {
            '4b0595ef4a6043fc9d0887513a96e553': '14', // A2-s1,d0
            '8484c2d9fde041d1aa77f88a4c9536ea': '13' // B-s2,d0
          },
          '38');

      case '0ceb461fc9094466880f5d00c47cd5cf':
        // Wärmedurchgangskoeffizient - U
        return `52={"from":${ property.filterLowerBoundValue },"to":${ property.filterUpperBoundValue }}`;

      case '8ded9ba6e7f34a0cbc360530ea51086c':
        // Baustoffklasse - de
        return this._convertNominalValueToArgument(
          property.filterNominalValue,
          {
            'aa7d883725a947a69e8695d8614d14b6': '62', // A2
            'fa3a48e61ec54220a62eb7760510e1f8': '63' // B1
          },
          '57'
        );

      case 'ea7a1079ceeb4286be13d277f5b83d69':
        // Befestigung sichtbar
        return this._convertNominalValueToArgument(
          property.filterNominalValue,
          {
            'adf1cb8a540c48398e5ed188ab163ff8': '64', // Nein
            'b90ef2cc039946c6ae0d48dee343ee41': '65' // Ja
          },
          '58'
        );
      case '5264a7b279014270a918b83c79088efe':
        // Schalldämmmaß - R'w
        return `51={"from":${ property.filterLowerBoundValue ?? property.filterNominalValue }}`;
      case '919306b039ec4347b80c7e56356f080c':
        // Anwendungsbereich
        const scopeOfApplicationFilter = filters?.find(filter => filter.id === '919306b039ec4347b80c7e56356f080c');
        let scopeOfApplicationFilterValue;
        if (Array.isArray(scopeOfApplicationFilter?.value) && scopeOfApplicationFilter.value.length === 1) {
          scopeOfApplicationFilterValue = scopeOfApplicationFilter.value[0];
        } else {
          scopeOfApplicationFilterValue = scopeOfApplicationFilter?.value;
        }
        if (scopeOfApplicationFilterValue && property.filterListValues?.includes(scopeOfApplicationFilterValue)) {
          return this._convertNominalValueToArgument(
            scopeOfApplicationFilterValue,
            {
              '29448ba3d07a418c9863817e3ea22bdb': 1, // Dach
              '678ec3ec8b7844c59477bca00043004f': 4, // Decke
              'd6c800a2b45a4e9abec4925277c9a645': 93, // Fassade
              'b26304b3750e4d4aa5e8e05fa813b927': 93 // Innenausbau
            },
            '35');
        } else {
          return '';
        }
      default:
        return '';
    }
  }

  private _convertNominalValueToArgument(nominalValue, propertyMapping, key) {
    if (propertyMapping.hasOwnProperty(nominalValue)) {
      return `${ key }=${ propertyMapping[nominalValue] }`;
    } else {
      return '';
    }
  }

  private _mapUuidToArgument(uuid: string) {
    const productPanelTypeMapping = {
      '270860da18274c7d9bcd58b07cb5796e': '98', // PIR-Wandpaneel Typ P
      '784944b90e38495c80e748c49a3a87e4': '99', // PIR-Wandpaneel Typ M
      'a4a1bebe2c384a67815952b8fb9a2fcc': '100', // PIR-Dachpaneel Typ D
      '216ee9c8dcbf4bd8bb10c2d8a3409c1f': '101', // MW-Wandpaneel Typ FP
      'ced8508980d54017860adbe58a616b67': '102', // MW-Wandpaneel Typ FP+
      '817e887536af4e1686365d150caa3122': '103', // MW-Wandpaneel Typ FV
      'b9fde5dcdf1844b2b293d3ec590766a4': '104', // MW-Wandpaneel Typ FV+
      '14225c3709d24c11a90e692e0349c009': '105', // MW-Dachpaneel Typ FD
      'dd19bc160dfb41069c984f9ebeb66cbb': '106', // MW-Dachpaneel Typ FD+
      '4dbddb0c53a74b339efe35a03b68aa6e': '107', // MW-Wandpaneel Typ AFP
      '6eeb5d2400504357b604cad55b89ff69': '108' // MW-Wandpaneel Typ AFP+
    };

    const value = productPanelTypeMapping[uuid];

    return value ? `75=${ value }` : '';
  }
}
