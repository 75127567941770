import { FilterBackendInterface } from './filter.backend-interface';
import { Filter } from './filter';
import { FilterType } from './filter-type.enum';


/**
 * Generates a filter based on backend api input
 *
 * @param input
 * @returns
 */
export const generateFilterFromBackendInput = (input: FilterBackendInterface): Filter => {

  const filter = {
    id: input.id,
    displayName: input.displayName,
    type: input.type
  } as Filter;

  if (input.description) {
    filter.description = input.description;
  }

  if (input.clusterId) {
    filter.clusterId = input.clusterId;
  }

  if (input.mainProperty) {
    filter.mainProperty = input.mainProperty;
  }

  if (input.hideInFilter) {
    filter.hideInFilter = input.hideInFilter;
  }


  switch (filter.type) {
    case FilterType.RangeMulti:
      filter.rangeInfo = input.rangeInfo;
      break;
    case FilterType.AliasSelect:
      filter.multiselect = input.multiselect;
      if (Array.isArray(input.valueRange)) {
        filter.valueRange = input.valueRange.map(v => generateFilterValueFromBackendInput(v)).filter(x => !!x);
      }
      break;
    case FilterType.Select:
      filter.multiselect = input.multiselect;
      filter.valueRange = input.valueRange;
      break;
  }

  return filter;
};

const generateFilterValueFromBackendInput = (input: any): {displayName: string; value: string} => {
  if (Array.isArray(input)) {
    return {
      displayName: input[0],
      value: input[1]
    };
  } else if (input instanceof Object) {
    if (input.displayName && input.value) {
      return {
        displayName: input.displayName,
        value: input.value
      };
    }
  }
  return null;
};
