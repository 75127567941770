import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  filter,
  map,
  switchMap,
  take
} from 'rxjs/operators';
import {
  select,
  Store
} from '@ngrx/store';

import { SearchResponseInterface } from '../../shared/common/interfaces/search-response.interface';
import { FilterType } from '../../shared/filter/model/filter-type.enum';
import {
  Filter,
  generateFilterFromBackendInput
} from '../../shared/filter/model';
import { environment } from '../../../environments/environment';
import { GetMultiResponseInterface } from './get-multi-response.interface';
import {
  Cluster,
  generateClusterFromBackendInput
} from '../../shared/cluster/model';
import { GeneratorInterface } from '../../core/generator-interface/generator-interface';
import {
  RequestPayload,
  normalizeRequestPayload
} from '../../shared/common/models/request-payload';
import * as categoryTreeReducer from '../../category-tree/reducer';


/**
 * Contains basic methods to interact with the backend rest api
 */
export abstract class BaseEntityService<T, R> {

  protected _treeUuid$: Observable<string>;

  constructor(
    /**
     * Instance of HttpService
     */
    protected _httpClient: HttpClient,
    /**
     * The name of the entity this service should be used for. Will be used in the request api-path
     */
    protected _entity: string,
    /**
     * The generator service that should be used to generate the entity object
     */
    protected _entityGenerator: GeneratorInterface<T, R>,

    protected _locale: string,
    private _store: Store<categoryTreeReducer.State>
  ) {
    this._treeUuid$ = this._store.pipe(
      select(categoryTreeReducer.getCategoryTreeUuid)
    );
  }


  /**
   * Returns a list of multiple values from the configured entity
   *
   * @param queryConfig
   * @param count
   * @param offset
   * @param queryUuid
   * @param restore flag to indicate that the search identified by queryUuid should be restored
   * @param basedOnRestore flag to indicate that this new search was based on a restored one
   * @param fromPaginate flag to indicate that this is not a new search but a pagination of an existing one
   */
  getMulti(
    queryConfig: RequestPayload,
    count: number,
    offset: number          = 0,
    queryUuid?: string,
    restore: boolean        = false,
    basedOnRestore: boolean = false,
    fromPaginate: boolean   = false
  ): Observable<SearchResponseInterface<R>> {
    let url = `${ environment.api_url }${ this._entity }/views/list`
              + `?locale=${ this._locale }&limit=${ count }&offset=${ offset }`
              + `&restore=${ restore }&basedOnRestore=${ basedOnRestore }&fromPaginate=${ fromPaginate }`;

    url += queryUuid ? `&queryUuid=${ queryUuid }` : '';

    return this._treeUuid$.pipe(
      filter(treeUuid => !environment.featureFlags.catalogConfig || !!treeUuid),
      take(1),
      switchMap(treeUuid => {
        if (treeUuid) {
          url += `&treeUuid=${ treeUuid }`;
        }

        return this._httpClient.post<GetMultiResponseInterface>(url, queryConfig, { observe: 'response' }).pipe(
          map(response => {
            const searchResponse = {} as SearchResponseInterface<R>;

            if (response.ok && response.status === 200) {
              const body = response.body;
              if (body && body[this._entity]) {
                searchResponse.items = body[this._entity].map(item => this._entityGenerator.generate(item));
                if (body['queryUuid']) {
                  searchResponse.queryUuid = body['queryUuid'];
                }
                if (queryUuid && restore) {
                  searchResponse.basedOnRestore = true;
                }

                if (body.query_config) {
                  searchResponse.queryConfig = normalizeRequestPayload(body.query_config);
                  searchResponse.queryConfig.basic_filters = searchResponse.queryConfig.basic_filters.filter(bf => bf.id !== 'product_uuid');
                }
              }
            } else if (response.ok && response.status === 204) {
              searchResponse.items = [];
              searchResponse.queryConfig = normalizeRequestPayload({
                ...queryConfig,
                ordering: Array.isArray(queryConfig.ordering) && queryConfig.ordering.length > 0 ? queryConfig.ordering : ['product_random']
              });
            }

            searchResponse.pagination = {
              page: (offset / count || 0) + 1,
              pageCount: Math.ceil(parseInt(response.headers.get('x-pagination-count'), 10) / count),
              itemsPerPage: count,
              totalCount: parseInt(response.headers.get('x-pagination-count'), 10)
            };

            return searchResponse;
          })
        );
      })
    );
  }

  /**
   * Returns a single entity item
   *
   * @param uuid
   */
  getSingle(uuid: string): Observable<R> {
    return this._treeUuid$.pipe(
      filter(treeUuid => !environment.featureFlags.catalogConfig || !!treeUuid),
      take(1),
      switchMap(treeUuid => {
        let url = `${ environment.api_url }${ this._entity }/${ uuid }/views/detail?locale=${ this._locale }`;

        if (treeUuid) {
          url += `&treeUuid=${ treeUuid }`;
        }

        return this._httpClient.get<T>(url).pipe(
          map(body => body ? this._entityGenerator.generate(body) : undefined)
        );
      })
    );
  }

  /**
   * Returns a single entity item summary
   *
   * @param uuid
   */
  getSingleSummary(uuid: string): Observable<R> {
    return this._httpClient.get<T>(`${ environment.api_url }${ this._entity }/${ uuid }/views/summary?locale=${ this._locale }`).pipe(
      map(body => body ? this._entityGenerator.generate(body) : undefined)
    );
  }

  /**
   * Returns the available filters for the configured entity
   *
   * @param additionalParameters - optional paramters for filter values that have already been set. May result in more advanced filters
   */
  getFilters(additionalParameters?: Record<string, unknown>): Observable<{
    basicFilters: Filter[];
    propertyFilters: Filter[];
    propertyClusters: Cluster[];
    settingFilters: Filter[];
  }> {
    let additionalParametersString = `?locale=${ this._locale }&verbose=false`;

    if (additionalParameters) {
      for (const parameter in additionalParameters) {
        if (additionalParameters.hasOwnProperty(parameter) && additionalParameters[parameter] != null) {
          additionalParametersString += `&${ parameter }=${ additionalParameters[parameter] }`;
        }
      }
    }

    return this._httpClient.get(`${ environment.api_url }${ this._entity }/filters${ additionalParametersString }`).pipe(
      map(body => {
        if (!body || !body['basic_filters']) {
          return;
        }

        const result: {basicFilters: Filter[]; propertyFilters: Filter[]; propertyClusters: Cluster[]; settingFilters: Filter[]} = {
          basicFilters: undefined,
          propertyFilters: undefined,
          propertyClusters: undefined,
          settingFilters: undefined
        };

        const apiBasicFilters = body['basic_filters'];
        if (apiBasicFilters) {
          result.basicFilters = apiBasicFilters
            .filter(f => f.id === 'supplier_uuids' || f.id === 'product_categories')
            .sort(f => f.id === 'product_categories' ? -1 : 1)
            .map(bf => generateFilterFromBackendInput(bf));

          const apiSettingFilters = body['settings'];
          if (apiSettingFilters) {
            result.settingFilters = apiSettingFilters.map(sf => generateFilterFromBackendInput(sf));
          }

          const apiPropertyClusters = body['property_clusters'];
          if (apiPropertyClusters) {
            result.propertyClusters = apiPropertyClusters
              .map((propertyClusters) => generateClusterFromBackendInput(propertyClusters));
          }

          // TODO: Remove clusterNameMapping when merging of property sets is done in the backend
          const clusterMappings = (result.propertyClusters || []).reduce((mappings, cluster) => {
            mappings.nameMapping.set(cluster.displayName, { ...mappings.nameMapping.get(cluster.displayName), ...cluster });
            mappings.uuidMapping.set(cluster.uuid, cluster.displayName);

            return mappings;
          },
          {
            uuidMapping: new Map<string, string>(),
            nameMapping: new Map<string, Cluster>()
          });

          result.propertyClusters = Array.from(clusterMappings.nameMapping.values());

          const apiPropertyFilters = body['property_filters'];
          if (apiPropertyFilters) {
            result.propertyFilters = apiPropertyFilters
              .map((propertyFilters) => generateFilterFromBackendInput(propertyFilters))
              .map(propertyFilters => {
                if (propertyFilters.clusterId && clusterMappings.uuidMapping.has(propertyFilters.clusterId)) {
                  const targetCluster = clusterMappings.nameMapping.get(clusterMappings.uuidMapping.get(propertyFilters.clusterId));
                  if (targetCluster) {
                    propertyFilters.clusterId = targetCluster.uuid;
                  }
                }
                return propertyFilters;
              })
              .filter((propertyFilters) => !(propertyFilters.type === FilterType.RangeMulti && !propertyFilters.rangeInfo));
          }

          return result;
        }
      })
    );
  }
}
