import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { FormComponent } from './form/form.component';
import { SvgWindowPictogramComponent } from './svg-window-pictogram/svg-window-pictogram.component';
import { AddNullSelectValuePipe } from './pipes/add-null-select-value.pipe';


@NgModule({
  declarations: [
    FormComponent,
    SvgWindowPictogramComponent,
    AddNullSelectValuePipe
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule
  ],
  exports: [
    FormComponent,
    SvgWindowPictogramComponent
  ]
})
export class SchuecoProductValidationModule {}
