import {
  APP_INITIALIZER,
  NgModule
} from '@angular/core';

import { KeycloakAdapterService } from './keycloak-adapter.service';


@NgModule({
  declarations: [],
  imports: [],
  providers: [
    KeycloakAdapterService,
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakLoader,
      deps: [KeycloakAdapterService],
      multi: true
    }
  ]
})
export class KeycloakAdapterModule {}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function keycloakLoader(keycloakAdapterService: KeycloakAdapterService) {
  return () => {
    keycloakAdapterService.initKeycloak();
  };
}
