import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  NavigationEnd,
  Router
} from '@angular/router';
import {
  combineLatest,
  merge,
  Observable,
  Subject
} from 'rxjs';
import {
  select,
  Store
} from '@ngrx/store';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  share,
  shareReplay,
  startWith,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';

import { WindowRef } from '@p1/libs/browser-api-wrapper';

import * as rootReducer from '../../app.reducers';
import * as authReducer from '../../auth/reducer/auth.reducer';
import { BackAction } from '../../routing/actions/router.actions';
import { ResizeService } from '../service-resize/resize.service';
import { NavigationBarState } from './navigation-bar-state.enum';
import { NavigationBarItem } from './navigation-bar-item.interface';
import { NavigationSection } from './navigation-section.interface';
import { NavigationBarItemsService } from './navigation-bar-items.service';
import * as productReducer from '../../product/reducer';
import { GoogleTagManagerService } from '../google-tag-manager/service/google-tag-manager.service';
import { NavBarCategory } from '../../routing/enum/nav-bar-category.enum';
import { SearchBarComponent } from '../../search/search-bar/search-bar.component';
import { environment } from '../../../environments/environment';
import { externalPages } from '../../routing/external-pages';


@Component({
  selector: 'p1-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationBarComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('categoryListItem')
  categoryListItem: ElementRef<HTMLLIElement>;

  @ViewChild('navigationBar', { static: true })
  navigationBar: ElementRef<HTMLDivElement>;

  @ViewChild('categoryTreeMenu', {
    read: ElementRef
  })
  categoryTreeMenu: ElementRef;

  @ViewChild(SearchBarComponent)
  searchBar: SearchBarComponent;

  searchEnabled$: Observable<boolean>;
  rightOffCanvasOpen$: Observable<boolean>;
  navigationBarState$: Observable<NavigationBarState>;
  visibleNavigationSection$: Observable<NavigationSection>;
  hoverFirstLevelItem$: Observable<NavigationBarItem>;
  focusFirstLevelItem$: Observable<NavigationBarItem>;
  homeRouterLink$: Observable<{title: string; url: string[]}>;
  showLeftScrollTrigger$: Observable<boolean>;
  showRightScrollTrigger$: Observable<boolean>;
  showRightOffCanvas$: Observable<boolean>;
  sCatIsSet$: Observable<boolean>;
  hasAnalyticsCompany: boolean = false;
  secondLevel: boolean = false;
  home$: Observable<boolean>;
  searchPage$: Observable<boolean>;
  searchTermValue: string;
  featureFlags = environment.featureFlags;
  NavigationBarState = NavigationBarState;
  showCategoryDropdown: boolean = false;
  searchExpanded: boolean = false;
  categoryDropdownLeft: number;
  categoryDropdownTop: number;
  referenceProjectsLink: string =  externalPages.referenceProjects;

  private _userName$: Observable<string>;
  private _activeFirstLevelItem$: Observable<NavigationBarItem>;
  private _ngAfterViewInit$: Subject<void> = new Subject();
  private _scrollContainerWidthChangeStream$: Observable<any>;
  private _firstLevelScrollStream: Subject<Event> = new Subject<Event>();
  private _unsubscribe: Subject<void> = new Subject();
  private _firstLevelHover: Subject<string> = new Subject<string>();
  private _firstLevelHoverTimeout;

  constructor(
    private _element: ElementRef,
    private _window: WindowRef,
    private _store: Store<authReducer.StateInterface>,
    private _router: Router,
    private _navigationBarItems: NavigationBarItemsService,
    private _resizeService: ResizeService,
    private _cdr: ChangeDetectorRef,
    private _googleTagManager: GoogleTagManagerService,
    @Inject(PLATFORM_ID) private _platformId: Record<string, unknown>
  ) {
    this.rightOffCanvasOpen$ = this._store.pipe(
      select(rootReducer.getRightOffCanvasIsOpen)
    );

    this.searchEnabled$ = this._store.pipe(
      select(rootReducer.getSearchbarVisible)
    );

    this.searchPage$ = this._store.pipe(
      select(rootReducer.getRouterUrl),
      map((path) => path.indexOf('/search') > -1)
    );

    this.homeRouterLink$ = this._store.pipe(
      select(authReducer.getHasAnalyticsCompany),
      map(hasAnalyticsCompany => {
        let homeIconTitle = '';
        if (hasAnalyticsCompany) {
          homeIconTitle = $localize`:|home icon title suppliers @@navigation-bar.component_home-icon-title-suppliers:Go to supplier dashboard`;
          return {title: homeIconTitle, url: ['/company/analytics/dashboard']};
        }

        homeIconTitle = $localize`:|home icon title default @@navigation-bar.component_home-icon-title-default:Go home`;
        return {title: homeIconTitle, url: ['/']};
      })
    );

    this._userName$ = this._store.pipe(
      select(authReducer.getUser),
      map(user => user ? user.name : null)
    );

    this.home$ = this._store.pipe(
      select(rootReducer.getRouterUrl),
      withLatestFrom(
        this._store.pipe(
          select(authReducer.getHasAnalyticsCompany)
        )
      ),
      map(([url, hasAnalyticsCompany]) => {
        if (hasAnalyticsCompany) {
          return url === '/company/analytics/dashboard';
        }

        return url === '/';
      })
    );

    this.sCatIsSet$ = this._store.pipe(
      select(productReducer.getTempBasicFilterHasCategories)
    );

    this.showRightOffCanvas$ = combineLatest([
      this._store.pipe(select(productReducer.getTempBasicFilterHasCategories)),
    ]).pipe(map(([hasBasicFilters]) => !!(hasBasicFilters)
    ));

    /**
     * Emits the currently displayed navigationSection based on the current navBarCategory
     */
    this.visibleNavigationSection$ = this._navigationBarItems.navigationBarItems$;

    /**
     * Emits the currently active firstLevel NavigationItemInterface if it has secondLevelItems
     */
    this._activeFirstLevelItem$ = combineLatest([
      this._store.pipe(
        select(rootReducer.getRouterUrl),
        map(routerUrl => this.hasAnalyticsCompany ? routerUrl.split('/')[3] : routerUrl.split('/')[1])
      ),
      this.visibleNavigationSection$
    ]).pipe(
      filter(([_, visibleNavigationSection]) => !!visibleNavigationSection),
      map(([firstLevelIdentifier, visibleNavigationSection]) =>
        visibleNavigationSection.firstLevelItems.find(item => !!item.secondLevelItems && item.name === firstLevelIdentifier)
      )
    );

    /**
     * Emits the currently hovered firstLevel NavigationItemInterface
     */
    this.hoverFirstLevelItem$ = combineLatest([
      this._firstLevelHover.asObservable().pipe(
        startWith(undefined)
      ),
      this.visibleNavigationSection$
    ]).pipe(
      filter(([_, visibleNavigationSection]) => !!visibleNavigationSection),
      map(([firstLevelIdentifier, visibleNavigationSection]) =>
        visibleNavigationSection.firstLevelItems.find(item => item.name === firstLevelIdentifier)
      ),
      /**
       * This observable gets a subscription in the template within an *ngIf. Because this observable and the one used
       * for the *ngIf emit at the same time, it is possible that the subscription inside the *ngIf "misses" an
       * emission and the view does not get updated.
       * The shareReplay operator prevents this by emitting his last emission to every new subscriber.
       */
      shareReplay(1)
    );

    /**
     * Emits the current navigationBarState, see NavigationBarState enum
     */
    this.navigationBarState$ = combineLatest([
      this.hoverFirstLevelItem$,
      this._activeFirstLevelItem$
    ]).pipe(
      map(([firstLevelHover, firstLevelActive]) => {
        if (!firstLevelHover && firstLevelActive) {
          return NavigationBarState.activeFirstLevel;
        } else if (firstLevelHover && !!firstLevelHover.secondLevelItems && firstLevelActive) {
          return NavigationBarState.hoverOverActiveFirstLevel;
        } else if (firstLevelHover && !firstLevelHover.secondLevelItems && firstLevelActive) {
          return NavigationBarState.emptyHoverOverActiveFirstLevel;
        } else if (firstLevelHover && !!firstLevelHover.secondLevelItems && !firstLevelActive) {
          return NavigationBarState.hoverFirstLevel;
        } else {
          return NavigationBarState.default;
        }
      }),
      share()
    );

    /**
     * Emits the currently focused firstLevel navigationItem that should be used to generate the secondLevel navigation
     */
    this.focusFirstLevelItem$ = combineLatest([
      this.navigationBarState$,
      this._activeFirstLevelItem$,
      this.hoverFirstLevelItem$
    ]).pipe(
      map(([navigationBarState, firstLevelActive, firstLevelHover]) => {
        switch (navigationBarState) {
          case NavigationBarState.emptyHoverOverActiveFirstLevel:
          case NavigationBarState.default:
            return undefined;
          case NavigationBarState.activeFirstLevel:
            return firstLevelActive;
          case NavigationBarState.hoverOverActiveFirstLevel:
            return firstLevelHover;
          case NavigationBarState.hoverFirstLevel:
            return firstLevelHover;
        }
      })
    );

    // a stream of every event that may lead to changes in the scrollContainerWidth
    this._scrollContainerWidthChangeStream$ = merge(
      this._store.pipe(
        select(rootReducer.getLayoutRightOffCanvasMode)
      ),
      this._router.events.pipe(
        filter(e => e instanceof NavigationEnd)
      ),
      this._userName$,
      this._resizeService.onResize$
    );

    // combined stream that emits wether the two scroll trigger hints should be visible or not
    const showScrollTriggers = merge(
      this._scrollContainerWidthChangeStream$,
      this._ngAfterViewInit$,
      this._firstLevelScrollStream,
      this.visibleNavigationSection$
    ).pipe(
      debounceTime(300),
      map(() =>
        this._element.nativeElement.getElementsByClassName('navigation__scroll-container')[0]
      ),
      filter((firstLevelScrollContainerElement) => !!firstLevelScrollContainerElement),
      map((firstLevelScrollContainerElement) => {
        const result = [false, false];

        if (firstLevelScrollContainerElement.scrollLeft > 10) {
          result[0] = true;
        }

        if (firstLevelScrollContainerElement.scrollLeft < (firstLevelScrollContainerElement.scrollWidth - firstLevelScrollContainerElement.offsetWidth)) {
          result[1] = true;
        }

        return result;
      }),
      distinctUntilChanged((prev, next) => prev[0] === next[0] && prev[1] === next[1])
    );

    this.showLeftScrollTrigger$ = showScrollTriggers.pipe(
      map(([showLeft]) => showLeft)
    );

    this.showRightScrollTrigger$ = showScrollTriggers.pipe(
      map(([_, showRight]) => showRight)
    );
  }

  /**
   * Reset search-bar if user clicked outside from search-bar
   *
   * @param ev event target of click
   */
  @HostListener('click', ['$event.target'])
  @HostListener('window:click', ['$event.target'])
  @HostListener('window:touchstart', ['$event.target'])
  onWindowClick(ev) {
    if (!this._element.nativeElement.contains(ev) || ev.classList.contains('backdrop')) {
      this.initSearchState();
    }
  }

  @HostListener('window:resize')
  positionCategoryDropdown() {
    if (isPlatformBrowser(this._platformId) && this.categoryListItem && this.navigationBar) {
      const categoriesLinkContainerRect = this.categoryListItem.nativeElement.getBoundingClientRect();
      const topMargin = Number(this._window.nativeWindow.getComputedStyle(this.navigationBar.nativeElement).marginTop.replace('px', ''));
      this.categoryDropdownLeft = categoriesLinkContainerRect.left;
      this.categoryDropdownTop = topMargin + 10 + categoriesLinkContainerRect.height;
      this._cdr.detectChanges();
    }
  }

  @HostListener('document:click', ['$event'])
  closeCategoryDropdownOnOutsideClick(event) {
    if (isPlatformBrowser(this._platformId) && this.showCategoryDropdown && this.categoryListItem && this.categoryTreeMenu.nativeElement) {
      if (!this.categoryListItem.nativeElement.contains(event.target) && !this.categoryTreeMenu.nativeElement.contains(event.target)) {
        this.showCategoryDropdown = false;
      }

      window.addEventListener('searchFocused', () => {
        this.showCategoryDropdown = false;
        this._cdr.detectChanges();
      });
    }
  }

  ngOnInit() {
    this._store.pipe(
      select(rootReducer.getRouterQueryParams),
      takeUntil(this._unsubscribe)
    ).subscribe(params => {
      if (params) {
        if (params.searchTerm) {
          this.searchTermValue = params.searchTerm;
        } else {
          this.searchTermValue = '';
        }
      }
    });

    this.rightOffCanvasOpen$.pipe(
      delay(1),
      takeUntil(this._unsubscribe)
    ).subscribe(() => {
      this.positionCategoryDropdown();
    });

    this._store.pipe(
      select(rootReducer.getRouterUrl),
      takeUntil(this._unsubscribe)
    ).subscribe(() => {
      if (this.showCategoryDropdown) {
        this.showCategoryDropdown = false;
      }
    });

    this._store.pipe(
      select(authReducer.getUser),
      filter(user => user && user.companyUuid && user.supplierAreaAccess),
      takeUntil(this._unsubscribe)
    ).subscribe(() => this.hasAnalyticsCompany = true);

    this.visibleNavigationSection$.pipe(
      filter(visibleNavigationSection => !!visibleNavigationSection)
    ).subscribe(visibleNavigationSection => {
      visibleNavigationSection.firstLevelItems.some(items => this.secondLevel = !!items.secondLevelItems);
    });

    // make sure the navigation link for the current site is visible
    merge(
      this._scrollContainerWidthChangeStream$,
      this._ngAfterViewInit$
    ).pipe(
      debounceTime(100),
      map(() => this._element.nativeElement.querySelector('.navigation__listitem--active')),
      filter(activeRouterLink => !!activeRouterLink),
      takeUntil(this._unsubscribe)
    ).subscribe(activeRouterLink => {
      this._ensureNavigationItemVisibility(activeRouterLink);
    });
  }

  /**
   * Sets the visibility of the right scrollable indicator
   * Ensures navigation anchor tag visibility
   */
  ngAfterViewInit() {
    this._ngAfterViewInit$.next();
    this._ngAfterViewInit$.complete();
  }

  /**
   * Unsubscribes from all observables
   */
  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  get navigationCategoryIsApp() {
    return this._navigationBarItems.activeNavigationBarCategory === NavBarCategory.app;
  }

  /**
   * Sets the identifier for the currently hovered firstLevel navigationItem
   *
   * @param firstLevel
   */
  setFirstLevelHover(firstLevel: string) {
    if ('ontouchstart' in document.documentElement) {
      return;
    }

    clearTimeout(this._firstLevelHoverTimeout);

    if (firstLevel) {
      this._firstLevelHover.next(firstLevel);
    } else {
      this._firstLevelHoverTimeout = setTimeout(() => {
        this._firstLevelHover.next(undefined);
      }, 250);
    }
  }

  /**
   * Navigates back
   *
   * @param $event - click event
   */
  back($event) {
    $event.preventDefault();
    this._store.dispatch(new BackAction());
  }

  emitFirstLevelScrollStream($event) {
    this._firstLevelScrollStream.next($event);
  }

  scrollRight() {
    const firstLevelScrollContainerElement = this._element.nativeElement.getElementsByClassName('navigation__scroll-container')[0];
    const firstLevelNavigationItemElements = this._element.nativeElement.getElementsByClassName('navigation__listitem');

    for (const listItem of firstLevelNavigationItemElements) {
      if ((listItem.offsetLeft + listItem.offsetWidth)
          > (firstLevelScrollContainerElement.scrollLeft + firstLevelScrollContainerElement.offsetWidth)) {
        this._ensureNavigationItemVisibility(listItem);
        break;
      }
    }
  }

  scrollLeft() {
    const firstLevelScrollContainerElement = this._element.nativeElement.getElementsByClassName('navigation__scroll-container')[0];
    const navigationItems: HTMLElement[] = Array.from(this._element.nativeElement.getElementsByClassName('navigation__listitem'));

    navigationItems.unshift(this._element.nativeElement.querySelector('.navigation__section-title'));

    for(const listItem of [...navigationItems].reverse()) {
      if (listItem.offsetLeft < firstLevelScrollContainerElement.scrollLeft) {
        this._ensureNavigationItemVisibility(listItem);
        break;
      }
    }
  }

  toggleCategoryDropdown() {
    this.showCategoryDropdown = !this.showCategoryDropdown;
    if (this.showCategoryDropdown) {
      this._googleTagManager.pushEvent({
        category: 'Categorytree',
        action: 'Open CategorytreeMenu',
        label: 'Navigation - CategorytreeMenuTrigger'
      });

      this.positionCategoryDropdown();
    }
  }

  toggleSearch(state: boolean) {
    this.searchExpanded = state;
  }

  initSearchState() {
    this.searchExpanded = false;
    if (this.searchBar) {
      this.searchBar.closeSearchPreview();
    }
  }

  /**
   * check if a given url is a parent of the current url
   * this check is used instead of routerLinkActive because we want to ignore the queryParams
   *
   * @param url
   */
  isLinkActive(url: string[]) {
    const joinedUrl = Array.isArray(url) ? url.join('/') : '';
    const queryParamsIndex = this._router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this._router.url : this._router.url.slice(0, queryParamsIndex);

    if (joinedUrl.length > 1) {
      return baseUrl.startsWith(joinedUrl);
    }

    return baseUrl === joinedUrl;
  }

  private _ensureNavigationItemVisibility(navigationItemElement: HTMLElement) {
    const firstLevelScrollContainerElement = this._element.nativeElement.getElementsByClassName('navigation__scroll-container')[0];

    if (firstLevelScrollContainerElement) {
      const scrollIndicatorOffset = 20; // ~half width of the gradient scroll indicators

      const routerLinkOffsetLeft = navigationItemElement.offsetLeft;
      const routerLinkOffsetRight = firstLevelScrollContainerElement.scrollWidth - (navigationItemElement.offsetLeft + navigationItemElement.offsetWidth);

      const containerScrollLeft = firstLevelScrollContainerElement.scrollLeft;
      const containerScrollRight = firstLevelScrollContainerElement.scrollWidth
                                   - (firstLevelScrollContainerElement.scrollLeft + firstLevelScrollContainerElement.offsetWidth);

      if (containerScrollLeft > routerLinkOffsetLeft) {
        firstLevelScrollContainerElement.scrollLeft = routerLinkOffsetLeft - scrollIndicatorOffset;
      } else if (containerScrollRight > routerLinkOffsetRight) {
        firstLevelScrollContainerElement.scrollLeft = containerScrollLeft + (containerScrollRight - routerLinkOffsetRight) + scrollIndicatorOffset;
      }

    }
  }
}
