import { createSelector } from '@ngrx/store';

import { TreeCategory } from '@p1/libs/catalog-service';

import {
  getAllParentsOfCategory,
  getCategoryTreeItems,
  getCategoryTreeLeaves
} from '../../category-tree/reducer';
import { Product } from '../model';
import {
  getSelectedItem,
  getSimilarProducts
} from '../reducer/';
import * as productComparisonReducer from '../../product-comparison/reducer/product-comparison.reducer';


export const getComparisonProductTreeCategoryMapping = createSelector(productComparisonReducer.getComparisonProducts, getCategoryTreeLeaves,
  (products, leaves) => getTreeCategoryMappingForProducts(products, leaves)
);

export const getSimilarProductsTreeCategoryMapping =
               createSelector(getSimilarProducts, getCategoryTreeLeaves, (similarProducts, leaves) =>
                 getTreeCategoryMappingForProducts(similarProducts, leaves));

export const getTreeCategoryForGivenProduct =
               createSelector(getCategoryTreeLeaves, (leaves, props: {product: Product}) =>
                 props.product ? getTreeCategoryForProduct(props.product, leaves) : null);

export const getTreeCategoryRootlineForGivenProduct = createSelector(
  getCategoryTreeLeaves,
  getCategoryTreeItems,
  getSelectedItem,
  (leaves, categoryItems, product) => {
    const productCategory = product ? getTreeCategoryForProduct(product, leaves) : null;
    if (productCategory?.uuid && categoryItems && categoryItems.hasOwnProperty(productCategory.uuid)) {
      return getAllParentsOfCategory(productCategory.uuid, categoryItems);
    }
  }
);

const getTreeCategoryMappingForProducts = (products: Product[], treeCategories: TreeCategory[]) => {
  if (Array.isArray(products) && Array.isArray(treeCategories)) {
    return products.reduce((mapping, product) => {
      mapping[product.uuid] = getTreeCategoryForProduct(product, treeCategories);
      return mapping;
    }, {});
  }
};

const getTreeCategoryForProduct = (product: Product, treeCategories: TreeCategory[]) => {
  if (Array.isArray(product.treeCategories) && Array.isArray(treeCategories)) {
    return treeCategories.filter(categeory => !categeory.advertised).find(category => product.treeCategories.includes(category.uuid));
  }
};
