import * as analyticsCompanyActions from '../../actions/analytics-company.actions';
import { AnalyticsCompany } from '../../model';


export const analyticsCompany = (state: AnalyticsCompany, action: analyticsCompanyActions.Actions) => {
  switch (action.type) {
    case analyticsCompanyActions.FETCH_ANALYTICS_COMPANY_SUCCEEDED:
      return action.payload;
    case analyticsCompanyActions.UPDATE_ANALYTICS_COMPANY:
      return action.payload;
    default:
      return state;
  }
};
