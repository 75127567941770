<ng-container *ngIf="{
                       isAuthenticated: isAuthenticated$ | async,
                       currentUrl: currentUrl$ | async,
                       user: user$ | async,
                       hasSupplierSelfServices: hasSupplierSelfServices$ | async
                     } as profileMenuData">
  <div class="profile-menu profile-menu--logged-in">
      <button p1Button
           *ngIf="featureFlags.login === 'full'"
           class="profile-menu__cta"
           (click)="toggleMenu()"
           [ngClass]="{
              'profile-menu__cta--open': isOpen,
              'profile-menu__cta--disabled': featureFlags.hideProfile && featureFlags.hideLogout
           }"
           [disabled]="featureFlags.hideProfile && featureFlags.hideLogout">
        <ng-container *ngIf="!profileMenuData.isAuthenticated">
          <i class="profile-menu__cta__avatar icon">
            <p1-lib-icons-icon-wrapper name="iconAvatar"></p1-lib-icons-icon-wrapper>
          </i>
          <span class="profile-menu__cta__text"
                i18n="login link text // page: all @@profile-menu.component_login_link_text">
            LOG IN
          </span>
        </ng-container>
        <span *ngIf="profileMenuData.isAuthenticated"
              class="profile-menu__cta__initials">
          {{profileMenuData.user?.firstName?.slice(0,1)}}{{profileMenuData.user?.lastName?.slice(0,1)}}
        </span>
      </button>
    <div class="profile-menu__dropdown"
         [@profilemenuContent]="isOpen ? 'is-open' : ''">
      <ul class="profile-menu__list list--without-dots">
        <li *ngIf="!profileMenuData.isAuthenticated"
            class="list__item list__item--unauthenticated">
          <ul class="profile-menu__sublist list--without-dots">
            <li class="sublist__item">
              <a class="button button--primary profile-menu__login-button"
                 [routerLink]="['/auth/login']"
                 [queryParams]="profileMenuData.isAuthenticated ? null : { from: profileMenuData.currentUrl }"
                 i18n="login button // page: all @@profile-menu.component_login_button_text">
                LOG IN
              </a>
            </li>
            <li class="sublist__item"
                i18n="no account hint with link text to register-page // page: all @@profile-menu.component_registration_no_account_hint_link_text">
              <span>No Account?</span>
              <a [routerLink]="['/auth/register']"
                 [queryParams]="profileMenuData.isAuthenticated ? null : { from: profileMenuData.currentUrl }">
                Register here
              </a>
            </li>
          </ul>
        </li>
        <li *ngIf="!featureFlags.hideProfile"
            class="list__item">
          <a class="list__link"
             [routerLink]="profileMenuData.isAuthenticated ? ['/userAccount', 'profile', 'data'] : ['/auth/login']"
             [queryParams]="profileMenuData.isAuthenticated ? null : { from: '/userAccount/profile/data' }"
             i18n="dashboard link text // page: all @@profile-menu.component_dashboard_link_text">
            My profile
          </a>
        </li>
        <li *ngIf="profileMenuData.hasSupplierSelfServices"
          class="list__item">
        <a class="list__link"
           [routerLink]="profileMenuData.isAuthenticated ? ['/supplier-self-services'] : ['/auth/login']"
           [queryParams]="profileMenuData.isAuthenticated ? null : { from: '/supplier-self-services' }"
           i18n="dashboard link text // page: all @@profile-menu.component_selfservice_link_text">
          Supplier self services
        </a>
      </li>
      <li *ngIf="profileMenuData.isAuthenticated && !featureFlags.hideLogout"
            class="list__item">
          <a class="list__link"
             (click)="logout()"
             i18n="logout link text // page: all @@profile-menu.component_logout_link_text">
            Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
