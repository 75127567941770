import { SearchResult } from './search-result';
import { SearchResultBackendInterface } from './search-result.backend-interface';


export const generateSearchResultFromBackendInput = (input: SearchResultBackendInterface, urlTarget?: string): SearchResult => {
  const searchResult: SearchResult = {
    matchCases: input.MatchCases,
    matchCount: input.MatchCount
  };

  if (urlTarget) {
    searchResult.urlTarget = urlTarget;
  }

  return searchResult;
};
