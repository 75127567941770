import { InjectionToken } from '@angular/core';


export interface CookiehandlingServiceInterface {
  setDomain(domain: string);

  write(name: string, value: string, expires?: Date, domain?: string, path?: string);

  read(name: string): string;
}

export const COOKIEHANDLING_SERVICE = new InjectionToken<CookiehandlingServiceInterface>('COOKIEHANDLING_SERVICE');
