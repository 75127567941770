<div class="page__wrapper" [ngClass]="{
    'right-off-canvas--open': ((showRightOffCanvas$ | async) && ((currentBreakpoint$ | async) >= breakpoint.bpMinM)) && rightOffCanvasOpen !== rightOffCanvasTypeView.hidden && (rightOffCanvasExists$ | async)
  }">

  <p1-header *ngIf="!featureFlags.embedLayout"
             [ngClass]="{'overlay--open': navigationOpen}"
             [navigationOpen]="navigationOpen"
             [unreadNews]="unreadNewsInCategories$ | async"
             [showNewsBell]="showNewsBellInNavigation$ | async"
             (navigationToggle)="toggleNavigation($event)"
             (newsToggle)="toggleNewsDialog(true)"></p1-header>

  <div class="page__container"
       [ngClass]="{'page__container__plugin-bar-spacing': featureFlags.embedSelectProduct && activeProduct}">
    <p1-navigation-bar></p1-navigation-bar>

    <div class="main__wrapper"
         [ngClass]="{
            'right-off-canvas--open': ((showRightOffCanvas$ | async) && ((currentBreakpoint$ | async) >= breakpoint.bpMinM)) && rightOffCanvasOpen !== rightOffCanvasTypeView.hidden && (rightOffCanvasExists$ | async),
            'has-right-off-canvas': (showRightOffCanvas$ | async) && rightOffCanvasExists$ | async
         }">
      <router-outlet></router-outlet>
    </div>
    <p1-footer *ngIf="!featureFlags.embedLayout" [hasRightOffCanvas]="(showRightOffCanvas$ | async) && rightOffCanvasExists$ | async"></p1-footer>
  </div>

  <div *ngIf="featureFlags.burgerMenu"
       class="overlay overlay--fixed"
       [ngClass]="{'overlay--open': navigationOpen}">
    <p1-navigation [open]="navigationOpen" (toggle)="toggleNavigation($event)"></p1-navigation>
  </div>

  <p1-notification-container></p1-notification-container>

  <p1-dialog-container></p1-dialog-container>

  <p1-lib-ui-elements-portal-outlet [portalName]="portalOutletName.modalOverlay"></p1-lib-ui-elements-portal-outlet>

  <p1-news-dialog *ngIf="featureFlags.news && (showNewsDialog$ | async) && showNewsDialog  && ((currentBreakpoint$ | async) >= breakpoint.bpMinM)
                          || featureFlags.news && showNewsDialog && ((currentBreakpoint$ | async) >= breakpoint.bpMinM)"
                  [unreadNewsInCategories]="unreadNewsInCategories$ | async"
                  (readNews)="addReadNews($event);"
                  (abortNewsDialog)="toggleNewsDialog(false)"></p1-news-dialog>

  <p1-lib-schueco-stahl-contact *ngIf="catalogIdentifier === 'SchuecoStahlsysteme' && showSchuecoStahlContactModal"
  (closeDialog)="showSchuecoStahlContactModal = false">
  </p1-lib-schueco-stahl-contact>

  <ng-content></ng-content>
</div>
