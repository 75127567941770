import { Action } from '@ngrx/store';

import { KeycloakData } from '@p1/libs/keycloak-adapter';

import { ActionWithPayload } from '../../shared/ngrx/action-with-payload.class';


/**
 * Keycloak Login redirect, payload contains redirectURI as relative path to app root
 */
export const KEYCLOAK_LOGIN = '[KEYCLOAK] Login';
export class KeycloakLoginAction implements ActionWithPayload<{ fromPath: string; reason?: string}> {
  readonly type = KEYCLOAK_LOGIN;

  constructor(public payload: { fromPath: string; replaceUrl: boolean; reason?: string }) {}
}

/**
 * Keycloak Login Succeeded, payload contains keycloak data such as bearer token, id token, refresh token, and access roles
 */
export const KEYCLOAK_LOGIN_SUCCEEDED = '[KEYCLOAK] Login Succeeded';
export class KeycloakLoginSucceededAction implements ActionWithPayload<KeycloakData> {
  readonly type = KEYCLOAK_LOGIN_SUCCEEDED;

  constructor(public payload: KeycloakData) {}
}

/**
 * Keycloak Logout redirect, payload contains redirectURI as relative path to app root
 */
export const KEYCLOAK_LOGOUT = '[KEYCLOAK] Logout';
export class KeycloakLogoutAction implements ActionWithPayload<string> {
  readonly type = KEYCLOAK_LOGOUT;

  constructor(public payload: string) {}
}

/**
 * Keycloak Registration redirect, payload contains redirectURI as relative path to app root
 */
export const KEYCLOAK_REGISTRATION = '[KEYCLOAK] Registration';
export class KeycloakRegistrationAction implements ActionWithPayload<{  fromPath: string }> {
  readonly type = KEYCLOAK_REGISTRATION;

  constructor(public payload: { fromPath: string; replaceUrl: boolean}) {}
}

/**
 * Keycloak bearer token update, payload contains new bearer token
 */
export const KEYCLOAK_TOKEN_UPDATE = '[KEYCLOAK] Bearer Token Update';
export class UpdateKeycloakTokenAction implements ActionWithPayload<string> {
  readonly type = KEYCLOAK_TOKEN_UPDATE;

  constructor(public payload: string) {}
}

export const KEYCLOAK_PROFILE = '[KEYCLOAK] Profile';
export class KeycloakProfileAction implements Action {
  readonly type = KEYCLOAK_PROFILE;

  constructor(public payload: {replaceUrl: boolean}) {}
}

export const KEYCLOAK_EDIT_PASSWORD = '[KEYCLOAK] Edit password';
export class KeycloakEditPasswordAction implements Action {
  readonly type = KEYCLOAK_EDIT_PASSWORD;

  constructor(public payload: {replaceUrl: boolean}) {}
}

export type Actions =
  KeycloakLoginAction |
  KeycloakLoginSucceededAction |
  KeycloakLogoutAction |
  KeycloakRegistrationAction |
  UpdateKeycloakTokenAction |
  KeycloakProfileAction |
  KeycloakEditPasswordAction;
