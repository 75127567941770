import * as categoryTreeActions from '../../actions/category-tree.actions';
import { CategoryTreeNodeMapping } from '../../model/category-tree-node-mapping';


/**
 * items reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const items = (state: CategoryTreeNodeMapping = {}, action: categoryTreeActions.Actions): CategoryTreeNodeMapping => {
  switch (action.type) {
    case categoryTreeActions.FETCH_CATEGORY_TREE_SUMMARY_SUCCEEDED:
      const newStateMappingSummary: CategoryTreeNodeMapping = {};
      action.payload.treeNodes.forEach(category => {
        newStateMappingSummary[category.uuid] = { ...category};
      });
      return newStateMappingSummary;
    default:
      return state;
  }
};
