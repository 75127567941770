import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';

import * as productComparisonActions from '../actions/product-comparison.actions';
import { ProductComparison } from '../model/product-comparison';
import { Product } from '../../product/model';
import { ProductScore } from '../../product/model-score';
import * as productReducers from '../../product/reducer';
import { compareArrays } from '../../shared/common/helper/compare-arrays/compare-arrays';
import { deepCopy } from '../../shared/common/helper/deep-copy/deep-copy';


export const productComparisonFeatureKey = 'productComparison';

export interface State {
  comparisonProductUuids: string[];
  comparisonSaveInProgress: boolean;
  comparisonServerState: ProductComparison;
}

export const initialState: State = {
  comparisonProductUuids: null,
  comparisonSaveInProgress: false,
  comparisonServerState: null
};

const productComparisonReducers = createReducer(
  initialState,
  on(productComparisonActions.addProductToProductComparison,
    (state, { uuid }): State => {
      const tmpState: State = deepCopy(state);

      return Array.isArray(tmpState.comparisonProductUuids) && tmpState.comparisonProductUuids.length > 0 ? {
        ...state,
        comparisonProductUuids: [
          // Use new set to avoid duplicates
          ...new Set([
            ...tmpState.comparisonProductUuids,
            uuid
          ])
        ]
      } : {
        ...state,
        comparisonProductUuids: [uuid]
      };
    }
  ),
  on(productComparisonActions.removeProductFromProductComparison,
    (state, { uuid }): State => {
      const tmpState: State = deepCopy(state);
      const comparisonProductUuids = tmpState.comparisonProductUuids.filter(item => item !== uuid);

      return {
        ...state,
        comparisonProductUuids: comparisonProductUuids.length > 0 ? comparisonProductUuids : null
      };
    }
  ),
  on(productComparisonActions.clearProductsFromProductComparison,
    (state): State => ({
      ...state,
      comparisonProductUuids: null
    })
  ),
  on(productComparisonActions.loadProductComparisonSuccess,
    (state, { productComparison }): State => ({
      ...state,
      comparisonProductUuids: productComparison.productUuids,
      comparisonServerState: productComparison
    })
  ),
  on(productComparisonActions.saveProductComparisonTriggered,
    (state): State => ({
      ...state,
      comparisonSaveInProgress: true
    })
  ),
  on(productComparisonActions.saveProductComparisonSuccess,
    (state, { productComparison }): State => ({
      ...state,
      comparisonProductUuids: productComparison.productUuids,
      comparisonSaveInProgress: false,
      comparisonServerState: productComparison
    })
  ),
  on(productComparisonActions.saveProductComparisonFailure,
    (state): State => ({
      ...state,
      comparisonSaveInProgress: false
    })
  ),
  on(productComparisonActions.createProductComparisonFromProductsTriggered,
    (state, { uuids }): State => ({
      ...state,
      comparisonProductUuids: uuids
    })
  )
);

export const reducers = (state: State | undefined, action: Action) => productComparisonReducers(state, action);

export const getProductComparisonState = createFeatureSelector<State>(productComparisonFeatureKey);

export const getComparisonProductUuids = createSelector(getProductComparisonState, (state: State) =>
  state ? Array.isArray(state.comparisonProductUuids) ? state.comparisonProductUuids : [] : null);
export const getComparisonSaveInProgress = createSelector(getProductComparisonState, (state: State) =>
  state ? state.comparisonSaveInProgress : null);
export const getComparisonServerState = createSelector(getProductComparisonState, (state: State) =>
  state ? state.comparisonServerState ? state.comparisonServerState : undefined : null);
export const getComparisonUuid = createSelector(getComparisonServerState, (productComparison: ProductComparison) =>
  productComparison ? productComparison.uuid : undefined);

/**
 * Checks if the comparison server state is up to date with the local comparison
 */
export const getComparisonIsSynced = createSelector(getComparisonServerState, getComparisonProductUuids, productReducers.getQueryUuid,
  (comparisonServerState: ProductComparison, comparisonProductUuids: string[] | null, queryUuid: string) => {
    if ((!comparisonServerState && Array.isArray(comparisonProductUuids) && comparisonProductUuids.length > 0) ||
        (comparisonServerState && comparisonServerState.queryUuid !== queryUuid)) {
      return false;
    }

    return compareArrays(!!comparisonServerState ? comparisonServerState.productUuids : [], comparisonProductUuids);
  }
);

/**
 * Returns all products that are part of the current comparison
 *
 * @type {OutputSelector<State, Product[], (res: any) => Product[]>}
 */
export const getComparisonProducts = createSelector(
  getComparisonProductUuids, productReducers.getItems, (compareProductsUuids, items): Product[] => {
    if (Array.isArray(compareProductsUuids) && compareProductsUuids.length > 0) {
      return compareProductsUuids.map(uuid => items && items[uuid] || { uuid } as Product);
    }
    return undefined;
  }
);

/**
 * Returns all products that are part of the current comparison
 *
 * @type {OutputSelector<State, Product[], (res: any) => Product[]>}
 */
export const getComparisonProductsWithCompleteItems = createSelector(
  getComparisonProducts, (comparisonProducts): Product[] => {
    if (Array.isArray(comparisonProducts)) {
      const compareProductsWithDetails = comparisonProducts.filter(product => product.detailsLoaded);
      return compareProductsWithDetails.length > 0 ? compareProductsWithDetails : undefined;
    }
    return undefined;
  }
);

/**
 * Returns the count of products in compare list
 */
export const getComparisonProductsCount = createSelector(
  getComparisonProductUuids, (compareProductsUuids = []) => compareProductsUuids.length
);

/**
 * Returns a map with Scores for compare products
 *
 * @type {OutputSelector<State, Map<string, ProductScore>, (res1: string[], res2: Map<string, ProductScore>) => Map<string, ProductScore>>}
 */
export const getComparisonProductScores = createSelector(getComparisonProductUuids, productReducers.getCurrentScores,
  (compareProductsUuids = [], itemScores = {}): Map<string, ProductScore> => {
    const result = new Map<string, ProductScore>();

    compareProductsUuids.forEach(uuid => {
      if (itemScores.hasOwnProperty(uuid)) {
        result.set(uuid, itemScores[uuid]);
      }
    });

    return result.size > 0 ? result : undefined;
  }
);
