<div class="modal"
     role="dialog"
     [ngClass]="{
       'modal--default-spacings': useDefaultSpacings,
       'modal--default-gap': useDefaultGap,
       'modal--fixed-height': fixedHeight,
       'modal--fixed-width': fixedWidth,
       'modal--min-width': useMinWidth,
       'modal--max-width': useMaxWidth,
       'modal--max-width-smallest': useSmallestMaxWidth,
       'modal--fullscreen': fullscreen,
       'left-bottom': leftBottom
     }">
  <div class="modal__header">
    <div class="modal__header__inner">
      <ng-content select="[modal-header]"></ng-content>
    </div>
    <div class="modal-close">
      <button *ngIf="closeable"
              class="modal-close__button"
              title="Close Window"
              [attr.data-gtm-event-category]="context ? context : 'P1Modal'"
              [attr.data-gtm-event-action]="trackingAction ? trackingAction : 'Close P1Modal'"
              [attr.data-gtm-event-label]="(context ? context : 'P1Modal') + ' - Close - Button'"
              (click)="close()">

        <i class="icon cross"></i>
      </button>
    </div>
  </div>

  <div class="modal__content"
       [ngClass]="{'scrollable': contentIsScrollable}">
    <div class="modal__content__inner">
      <ng-content select="[modal-content]"></ng-content>
    </div>
  </div>

  <div *ngIf="showActions"
       class="modal__actions">
    <ng-content select="[modal-actions]"></ng-content>
  </div>
</div>
