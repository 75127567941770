import { TreeCategoryWithProducts } from './tree-category-with-products';
import { CategoryTreeNodeWithProductsBackendInterface } from './category-tree-node-with-products.backend-interface';
import { generateProductFromBackendInput } from '../../product/model/product.generators';
import { generateTreeCategoryFromBackendInput } from '@p1/libs/catalog-service/';


/**
 * Generates a Category Tree Node with Products object based on api input
 *
 * @param input
 * @returns
 */
export const generateCategoryTreeNodeWithProductsFromBackendInterface = (input: CategoryTreeNodeWithProductsBackendInterface): TreeCategoryWithProducts => {
  const categoryTreeNodeWithProducts: TreeCategoryWithProducts = generateTreeCategoryFromBackendInput(input);

  if (input.Products) {
    categoryTreeNodeWithProducts.products = input.Products.map(generateProductFromBackendInput);
  }

  return categoryTreeNodeWithProducts;
};
