export const serverErrorMarkup = `<div class="customErrorModal__wrapper"
     style="background-color: rgba(100, 100, 100, .7);
        height: 100%;
        left: 0;
        min-width: 320px;
        padding: 20px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 106;">
  <div class="customErrorModal"
       style="background: white;
        height: auto;
        margin: auto;
        max-height: 100%;
        max-width: 600px;
        overflow-y: auto;
        position: relative;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);">
    <div class="customErrorModal__content"
         style="padding: 40px;">
      <div class="illustration"
           style="margin-bottom: 20px; width: 100%;">
        <div style="margin: auto;">
            <svg xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 400 400">
            <g fill="none" fill-rule="evenodd" transform="translate(1 50)">
            <path
              fill="#FFF"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M384.3,183.4 C384.3,82.3 302.4,0.4 201.3,0.4 C100.2,0.4 18.3,82.3 18.3,183.4 C18.3,210.3 24.2,235.9 34.6,258.9 L368,258.9
              C378.4,235.8 384.3,210.3 384.3,183.4 Z"/>
            <path
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M20.6 258.9L377.4 258.9M0 258.9L11.4 258.9M386.6 258.9L398 258.9"/>
            <path fill="#BFBEC8"
              d="M370.3 293.7C370.3 297 346.1 299.6 316.3 299.6 286.5 299.6 262.3 296.9 262.3 293.7 262.3 290.4 286.5 287.8 316.3 287.8
              346.2 287.8 370.3 290.4 370.3 293.7zM164.1 288.3C164.1 290.8 144 292.8 119.3 292.8 94.6 292.8 74.5 290.8 74.5 288.3 74.5
              285.8 94.6 283.8 119.3 283.8 144 283.8 164.1 285.8 164.1 288.3z"/>
            <path fill="#FFF"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M267.4,258.9 L60.7,258.9 C59.7,258.9 58.8,258.1 58.8,257 L58.8,135.1 L269.3,135.1 L269.3,257 C269.3,258.1 268.5,258.9
              267.4,258.9 Z"/>
            <path fill="#F6F5F5"
              stroke="#494949"
              d="M269.3,135.1 L58.8,135.1 L58.8,107.5 C58.8,106.3 59.7,105.4 60.9,105.4 L267.2,105.4 C268.4,105.4 269.3,106.3 269.3,107.5
              L269.3,135.1 Z"/>
            <path fill="#FFF"
              stroke="#494949"
              d="M78.6 120.3C78.6 123 76.4 125.3 73.6 125.3 70.8 125.3 68.6 123.1 68.6 120.3 68.6 117.6 70.8 115.3 73.6 115.3 76.4
              115.3 78.6 117.5 78.6 120.3zM98.4 120.3C98.4 123 96.2 125.3 93.4 125.3 90.7 125.3 88.4 123.1 88.4 120.3 88.4 117.6 90.6
              115.3 93.4 115.3 96.2 115.3 98.4 117.5 98.4 120.3zM118.2 120.3C118.2 123 116 125.3 113.2 125.3 110.4 125.3 108.2 123.1
              108.2 120.3 108.2 117.6 110.4 115.3 113.2 115.3 116 115.3 118.2 117.5 118.2 120.3z"/>
            <g fill="#FFF" stroke="#494949" stroke-linecap="round" stroke-linejoin="round" transform="translate(82 235)">
              <path d="M37.9 52.9L2.7 17.7C2.5 17.5 2.5 17.2 2.7 17L7.4 12.3C7.6 12.1 7.9 12.1 8.1 12.3L43.3 47.5C43.5 47.7 43.5
                48 43.3 48.2L38.6 52.9C38.4 53.1 38.1 53.1 37.9 52.9zM74.8 52.9L39.6 17.7C39.4 17.5 39.4 17.2 39.6 17L44.3 12.3C44.5
                12.1 44.8 12.1 45 12.3L80.2 47.5C80.4 47.7 80.4 48 80.2 48.2L75.5 52.9C75.3 53.1 75 53.1 74.8 52.9z"/>
              <path d="M8.1,52.9 L43.3,17.7 C43.5,17.5 43.5,17.2 43.3,17 L38.6,12.3 C38.4,12.1 38.1,12.1 37.9,12.3
                L2.7,47.5 C2.5,47.7 2.5,48 2.7,48.2 L7.4,52.9 C7.6,53.1 7.9,53.1 8.1,52.9 Z"/>
              <path d="M45.4 53.3L38.5 53.3C38.3 53.3 38.1 53.1 38.1 52.9L38.1.5C38.1.3 38.3.1 38.5.1L45.4.1C45.6.1
                45.8.3 45.8.5L45.8 53C45.7 53.2 45.6 53.3 45.4 53.3zM7.5 53.3L.6 53.3C.4 53.3.2 53.1.2 52.9L.2.5C.2.3.4.1.6.1L7.5.1C7.7.1
                7.9.3 7.9.5L7.9 53C7.8 53.2 7.7 53.3 7.5 53.3z"/>
              <path d="M37.6,13.5 L8.3,13.5 C8,13.5 7.8,13.3 7.8,13 L7.8,6.4 C7.8,6.1 8,5.9 8.3,5.9 L37.6,5.9 C37.9,5.9 38.1,6.1
                38.1,6.4 L38.1,13 C38.1,13.3 37.9,13.5 37.6,13.5 Z"/>
            </g>
            <path fill="#FFF"
              stroke="#494949"
              d="M127.2 194L77.2 194C76.6 194 76.2 193.6 76.2 193L76.2 153.4C76.2 152.8 76.6 152.4 77.2 152.4L127.2 152.4C127.8 152.4
              128.2 152.8 128.2 153.4L128.2 193C128.2 193.6 127.7 194 127.2 194zM243.5 226.7L163.8 226.7C163.2 226.7 162.8 227.1 162.8
              227.7L162.8 258.9 244.5 258.9 244.5 227.7C244.5 227.2 244.1 226.7 243.5 226.7z"/>
            <rect width="81.7" height="12.4" x="162.8" y="203.9" fill="#FFF" stroke="#494949" stroke-linecap="round" stroke-linejoin="round"/>
            <polygon fill="#FFF" points="285.3 80.3 279.4 100.9 320.7 100.9 320.7 80.3"/>
            <path fill="#F6F5F5"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M369.8,80.3 L175.6,80.3 C175,80.3 174.6,79.9 174.6,79.3 L174.6,69.5 C174.6,68.9 175,68.5 175.6,68.5 L369.9,68.5 C370.5,68.5
              370.9,68.9 370.9,69.5 L370.9,79.3 C370.8,79.8 370.4,80.3 369.8,80.3 Z"/>
            <rect width="23.6" height="179.9" x="291.2" y="112.7" fill="#FFF" stroke="#494949"/>
            <path stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M179.5 68.5L303.3 37.6M356.1 68.5L303.3 37.6"/>
            <path fill="#FFF"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M281.9 112.7L318.2 112.7C319.6 112.7 320.7 111.6 320.7 110.2L320.7 100.9 279.4 100.9 279.4 110.2C279.4 111.6 280.5
              112.7 281.9 112.7zM209.5 136.7C209.5 140 206.9 142.6 203.6 142.6 200.3 142.6 197.7 140 197.7 136.7 197.7 133.4 200.3
              130.8 203.6 130.8 206.9 130.8 209.5 133.4 209.5 136.7z"/>
            <path fill="#F6F5F5"
              stroke="#494949"
              d="M291.2 168.7L314.8 192.3M314.8 168.7L291.2 192.3M291.2 195.2L314.8 218.8M314.8 195.2L291.2 218.8M291.2 219.5L314.8
              243.1M314.8 219.5L291.2 243.1M291.2 244.3L314.8 267.8M314.8 244.3L291.2 267.8M291.2 142.2L314.8 165.8M314.8 142.2L291.2
              165.8M291.2 115.6L314.8 139.2M314.8 115.6L291.2 139.2"/>
            <path fill="#F6F5F5"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M274.1 282.7L359.8 282.7C360.1 282.7 360.3 282.9 360.3 283.2L360.3 292.6 273.6 292.6 273.6 283.2C273.6 282.9 273.8
              282.7 274.1 282.7zM286.5 272.8L317.7 272.8C318 272.8 318.2 273 318.2 273.3L318.2 282.7 286 282.7 286 273.3C286 273
              286.2 272.8 286.5 272.8z"/>
            <rect width="24.8"
              height="9.9"
              x="330.515"
              y="272.795"
              fill="#FFF"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="rotate(-180 342.915 277.745)"/>
            <rect width="24.8"
              height="9.9"
              x="330.515"
              y="262.886"
              fill="#FFF"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="rotate(-180 342.915 267.836)"/>
            <rect width="24.8"
              height="9.9"
              x="330.515"
              y="252.976"
              fill="#FFF"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="rotate(-180 342.915 257.926)"/>
            <polygon
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              points="203.6 142.6 213.1 158 194.2 158"/>
            <rect
              width="19.5"
              height="21.5"
              x="163.155"
              y="174.819"
              fill="#FFF"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="rotate(-180 172.905 185.57)"/>
            <rect
              width="19.5"
              height="21.5"
              x="224.707"
              y="174.819"
              fill="#FFF"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="rotate(-180 234.457 185.57)"/>
            <rect
              width="19.5"
              height="21.5"
              x="193.981"
              y="158.138"
              fill="#FFF"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="rotate(-180 203.731 168.889)"/>
            <path
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M203.6,130.8 L203.6,91.4"/>
            <polygon fill="#BFBEC8" points="285.3 80.1 291.2 80.1 311.6 100.5 279.6 100.5"/>
            <polygon stroke="#494949" stroke-linecap="round" stroke-linejoin="round"
              points="285.3 80.3 279.4 100.9 320.7 100.9 320.7 80.3"/>
            <rect
              width="23.6"
              height="11.8"
              x="191.6"
              y="80.3"
              fill="#F6F5F5"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"/>
            <path
              fill="#F6F5F5"
              stroke="#494949"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M314.8,68.5 L291.2,68.5 L300.6,37.9 C301.3,35.6 304.6,35.6 305.3,37.9 L314.8,68.5 Z"/>
              <g fill="#E6004F" fill-rule="nonzero" transform="translate(87 163)">
              <path
                d="M4.2,2.4 L4.2,3 L2.8,3 L2.8,2.4 L4.2,2.4 Z M6.2,0.4 L0.8,0.4 L0.8,5 L6.2,5 L6.2,0.4 Z" transform="translate(26 15)"/>
                <path
                  d="M4.2,2.3 L4.2,12.3 L4.2,17.2 L7.6,13.7 L8.9,12.4 L8.9,13.8 L4.8,17.9 L2.6,17.9 L2.6,2.2 L4.2,2.2 L4.2,2.3 Z
                  M6.2,0.3 L0.7,0.3 L0.7,20 L5.7,20 L10.9,14.7 L10.9,7.5 L6.2,12.4 L6.2,0.3 Z"/>
                <path
                  d="M8.9,2.3 L8.9,18 L7.4,18 L7.4,7 L7.4,2.2 L8.9,2.2 L8.9,2.3 Z M7.2,2.3 L3.9,5.6 L2.6,6.9 L2.6,5.4 L5.6,2.3 L7.2,2.3
                  Z M10.9,0.3 L4.8,0.3 L0.6,4.6 L0.6,11.8 L5.3,7 L5.3,20 L10.8,20 L10.8,0.3 L10.9,0.3 Z"
                  transform="translate(13)"/>
              </g>
            </g>
          </svg>
        </div>
      </div>

      <h1>##headline##</h1>
      <p>##text##</p>

      <div style="margin-bottom: 40px;">
        <a href="https://www.facebook.com/plandotone" target="_blank" style="border: none; padding: 5px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 56 56">
            <path
              fill="#C3C3C3"
              fill-rule="evenodd"
              d="M52.8175437,1.97183099e-05 L3.07672676,1.97183099e-05 C1.38055775,1.97183099e-05 7.88732394e-05,1.38030141 7.88732394e-05,3.07607606
              L7.88732394e-05,52.8056535 C7.88732394e-05,54.5014282 1.38055775,55.8817099 3.07672676,55.8817099 L29.860107,55.8817099 L29.860107,34.2507239
              L22.5826704,34.2507239 L22.5826704,25.8112873 L29.860107,25.8112873 L29.860107,19.6000197 C29.860107,12.3831183 34.2781915,8.45917465
              40.7077408,8.45917465 C43.7843887,8.45917465 46.4469521,8.69579437 47.2163606,8.79438592 L47.2163606,16.3464986 L42.7393183,16.3464986
              C39.2284732,16.3464986 38.5580507,18.0028366 38.5580507,20.447907 L38.5580507,25.8310056 L46.9203887,25.8310056 L45.8356845,34.2704423
              L38.5775718,34.2704423 L38.5775718,55.9211465 L52.8175437,55.9211465 C54.5137127,55.9211465 55.8943887,54.5408648 55.8943887,52.8450901
              L55.8943887,3.07607606 C55.9139099,1.38030141 54.5332338,1.97183099e-05 52.8175437,1.97183099e-05"/>
          </svg>
        </a>
        <a href="https://www.instagram.com/plandotone/" target="_blank" style="border: none; padding: 5px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 56 56">
            <g fill="#C3C3C3" fill-rule="evenodd">
              <path d="M46.5178592,35.6503099 C46.4271549,37.6389014 46.1112676,38.9970986 45.6494648,40.1855211 C45.1720845,41.4139718
                44.5334085,42.4560845 43.4946479,43.4944507 C42.4558873,44.5332113 41.4137746,45.1720845 40.1853239,45.6494648 C38.9969014,
                46.1114648 37.6389014,46.4271549 35.6503099,46.5178592 C33.6573803,46.6087606 33.0212676,46.6304507 27.9471549,46.6304507
                C22.8732394,46.6304507 22.2369296,46.6087606 20.2441972,46.5178592 C18.2556056,46.4271549 16.8974085,46.1114648 15.7091831,
                45.6494648 C14.4805352,45.1720845 13.4384225,44.5332113 12.3998592,43.4944507 C11.3610986,42.4560845 10.7224225,41.4139718
                10.2450423,40.1855211 C9.78304225,38.9970986 9.46735211,37.6389014 9.37664789,35.6503099 C9.28574648,33.6575775 9.26405634,
                33.0212676 9.26405634,27.9473521 C9.26405634,22.8732394 9.28574648,22.2371268 9.37664789,20.2441972 C9.46735211,18.2556056
                9.78304225,16.8974085 10.2450423,15.7089859 C10.7224225,14.4805352 11.3610986,13.4384225 12.3998592,12.4000563 C13.4384225,
                11.3612958 14.4805352,10.7224225 15.7091831,10.2450423 C16.8974085,9.78304225 18.2556056,9.46735211 20.2441972,9.37664789
                C22.2369296,9.28574648 22.8732394,9.26425352 27.9471549,9.26425352 C33.0212676,9.26425352 33.6573803,9.28574648 35.6503099,
                9.37664789 C37.6389014,9.46735211 38.9969014,9.78304225 40.1853239,10.2450423 C41.4137746,10.7224225 42.4558873,11.3612958
                43.4946479,12.4000563 C44.5334085,13.4384225 45.1720845,14.4805352 45.6494648,15.7089859 C46.1112676,16.8974085 46.4271549,
                18.2556056 46.5178592,20.2441972 C46.6087606,22.2371268 46.6302535,22.8732394 46.6302535,27.9473521 C46.6302535,33.0212676
                46.6087606,33.6575775 46.5178592,35.6503099 M52.7989296,0 L3.07566197,0 C1.38008451,0 0,1.38008451 0,3.07585915 L0,52.7991268
                C0,54.494507 1.38008451,55.894507 3.07566197,55.894507 L52.7989296,55.894507 C54.5144225,55.894507 55.894507,54.5144225
                55.894507,52.7991268 L55.894507,3.07585915 C55.894507,1.38008451 54.5144225,0 52.7989296,0"/>
              <path
                d="M27.9177746,21.6901408 C24.4783099,21.6901408 21.6901408,24.4783099 21.6901408,27.9177746 C21.6901408,31.3572394 24.4783099,
                34.1454085 27.9177746,34.1454085 C31.3572394,34.1454085 34.1454085,31.3572394 34.1454085,27.9177746 C34.1454085,24.4783099
                31.3572394,21.6901408 27.9177746,21.6901408"/>
               <path d="M37.9096338,20.2053521 C36.6715211,20.2053521 35.667662,19.2016901 35.667662,17.9633803 C35.667662,16.7252676
               36.6715211,15.7214085 37.9096338,15.7214085 C39.1477465,15.7214085 40.1514085,16.7252676 40.1514085,17.9633803 C40.1514085,
               19.2016901 39.1477465,20.2053521 37.9096338,20.2053521 M27.936507,37.5304507 C22.638,37.5304507 18.3425634,33.2352113
               18.3425634,27.936507 C18.3425634,22.6378028 22.638,18.3425634 27.936507,18.3425634 C33.2350141,18.3425634 37.5304507,
               22.6378028 37.5304507,27.936507 C37.5304507,33.2352113 33.2350141,37.5304507 27.936507,37.5304507 M43.1442535,20.3869577
               C43.0612394,18.5653803 42.7567887,17.5761127 42.5010423,16.9177183 C42.1620845,16.0455775 41.7572676,15.4232676 41.1034085,
               14.7696056 C40.4497465,14.1157465 39.8274366,13.7107324 38.9552958,13.3719718 C38.2969014,13.1162254 37.3076338,12.8117746
               35.4860563,12.7285634 C33.516,12.6388451 32.9250423,12.6197183 27.936507,12.6197183 C22.9479718,12.6197183 22.3570141,12.6388451
               20.3869577,12.7285634 C18.5651831,12.8117746 17.5761127,13.1162254 16.9177183,13.3719718 C16.0455775,13.7107324 15.4232676,
               14.1157465 14.7694085,14.7696056 C14.1157465,15.4232676 13.7107324,16.0455775 13.3719718,16.9177183 C13.1162254,17.5761127
               12.8117746,18.5653803 12.7287606,20.3869577 C12.6386479,22.3570141 12.6197183,22.9477746 12.6197183,27.936507 C12.6197183,
               32.9252394 12.6386479,33.516 12.7287606,35.4860563 C12.8117746,37.3076338 13.1162254,38.2969014 13.3719718,38.9552958
               C13.7107324,39.8274366 14.1157465,40.4497465 14.7694085,41.1034085 C15.4232676,41.7572676 16.0455775,42.1622817 16.9177183,
               42.5010423 C17.5761127,42.7567887 18.5651831,43.0612394 20.3869577,43.1444507 C22.3568169,43.234169 22.9475775,43.2532958
               27.936507,43.2532958 C32.9254366,43.2532958 33.5161972,43.234169 35.4860563,43.1444507 C37.3076338,43.0612394 38.2969014,
               42.7567887 38.9552958,42.5010423 C39.8274366,42.1622817 40.4497465,41.7572676 41.1034085,41.1034085 C41.7572676,40.4497465
               42.1620845,39.8274366 42.5010423,38.9552958 C42.7567887,38.2969014 43.0612394,37.3076338 43.1442535,35.4860563 C43.2343662,
               33.516 43.2532958,32.9252394 43.2532958,27.936507 C43.2532958,22.9477746 43.2343662,22.3570141 43.1442535,20.3869577"/>
            </g>
          </svg>
        </a>
        <a href="https://linkedin.com/company/plan.one" target="_blank" style="border: none; padding: 5px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 56 56">
            <path
            fill="#C3C3C3"
            fill-rule="evenodd"
            d="M46.8182676,45.8006451 L37.8434789,45.8006451 L37.8434789,33.2359408 C37.8434789,31.2544479 36.2372254,29.6462225 34.2537606,
            29.6462225 C32.2702958,29.6462225 30.6638451,31.2544479 30.6638451,33.2359408 L30.6638451,45.8006451 L21.6892535,45.8006451
            C21.6892535,45.8006451 21.7951408,24.2615465 21.6892535,22.4663915 L30.6638451,22.4663915 L30.6638451,25.1319127 C30.6638451,
            25.1319127 33.4423521,22.5419127 37.7322676,22.5419127 C43.0489155,22.5419127 46.8182676,26.3903352 46.8182676,33.8572648
            L46.8182676,45.8006451 Z M13.5958732,18.8764761 L13.5418451,18.8764761 C10.8295915,18.8764761 9.07604225,16.7547859 9.07604225,
            14.423293 C9.07604225,12.0377718 10.8853944,10.0939408 13.6477324,10.0939408 C16.4138169,10.0939408 18.1137324,12.1006732
            18.169338,14.4859972 C18.1673662,16.8178845 16.4138169,18.8764761 13.5958732,18.8764761 Z M9.1245493,45.8006451 L18.099338,
            45.8006451 L18.099338,22.4663915 L9.1245493,22.4663915 L9.1245493,45.8006451 Z M3.07556338,0.000138028169 C1.37998592,0.000138028169
            -9.85915493e-05,1.38022254 -9.85915493e-05,3.0758 L-9.85915493e-05,52.7990676 C-9.85915493e-05,54.4946451 1.37998592,55.8944479
            3.07556338,55.8944479 L52.7990282,55.8944479 C54.5143239,55.8944479 55.8944085,54.5143634 55.8944085,52.7990676 L55.8944085,3.0758
            C55.8944085,1.38022254 54.5143239,0.000138028169 52.7990282,0.000138028169 L3.07556338,0.000138028169 Z"/>
          </svg>
        </a>
        <a href="https://twitter.com/plandotone" target="_blank" style="border: none; padding: 5px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 56 56">
            <path
              fill="#C3C3C3"
              fill-rule="evenodd"
              d="M42.9504817,20.1215887 C42.9660592,20.4595606 42.9711859,20.7973352 42.9711859,21.1404338 C42.9711859,31.5440113 35.0538901,
              43.5396451 20.5739465,43.5396451 C16.1264817,43.5396451 11.9909606,42.2366592 8.50515775,40.0035606 C12.6146507,40.4850817
              16.7917775,39.3849972 20.1614394,36.7449127 C16.7172423,36.6808282 13.8101718,34.4043493 12.8071014,31.2788 C13.9937493,
              31.5041803 15.2099746,31.4554761 16.3638901,31.1419549 C12.760369,30.4178986 10.0473268,27.2369408 10.0473268,23.4220394
              C10.0473268,23.3873352 10.0473268,23.3561803 10.0491014,23.3232507 C11.1093549,23.9124338 12.322031,24.2657859 13.6125944,
              24.3073915 C11.502538,22.8953634 10.1112141,20.4871662 10.1112141,17.7549972 C10.1112141,16.3120113 10.4994676,14.9587437
              11.1767915,13.7963493 C15.0593268,18.5606873 20.8614394,21.6949127 27.4031859,22.0222366 C27.2698901,21.4452789 27.2004817,
              20.8440676 27.2004817,20.2272789 C27.2004817,15.8823493 30.7243408,12.3549408 35.0730169,12.3549408 C37.3372704,12.3549408
              39.3832423,13.3130535 40.8179465,14.8412225 C42.6109324,14.4876732 44.2966507,13.8310535 45.8177211,12.9285465 C45.2305099,
              14.768462 45.1559746,15.4524901 43.5307915,16.4263775 C44.7903972,17.048293 45.9788197,17.0275887 47.3890732,16.3985746
              C46.3339465,17.9786028 44.4871296,19.0128282 42.9504817,20.1215887 M52.7989887,3.94366197e-05 L3.07552394,3.94366197e-05
              C1.38014366,3.94366197e-05 5.91549296e-05,1.38012394 5.91549296e-05,3.07589859 L5.91549296e-05,52.7991662 C5.91549296e-05,
              54.4945465 1.38014366,55.8945465 3.07552394,55.8945465 L52.7989887,55.8945465 C54.5144817,55.8945465 55.8945662,54.514462
              55.8945662,52.7991662 L55.8945662,3.07589859 C55.8945662,1.38012394 54.5144817,3.94366197e-05 52.7989887,3.94366197e-05"/>
          </svg>
        </a>
        <a href="https://www.xing.com/companies/plan.one" target="_blank" style="border: none; padding: 5px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 56 56">
            <path
              fill="#C3C3C3"
              fill-rule="evenodd"
              d="M40.7133014,47.7715099 L32.7678085,47.7715099 L24.092738,31.9199606 L37.2430761,8.41869296 L45.2871606,8.41869296
              L32.1171042,31.9199606 L40.7133014,47.7715099 Z M10.6069915,35.4885803 L17.3104282,23.6197352 L12.8154423,15.8319887
              L20.8593296,15.8319887 L25.3349915,23.5801014 L18.6315549,35.468862 L10.6069915,35.468862 L10.6069915,35.4885803 Z M52.7990479,
              -3.94366197e-05 L3.0755831,-3.94366197e-05 C1.38000563,-3.94366197e-05 -7.88732394e-05,1.38024225 -7.88732394e-05,3.07581972
              L-7.88732394e-05,52.7990873 C-7.88732394e-05,54.4946648 1.38000563,55.8944676 3.0755831,55.8944676 L52.7990479,55.8944676
              C54.5143437,55.8944676 55.8944282,54.5143831 55.8944282,52.7990873 L55.8944282,3.07581972 C55.8944282,1.38024225 54.5143437,
              -3.94366197e-05 52.7990479,-3.94366197e-05 Z"/>
          </svg>
        </a>
        <a href="https://www.youtube.com/channel/UCHonur3srcv12st5mif4tiA" target="_blank" style="border: none; padding: 5px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 56 56">
            <g fill="#C3C3C3" fill-rule="evenodd">
              <polygon points="23.411 34.528 34.979 27.947 23.411 21.366"/>
              <path
              d="M49.1436479,38.6700901 C48.6345211,40.586907 47.1347465,42.0963437 45.2303521,42.6088225 C41.7784648,43.5395268 27.9373944,
              43.5395268 27.9373944,43.5395268 C27.9373944,43.5395268 14.0963239,43.5395268 10.6446338,42.6088225 C8.74023944,42.0963437
              7.24026761,40.586907 6.73133803,38.6700901 C5.8065493,35.1959211 5.8065493,27.9472732 5.8065493,27.9472732 C5.8065493,27.9472732
              5.8065493,20.6986254 6.73133803,17.2244563 C7.24026761,15.3078366 8.74023944,13.7982028 10.6446338,13.2859211 C14.0963239,12.3550197
              27.9373944,12.3550197 27.9373944,12.3550197 C27.9373944,12.3550197 41.7784648,12.3550197 45.2303521,13.2859211 C47.1347465,13.7982028
              48.6345211,15.3078366 49.1436479,17.2244563 C50.0684366,20.6986254 50.0684366,27.9472732 50.0684366,27.9472732 C50.0684366,27.9472732
              50.0684366,35.1959211 49.1436479,38.6700901 M52.7990282,0.000118309859 L3.07556338,0.000118309859 C1.3801831,0.000118309859
              -9.85915493e-05,1.38020282 -9.85915493e-05,3.07578028 L-9.85915493e-05,52.7990479 C-9.85915493e-05,54.4946254 1.3801831,55.8946254
              3.07556338,55.8946254 L52.7990282,55.8946254 C54.5143239,55.8946254 55.8944085,54.5145408 55.8944085,52.7990479 L55.8944085,3.07578028
              C55.8944085,1.38020282 54.5143239,0.000118309859 52.7990282,0.000118309859"/>
            </g>
          </svg>
        </a>
      </div>

      <button class="button button--primary">
        <i class="icon">
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 48 48">
            <g fill="none"
               fill-rule="evenodd"
               stroke="currentColor"
               stroke-linecap="round"
               stroke-linejoin="round"
               transform="translate(1 4)">
              <polyline points="1.036 8.44 7.309 17.85 14.627 9.486"
                        transform="rotate(14 7.832 13.145)"
                        vector-effect="non-scaling-stroke"/>
              <path
                d="M6.5016886,16.3855369 C8.22063476,6.85284462 16.3740393,0.379521407 25.4024545,
                0.0454545455 C36.3650909,-0.353909091 45.5797273,8.20418182 45.9853636,19.1668182 C46.3868182,
                30.1315455 37.8287273,39.3440909 26.864,39.7497273 C18.6216364,40.0529091 11.3703636,
                35.2898182 8.10227273,28.2455455"
                vector-effect="non-scaling-stroke"/>
            </g>
          </svg>
        </i>
        <span>
            ##buttontext##
        </span>
      </button>
    </div>
    <div class="customErrorModal__hint"
         style="background: #f2f2f2;
          padding: 40px;">
      <p style="margin-bottom: 0;">
        ##contact##
      </p>
    </div>
  </div>
</div>
`;
