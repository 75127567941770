import * as supplierAnalyticsActions from '../../actions/analytics-company.actions';
import { Lead } from '../../model/leads/lead.interface';


export const leads = (state: Lead[], action: supplierAnalyticsActions.Actions): Lead[] => {
  switch (action.type) {
    case supplierAnalyticsActions.FETCH_LEADS_SUCCEEDED:
      return action.payload;
    case supplierAnalyticsActions.UPDATE_LEAD:
      const newState: Lead[] = state.filter(lead => lead.uuid !== action.payload.uuid);
      newState.push(action.payload);
      return newState;
    default:
      return state;
  }
};
