import { ProductComparisonBackendInterface } from './product-comparison.backend-interface';
import { ProductComparison } from './product-comparison';


/**
 * Generates a ProductComparison based on backend api input
 *
 * @param input
 * @returns
 */
export const generateProductComparisonFromBackendInput = (input: ProductComparisonBackendInterface): ProductComparison => {
  const productComparison: ProductComparison = {
    uuid: input.Uuid,
    productUuids: input.ProductUuids,
    name: input.Name,
    supplier: input.Supplier,
    category: input.Category
  };

  if (input.PreviousComparisonUuid) {
    productComparison.previousComparisonUuid = input.PreviousComparisonUuid;
  }

  if (input.QueryUuid) {
    productComparison.queryUuid = input.QueryUuid;
  }

  return productComparison;
};
