import { generateTreeCategoryFromBackendInput } from '@p1/libs/catalog-service';

import { CategorySearchResult } from './category-search-result';
import { CategorySearchResultBackendInterface } from './category-search-result.backend-interface';
import { generateSearchResultFromBackendInput } from '../search-result';
import { generateProductFromBackendInput } from '../../../product/model/product.generators';


export const generateCategorySearchResultFromBackendInput = (input: CategorySearchResultBackendInterface[], urlTarget: string): CategorySearchResult[] => {
  const categorySearchResult: CategorySearchResult[] =
          input.map(category => {
            const generatedCategory = generateTreeCategoryFromBackendInput(category);
            const result: CategorySearchResult = {
              ...generateSearchResultFromBackendInput(category, `${urlTarget}/${generatedCategory.pathedSlug}`),
              ...generatedCategory
            };

            if (category.Products) {
              result.products = category.Products.map(generateProductFromBackendInput);
            }
            return result;
          });
  return categorySearchResult;
};
