import * as productActions from '../../../actions/product.actions';


/**
 * fetchFilterError reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const fetchFilterError = (state: any, action: productActions.Actions): any => {
  switch (action.type) {
    case productActions.FETCH_FILTER_FAILED:
      return action.payload;
    case productActions.FETCH_FILTER:
      return undefined;
    default:
      return state;
  }
};
