import { Cluster } from './cluster';
import { ClusterBackendInterface } from './cluster.backend-interface';


/**
 * Generates a filter based on backend api input
 *
 * @param input
 * @returns
 */
export const generateClusterFromBackendInput = (input: ClusterBackendInterface): Cluster => {
  const cluster: Cluster = {
    displayName: input.displayName,
    uuid: input.uuid
  };

  if (input.icon) {
    cluster.icon = input.icon;
  }

  if (input.description) {
    cluster.description = input.description;
  }

  return cluster;
};
