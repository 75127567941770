import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';


@Injectable()
export class InDevelopGuard implements CanActivate {

  constructor(
    private _router: Router
  ) {}


  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (environment.environmentName !== 'prod') {
      return true;
    }

    this._router.navigate(['/']);
    return false;
  }
}
