import { News } from '../../model/news';
import * as newsActions from '../../actions/news.actions';


export const items = (state: News[], action: newsActions.Actions) => {
  switch (action.type) {
    case newsActions.fetchNewsSucceeded.type:
      return action.news;
    default:
      return state;
  }
};
