import {
  Injectable,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  Observable,
  Subject
} from 'rxjs';

import {
  LoggerServiceInterface,
  LOGGING_SERVICE
} from '@p1/libs/logging';
import { WindowRef } from '@p1/libs/browser-api-wrapper';

import {
  EMBED_MESSAGING_CONFIG,
  EmbedMessagingConfigInterface
} from './config/embed-messaging-config.interface';


@Injectable({
  providedIn: 'root'
})
export class EmbedMessagingService {

  get messages$(): Observable<Record<string, unknown>> {
    return this._incomingMessages.asObservable();
  }

  private _incomingMessages: Subject<Record<string, unknown>> = new Subject<Record<string, unknown>>();

  constructor(
    private _windowRef: WindowRef,
    @Inject(LOGGING_SERVICE) private _loggerService: LoggerServiceInterface,
    @Inject(PLATFORM_ID) private _platformId: Record<string, unknown>,
    @Inject(EMBED_MESSAGING_CONFIG) private _config: EmbedMessagingConfigInterface
  ) {
    if (!this._config) {
      throw new Error('EMBED_MESSAGING_CONFIG missing');
    }

    if (isPlatformBrowser(this._platformId)) {
      this._windowRef.nativeWindow.addEventListener('message', message => this._receiveMessage(message));
    }
  }

  postMessage(message: Record<string, unknown>) {
    if (!isPlatformBrowser(this._platformId)) {
      throw new Error('EmbedMessaging only supported in browser environment');
    }

    if (this._windowRef.nativeWindow.parent) {
      const parentOrigin = this._windowRef.nativeWindow.document.location.ancestorOrigins.item(0);
      const usedOrigin = this._config.parentOrigins.find(origin => origin === parentOrigin);
      if (usedOrigin) {
        this._windowRef.nativeWindow.parent.postMessage(message, usedOrigin);
      } else {
        this._loggerService.error('ParentOrigin of embed not found or not allowed. ParentOrigin:', parentOrigin);
      }
    }
  }

  private _receiveMessage(event) {
    if (event && this._config && this._config.parentOrigins.includes(event.origin)) {
      this._incomingMessages.next(event.data);
    }
  }
}
