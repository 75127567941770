import * as keycloakActions from '../../action/keycloak.actions';


/**
 * Sets the AccessToken string value
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const keycloakAccessToken = (state: string, action: keycloakActions.Actions): string => {
  switch (action.type) {
    case keycloakActions.KEYCLOAK_LOGIN_SUCCEEDED:
      return action.payload.bearerToken;
    case keycloakActions.KEYCLOAK_TOKEN_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
