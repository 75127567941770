import { Injectable } from '@angular/core';
import {
  Observable,
  of
} from 'rxjs';

import { environment } from '../../../environments/environment';
import { deepCopy } from '../../shared/common/helper/deep-copy/deep-copy';
import { NavigationSection } from './navigation-section.interface';


@Injectable({
  providedIn: 'root'
})
export class AppNavigationBarItemsService {

  appNavigationSection$: Observable<NavigationSection>;

  private _suppliersNavItem = {
    title: $localize`:|suppliers navigation item @@navigation-bar.component_suppliers-nav-item:Suppliers`,
    routerLink: ['/suppliers'],
    name: 'suppliers'
  };

  private _appSectionDefinition = {
    title: $localize`:|app navigation item @@navigation-bar.component_app-item:App`,
    firstLevelItems: []
  };

  constructor() {

    const appNavigationSection: NavigationSection = deepCopy(this._appSectionDefinition);

    if (environment.featureFlags.supplierList) {
      appNavigationSection.firstLevelItems.push(this._suppliersNavItem);
    }

    this.appNavigationSection$ = of(appNavigationSection);
  }
}
