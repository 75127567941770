export const searchClusterNames = {
  products: $localize`:|search cluster names products @@search-cluster-names.constant_names-products:Products`,
  suppliers: $localize`:|search cluster names suppliers @@search-cluster-names.constant_names-suppliers:Suppliers`,
  categories: $localize`:|search cluster names categories @@search-cluster-names.constant_names-categories:Categories`,
  all: $localize`:|search cluster names all @@search-cluster-names.constant_names-all:All`
};

export const searchBarPlaceholderDefault = $localize`:|search cluster placeholder default @@search-cluster-names.constant_placeholder-default:Search for products, properties, suppliers and categories`;

export const searchBarPlaceholderAlternatives = [
  $localize`:|search cluster placeholder search in products @@search-cluster-names.constant_placeholder-search-in-products:Search in Products`,
  $localize`:|search cluster placeholder search in suppliers @@search-cluster-names.constant_placeholder-search-in-suppliers:Search in Suppliers`,
  $localize`:|search cluster placeholder search in categories @@search-cluster-names.constant_placeholder-search-in-categories:Search in Categories`,
  $localize`:|search cluster placeholder search in properties @@search-cluster-names.constant_placeholder-search-in-properties:Search in Properties`,
  $localize`:|search cluster placeholder search window rc3 @@search-cluster-names.constant_placeholder-search-window-rc3:Search "Window RC 3"`,
  $localize`:|search cluster placeholder search window rc3 @@search-cluster-names.constant_placeholder-search-window-rc3:Search "Window RC 3"`,
  $localize`:|search cluster placeholder search parquet dark oak @@search-cluster-names.constant_placeholder-search-parquet-dark-oak:Search "Parquet dark oak"`,
  $localize`:|search cluster placeholder search brick flat matt black @@search-cluster-names.constant_placeholder-search-brick-flat-matt-black:Search "brick flat matt black"`,
  $localize`:|search cluster placeholder search wc flush floor standing @@search-cluster-names.constant_placeholder-search-wc-flush-floor-standing:Search "WC flush floor standing"`,
  $localize`:|search cluster placeholder search facade trespa @@search-cluster-names.constant_placeholder-search-facade-trespa:Search "Facade Trespa"`
];
