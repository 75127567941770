import {
  Directive,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';

import { ButtonType } from '@p1/libs/ui-elements/src/lib/directives/button/button.type';


@Directive({
  selector: '[p1Button]'
})
export class ButtonDirective implements OnInit {

  // e.g. 'primary', 'secondary', 'twitter', ...
  @Input() buttonType: ButtonType | ButtonType[] = 'primary';

  constructor(private _el: ElementRef) {}

  ngOnInit(): void {
    this._el.nativeElement.classList.add('button');

    if (Array.isArray(this.buttonType)) {
      this.buttonType.forEach(item => {
        this._el.nativeElement.classList.add(`button--${ item }`);
      });
    } else if (this.buttonType) {
      this._el.nativeElement.classList.add(`button--${ this.buttonType }`);
    }
  }
}
