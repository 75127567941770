import {
  Inject,
  Injectable,
  LOCALE_ID
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take
} from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {
  AllClusterSearchResult,
  AllClusterSearchResultBackendInterface,
  generateAllClusterSearchResultFromBackendInput
} from '../model-result';
import { SearchCluster } from '../search-cluster.enum';
import {
  select,
  Store
} from '@ngrx/store';
import * as categoryTreeReducer from '../../category-tree/reducer';


@Injectable()
export class SearchService {

  private _treeUuid$: Observable<string>;

  constructor(private _httpClient: HttpClient, @Inject(LOCALE_ID) private _locale: string, private _store: Store<categoryTreeReducer.State>) {
    this._treeUuid$ = this._store.pipe(
      select(categoryTreeReducer.getCategoryTreeUuid)
    );
  }

  fetchDetailSearchResults(searchParams: {searchTerm: string; limit: number; type: SearchCluster}) {
    let requestUrl = `${ environment.api_url }search?locale=${ this._locale }`;

    const postBody = {
      searchString: searchParams.searchTerm,
      searchType: [searchParams.type],
      limit: searchParams.limit,
      offset: 0,
      fullSearch: true
    };

    return this._waitForTreeUuid().pipe(
      switchMap(treeUuid => {
        if (treeUuid) {
          requestUrl += `&treeUuid=${ treeUuid }`;
        }

        return this._httpClient.post<AllClusterSearchResultBackendInterface>(
          requestUrl,
          postBody
        ).pipe(
          map(generateAllClusterSearchResultFromBackendInput)
        );
      })
    );
  }

  fetchSearchResults(
    searchParams: {
      searchTerm: string;
      fullSearch: boolean;
      itemsPerPage?: number;
      page?: number;
      supplierUuids?: string[];
      categoryUuids?: string[];
      analyticsCall?: boolean;
    }): Observable<AllClusterSearchResult> {

    const { searchTerm, itemsPerPage, page, supplierUuids, categoryUuids, analyticsCall, fullSearch } = searchParams;
    let requestUrl = `${ environment.api_url }search?locale=${ this._locale }`;

    const postBody = {
      searchString: searchTerm,
      searchType: [SearchCluster.all],
      limit: itemsPerPage,
      offset: this._getOffsetByPage(page, itemsPerPage),
      fullSearch
    };

    if (supplierUuids) {
      postBody['supplierUuids'] = supplierUuids;
    }

    if (categoryUuids) {
      postBody['categoryUuids'] = categoryUuids;
    }

    if (analyticsCall) {
      postBody['analyticsCall'] = analyticsCall;
    }

    return this._waitForTreeUuid().pipe(
      switchMap(treeUuid => {
        if (treeUuid) {
          requestUrl += `&treeUuid=${ treeUuid }`;
        }
        return this._httpClient.post<AllClusterSearchResultBackendInterface>(
          requestUrl,
          postBody,
          { observe: 'response' }
        ).pipe(
          map(response => {
            let allClusterSearchResult: AllClusterSearchResult = {};

            if (response.ok && response.status === 200) {
              const body = response.body;

              allClusterSearchResult = {
                ...generateAllClusterSearchResultFromBackendInput(body),
                searchTerm,
                searchCluster: [SearchCluster.all]
              };

              allClusterSearchResult.pagination = {
                page: this._getPageByOffset(postBody.offset, itemsPerPage),
                pageCount: this._getPageCount(parseInt(response.headers.get('x-pagination-count'), 10), itemsPerPage),
                itemsPerPage
              };
            }

            return allClusterSearchResult;
          })
        );
      })
    );
  }

  fetchSearchBarSearchResults(
    searchParams: {
      searchTerm: string;
      supplierUuids?: string[];
      categoryUuids?: string[];
      analyticsCall?: boolean;
    }): Observable<AllClusterSearchResult> {

    const { searchTerm, supplierUuids, categoryUuids, analyticsCall } = searchParams;
    let requestUrl = `${ environment.api_url }search?locale=${ this._locale }`;

    const postBody = {
      searchString: searchTerm,
      searchType: [SearchCluster.all],
      limit: 24
    };

    if (supplierUuids) {
      postBody['supplierUuids'] = supplierUuids;
    }
    if (categoryUuids) {
      postBody['categoryUuids'] = categoryUuids;
    }
    if (analyticsCall) {
      postBody['analyticsCall'] = analyticsCall;
    }

    return this._waitForTreeUuid().pipe(
      switchMap(treeUuid => {
        if (treeUuid) {
          requestUrl += `&treeUuid=${ treeUuid }`;
        }
        return this._httpClient.post<AllClusterSearchResultBackendInterface>(
          requestUrl,
          postBody
        ).pipe(
          map(body => generateAllClusterSearchResultFromBackendInput(body))
        );
      })
    );
  }

  private _getOffsetByPage(page, itemsPerPage) {
    return parseInt(page, 10) === 1 ? 0 : (page * itemsPerPage) - (itemsPerPage + 1);
  }

  private _getPageByOffset(offset, itemsPerPage) {
    return Math.ceil((offset / itemsPerPage || 0) + 1);
  }

  private _getPageCount(totalItems, itemsPerPage) {
    return Math.ceil(totalItems / itemsPerPage);
  }

  private _waitForTreeUuid() {
    return this._treeUuid$.pipe(
      filter(treeUuid => !environment.featureFlags.catalogConfig || !!treeUuid),
      take(1)
    );
  }
}
