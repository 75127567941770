<div class="category-menu" *ngIf="(categoryTree$ | async) as categoryTree"
     [style.left]="positionLeft + 'px'"
     [style.top]="positionTop + 'px'">
  <ul class="cm__list cm__list--root">
    <li *ngFor="let category of categoryTree"
        class="cm__item"
        [ngClass]="{
          'cm__item--has-children': (category.childNodes),
          'cm__item--active': (selectedCategory == category),
          'cm__item--hidden': (selectedCategory != category && selectedCategory != null),
          'cm__item--selected': (categoryRootLineUuids$ | async)?.includes(category.uuid)
         }">
      <p1-category-tree-link
        [category]="category"
        [activeChild]="childIsActive"
        [breakpoint]="breakpoint$ | async"
        (categorySelected)="setActiveCategory($event)">
      </p1-category-tree-link>
      <ul *ngIf="category.childNodes"
          class="cm__list cm__list--sub"
          [categoryTree]="category.childNodes"
          [selecedCategoryUuids]="categoryRootLineUuids$ | async"
          [breakpoint]="breakpoint$ | async"
          (subCategorySelected)="selectCategory($event)"
          p1-category-tree-sublist>
        <li class="cm__item"
            [ngClass]="{
              'cm__item--hidden': (selectedCategory != category && selectedCategory != null)
            }">
          <p1-category-tree-link
            [category]="category"
            [activeChild]="childIsActive"
            [breakpoint]="breakpoint$ | async"
            [showAll]="true">
          </p1-category-tree-link>
        </li>
      </ul>
    </li>
  </ul>
</div>
