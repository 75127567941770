import { Injectable } from '@angular/core';


/**
 * Provides a reference to the native window object and makes it mockable for tests
 * Naming and structure inspired by native angular methods (elementRef.nativeElement etc.)
 */
@Injectable({
  providedIn: 'root'
})
export class WindowRef {

  private _window: Window;

  /**
   * Returns the native window object if it exists
   *
   * @returns
   */
  get nativeWindow(): Window {
    return this._window;
  }


  constructor() {
    this._window = window;
  }

}
