import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  IconsModule,
  IconsRegistry,
  iconTechnicalProperties
} from '@p1/libs/icons';

import { SharedModule } from '../shared/shared.module';
import { QaStateMenuComponent } from './qa-state-menu/qa-state-menu.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    IconsModule
  ],
  declarations: [
    QaStateMenuComponent
  ],
  exports: [
    QaStateMenuComponent
  ]
})
export class QaStateModule {
  constructor(private _iconsRegistry: IconsRegistry) {
    this._iconsRegistry.registerIcons([
      iconTechnicalProperties
    ]);
  }
}
