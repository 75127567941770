import {
  Inject,
  Injectable,
  LOCALE_ID
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
  APP_BASE_HREF,
  DOCUMENT
} from '@angular/common';
import {
  select,
  Store
} from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import * as catalogConfigReducer from '../../catalog-config/reducers';


export const baseUrls = {
  'en-GB': environment.urlScheme + 'en.' + environment.host,
  'de-DE': environment.urlScheme + environment.host
};

@Injectable({
  providedIn: 'root'
})

/**
 * Class represents the service for all seo-relevant information who are important for a search optimized page,
 * eg. page title, page description, page keywords.
 */
export class SeoService {
  catalogHosts = [];
  private _metaService: Meta;

  /**
   * Create the seoService.
   *
   * @constructor
   * @param meta - Object for setting Meta tags
   * @param _document
   * @param _locale
   * @param _store
   * @param _baseHref
   */
  constructor(
    meta: Meta,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(LOCALE_ID) private _locale: string,
    private _store: Store<catalogConfigReducer.State>,
    @Inject(APP_BASE_HREF) private _baseHref: string
  ) {
    this._metaService = meta;

    this._store.pipe(
      select(catalogConfigReducer.getCatalogConfigHosts),
      filter(catalogHosts => !!catalogHosts)
    ).subscribe(catalogHosts => {
      catalogHosts.forEach(catalogHost => {
        this.catalogHosts[catalogHost.locale] = environment.urlScheme + catalogHost.domain + this._baseHref.slice(0, -1);
      });
    });
  }

  /**
   * Set the canonical tag with default or custom url (<link rel="canonical" href="xyz"/>).
   *
   * @param path
   */
  setCanonicalPath(path?: string) {
    if (path && path !== '') {
      const canonicalTag = this._getCanonicalTag();
      const newCanonicalUrl = environment.featureFlags.catalogConfig
        ? this.catalogHosts[this._locale] + '/' + path
        : baseUrls[this._locale] + path;
      const canonicalUrlEqualsNewUrl = canonicalTag && canonicalTag.getAttribute('href') === newCanonicalUrl;
      if (!canonicalUrlEqualsNewUrl) {
        if (canonicalTag) {
          canonicalTag.setAttribute('href', newCanonicalUrl);
        } else {
          const link = this.createLinkTagWithAttributes('canonical', newCanonicalUrl, '');
          this._document.head.appendChild(link);
        }
      }
    } else {
      this._removeCanonicalTag();
    }
  }

  /**
   * Set the canonical tag with default or custom url (<link rel="canonical" href="xyz"/>).
   *
   * @param path
   */
  setAlternateTags(path?: string) {
    if (path && path !== '') {
      const alternateTags = this._getAlternateTag();

      const deLocale = 'de-DE';
      const enLocale = 'en-GB';
      const deUrl = environment.featureFlags.catalogConfig ? this.catalogHosts['de-DE'] + '/' + path : baseUrls['de-DE'] + path;
      const enUrl = environment.featureFlags.catalogConfig ? this.catalogHosts['en-GB'] + '/' + path : baseUrls['en-GB'] + path;

      if (alternateTags.length === 0) {

        const linkDe = this.createLinkTagWithAttributes('alternate', deUrl, deLocale);
        this._document.head.appendChild(linkDe);

        const linkEn = this.createLinkTagWithAttributes('alternate', enUrl, enLocale);
        this._document.head.appendChild(linkEn);
      } else {
        alternateTags.forEach(alternateTag => {
          if (alternateTag.getAttribute('hreflang') === deLocale) {
            const currentDeUrl: string = alternateTag.getAttribute('href');
            if (currentDeUrl !== deUrl) {
              alternateTag.setAttribute('href', deUrl);
            }
          }
          if (alternateTag.getAttribute('hreflang') === enLocale) {
            const currentEnUrl: string = alternateTag.getAttribute('href');
            if (currentEnUrl !== enUrl) {
              alternateTag.setAttribute('href', enUrl);
            }
          }
        });
      }
    } else {
      this._removeAlternateTag();
    }
  }

  setSeoPath(path?: string) {
    this.setCanonicalPath(path);
    this.setAlternateTags(path);
  }

  /**
   * Set Meta descriptions with custom string.
   *
   * @param description
   */
  setMetaDescription(description?: string) {
    if (description && description !== '') {
      this._metaService.updateTag({
        name: 'description',
        content: description
      });
    } else {
      this._removeMetaDescription();
    }
  }

  createLinkTagWithAttributes(rel: string, url: string, lang?: string): HTMLLinkElement {
    const link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', rel);
    if (lang !== '') {
      link.setAttribute('hreflang', lang);
    }
    link.setAttribute('href', url);

    return link;
  }

  /**
   * Set Open Graph Image with custom URL
   *
   * @param url
   */
  setOgImage(url?: string) {
    if (url && url !== '') {
      this._metaService.updateTag({
        property: 'og:image',
        content: url
      });
    } else {
      this._removeOgImage();
    }
  }

  private _getCanonicalTag() {
    return this._document.head.querySelector('link[rel=canonical]');
  }

  private _removeCanonicalTag() {
    const tag = this._getCanonicalTag();
    if (tag) {
      this._document.head.removeChild(tag);
    }
  }

  private _getAlternateTag() {
    return this._document.head.querySelectorAll('link[rel=alternate]');
  }

  private _removeAlternateTag() {
    const tags = this._getAlternateTag();
    if (tags.length > 0) {
      tags.forEach(tag => {
        this._document.head.removeChild(tag);
      });
    }
  }

  private _getMetaDescriptionTag() {
    return this._document.head.querySelector('meta[name=description]');
  }

  private _removeMetaDescription() {
    const tag = this._getMetaDescriptionTag();
    if (tag) {
      this._document.head.removeChild(tag);
    }
  }

  private _getOgImageTag() {
    return this._document.head.querySelector('meta[property=og\\:image]');
  }

  private _removeOgImage() {
    const tag = this._getOgImageTag();
    if (tag) {
      this._document.head.removeChild(tag);
    }
  }
}
