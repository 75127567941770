import {
  Inject,
  Injectable,
  LOCALE_ID
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  filter,
  map,
  switchMap,
  take
} from 'rxjs/operators';
import {
  forkJoin,
  Observable
} from 'rxjs';

import { generateProductFromBackendInput } from '../model/product.generators';
import {
  Product,
  ProductBackendInterface
} from '../model';
import { environment } from '../../../environments/environment';
import { BaseEntityService } from '../../abstract/base-entity/base-entity.service';
import {
  generateProductScoreFromBackendInput,
  ProductScore
} from '../model-score';
import { ProductGeneratorService } from './product.generator-service';
import { Store } from '@ngrx/store';
import * as categoryTreeReducer from '../../category-tree/reducer';


@Injectable()
export class ProductService extends BaseEntityService<ProductBackendInterface, Product> {

  constructor(
    httpClient: HttpClient,
    productGeneratorService: ProductGeneratorService,
    @Inject(LOCALE_ID) locale: string,
    store: Store<categoryTreeReducer.State>
  ) {
    super(
      httpClient,
      'products',
      productGeneratorService,
      locale,
      store
    );
  }


  /**
   * Returns the score for the given item uuid and query uuid
   *
   * @param itemUuid
   * @param queryUuid
   * @returns
   */
  getItemScore(itemUuid: string, queryUuid: string): Observable<ProductScore> {
    return this._httpClient.get(`${ environment.api_url }products/${ itemUuid }/score?query_uuid=${ queryUuid }`).pipe(
      map(body => body && body['Score'] ? generateProductScoreFromBackendInput(body['Score']) : undefined));
  }


  /**
   * Returns similar products
   *
   * @param uuid
   * @param loadWithDetail
   * @param limit
   * @param offset
   * @param treeCategoryUuid
   */
  getSimilarProducts(uuid: string,
                     loadWithDetail: boolean = false,
                     limit: number = 6,
                     offset: number = 0,
                     treeCategoryUuid: string = null): Observable<Product[]> {
    return this._treeUuid$.pipe(
      filter(treeUuid => !environment.featureFlags.catalogConfig || !!treeUuid),
      take(1),
      switchMap(treeUuid => this._httpClient.get<ProductBackendInterface[]>(
        `${ environment.api_url }${ this._entity }/${ uuid }/similarProducts?limit=${ limit }&offset=${ offset }&viewAsDetail=${ loadWithDetail }`
          + (treeCategoryUuid ? `&treeCategory=${ treeCategoryUuid }` : '')
          + (treeUuid ? `&treeUuid=${ treeUuid }` : '')  + `&locale=${ this._locale }`
      ).pipe(
        map(response => Array.isArray(response) ? response.map(generateProductFromBackendInput) : [])
      ))
    );
  }

  /**
   * Returns complementary products
   *
   * @param productUuid
   */
  getComplementaryProducts(productUuid: string): Observable<Product[]> {
    const productToComplementaryProductMapping = {
      d454f441ee914f8888c1c2d8ae98e1af: [ // Mosecker - Optima S, Wand-Tiefspül-WC, spülrandlos
        '422a3affff184245b549f9e4cc1067f3',
        'b57a8dcf414d4c249a14771f3a5e9f38',
        '465fbf44a95e4e32b1fbcef1d0d0cb49',
        'ad5085e4547944629af75e22710180e8',
        '922213fb61494041ba3bd4b9e450bb8a',
        'c2919ba7210d4530be2f7d89c17d422f'
      ],
      '98612e51a1b74c7296c72ed4f54e9fea': [ // Schüco – AD UP 75
        'a393428b2dc94e1db4f5a79279c66f07',
        '9ce244f9b801496eaded30f89ba21384',
        '2f69faafc1ed438ab20fb1e185036de7',
        '68dd7225add44e9f8161932aa67a9832',
        '880c22fce5174517a52abcd7eb20f51b',
        'e427c82e3ba84278bd16988a2234e696'
      ],
      '6cca9e31bfaf4178ad7bd96d7bc1f31b': [ // Schüco - AWS 90 AC.SI
        '01074cfaa5104c73abc8a5d58f675523',
        '3453cca61fc0442c975569473d5a71d5',
        '9cc05d384b174701b29f94c201624eff',
        '75093fcbcd8449edb91020bbf485d301',
        '6b1b657f020e4fce867efb44fdab5b33',
        '44a9400d9bd24b13b0ccb6346db6483c'
      ],
      '9bd65ff5821e4543bcd747d7808e712f': [ // Knauf - W115.de - Metall-Doppelständerwand, W115-50Y6-10
        '6a669e73e22a4ebe8735e0488698787b',
        '2950bcb5427e42be9a911a259fc193e3',
        'ded399292d474b44a1d06a9e9c0b79c3',
        'c2405b6775c144ab8bd37ab8b90cbb18',
        'd2b78072d95a47f99dcc70c63743d7b8',
        '729b11411e05489f8b671b8f50bff1e9'
      ]
    };
    const observables = [];
    if (productToComplementaryProductMapping.hasOwnProperty(productUuid)) {
      productToComplementaryProductMapping[productUuid].forEach(complementaryProductUuid => {
        observables.push(
          this.getSingle(complementaryProductUuid)
        );
      }
      );
    }

    return forkJoin(observables as Observable<Product>[]);
  }
}
