import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
  select,
  Store
} from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  filter,
  takeUntil
} from 'rxjs/operators';

import * as rootReducer from '../../../../app.reducers';
import * as routerActions from '../../../../routing/actions/router.actions';
import { routeTitle } from '../../../../routing/routes/route-title-names.constant';


/**
 * Component represents our 404 not found page
 */
@Component({
  selector: 'p1-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit, OnDestroy {
  headline: string;
  copytext: string;
  buttonLabel: string;
  buttonLink: string[];

  private _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private _meta: Meta,
    private _store: Store<rootReducer.StateInterface>
  ) {}

  ngOnInit() {
    this._store.pipe(
      select(rootReducer.getRouterUrl),
      takeUntil(this._unsubscribe),
      filter(urlPath => !!urlPath)
    ).subscribe(urlPath => {
      const pathSegments = urlPath.split('/');

      switch (pathSegments[1]) {
        case 'products':
          this.headline = $localize`:|product not found headline // 404 page @@not-found-product.component_headline:404 <br> That went wrong`;
          this.copytext = $localize`:|product not found paragraph // 404 page @@not-found-product.component_explanation_text:The product you are looking for does not exist or has been removed.`;
          this.buttonLabel = $localize`:|back to the landing page button text // 404 page @@not-found-product.component_home_button_text:Take me home!`;
          this.buttonLink = ['/'];
          this._store.dispatch(new routerActions.SetTitleAction(routeTitle.notFoundProduct));
          break;
        case 'product-validation':
          this.headline = $localize`:|validation not found headline // 404 page @@not-found-validation.component_headline:404 <br> That went wrong`;
          this.copytext = $localize`:|validation not found paragraph // 404 page @@not-found-validation.component_explanation_text:The validation you are looking for does not exist or has been removed.`;
          this.buttonLabel = $localize`:|back to home button text // 404 page @@not-found-validation.component_home_button_text:Take me home!`;
          this.buttonLink = ['/'];
          this._store.dispatch(new routerActions.SetTitleAction(routeTitle.notFoundValidation));
          break;
        case 'suppliers':
          this.headline = $localize`:|supplier not found headline // 404 page @@not-found-supplier.component_headline:404 <br> That went wrong`;
          this.copytext = $localize`:|supplier not found paragraph // 404 page @@not-found-supplier.component_explanation_text:The supplier you are looking for does not exist or has been removed.`;
          this.buttonLabel = $localize`:|back to supplier list button text // 404 page @@not-found-supplier.component_supplier_list_button_text:Take me to supplier list!`;
          this.buttonLink = ['/suppliers'];
          this._store.dispatch(new routerActions.SetTitleAction(routeTitle.notFoundSupplier));
          break;
        case 'myWatchlists':
          this.headline = $localize`:|watchlist not found headline // 404 page @@not-found-watchlist.component_headline:404 <br> That went wrong`;
          this.copytext = $localize`:|watchlist not found paragraph // 404 page @@not-found-watchlist.component_explanation_text:The watchlist you are looking for does not exist or has been removed.`;
          this.buttonLabel = $localize`:|back to watchlist list button text // 404 page @@not-found-watchlist.component_watchlist_list_button_text:Take me to my watchlists overview!`;
          this.buttonLink = ['/myWatchlists'];
          this._store.dispatch(new routerActions.SetTitleAction(routeTitle.notFoundWatchlist));
          break;
        default:
          this.headline = $localize`:|page not found headline // 404 page @@not-found-page.component_headline:404 <br> That went wrong`;
          this.copytext = $localize`:|page not found paragraph // 404 page @@not-found-page.component_explanation_text:The page you are looking for does not exist or has been removed.`;
          this.buttonLabel = $localize`:|back to the landing page button text // 404 page @@not-found-page.component_home_button_text:Take me home!`;
          this.buttonLink = ['/'];
          this._store.dispatch(new routerActions.SetTitleAction(routeTitle.pageNotFound));
          break;
      }
    });

    this._meta.addTag({
      name: 'robots',
      content: 'noindex, nofollow'
    });
  }

  ngOnDestroy() {
    this._meta.removeTag('name=robots');

    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
