import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Productspecification,
  PRODUCTSPECIFICATION_CONFIG,
  ProductspecificationBackendFormData,
  ProductspecificationBackendInterface,
  ProductspecificationConfigInterface,
  ProductspecificationFormData
} from '@p1/libs/productspecifications';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductspecificationsService {

  constructor(
    private _httpClient: HttpClient,
    @Inject(PRODUCTSPECIFICATION_CONFIG) private _config: ProductspecificationConfigInterface
  ) {}

  getProductspecification({uuid}) {
    return this._httpClient.get<ProductspecificationBackendInterface>(`${this._config.apiUrl}productspecifications/${uuid}`).pipe(
      map(result => this.generateProductSpecificationFromBackendInterface(result))
    );
  }

  createProductspecification(formData: ProductspecificationFormData) {
    return this._httpClient.post<ProductspecificationBackendInterface>(
      `${this._config.apiUrl}productspecifications`, this.generateProductSpecificationBackendFromFormData(formData)
    ).pipe(
      map(result => this.generateProductSpecificationFromBackendInterface(result))
    );
  }

  generateProductSpecificationFromBackendInterface(specification: ProductspecificationBackendInterface): Productspecification {
    return {
      uuid: specification.uuid,
      productUuid: specification.product_uuid,
      specifiedProperties: specification.specified_properties
    };
  }

  generateProductSpecificationBackendFromFormData(formData: ProductspecificationFormData): ProductspecificationBackendFormData {
    return {
      product_uuid: formData.productUuid,
      specified_properties: formData.specifiedProperties
    };
  }
}
