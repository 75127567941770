<div class="news-navigation">
  <ul class="news-navigation__list">
    <li class="news-navigation__item">
      <button p1Button
              (click)="newsCategoryIdSelected.emit(null)"
              class="news-navigation__button"
              [ngClass]="{'news-navigation__button--active': selectedNewsCategoryId === null}"
              data-gtm-event-category="News"
              data-gtm-event-action="Filter News Category - All"
              data-gtm-event-label="News Dialog - Navigation - Category link">
        <span *ngIf="unreadNewsInCategories?.length > 0"
              class="news-navigation__button__badge">{{unreadNewsInCategories.length}}</span>
        <span class="news-navigation__button__text">Alle Neuigkeiten</span>
      </button>
    </li>
    <li class="news-navigation__item">
      <button p1Button
              (click)="newsCategoryIdSelected.emit(newsCategory.functions)"
              class="news-navigation__button"
              [ngClass]="{'news-navigation__button--active': selectedNewsCategoryId === newsCategory.functions}"
              data-gtm-event-category="News"
              data-gtm-event-action="Filter News Category - Functions"
              data-gtm-event-label="News Dialog - Navigation - Category link">
        <span *ngIf="unreadNewsInCategories?.includes(newsCategory.functions)"
              class="news-navigation__button__badge">{{countArticles(newsCategory.functions)}}</span>
        <span class="news-navigation__button__text">Funktionen</span>
      </button>
    </li>
    <li class="news-navigation__item">
      <button p1Button
              (click)="newsCategoryIdSelected.emit(newsCategory.suppliers)"
              class="news-navigation__button"
              [ngClass]="{'news-navigation__button--active': selectedNewsCategoryId === newsCategory.suppliers}"
              data-gtm-event-category="News"
              data-gtm-event-action="Filter News Category - Suppliers"
              data-gtm-event-label="News Dialog - Navigation - Category link">
        <span *ngIf="unreadNewsInCategories?.includes(newsCategory.suppliers)"
              class="news-navigation__button__badge">{{countArticles(newsCategory.suppliers)}}</span>
        <span class="news-navigation__button__text">Hersteller</span>
      </button>
    </li>
    <li class="news-navigation__item">
      <button p1Button
              (click)="newsCategoryIdSelected.emit(newsCategory.categories)"
              class="news-navigation__button"
              [ngClass]="{'news-navigation__button--active': selectedNewsCategoryId === newsCategory.categories}"
              data-gtm-event-category="News"
              data-gtm-event-action="Filter News Category - Categories"
              data-gtm-event-label="News Dialog - Navigation - Category link">
        <span *ngIf="unreadNewsInCategories?.includes(newsCategory.categories)"
              class="news-navigation__button__badge">{{countArticles(newsCategory.categories)}}</span>
        <span class="news-navigation__button__text">Kategorien</span>
      </button>
    </li>
  </ul>

  <button p1Button
          buttonType="link-style"
          [disabled]="unreadNewsInCategories?.length === 0"
          class="news-navigation__checked"
          (click)="addReadNews.emit()"
          data-gtm-event-category="News"
          data-gtm-event-action="Click read all news"
          data-gtm-event-label="News Dialog - Navigation - Read all">
    <i class="news-navigation__checked__indicator"></i>Alle als gelesen markieren</button>

  <div class="news-navigation__newsletter">
    <span class="news-navigation__newsletter__text">
      <b class="spacing-down">Sie wollen keine Neuigkeiten <br> mehr verpassen?</b>
    </span>
    <span class="news-navigation__newsletter__text">Melden Sie sich für <br> unseren Newsletter an</span>
    <a class="news-navigation__newsletter__link"
       href="https://share.hsforms.com/1YiRg_nZYR6allpVOSwfv6w311tp"
       target="_blank"
       data-gtm-event-category="News"
       data-gtm-event-action="Click newsletter link"
       data-gtm-event-label="News Dialog - Navigation - Newsletter link">
      <i class="icon"><p1-lib-icons-icon-wrapper name="iconMailOutline"></p1-lib-icons-icon-wrapper></i>
      <span>Zur Anmeldung</span>
    </a>
  </div>
</div>
