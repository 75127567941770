<nav class="nav-menu"
     [ngClass]="{'nav-menu--open': open}">
  <ng-content select="[auth-controll-bar]"></ng-content>

  <ul class="list--without-dots">
    <li class="has-children">
      <button p1Button
              buttonType="link-style"
              (click)="toggleSubNav('products')"
              [ngClass]="{'is-open': currentSubnav === 'products'}"
              class="nav-link"
              i18n="products link // page: all @@navigation.component_products">
        Products
      </button>
      <ul class="list--without-dots"
          [@navigationContent]="currentSubnav === 'products' ? 'is-open' : ''">
        <li *ngFor="let route of appRoutes">
          <a class="nav-link nav-link--child"
             [routerLink]="route.path"
             [routerLinkActive]="'nav-link--active'"
             [routerLinkActiveOptions]="{exact: true}"
             (click)="closeNavigation()">
            {{ route.title }}
          </a>
        </li>
        <li *ngIf="featureFlags.referenceProjects">
          <a class="nav-link nav-link--child"
             [href]="referenceProjectsLink"
             i18n="Link text Reference projects // page: all @@navigation.component_link-text-reference-projects"
             target="_blank"
             data-gtm-event-category="ReferenceProjects"
             data-gtm-event-action="Click reference projects"
             data-gtm-event-label="Burger Menu - Link">
            Reference projects
          </a>
        </li>
      </ul>
    </li>
    <ng-container *ngIf="featureFlags.burgerMenuExternalLinks">
      <li class="has-children">
        <button p1Button
                buttonType="link-style"
                (click)="toggleSubNav('services')"
                [ngClass]="{'is-open': currentSubnav === 'services'}"
                class="nav-link"
                i18n="service link // page: all @@navigation.component_service">
          Services
        </button>
        <ul class="list--without-dots"
            [@navigationContent]="currentSubnav === 'services' ? 'is-open' : ''">
          <li>
            <a class="nav-link nav-link--child"
               [href]="wordpressPages.serviceSupplier"
               i18n="service brand link // page: all @@navigation.component_service-brand">
              For Suppliers
            </a>
          </li>
          <li>
            <a class="nav-link nav-link--child"
               [href]="wordpressPages.serviceArchitects"
               i18n="service architects link // page: all @@navigation.component_service-architexts">
              For Architects
            </a>
          </li>
        </ul>
      </li>
      <li class="has-children">
        <button p1Button
                buttonType="link-style"
                (click)="toggleSubNav('media')"
                [ngClass]="{'is-open': currentSubnav === 'media'}"
                class="nav-link"
                i18n="media link // page: all @@navigation.component_media">
          Media
        </button>
        <ul class="list--without-dots"
            [@navigationContent]="currentSubnav === 'media' ? 'is-open' : ''">
          <li>
            <a class="nav-link nav-link--child"
               [href]="wordpressPages.mediaCenter"
               i18n="media-center link // page: all @@navigation.component_media-center">
              Media Center
            </a>
          </li>
          <li>
            <a class="nav-link nav-link--child"
               [href]="wordpressPages.blog"
               i18n="blog link // page: all @@navigation.component_blog">
              Blog
            </a>
          </li>
          <li *ngIf="locale === 'de-DE'">
            <a class="nav-link nav-link--child"
               [href]="wordpressPages.ebooks"
               i18n="ebooks link // page: all @@navigation.component_ebooks">
              Ebooks
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="nav-link"
           [href]="wordpressPages.career"
           i18n="career link // page: all @@navigation.component_career">
          Career
        </a>
      </li>
      <li>
        <a class="nav-link"
           [href]="wordpressPages.contact"
           i18n="contact link // page: all @@navigation.component_contact">
          Contact
        </a>
      </li>
    </ng-container>
  </ul>
</nav>

<div class="nav-closing-area"
     [ngClass]="{'nav-closing-area--active': open}"
     (click)="closeNavigation()"
     (mouseenter)="startClosingTimer()"
     (mouseleave)="resetClosingTimer()"></div>
