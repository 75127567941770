import {
  Component,
  EventEmitter, Input,
  Output,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
} from 'rxjs';
import {
  map,
} from 'rxjs/operators';
import {
  Store
} from '@ngrx/store';

import { NewsCookieService } from '../service/news-cookie.service';
import * as newsReducer from '../reducer';
import * as rootReducer from '../../app.reducers';


@Component({
  selector: 'p1-news-dialog',
  templateUrl: './news-dialog.component.html',
  styleUrls: ['./news-dialog.component.scss']
})

export class NewsDialogComponent {

  @Input()
  unreadNewsInCategories: number;

  @Output()
  abortNewsDialog: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readNews: EventEmitter<any> = new EventEmitter<any>();

  newsArticlesToDisplay$: Observable<{id: number; categories: number[]}[]>;
  selectedNewsCategory$: BehaviorSubject<number> = new BehaviorSubject(null);
  newsDialogOpened: boolean = true;
  allNewsIds: number[];

  constructor(
    private _store: Store<rootReducer.StateInterface>,
    private _newsCookieService: NewsCookieService,
  ) {
    this.newsArticlesToDisplay$ = combineLatest([this._store.select(newsReducer.getNews), this.selectedNewsCategory$]).pipe(
      map(([allArticles, selectedCategory]) => {
        if (Array.isArray(allArticles) && selectedCategory !== null) {
          return allArticles.filter(article => article.categories?.includes(selectedCategory));
        } else {
          return allArticles;
        }
      })
    );
  }

  selectNewsCategory(categoryId: number) {
    this.selectedNewsCategory$.next(categoryId);
  }

  getReadNews() {
    return this._newsCookieService.getNewsReadIds();
  }

}
