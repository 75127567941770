import {
  CanActivate,
  CanLoad
} from '@angular/router';
import { Store } from '@ngrx/store';

import { State } from '../../reducer/router.reducer';
import { GoAction } from '../../actions/router.actions';


export class BaseFeatureGuard implements CanActivate, CanLoad {
  constructor(
    private _store: Store<State>,
    private _active: boolean
  ) {}

  canLoad(): boolean {
    return this._canAccess();
  }

  canActivate(): boolean {
    return this._canAccess();
  }

  private _canAccess() {
    if (this._active) {
      return true;
    }

    this._store.dispatch(new GoAction({ path: ['**'] }));

    return false;
  }
}
