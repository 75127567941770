import {
  Component,
  ElementRef,
  Inject, LOCALE_ID,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import {
  NavigationEnd,
  Router
} from '@angular/router';
import {
  APP_BASE_HREF,
  DOCUMENT,
  isPlatformBrowser,
  PlatformLocation
} from '@angular/common';
import { Title } from '@angular/platform-browser';
import {
  BehaviorSubject,
  combineLatest,
  Observable
} from 'rxjs';
import {
  select,
  Store
} from '@ngrx/store';
import {
  map, take,
  withLatestFrom
} from 'rxjs/operators';

import {
  KeycloakAdapterService,
  KeycloakData
} from '@p1/libs/keycloak-adapter';
import { PortalOutletName } from '@p1/libs/ui-elements';
import { UserIdleService } from '@p1/libs/schueco-stahl-contact/src/lib/service/user-idle.service';

import { User } from './user/model';
import * as rootReducer from './app.reducers';
import * as keycloakActions from './auth/action/keycloak.actions';
import * as authReducer from './auth/reducer/auth.reducer';
import { RightOffCanvasState } from './core/enum/right-off-canvas-state.enum';
import { ResizeService } from './core/service-resize/resize.service';
import { Breakpoint } from './core/enum/breakpoint.enum';
import { breakpointChangeDetected } from './core/actions/layout.actions';
import { GoogleTagManagerService } from './core/google-tag-manager/service/google-tag-manager.service';
import { environment } from '../environments/environment';
import * as treeReducer from './category-tree/reducer';
import * as productReducer from './product/reducer';
import * as newsReducer from './news/reducer';
import {NewsCookieService} from './news/service/news-cookie.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  messages$: Observable<string>;
  navigationOpen: boolean = false;
  rightOffCanvasOpen: RightOffCanvasState;
  rightOffCanvasExists$: Observable<boolean>;
  user$: Observable<User>;
  rightOffCanvasTypeView = RightOffCanvasState;
  portalOutletName = PortalOutletName;
  showRightOffCanvas$: Observable<boolean>;
  currentBreakpoint$: Observable<Breakpoint>;
  showNewsDialog$: Observable<boolean>;
  showNewsBellInNavigation$: Observable<boolean>;
  unreadNewsInCategories$: Observable<any>;
  breakpoint = Breakpoint;
  featureFlags = environment.featureFlags;
  catalogIdentifier = environment.catalogIdentifier;
  activeProduct: boolean;
  currentLanguage: string;
  showNewsDialog: boolean;
  allNewsIds: number[];
  showSchuecoStahlContactModal: boolean;

  // Todo: Refactor -> put read articles into store and get information about the read articles only from there in all components
  private _readArticleChange$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private _router: Router,
    private _store: Store<rootReducer.StateInterface>,
    private _resizeService: ResizeService,
    @Inject(DOCUMENT) private _document: Document,
    private _location: PlatformLocation,
    private renderer: Renderer2,
    private _googleTagManager: GoogleTagManagerService,
    private _title: Title,
    private _element: ElementRef,
    private _newsCookieService: NewsCookieService,
    private _idleService: UserIdleService,
    @Inject(PLATFORM_ID) private _platformId: Record<string, unknown>,
    @Inject(APP_BASE_HREF) private _baseHref: string,
    @Optional() private _keycloak: KeycloakAdapterService,
    @Inject(LOCALE_ID) private _locale: string
  ) {
    this.user$ = this._store.pipe(
      select(authReducer.getUser)
    );

    this.rightOffCanvasExists$ = combineLatest([
      this._store.pipe(select(treeReducer.getCurrentCategoryIsLeave)),
      this._store.pipe(select(rootReducer.getRouterUrl)),
      this._store.pipe(select(productReducer.getTempBasicFilterHasCategories)),
      this._store.pipe(select(rootReducer.getRouterParams)),
      this._store.pipe(select(productReducer.getFetchSingleError))
    ]).pipe(
      map(([categoryIsLeave, routerUrl, hasBasicFilterWithCategory, params, fetchSingleError]) =>
        (routerUrl.startsWith('/products/') || routerUrl.startsWith('/categories/')) &&
        ((hasBasicFilterWithCategory && !params['selectedProductUuidOrSlug']) || categoryIsLeave) &&
        !fetchSingleError && environment.featureFlags.filters
      )
    );

    this.showRightOffCanvas$ = combineLatest([
      this._store.pipe(select(treeReducer.getCurrentCategoryIsLeave)),
      this._store.pipe(select(productReducer.getTempBasicFilterHasCategories)),
      this._store.pipe(select(rootReducer.getRouterParams))
    ]).pipe(map(([categoryIsLeave, hasBasicFilterWithCategory, params]) =>
      (hasBasicFilterWithCategory && !params['selectedProductUuidOrSlug']) || categoryIsLeave));

    this.currentBreakpoint$ = this._store.pipe(
      select(rootReducer.getLayoutBreakpoint)
    );

    if (this.featureFlags.news) {
      this.showNewsDialog$ = this._store.pipe(
        select(newsReducer.getNewsIds),
        withLatestFrom(
          this._store.pipe(
            select(rootReducer.getRouterUrl)
          )
        ),
        take(1),
        map(([newsIds, params]) => {
          if (params.includes('hideNews')) {
            this.showNewsDialog = false;
            return false;
          }

          const visitCount = this._newsCookieService.getVisitCount();
          const readNewsIds = this._newsCookieService.getNewsReadIds();

          if (this._locale !== 'de-DE'|| visitCount > 1) {
            return false;
          }
          const unreadNews = String(newsIds) !== String(readNewsIds);

          if (unreadNews) {
            this._newsCookieService.increaseVisitCount();
            this.showNewsDialog = true;
            return true;
          } else {
            return false;
          }
        })
      );

      this.unreadNewsInCategories$ = combineLatest([this._store.select(newsReducer.getNews), this._readArticleChange$]).pipe(
        map(([allArticles, _]) => {
          if (Array.isArray(allArticles)) {
            const readNewsIds = this._newsCookieService.getNewsReadIds();
            const unreadArticles = allArticles.filter(article => !readNewsIds.includes(article.id));
            this.allNewsIds = unreadArticles.map(article => article.id);

            // TODO: Could contain multiple category ids
            return unreadArticles.map(article => article.categories[0]);
          }
        })
      );

      this.showNewsBellInNavigation$ = this._store.pipe(
        select(rootReducer.getLayoutBreakpoint),
        map(currentBreakpoint => {
          if (currentBreakpoint >= Breakpoint.bpMinM && this._locale === 'de-DE') {
            return true;
          }
        })
      );
    }
  }


  /**
   * Checks if scrolling to top is necessary
   * If RouteChange is fired by browser navigation buttons it should not scroll to top
   * and it does not scroll to top if only an anchor-hash is added to the url
   */
  ngOnInit() {
    if (this._keycloak) {
      this._keycloak.authSuccessData$.subscribe((keycloakData: KeycloakData) => {
        if (keycloakData) {
          this._store.dispatch(new keycloakActions.KeycloakLoginSucceededAction(keycloakData));
        }
      });
    }
    this._store.pipe(
      select(rootReducer.getLayoutRightOffCanvasMode)
    ).subscribe(state => {
      this.rightOffCanvasOpen = state;
    });

    combineLatest([
      this.rightOffCanvasExists$,
      this._store.pipe(select(rootReducer.getLayoutRightOffCanvasMode))
    ]).subscribe(([exists, mode]) => {
      if (exists && mode) {
        this.renderer.addClass(this._element.nativeElement, 'right-off-canvas--open');
      } else if (this._element) {
        this.renderer.removeClass(this._element.nativeElement, 'right-off-canvas--open');
      }
    });

    this._store.pipe(
      select(rootReducer.getRouterParams)
    ).subscribe(routerParams => {
      if (routerParams) {
        this.activeProduct = !!routerParams['selectedProductUuidOrSlug'];
      }
    });

    if (isPlatformBrowser(this._platformId)) {
      let scrolltop = true;
      this._location.onPopState(() => {
        scrolltop = false;
      });
      this._router.events.subscribe(ev => {
        const tree = this._router.parseUrl(this._router.url);
        if (ev instanceof NavigationEnd && !tree.fragment) {
          if (scrolltop) {
            window.scrollTo(0, 0);
          }
          scrolltop = true;
        }
      });
      this._router.events.subscribe(ev => {
        if (ev instanceof NavigationEnd) {
          setTimeout(() => {
            if (!this._router.url.includes('tCat=')
                && !this._router.url.includes('#')) {
              this._googleTagManager.pushPageView({
                url: this._baseHref.slice(0, -1) + this._router.url,
                title: this._title.getTitle()
              });
            }
          }, 0);
        }
      });

      this._resizeService.onResize$.pipe(
        withLatestFrom(this._store.pipe(
          select(rootReducer.getLayoutBreakpoint)
        ))
      ).subscribe(([_, stateBp]) => {
        // get the current breakpoint, defined in _page.scss
        const currentBp = this._getCurrentBreakpoint();

        if (currentBp !== stateBp) {
          this._store.dispatch(breakpointChangeDetected({ breakpoint: currentBp }));
        }
      });

      this._store.dispatch(breakpointChangeDetected({ breakpoint: this._getCurrentBreakpoint() }));

    } else {
      this._store.dispatch(breakpointChangeDetected({ breakpoint: Breakpoint.bpMinXl }));
    }

    this._addCssSupportClasses();

    if (this.catalogIdentifier === 'SchuecoStahlsysteme') {
      const idleTimeoutInSeconds = 5;
      this._idleService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {
        if (isTimeOut) {
          this.showSchuecoStahlContactModal = true;
        }
      });
    }
  }

  /**
   * Toggles the navigation open state
   *
   * @param state
   */
  toggleNavigation(state: boolean) {
    if (state) {
      this._document.documentElement.classList.add('block-scroll');
    } else {
      this._document.documentElement.classList.remove('block-scroll');
    }

    this.navigationOpen = state;
  }

  toggleNewsDialog(state: boolean) {
    this.showNewsDialog = state;
  }

  addReadNews(id?: number) {
    if (id) {
      this._newsCookieService.addNewsReadId(id);
    } else {
      this.allNewsIds.forEach(newsId => this._newsCookieService.addNewsReadId(newsId));
    }
    this._readArticleChange$.next(id);
  }

  private _addCssSupportClasses() {
    if ('ontouchstart' in document.documentElement) {
      this._document.documentElement.classList.add('touch');
    } else {
      this._document.documentElement.classList.add('no-touch');
    }
  }

  private _getCurrentBreakpoint() {
    return Number(window.getComputedStyle(document.querySelector('.page__wrapper'), ':before')
      .getPropertyValue('content').replace(/"/g, '')) as Breakpoint;
  }
}
