export const customErrorMarkup = `<div class="customErrorModal__wrapper"
     style="background-color: rgba(100, 100, 100, .7);
        height: 100%;
        left: 0;
        min-width: 320px;
        padding: 20px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 106;">
  <div class="customErrorModal"
       style="background: white;
        height: auto;
        margin: auto;
        max-height: 100%;
        max-width: 600px;
        overflow-y: auto;
        position: relative;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);">
    <div class="customErrorModal__content"
         style="padding: 40px;">
      <div class="illustration"
           style="margin-bottom: 20px; width: 100%;">
        <div style="margin: auto;
              width: 50%;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 137">
              <g fill="none" fill-rule="evenodd" transform="translate(0 1)">
                <g transform="translate(32.381 30.388)">
                  <path fill="#FFF"
                        fill-rule="nonzero"
                        d="M98.3238095,14.0761905 L16.8380952,95.5619048 L6.74285714,85.4666667 C6.02751603,84.7521209 5.62557464,
                        83.7825114 5.62557464,82.7714286 C5.62557464,81.7603457 6.02751603,80.7907362 6.74285714,80.0761905 L79.5238095,
                        7.2952381 C80.6968792,6.12632167 82.4907485,5.84915732 83.9619048,6.60952381 L98.3238095,14.0761905 Z"/>
                  <path stroke="#696968"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M98.3238095,14.0761905 L16.8380952,95.5619048 L6.74285714,85.4666667 C6.02751603,84.7521209 5.62557464,
                        83.7825114 5.62557464,82.7714286 C5.62557464,81.7603457 6.02751603,80.7907362 6.74285714,80.0761905 L79.5238095,
                        7.2952381 C80.6968792,6.12632167 82.4907485,5.84915732 83.9619048,6.60952381 L98.3238095,14.0761905 Z"/>
                  <path fill="#FFF"
                        fill-rule="nonzero"
                        d="M78.8,8.01904762 L8.0952381,78.7428571 L2.7047619,73.352381 C1.98942079,72.6378352 1.5874794,71.6682257 1.5874794,
                        70.6571429 C1.5874794,69.64606 1.98942079,68.6764505 2.7047619,67.9619048 L66.6666667,4.05714286 C67.9051611,
                        2.81501647 69.831729,2.5791102 71.3333333,3.48571429 L78.8,8.01904762 Z"/>
                  <path stroke="#696968"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M78.8,8.01904762 L8.0952381,78.7428571 L2.7047619,73.352381 C1.98942079,72.6378352 1.5874794,71.6682257 1.5874794,
                        70.6571429 C1.5874794,69.64606 1.98942079,68.6764505 2.7047619,67.9619048 L66.6666667,4.05714286 C67.9051611,
                        2.81501647 69.831729,2.5791102 71.3333333,3.48571429 L78.8,8.01904762 Z"/>
                  <path fill="#FFF"
                        fill-rule="nonzero"
                        d="M102.857143,11.847619 C106.39477,26.2155648 102.161128,41.3873214 91.6952381,51.847619 C69.0545616,74.42729 52.0740543,
                        91.3620432 40.753716,102.651879 C38.8834297,104.517125 17.0440619,95.3570261 18.0994261,94.3022059 C25.6074902,
                        86.798012 36.8695862,75.5417211 51.8857143,60.5333333 C54.0342699,58.3874517 55.2404832,55.4747205 55.2380952,
                        52.4380952 L55.2380952,48.4571429 C55.2306555,45.4232534 56.4298569,42.5109071 58.5714286,40.3619048 L94.8952381,
                        4.05714286 C95.8681834,3.08107129 97.2924419,2.70659625 98.6196471,3.0778977 C99.9468523,3.44919915 100.97003,
                        4.50837366 101.295238,5.84761905 L102.857143,11.847619 Z"/>
                  <path stroke="#696968"
                        stroke-linecap="round"
                        stroke-linejoin="round" d="M102.857143,11.847619 C106.39477,26.2155648 102.161128,41.3873214 91.6952381,51.847619 C69.5670823,
                        73.9161503 52.9709655,90.4675487 41.9068876,101.501814 C39.6522108,103.750416 18.1427702,94.2588841 19.7474887,
                        92.6549928 C26.8893166,85.5168463 37.6020585,74.8096264 51.8857143,60.5333333 C54.0342699,58.3874517 55.2404832,
                        55.4747205 55.2380952,52.4380952 L55.2380952,48.4571429 C55.2306555,45.4232534 56.4298569,42.5109071 58.5714286,
                        40.3619048 L94.8952381,4.05714286 C95.8681834,3.08107129 97.2924419,2.70659625 98.6196471,3.0778977 C99.9468523,3.44919915 100.97003,
                        4.50837366 101.295238,5.84761905 L102.857143,11.847619 Z"/>
                  <path fill="#FFF"
                        fill-rule="nonzero"
                        d="M6.03809524,50.8571429 C4.79529161,50.6082881 3.51010954,50.9938427 2.60952381,51.8857143 L1.35238095,53.1428571 C0.45455383,
                        54.0428863 0.0648503092,55.3314642 0.313432809,56.578201 C0.562015308,57.8249379 1.41612129,58.8655169 2.59047619,
                        59.352381 L8.76190476,61.9047619 L17.5238095,53.1428571 L6.03809524,50.8571429 Z"/>
                  <path stroke="#696968"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.03809524,50.8571429 C4.79529161,50.6082881 3.51010954,50.9938427 2.60952381,51.8857143 L1.35238095,53.1428571 C0.45455383,
                        54.0428863 0.0648503092,55.3314642 0.313432809,56.578201 C0.562015308,57.8249379 1.41612129,58.8655169 2.59047619,59.352381 L8.76190476,
                        61.9047619 L17.5238095,53.1428571 L6.03809524,50.8571429 Z"/>
                  <path fill="#FFF"
                        fill-rule="nonzero"
                        d="M66,4.64761905 L24.647619,46 C25.1098084,41.2590032 24.9110833,36.4767813 24.0571429,31.7904762 L54.5333333,1.31428571 C55.7375221,
                        0.134744191 57.5737276,-0.113182286 59.047619,0.704761905 L66,4.64761905 Z"/>
                  <path stroke="#696968"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M66,4.64761905 L24.647619,46 C25.1098084,41.2590032 24.9110833,36.4767813 24.0571429,31.7904762 L54.5333333,1.31428571 C55.7375221,
                        0.134744191 57.5737276,-0.113182286 59.047619,0.704761905 L66,4.64761905 Z"/>
                  <path fill="#FFF"
                        fill-rule="nonzero"
                        d="M51.1809524,4.64761905 L24.0571429,31.7904762 C23.3358458,27.898675 22.1528855,24.1068079 20.5333333,20.4952381 L39.6952381,
                        1.33333333 C40.9074849,0.110425683 42.7893489,-0.147256949 44.2857143,0.704761905 L51.1809524,4.64761905 Z"/>
                  <path stroke="#696968"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M51.1809524 4.64761905L24.0571429 31.7904762C23.3358458 27.898675 22.1528855 24.1068079 20.5333333 20.4952381L39.6952381
                        1.33333333C40.9074849.110425683 42.7893489-.147256949 44.2857143.704761905L51.1809524 4.64761905zM20.8761905
                        12.0571429L28.2857143 12.7428571"/>
                  <path d="M37.7142857,62.5714286 C39.0792693,63.9321492 39.4894094,65.9814965 38.753231,67.762727 C38.0170526,69.5439575 36.2797472,
                        70.7057706 34.352381,70.7057706 C32.4250147,70.7057706 30.6877093,69.5439575 29.9515309,67.762727 C29.2153525,
                        65.9814965 29.6254926,63.9321492 30.9904762,62.5714286 C32.8489036,60.7188053 35.8558583,60.7188053 37.7142857,62.5714286 Z"/>
                  <path stroke="#4550E5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M37.7142857,62.5714286 C39.0792693,63.9321492 39.4894094,65.9814965 38.753231,67.762727 C38.0170526,69.5439575 36.2797472,
                        70.7057706 34.352381, 70.7057706 C32.4250147,70.7057706 30.6877093,69.5439575 29.9515309,67.762727 C29.2153525,65.9814965 29.6254926,
                        63.9321492 30.9904762,62.5714286 C32.8489036, 60.7188053 35.8558583,60.7188053 37.7142857,62.5714286 Z"/>
                  <path d="M17.1114751,75.374926 L24.7305227,75.374926 C25.7824937,75.374926 26.6352846,76.227717 26.6352846,77.279688 L26.6352846,
                        88.7082594 L15.2067132,88.7082594 L15.2067132,77.279688 C15.2067132,76.227717 16.0595041,75.374926 17.1114751,75.374926 Z"
                        transform="rotate(-45 20.921 82.042)"/>
                  <path stroke="#4550E5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.1114751,75.374926 L24.7305227,75.374926 C25.7824937,75.374926 26.6352846,76.227717 26.6352846,77.279688 L26.6352846,
                        88.7082594 L15.2067132, 88.7082594 L15.2067132,77.279688 C15.2067132,76.227717 16.0595041,75.374926 17.1114751,75.374926 Z"
                        transform="rotate(-45 20.921 82.042)"/>
                  <circle cx="67.352" cy="43.714" r="3.81" fill="#FFF" fill-rule="nonzero" stroke="#E4094F" stroke-linecap="round" stroke-linejoin="round"/>
                  <circle cx="80.152" cy="30.914" r="3.81" fill="#FFF" fill-rule="nonzero" stroke="#E4094F" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill="#FFF"
                        fill-rule="nonzero"
                        d="M47.8285714,52.4761905 C49.6847523,54.3370697 49.6822134,57.3499521 47.822899,59.2077004 C45.9635846,61.0654487 42.9507011,
                        61.0654487 41.0913867,59.2077004 C39.2320723,57.3499521 39.2295335,54.3370697 41.0857143,52.4761905 C41.9790882,51.5805552 43.1921213,
                        51.0772021 44.4571429,51.0772021 C45.7221644,51.0772021 46.9351975,51.5805552 47.8285714,52.4761905 Z"/>
                  <path stroke="#696968"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M47.8285714,52.4761905 C49.6847523,54.3370697 49.6822134,57.3499521 47.822899,59.2077004 C45.9635846,
                        61.0654487 42.9507011,61.0654487 41.0913867,59.2077004 C39.2320723,57.3499521 39.2295335,54.3370697 41.0857143,
                        52.4761905 C41.9790882,51.5805552 43.1921213,51.0772021 44.4571429,51.0772021 C45.7221644,
                        51.0772021 46.9351975,51.5805552 47.8285714,52.4761905 Z"/>
                </g>
                <path fill="#F1F1F1"
                      fill-rule="nonzero"
                      d="M131.980952,86.5406593 C129.783182,90.234262 125.802772,92.4975858 121.504762,92.4975858 C117.206752,92.4975858 113.226341,
                      90.234262 111.028571,86.5406593 C108.823547,90.2244893 104.845717,92.4794651 100.552381,92.4794651 C96.2590446,
                      92.4794651 92.2812152,90.2244893 90.0761905,86.5406593 C87.8784206,90.234262 83.8980104,92.4975858 79.6,
                      92.4975858 C75.3019896,92.4975858 71.3215794,90.234262 69.1238095,86.5406593 C66.9187848,90.2244893 62.9409554,92.4794651 58.647619,
                      92.4794651 C54.3542827,92.4794651 50.3764533,90.2244893 48.1714286,86.5406593 C45.9736587,90.234262 41.9932485,92.4975858 37.6952381,
                      92.4975858 C33.3972277,92.4975858 29.4168175,90.234262 27.2190476,86.5406593 C27.2190476,86.5406593 25.5238095,92.5406593 21.2190476,
                      92.5406593 L11.7333333,92.5406593 C24.4922536,118.750564 51.0876379,135.383178 80.2380952,135.383178 C109.388553,135.383178 135.983937,
                      118.750564 148.742857,92.5406593 L137.980952,92.5406593 C133.67619,92.5406593 131.980952,86.5406593 131.980952,86.5406593 Z"/>
                <path stroke="#696968"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M154.533333 92.5025641L159.295238 92.5025641M1.2 92.5025641L5.96190476 92.5025641"/>
                <path fill="#F1F1F1"
                      fill-rule="nonzero"
                      stroke="#696968"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M150.704762,92.5406593 L137.980952,92.5406593 C133.67619,92.5406593 131.980952,86.5406593 131.980952,86.5406593 C129.783182,
                      90.234262 125.802772,92.4975858 121.504762,92.4975858 C117.206752,92.4975858 113.226341,90.234262 111.028571,86.5406593 C108.823547,
                      90.2244893 104.845717,92.4794651 100.552381,92.4794651 C96.2590446,92.4794651 92.2812152,90.2244893 90.0761905,86.5406593 C87.8784206,
                      90.234262 83.8980104,92.4975858 79.6,92.4975858 C75.3019896,92.4975858 71.3215794,90.234262 69.1238095,86.5406593 C66.9187848,
                      90.2244893 62.9409554,92.4794651 58.647619,92.4794651 C54.3542827,92.4794651 50.3764533,90.2244893 48.1714286,86.5406593 C45.9736587,
                      90.234262 41.9932485,92.4975858 37.6952381,92.4975858 C33.3972277,92.4975858 29.4168175,90.234262 27.2190476,
                      86.5406593 C27.2190476,86.5406593 25.5238095,92.5406593 21.2190476,92.5406593 L9.75238095,92.5406593"/>
                <path stroke="#696968"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M72.2666667 106.578755C69.6402441 106.62091 67.2003852 105.225661 65.9047619 102.940659 64.5800185 105.190925 62.1636333 106.572407
                      59.552381 106.572407 56.9411286 106.572407 54.5247434 105.190925 53.2 102.940659 51.8752566 105.190925 49.4588714 106.572407 46.847619
                      106.572407 44.2363667 106.572407 41.8199815 105.190925 40.4952381 102.940659 39.2059417 105.22263 36.7730929 106.618048 34.152381
                      106.578755M123.085714 112.064469C120.476974 112.115357 118.046979 110.743469 116.742857 108.483516 115.418114 110.733782 113.001729
                      112.115264 110.390476 112.115264 107.779224 112.115264 105.362839 110.733782 104.038095 108.483516 102.742472 110.768518 100.302613
                      112.163767 97.6761905 112.121612M85.7142857 123.721612C83.0878631 123.763767 80.6480043 122.368518 79.352381 120.083516 78.0276376
                      122.333782 75.6112523 123.715264 73 123.715264 70.3887477 123.715264 67.9723624 122.333782 66.647619 120.083516 65.3421859 122.386789
                      62.8753077 123.784931 60.2285714 123.721612"/>
                <path fill="#FFF"
                      fill-rule="nonzero"
                      d="M121.904762,6.42637363 C121.342877,6.42684849 120.786472,6.53683561 120.266667,6.75018315 C119.6281,3.10502324 116.462575,
                      0.445835259 112.761905,0.445835259 C109.061235,0.445835259 105.89571,3.10502324 105.257143,6.75018315 C104.738363,6.53344846 104.181273,
                      6.42332607 103.619048,6.42637363 C101.985833, 6.42637356 100.476681,7.29768291 99.6600742,8.71208786 C98.8434671,10.1264928 98.8434671,
                      11.8691116 99.6600742,13.2835165 C100.476681,14.6979215 101.985833, 15.5692308 103.619048,15.5692308 L121.904762,15.5692308 C124.429492,
                      15.5692308 126.47619,13.5225325 126.47619,10.9978022 C126.47619,8.47307191 124.429492,6.42637363 121.904762,6.42637363 Z"/>
                <path stroke="#696968"
                      stroke-linejoin="round"
                      d="M121.904762,6.42637363 C121.342877,6.42684849 120.786472,6.53683561 120.266667,6.75018315 C119.6281,3.10502324 116.462575,
                      0.445835259 112.761905, 0.445835259 C109.061235,0.445835259 105.89571,3.10502324 105.257143,6.75018315 C104.738363,6.53344846 104.181273,
                      6.42332607 103.619048, 6.42637363 C101.985833,6.42637356 100.476681,7.29768291 99.6600742,8.71208786 C98.8434671,10.1264928 98.8434671,
                      11.8691116 99.6600742, 13.2835165 C100.476681,14.6979215 101.985833,15.5692308 103.619048,15.5692308 L121.904762,15.5692308 C124.429492,
                      15.5692308 126.47619,13.5225325 126.47619,10.9978022 C126.47619,8.47307191 124.429492,6.42637363 121.904762,6.42637363 Z"/>
                <path fill="#FFF"
                      fill-rule="nonzero"
                      d="M41.5619048,22.6358974 C41.131246,22.6409181 40.7051561,22.7248449 40.3047619,22.8835165 C39.7990414,20.1250408 37.3949265,
                      18.1216117 34.5904762,18.1216117 C31.7860259,18.1216117 29.381911,20.1250408 28.8761905,22.8835165 C27.5625493,
                      22.3616938 26.0640209,22.6800597 25.0759926,23.6908773 C24.0879643,24.7016949 23.8038471,26.2070924 24.3554845,27.5084948 C24.9071219,
                      28.8098972 26.1865286,29.6525494 27.6,29.6454212 L41.6190476,29.6454212 C43.5546742,29.6454212 45.1238095,28.0762859 45.1238095,
                      26.1406593 C45.1238095,24.2050328 43.5546742,22.6358974 41.6190476,22.6358974 L41.5619048,22.6358974 Z"/>
                <path stroke="#696968"
                      stroke-linejoin="round"
                      d="M41.5619048,22.6358974 C41.131246,22.6409181 40.7051561,22.7248449 40.3047619,22.8835165 C39.7990414,20.1250408 37.3949265,
                      18.1216117 34.5904762,18.1216117 C31.7860259,18.1216117 29.381911,20.1250408 28.8761905,22.8835165 C27.5625493,22.3616938 26.0640209,
                      22.6800597 25.0759926,23.6908773 C24.0879643, 24.7016949 23.8038471,26.2070924 24.3554845,27.5084948 C24.9071219,28.8098972 26.1865286,
                      29.6525494 27.6,29.6454212 L41.6190476,29.6454212 C43.5546742,29.6454212 45.1238095,28.0762859 45.1238095,26.1406593 C45.1238095,
                      24.2050328 43.5546742,22.6358974 41.6190476,22.6358974 L41.5619048,22.6358974 Z"/>
              </g>
            </svg>
        </div>
      </div>

      <h1>##headline##</h1>
      <p>##text##</p>

      <button class="button button--primary">
        <i class="icon">
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 48 48">
            <g fill="none"
               fill-rule="evenodd"
               stroke="currentColor"
               stroke-linecap="round"
               stroke-linejoin="round"
               transform="translate(1 4)">
              <polyline points="1.036 8.44 7.309 17.85 14.627 9.486"
                        transform="rotate(14 7.832 13.145)"
                        vector-effect="non-scaling-stroke"/>
              <path
                d="M6.5016886,16.3855369 C8.22063476,6.85284462 16.3740393,0.379521407 25.4024545,
                0.0454545455 C36.3650909,-0.353909091 45.5797273,8.20418182 45.9853636,19.1668182 C46.3868182,
                30.1315455 37.8287273,39.3440909 26.864,39.7497273 C18.6216364,40.0529091 11.3703636,
                35.2898182 8.10227273,28.2455455"
                vector-effect="non-scaling-stroke"/>
            </g>
          </svg>
        </i> <span>
            ##buttontext##
        </span>
      </button>
    </div>
    <div class="customErrorModal__hint"
         style="background: #f2f2f2;
          padding: 40px;">
      <p style="margin-bottom: 0;">
        ##contact##
      </p>
    </div>
  </div>
</div>
`;
