import { ROUTER_NAVIGATION } from '@ngrx/router-store';

import * as productActions from '../../../actions/product.actions';


/**
 * hoveredItemUuid reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const hoveredItemUuid = (state: string, action: productActions.Actions | any): string => {
  switch (action.type) {
    case productActions.SET_HOVERED_ITEM_UUID:
      return action.payload;
    case ROUTER_NAVIGATION:
      return undefined;
    default:
      return state;
  }
};
