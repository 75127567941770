import { Environment } from './environment.interface';


export const environment: Environment = {
  environmentName: 'prod',
  catalogIdentifier: null,
  production: true,
  api_url: 'https://content.api.plan.one/v1/',
  api_files_url: 'https://files.api.plan.one/v1/',
  api_basic_auth: 'aG1taDpobW1oQHAx',
  api_auth_token: true,
  api_catalog_url: 'https://catalog-service.api.production.planone.company/v1/',
  tableau_url: 'https://analytics.plan.one',
  external_content_api_url: 'https://extac.api.production.planone.company/',
  schueco_product_validation_api_url: 'https://aiitec.api.production.planone.company/v1/',
  wordpress_api_url: 'https://plan.one/wp-json/wp/v2/',
  keycloak: {
    url: 'https://auth.plan.one/auth',
    realm: 'planone',
    clientId: 'planone-app',
    locale: 'en-GB'
  },
  host: 'plan.one',
  urlScheme: 'https://',
  useCaptchas: true,
  sentryDsn: 'https://8ddd7e9f5a284c5db71340e843c8bde3@sentry.io/1501247',
  allowedParentOrigins: [],
  imageCacheBustingVersion: 'v3',
  fileCacheBustingVersion: 'v1',
  pageTitleSuffix: 'Plan.One',
  searchPlaceholder: null,
  rollingSearchPlaceholders: null,
  featureFlags: {
    catalogConfig: false,
    myWatchlists: true,
    supplierAnalytics: true,
    similarProducts: true,
    loginProtectedDownload: false,
    embedSelectProduct: false,
    embedLayout: false,
    planoneSitemapFooter: true,
    planoneLegalLinks: true,
    languageSwitch: true,
    burgerMenu: true,
    burgerMenuExternalLinks: true,
    featuredProduct: true,
    supplierList: true,
    showSupplierOnProduct: true,
    featuredSupplier: true,
    login: 'full',
    forceLogin: false,
    advertisement: true,
    leadForm: true,
    schuecoToolsLink: true,
    productComparison: true,
    showExternalAdditionalContent: false,
    originalSource: false,
    linkProductOriginalSource: true,
    comparisonLogo: false,
    requestPlanningData: true,
    showTechnicalInformation: true,
    productValidationDialog: false,
    showPdpFixedHeader: true,
    productSpecification: true,
    basicFilter: true,
    filters: true,
    defaultProductOrderBy: 'product_random',
    referenceProjects: true,
    news: true,
    hideLogout: false,
    hideProfile: false,
    doNotTrackUser: false,
    hideSubCategoriesOnCategoryTile: false
  }
};
