import { Injectable } from '@angular/core';

import {
  Product,
  ProductBackendInterface
} from '../model';
import { GeneratorInterface } from '../../core/generator-interface/generator-interface';
import { generateProductFromBackendInput } from '../model/product.generators';


@Injectable()
export class ProductGeneratorService implements GeneratorInterface<ProductBackendInterface, Product> {

  generate(productBackendData): Product {
    return generateProductFromBackendInput(productBackendData);
  }
}
