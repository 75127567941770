import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers } from './reducer/searchBarReducer';
import { SearchService } from './service/search.service';
import { SearchEffects } from './effects/search.effects';


@NgModule({
  imports: [
    StoreModule.forFeature('searchBar', reducers),
    EffectsModule.forFeature([
      SearchEffects
    ])
  ],
  providers: [
    SearchService
  ]
})
export class SearchBarStoreModule {}
