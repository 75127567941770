import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

import {
  FileService,
  ImageMode
} from '@p1/libs/file';

@Directive({
  selector: '[p1Img]'
})
export class ImgDirective implements OnInit, OnChanges {

  @Input()
  fileId: string;

  @Input()
  width: number;

  @Input()
  height: number;

  @Input()
  fallback: string;

  private _imgElement: HTMLImageElement;
  private _fallbackUrl: string = 'assets/images/placeholder-image/placeholder.svg';

  constructor(
    private _el: ElementRef,
    private _fileService: FileService
  ) { }

  /**
   * Replaces the src-value with the default fallback url or the given fallback-url
   */
  @HostListener('error')
  onError() {
    if ((this._imgElement.currentSrc || this._imgElement.src).indexOf(this._fallbackUrl) < 0) {
      this._imgElement.src = this._fallbackUrl;
    }
  }

  ngOnInit(): void {
    this._imgElement = this._el.nativeElement;

    this._imgElement.src = this._fileService.getImageUrl(this.fileId, this.width, this.height, ImageMode.pad);
    this._imgElement.width = this.width;
    this._imgElement.height = this.height;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fallback?.currentValue) {
      this._fallbackUrl = this.fallback;
    }
  }
}
