import * as productActions from '../../../actions/product.actions';
import { ItemScoresStateInterface } from './item-scores-state.interface';


/**
 * items reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const itemScores = (state: ItemScoresStateInterface, action: productActions.Actions): ItemScoresStateInterface => {
  switch (action.type) {
    case productActions.FETCH_ITEM_SCORE_SUCCEEDED: {
      const {score, queryUuid, itemUuid} = action.payload;
      if (score && queryUuid && itemUuid) {
        const newEntry = {[queryUuid]: {[itemUuid]: score}};
        if (state) {
          if (state[queryUuid]) {
            return {...state, [queryUuid]: {...state[queryUuid], ...newEntry[queryUuid]}};
          } else {
            return {...state, ...newEntry};
          }
        } else {
          return newEntry;
        }
      }
      return state;
    }
    case productActions.FETCH_MULTI_SUCCEEDED: {
      if (action.payload.queryUuid && Array.isArray(action.payload.items)) {
        const queryUuid = action.payload.queryUuid;
        const newItemScores = action.payload.items.reduce((allScores, current) => {
          if (current.score) {
            allScores[current.uuid] = current.score;
          }
          return allScores;
        }, {});

        if (state && state[queryUuid]) {
          return {...state, ...{[queryUuid]: {...state[queryUuid], ...newItemScores}}};
        } else {
          return {...state, ...{[queryUuid]: newItemScores}};
        }
      }

      return state;
    }

    default:
      return state;
  }
};
