import { Injectable } from '@angular/core';

import { LoggerServiceInterface } from './logger-service.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggerService implements LoggerServiceInterface {

  constructor() {

  }

  public error(message: string, payload?: Record<string, unknown>  | number | string | boolean) {
    /* eslint-disable-next-line no-console */
    console.error(message, payload);
  }

  public log(message: string, payload?: Record<string, unknown>  | number | string | boolean) {
    /* eslint-disable-next-line no-console */
    console.info(message, payload);
  }

  public warn(message: string, payload?: Record<string, unknown>  | number | string | boolean) {
    /* eslint-disable-next-line no-console */
    console.warn(message, payload);
  }
}
