import { KeycloakData } from '@p1/libs/keycloak-adapter';
import { User } from '../../../user/model';
import * as keycloakActions from '../../action/keycloak.actions';


/**
 * Sets the user data state values
 *
 * @param state the current state
 * @param action performed action
 */
export const user = (state: User, action: keycloakActions.Actions): User => {
  switch (action.type) {
    case keycloakActions.KEYCLOAK_LOGIN_SUCCEEDED:
      const keycloakData: KeycloakData = action.payload;
      const resourceRoles: {[client: string]: string[]} = {};
      Object.keys(keycloakData.resourceAccess).forEach(key => {
        resourceRoles[key] = keycloakData.resourceAccess[key].roles;
      });
      const hasSupplierAreaAccess: boolean = keycloakData.realmAccess
                                             && Array.isArray(keycloakData.realmAccess.roles)
                                             && !!keycloakData.realmAccess.roles.find(role => role === 'supplierArea');
      const excludeAnalytics: boolean = keycloakData.realmAccess
                                        && Array.isArray(keycloakData.realmAccess.roles)
                                        && !!keycloakData.realmAccess.roles.find(role => role === 'excludeAnalytics');
      return {
        uuid: keycloakData.idTokenParsed['preferred_username'],
        name: keycloakData.idTokenParsed['name'],
        email: keycloakData.idTokenParsed['email'],
        firstName: keycloakData.idTokenParsed['given_name'],
        lastName: keycloakData.idTokenParsed['family_name'],
        realmAccessRoles: keycloakData.realmAccess && keycloakData.realmAccess.roles ? keycloakData.realmAccess.roles : null,
        resourceAccessRoles: resourceRoles,
        excludeFromAnalytics: excludeAnalytics,
        companyUuid: keycloakData.idTokenParsed['companyUuid'],
        supplierAreaAccess: hasSupplierAreaAccess
      };
    default:
      return state;
  }
};
