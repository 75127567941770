import * as productActions from '../../../actions/product.actions';
import { ProductItemsStateInterface } from './product-items-state.interface';
import { Product } from '../../../model/product';


/**
 * items reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const items = (state: ProductItemsStateInterface, action: productActions.Actions): ProductItemsStateInterface => {
  switch (action.type) {
    case productActions.FETCH_SINGLE_SUCCEEDED:
      const oldProduct = state ? state[action.payload.uuid] : null;
      return {
        ...state,
        [action.payload.uuid]: {...oldProduct, ...action.payload, detailsLoaded: true}
      };
    case productActions.FETCH_SIMILAR_PRODUCTS_SUCCEEDED:
      const activeProduct = state ? { ...state[action.payload.uuid] } : undefined;
      if (activeProduct && Array.isArray(action.payload.similarProducts)) {
        if (!Array.isArray(activeProduct.similarProducts)) {
          activeProduct.similarProducts = [];
        }
        const offset = action.payload.offset || 0;
        action.payload.similarProducts.forEach((product, index) => {
          const arrIndex = offset === 0 ? index : offset + index - 1;
          activeProduct.similarProducts = [...activeProduct.similarProducts];
          activeProduct.similarProducts[arrIndex] = product.uuid;
        });
        let newState = {
          ...state,
          [activeProduct.uuid]: activeProduct
        };

        action.payload.similarProducts.forEach(product => {
          newState = {...newState, [product.uuid]: {...newState[product.uuid], ...product, detailsLoaded: !!action.payload.withDetails}};
        });

        return newState;
      }
      return state;
    case productActions.fetchComplementaryProductsSucceeded.type:
      const currentProduct = state ? { ...state[action.payload.uuid] } : undefined;
      if (currentProduct && Array.isArray(action.payload.complementaryProducts)) {
        if (!Array.isArray(currentProduct.complementaryProducts)) {
          currentProduct.complementaryProducts = [];
        }
        action.payload.complementaryProducts.forEach((product, index) => {
          currentProduct.complementaryProducts = [...currentProduct.complementaryProducts];
          currentProduct.complementaryProducts[index] = product.uuid;
        });
        let newState = {
          ...state,
          [currentProduct.uuid]: currentProduct
        };

        action.payload.complementaryProducts.forEach(product => {
          newState = {...newState, [product.uuid]: {...newState[product.uuid], ...product}};
        });

        return newState;
      }
      return state;
    case productActions.FETCH_MULTI_SUCCEEDED: {
      const newProducts = Array.isArray(action.payload.items) ? action.payload.items.reduce((prev, curr) => {
        const newProduct = stripUnusedProperties(curr);
        return {...prev, [newProduct.uuid]: state ? {...state[newProduct.uuid], ...newProduct} : newProduct};
      }, {}) : null;

      return {...state, ...newProducts};

    }
    case productActions.FETCH_FEATURED_PRODUCTS_SUCCEEDED: {
      const newProducts = Array.isArray(action.payload.items) ? action.payload.items.reduce((prev, curr) => {
        const newProduct = stripUnusedProperties(curr);
        return { ...prev, [newProduct.uuid]: state ? { ...state[newProduct.uuid], ...newProduct } : newProduct
        };
      }, {}) : null;

      return {...state, ...newProducts};
    }
    default:
      return state;
  }
};

const stripUnusedProperties = (product: Product): Product => {
  const newProduct = {... product};
  if (newProduct.score) {
    delete newProduct.score;
  }
  if (newProduct.advertised) {
    delete newProduct.advertised;
  }
  return newProduct;
};
