import {
  createSelector,
  createFeatureSelector
} from '@ngrx/store';
import { StateInterface } from '../../app.reducers';
import { items } from './items/items.reducer';
import { News } from '../model/news';


export interface NewsState {
  items: News[];
}

export interface State extends StateInterface {
  news: NewsState;
}

export const reducers = {
  items
};

export const getNewsState = createFeatureSelector<NewsState>('news');

export const getNews = createSelector(getNewsState, (state: NewsState) => state ? state.items : undefined);
export const getNewsIds = createSelector(getNewsState, (state: NewsState) => state && state.items ? state.items.map(item => item.id) : undefined);
