import { SupplierTreeCategory } from './supplier-treecategory';
import { SupplierTreeCategoryBackendInterface } from './supplier-treecategory.backend-interface';
import { generateCategoryTreeNodeWithProductsFromBackendInterface } from '../../category-tree/model/category-tree-node-with-products.generator';


export const generateSupplierTreeCategoryFromBackendInput = (input: SupplierTreeCategoryBackendInterface): SupplierTreeCategory => {
  const supplierTreeCategory: SupplierTreeCategory = {};

  if (Array.isArray(input.Categories)) {
    supplierTreeCategory.categories = input.Categories.map(generateCategoryTreeNodeWithProductsFromBackendInterface).filter(item => !!item);
  }

  if (input.ProductsPerCategory) {
    supplierTreeCategory.productsPerCategory = input.ProductsPerCategory;
  }

  if (input.TotalCounts) {
    supplierTreeCategory.totalCounts = input.TotalCounts;
  }

  return supplierTreeCategory;
};
