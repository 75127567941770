import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {
  Injectable,
  Optional
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  select,
  Store
} from '@ngrx/store';
import {
  from,
  NEVER,
  Observable,
  of
} from 'rxjs';
import {
  catchError,
  switchMap,
  take
} from 'rxjs/operators';

import { KeycloakAdapterService } from '@p1/libs/keycloak-adapter';
import { WindowRef } from '@p1/libs/browser-api-wrapper';

import { environment } from '../../../environments/environment';
import { UpdateKeycloakTokenAction } from '../../auth/action/keycloak.actions';
import * as authReducer from '../../auth/reducer/auth.reducer';
import { GoAction } from '../../routing/actions/router.actions';


@Injectable()
/**
 * Intercepts api requests to add basic auth and user accessToken headers
 * Intercepts responses and checks for 401 status and redirects to login
 */
export class AuthInterceptor implements HttpInterceptor {

  private _keycloakAccessToken$: Observable<string>;

  constructor(private _store: Store<authReducer.StateInterface>,
              @Optional() private _keycloak: KeycloakAdapterService,
              private _windowRef: WindowRef,
              private _activatedRoute: ActivatedRoute) {
    this._keycloakAccessToken$ = this._store.pipe(
      select(authReducer.getKeycloakAccessToken)
    );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const snapshot = !this._activatedRoute.snapshot['queryParams'].hasOwnProperty('from') ? this._activatedRoute.snapshot['_routerState'].url : null;
    const apiUrls = [environment.api_url, environment.schueco_product_validation_api_url];

    const isApiRequest = !!apiUrls.find(url => req.url.indexOf(url) > -1);

    if (!isApiRequest) {
      return next.handle(req);
    }
    /**
     * There are multiple possibilities for timing between token loading and api requests.
     * The token could be in the store when the interceptor is called.
     * The token could be loading in via silent SSO Check, so we have to wait for it.
     * Or no token could be present, so the request is unauthenticated.
     */
    return this._keycloakAccessToken$.pipe(
      take(1),
      switchMap(token => {
        if (this._keycloak && token) {
          return this._handleRequestWithToken(req, next, snapshot);
        } else if (this._keycloak && this._windowRef.nativeWindow['keycloakInit']) {
          return this._tryToWaitForToken(req, next, snapshot);
        } else {
          return next.handle(this._addAuthHeader(req));
        }
      })
    );
  }

  /**
   * Waits for the keycloak sso init and adds the token to the request if the authentication succeeded
   */
  private _tryToWaitForToken(req: HttpRequest<any>, next: HttpHandler, snapshot) {
    return from(this._windowRef.nativeWindow['keycloakInit']).pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          return this._handleRequestWithToken(req, next, snapshot);
        } else {
          return next.handle(this._addAuthHeader(req));
        }
      })
    );
  }

  private _handleRequestWithToken(req: HttpRequest<any>, next: HttpHandler, snapshot) {
    return this._keycloak.refreshBearerToken().pipe(
      catchError(_ => this._redirectAfterInvalidToken(snapshot)),
      switchMap((accessToken) => {
        if (req.url.indexOf(environment.api_url) > -1) {
          return this._keycloak.refreshRpt('api-content');
        } else {
          return of(accessToken);
        }
      }),
      switchMap((refreshedToken: string) => next.handle(this._addAuthHeader(req, refreshedToken)))
    );
  }

  private _redirectAfterInvalidToken(snapshot) {
    this._keycloak.clearToken();
    this._store.dispatch(new UpdateKeycloakTokenAction(undefined));

    if (snapshot) {
      setTimeout(() => {
        this._store.dispatch(new GoAction({
          path: ['auth/login'],
          queryParams: {
            reason: 'sessionExpired',
            from: snapshot
          }
        }));
      }, 50);
    } else {
      this._store.dispatch(new GoAction({
        path: ['auth/login'],
        queryParams: { reason: 'sessionExpired' }
      }));
    }
    return NEVER;
  }

  private _addAuthHeader(req: HttpRequest<any>, keycloakToken?: string): HttpRequest<any> {
    const headers = {};

    if (req.url.indexOf(environment.schueco_product_validation_api_url) > -1) {
      headers['X-Auth-Token'] = `Bearer ${ keycloakToken }`;
    } else {
      if (environment.api_basic_auth) {
        headers['Authorization'] = `Basic ${ environment.api_basic_auth }`;
      }
      if (keycloakToken) {
        headers['Auth-Token'] = `Bearer ${ keycloakToken }`;
      }
    }

    return req.clone({ setHeaders: headers });
  }
}
