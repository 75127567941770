import { ProductCategoryBackendInterface } from './product-category.backend-interface';
import { ProductCategory } from './product-category';
import { generateProductFromBackendInput } from '../model/product.generators';
import { differenceInCalendarDays } from 'date-fns/esm';


/**
 * Generates a ProductCategory based on backend api input
 *
 * @param input
 * @returns
 */
export const generateProductCategoryFromBackendInput = (input: ProductCategoryBackendInterface): ProductCategory => {
  try {
    const productCategory: ProductCategory = {
      name: input.Name,
      type: input.Type
    };

    if (input.Uuid !== undefined) {
      productCategory.uuid = input.Uuid;
    }

    if (Array.isArray(input.Products)) {
      productCategory.products = input.Products.map(generateProductFromBackendInput).filter(item => !!item);
    }

    if (input.LastSupplierApproval) {
      productCategory.lastSupplierApproval = differenceInCalendarDays(new Date(), new Date(input.LastSupplierApproval * 1000)).toString();
    }

    return productCategory;
  } catch (e) {
    return null;
  }
};
