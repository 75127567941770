import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';

import { CatalogConfig } from '../model/catalog-config';
import * as catalogConfigActions from '../actions/catalog-config.actions';


export const CatalogConfigFeatureKey = 'catalogConfig';

export interface State {
  config: CatalogConfig;
}

export const initialState: State = {
  config: null
};

const catalogConfigReducer = createReducer(
  initialState,
  on(catalogConfigActions.loadCatalogConfigSuccess,
    (state, { config }) => ({
      ...state,
      config
    }))
);

export const reducer = (state: State | undefined, action: Action) => catalogConfigReducer(state, action);

export const getCatalogConfigState = createFeatureSelector<State>(CatalogConfigFeatureKey);

export const getCatalogConfig = createSelector(getCatalogConfigState, state => state ? state.config : null);
export const getCatalogConfigSearchPlaceholder = createSelector(getCatalogConfig, config => config ? config.searchPlaceholder : null);
export const getCatalogConfigRollingSearchPlaceholder = createSelector(getCatalogConfig, config => config ? config.rollingSearchPlaceholder : null);
export const getCatalogConfigPageTitleSuffix = createSelector(getCatalogConfig, config => config ? config.pageTitleSuffix : null);
export const getCatalogConfigHosts = createSelector(getCatalogConfig, config => config ? config.hosts : null);
export const getCatalogConfigWatchlistShareSubject = createSelector(getCatalogConfig, config => config ? config.watchlistShareSubject : null);
export const getCatalogConfigWatchlistShareContent = createSelector(getCatalogConfig, config => config ? config.watchlistShareContent : null);
export const getCatalogConfigSupplierShareSubject = createSelector(getCatalogConfig, config => config ? config.supplierShareSubject : null);
export const getCatalogConfigSupplierShareContent = createSelector(getCatalogConfig, config => config ? config.supplierShareContent : null);
export const getCatalogConfigProductShareSubject = createSelector(getCatalogConfig, config => config ? config.productShareSubject : null);
export const getCatalogConfigProductShareContent = createSelector(getCatalogConfig, config => config ? config.productShareContent : null);
export const getCatalogConfigComparisonShareSubject = createSelector(getCatalogConfig, config => config ? config.comparisonShareSubject : null);
export const getCatalogConfigComparisonShareContent = createSelector(getCatalogConfig, config => config ? config.comparisonShareContent : null);
