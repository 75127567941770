import * as productActions from '../../../actions/product.actions';


/**
 * queryUuid reducer
 *
 * @param state the current state
 * @param action an action that may result in returning a new state
 */
export const queryUuid = (state: string, action: productActions.Actions): string => {
  switch (action.type) {
    case productActions.SET_QUERY_UUID:
      return action.payload;
    default:
      return state;
  }
};
