import {
  Inject,
  Injectable
} from '@angular/core';

import { WindowRef } from '@p1/libs/browser-api-wrapper';
import {
  COOKIEHANDLING_SERVICE,
  CookiehandlingServiceInterface
} from '@p1/libs/cookiehandling';

import { GoogleTagManagerEvent } from './google-tag-manager-event';
import { GoogleTagManagerVariable } from './google-tag-manager-variable';


/**
 * Service for pushing events and variables to google tag manager
 */
@Injectable()
export class GoogleTagManagerService {

  private _cookieConsentAccepted: boolean = false;
  private _tagManagerEventQueue: GoogleTagManagerEvent[] = [];
  private _tagManagerLastPageView: { url: string; title: string };

  constructor(
    private _windowRef: WindowRef,
    @Inject(COOKIEHANDLING_SERVICE) private _cookiehandlingService: CookiehandlingServiceInterface
  ) {
    const cookieConsent = this._cookiehandlingService.read('CookieConsent');

    if (cookieConsent === null) {
      _windowRef.nativeWindow.addEventListener('click', (event) => {
        if ((event.target as Element).classList.contains('cc-allow') && !this._cookieConsentAccepted) {
          this._cookieConsentAccepted = true;
          this.pushLastPageView();
          this.pushEventQueue();
        }
      });
    } else if (cookieConsent === 'allow') {
      this._cookieConsentAccepted = true;
    }
  }

  pushEvent(event: GoogleTagManagerEvent) {
    if (!event.hasOwnProperty('nonInteraction')) {
      event.nonInteraction = false;
    }
    if (this._windowRef.nativeWindow.dataLayer && this._cookieConsentAccepted) {
      this._windowRef.nativeWindow.dataLayer.push({event: 'Custom-GA-Event', ...event});
    } else {
      this._tagManagerEventQueue.push(event);
    }
  }

  pushPageView(pageViewEvent: {url: string; title: string}) {
    if (this._windowRef.nativeWindow.dataLayer && this._cookieConsentAccepted) {
      this._windowRef.nativeWindow.dataLayer.push({
        event: 'VirtualPageview',
        virtualPageURL: pageViewEvent.url,
        virtualPageTitle: pageViewEvent.title,
        nonInteraction: false
      });
    } else {
      this._tagManagerLastPageView = pageViewEvent;
    }
  }

  pushVariable(variable: GoogleTagManagerVariable) {
    if (this._windowRef.nativeWindow.dataLayer) {
      this._windowRef.nativeWindow.dataLayer.push(variable);
    }
  }

  pushEventQueue() {
    this._tagManagerEventQueue.forEach((event) => {
      this.pushEvent(event);
    });
  }

  pushLastPageView() {
    if (this._tagManagerLastPageView) {
      this.pushPageView(this._tagManagerLastPageView);
    }
  }

}
