import { ConfigOptionsBackendInterface } from './config-options.backend-interface';
import { ConfigOptionsInterface } from './config-options.interface';


export const generateConfigOptionsFromBackendInput = (configOptions: ConfigOptionsBackendInterface): ConfigOptionsInterface => {
  const configOptionsResult: ConfigOptionsInterface = {
    productUuid: configOptions.p1_uuid,
    supportedOpeningtypes: createKeyValuePair(configOptions.supported_openingtypes),
    supportedGlassconfigs: createKeyValuePair(configOptions.supported_glassconfigs),
    defaults: Object.entries(configOptions.defaults).map(defaultValue => ({
      label: defaultValue[0],
      value: defaultValue[1]
    }))
  };
  return configOptionsResult;
};

export const createKeyValuePair = (list) => list.map(item => ({
  label: item,
  value: item
}));
