import * as productActions from '../../../actions/product.actions';
import { Filter } from '../../../../shared/filter/model';


/**
 * propertyFilters reducer
 *
 * @param state
 * @param action
 * @returns
 */
export const propertyFilters = (state: Filter[], action: productActions.Actions): Filter[] => {
  switch (action.type) {
    case productActions.FETCH_FILTER_SUCCEEDED:
      return action.payload.propertyFilters;
    default:
      return state;
  }
};
