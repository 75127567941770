import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  IconsModule,
  IconsRegistry,
  iconCross,
  iconCrossCircledFilled,
  iconSearch
} from '@p1/libs/icons';

import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchBarStoreModule } from './search-bar.store.module';
import { SearchHeroComponent } from './search-hero/search-hero.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,
    SearchBarStoreModule,
    IconsModule
  ],
  declarations: [
    SearchBarComponent,
    SearchHeroComponent
  ],
  exports: [
    SearchBarComponent,
    SearchHeroComponent
  ]
})
export class SearchSharedModule {
  constructor(private _iconsRegistry: IconsRegistry) {
    this._iconsRegistry.registerIcons([
      iconCross,
      iconCrossCircledFilled,
      iconSearch
    ]);
  }
}
