import { environment } from '../../environments/environment';


const currentDeDomain =  'https://plan.one/';
const currentEnDomain =  'https://en.plan.one/';

const wordpressPagesTranslations = {
  landingPage: {
    'en-GB': '',
    'de-DE': ''
  },
  serviceSupplier: {
    'en-GB': 'services/suppliers/',
    'de-DE': 'service/hersteller/'
  },
  serviceArchitects: {
    'en-GB': 'services/architects/',
    'de-DE': 'service/architekten/'
  },
  plugins: {
    'en-GB': 'bim-ready/',
    'de-DE': 'bimready/'
  },
  tutorials: {
    'en-GB': 'supports/tutorials/',
    'de-DE': 'support/tutorials/'
  },
  contact: {
    'en-GB': 'contact/',
    'de-DE': 'kontakt/'
  },
  career: {
    'en-GB': 'career/',
    'de-DE': 'karriere/'
  },
  imprint: {
    'en-GB': environment.featureFlags.planoneLegalLinks ? 'imprint/' : 'catalogs/imprint/',
    'de-DE': environment.featureFlags.planoneLegalLinks ? 'impressum/' : 'catalog/impressum/'
  },
  termsOfService: {
    'en-GB': environment.featureFlags.planoneLegalLinks ? 'conditions-of-use/' : 'catalogs/conditions-of-use/',
    'de-DE': environment.featureFlags.planoneLegalLinks ? 'nutzungsbedingungen/' : 'catalog/nutzungsbedingungen/'
  },
  termsAndConditions: {
    'en-GB': environment.featureFlags.planoneLegalLinks ? 'terms-and-conditions/' : 'catalogs/terms-and-conditions/',
    'de-DE': environment.featureFlags.planoneLegalLinks ? 'agb/' : 'catalog/agb/'
  },
  privacy: {
    'en-GB': environment.featureFlags.planoneLegalLinks ? 'data-protection/' : 'catalogs/data-protection/',
    'de-DE': environment.featureFlags.planoneLegalLinks ? 'datenschutzerklaerung/' : 'catalog/datenschutzerklaerung/'
  },
  advertiseArchitects: {
    'en-GB': 'advertise-architects-planner/',
    'de-DE': 'architekten-planer-werben/'
  },
  mediaCenter: {
    'en-GB': 'supports/media-center/',
    'de-DE': 'mediathek/'
  },
  ebooks: {
    'de-DE': 'ebooks/'
  },
  blog: {
    'en-GB': 'blog-en/',
    'de-DE': 'blog/'
  }
};


export const getWordpressPages = (locale: string): {[key: string]: string} => Object.keys(wordpressPagesTranslations).reduce((sites, key) => {
  sites[key] = locale === 'de-DE' ? currentDeDomain + wordpressPagesTranslations[key]['de-DE'] : currentEnDomain + wordpressPagesTranslations[key]['en-GB'];
  return sites;
}, {});
