import {
  createAction,
  props
} from '@ngrx/store';

import { CatalogConfig } from '../model/catalog-config';


export const loadCatalogConfig = createAction(
  '[CatalogConfig] Load CatalogConfig'
);

export const loadCatalogConfigSuccess = createAction(
  '[CatalogConfig] Load CatalogConfig Success',
  props<{config: CatalogConfig}>()
);

export const loadCatalogConfigFailure = createAction(
  '[CatalogConfig] Load CatalogConfig Failure',
  props<{error: any}>()
);
