import { ProductPropertyBackendInterface } from './product-property.backend-interface';
import { ProductProperty } from './product-property';
import { PropertyType } from './property-type.enum';


/**
 * Generates a product property based on api input
 *
 * @param input
 * @returns
 */
export const generateProductPropertyFromBackendInput = (input: ProductPropertyBackendInterface): ProductProperty => {
  const productProperty: ProductProperty = {
    displayName: input.DisplayName,
    description: input.Description,
    ifdguid: input.ifdguid,
    uuid: input.Uuid,
    type: undefined,
    filterType: undefined
  };

  switch (input.Type) {
    case 'IfcPropertyBoundedValue':
      productProperty.type = PropertyType.IfcPropertyBoundedValue;
      productProperty.upperBoundValue = input.UpperBoundValue;
      productProperty.lowerBoundValue = input.LowerBoundValue;
      break;
    case 'IfcPropertySingleValue':
      productProperty.type = PropertyType.IfcPropertySingleValue;
      productProperty.nominalValue = input.NominalValue;
      break;
    case 'IfcPropertyListValue':
      productProperty.type = PropertyType.IfcPropertyListValue;
      productProperty.listValues = input.ListValues;
      break;
  }

  switch (input.FilterType) {
    case 'IfcPropertyBoundedValue':
      productProperty.filterType = PropertyType.IfcPropertyBoundedValue;
      productProperty.filterUpperBoundValue = input.FilterUpperBoundValue;
      productProperty.filterLowerBoundValue = input.FilterLowerBoundValue;
      break;
    case 'IfcPropertySingleValue':
      productProperty.filterType = PropertyType.IfcPropertySingleValue;
      productProperty.filterNominalValue = input.FilterNominalValue;
      break;
    case 'IfcPropertyListValue':
      productProperty.filterType = PropertyType.IfcPropertyListValue;
      productProperty.filterListValues = input.FilterListValues;
      break;
  }

  if (input.Unit && input.Unit.Name !== 'default') {
    productProperty.unit = input.Unit.Name;
  }

  return productProperty;
};
