<div *ngIf="notification"
     class="notification"
     [ngClass]="{
       'notification--success': notification.type === notificationType.SUCCESS,
       'notification--info': notification.type === notificationType.INFO,
       'notification--warning': notification.type === notificationType.WARNING,
       'notification--error': notification.type === notificationType.ERROR
     }">
  <button class="notification__close"
          (click)="closeNotification.emit()">
    <i class="icon cross"></i>
  </button>
  <div class="notification__icon-wrapper">
    <i *ngIf="!notification.icon" class="icon">
      <ng-container [ngSwitch]="notification.type">
        <p1-lib-icons-icon-wrapper name="iconProblemTriangle" *ngSwitchCase="notificationType.ERROR"></p1-lib-icons-icon-wrapper>
        <p1-lib-icons-icon-wrapper name="iconHammer" *ngSwitchCase="notificationType.WARNING"></p1-lib-icons-icon-wrapper>
        <p1-lib-icons-icon-wrapper name="iconInfoCircle" *ngSwitchCase="notificationType.INFO"></p1-lib-icons-icon-wrapper>
        <p1-lib-icons-icon-wrapper name="iconSuccessCircle" *ngSwitchCase="notificationType.SUCCESS"></p1-lib-icons-icon-wrapper>
      </ng-container>
    </i>
    <i *ngIf="notification.icon" class="icon">
      <p1-lib-icons-icon-wrapper [name]="notification.icon"></p1-lib-icons-icon-wrapper>
    </i>
    <img *ngIf="notification.image"
         class="icon" [src]="notification.image" />
  </div>
  <div class="notification__content-wrapper">
    <p class="content-wrapper__message" [innerHTML]="notification.content"></p>
    <div class="content-wrapper__action">
      <a *ngFor="let action of notification.actions"
         (click)="ctaClick.emit(action.action)">
        {{ action.label }}
      </a>
    </div>
  </div>
</div>
