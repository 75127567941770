import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import {
  Observable,
  of
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap
} from 'rxjs/operators';

import { NavigationSection } from './navigation-section.interface';
import { NavBarCategory } from '../../routing/enum/nav-bar-category.enum';
import { AppRoute } from '../../routing/routes/app-routes.interface';
import { AppNavigationBarItemsService } from './app-navigation-bar-items.service';
import { UserNavigationBarItemsService } from './user-navigation-bar-items.service';
import { SupplierAnalyticsNavigationBarItemsService } from './supplier-analytics-navigation-bar-items.service';


@Injectable({
  providedIn: 'root'
})
export class NavigationBarItemsService {

  navigationBarItems$: Observable<NavigationSection>;
  activeNavigationBarCategory: NavBarCategory;

  private _navBarCategory$: Observable<NavBarCategory>;
  private _appNavigationSection$: Observable<NavigationSection>;
  private _userNavigationSection$: Observable<NavigationSection>;
  private _supplierAnalyticsNavigationSection$: Observable<NavigationSection>;

  constructor(
    private _appNavigationBarItems: AppNavigationBarItemsService,
    private _userNavigationBarItems: UserNavigationBarItemsService,
    private _supplierAnalyticsNavigationBarItems: SupplierAnalyticsNavigationBarItemsService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this._appNavigationSection$ = this._appNavigationBarItems.appNavigationSection$;

    /**
     * checks which navigation should be displayed
     */
    this._navBarCategory$ = this._router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => {
        const snapshot = this._activatedRoute.snapshot;

        if (snapshot && snapshot.firstChild && snapshot.firstChild.firstChild && snapshot.firstChild.firstChild.routeConfig) {
          const routeConfig = snapshot.firstChild.firstChild.routeConfig as AppRoute;

          return routeConfig.navBarCategory;
        } else {
          return undefined;
        }
      }),
      distinctUntilChanged()
    );

    this._navBarCategory$.subscribe(category => {
      this.activeNavigationBarCategory = category;
    });

    this._userNavigationSection$ = this._userNavigationBarItems.userNavigationSection$;

    this._supplierAnalyticsNavigationSection$ = this._supplierAnalyticsNavigationBarItems.supplierAnalyticsNavigationSection$;

    this.navigationBarItems$ = this._navBarCategory$
      .pipe(
        switchMap((navBarCategory) => {
          switch (navBarCategory) {
            case NavBarCategory.app:
              return this._appNavigationSection$;
            case NavBarCategory.user:
              return this._userNavigationSection$;
            case NavBarCategory.supplierAnalytics:
              return this._supplierAnalyticsNavigationSection$;
            default:
              return of(null);
          }
        })
      );
  }
}
