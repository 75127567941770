import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';


@Component({
  selector: 'p1-lib-ui-elements-button-loading',
  templateUrl: './button-loading.component.html',
  styleUrls: ['./button-loading.component.scss']
})
export class ButtonLoadingComponent {

  @Input()
  loading: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  classes: string = '';

  @Input()
  type: string;

  @Input()
  gtmEventCategory?: string;

  @Input()
  gtmEventAction?: string;

  @Input()
  gtmEventLabel?: string;

  @Output()
  buttonClick: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @param $event
   */
  emitClickEvent($event) {
    if (!this.disabled && !this.loading) {
      this.buttonClick.emit($event);
    }
  }

}
