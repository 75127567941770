export enum FilterType {
    /**
     * Implemented as switch-button
     */
  Boolean = 0,
    /**
     * Implemented as Range-Slider
     */
  RangeMulti = 1,
  /**
   * Implemented as Checkbox-list
   */
  Select = 2,
    /**
     * Implemented as Checkbox-list with value alias
     */
  AliasSelect = 3
}
