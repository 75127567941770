<div class="supplier-menu">
  <button class="supplier-menu__cta"
       (click)="toggleSupplierMenu()"
       [ngClass]="{'supplier-menu__cta--open': isOpen}">
    <div class="supplier-menu__cta__avatar">
      <img *ngIf="(analyticsCompany$ | async) as analyticsCompany"
           p1Img
           [fileId]="analyticsCompany.logo?.fileId"
           alt="{{ analyticsCompany.name}}"
           width="32"
           height="32"
           loading="lazy">
    </div>
  </button>
  <div class="supplier-menu__dropdown"
       [@suppliermenuContent]="isOpen ? 'is-open' : ''">
    <ul class="supplier-menu__list list--without-dots">
      <li class="list__item">
        <a class="list__link"
           [routerLink]="['/company/analytics/dashboard']"
           i18n="Dashboard link text // page: all @@supplier-menu.component_dashboard_link_text">
          Dashboard
        </a>
      </li>
      <li *ngIf="(analyticsRoles$ | async)?.includes(SupplierAnalyticsRoles.PortfolioDashboard)"
          class="list__item">
        <a class="list__link"
           [routerLink]="['/company/analytics/portfolio']"
           i18n="My portfolio link text // page: all @@supplier-menu.component_portfolio_link_text">
          My portfolio
        </a>
      </li>
      <li *ngIf="(analyticsRoles$ | async)?.includes(SupplierAnalyticsRoles.CompetitiveAnalysis)"
          class="list__item">
        <a class="list__link"
           [routerLink]="['/company/analytics/benchmark']"
           i18n="Benchmark link text // page: all @@supplier-menu.component_benchmark_link_text">
          Competitive analysis
        </a>
      </li>
      <li *ngIf="(analyticsRoles$ | async)?.includes(SupplierAnalyticsRoles.PerformanceDashboard)"
          class="list__item">
        <a class="list__link"
           [routerLink]="['/company/analytics/performance']"
           i18n="Performance link text // page: all @@supplier-menu.component_performance_link_text">
          Performance
        </a>
      </li>
      <li *ngIf="(analyticsRoles$ | async)?.includes(SupplierAnalyticsRoles.DemandDataDashboard)"
          class="list__item">
        <a class="list__link"
           [routerLink]="['/company/analytics/demand']"
           i18n="Demand data link text // page: all @@supplier-menu.component_demand_data_link_text">
          Demand data analysis
        </a>
      </li>
      <li class="list__item">
        <a class="list__link"
           [routerLink]="['/company/analytics/leads']"
           i18n="Leads link text // page: all @@supplier-menu.component_lead_link_text">
          Leads
        </a>
      </li>
    </ul>
  </div>
</div>
