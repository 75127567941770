import { Notification } from '@p1/libs/ui-elements';

import { ActionWithPayload } from '../../shared/ngrx/action-with-payload.class';


/**
 * Action to add a notification to store
 *
 * @type {string}
 */
export const ADD_NOTIFICATION = '[NOTIFICATION] Add';
export class AddNotificationAction implements ActionWithPayload<Notification> {
  readonly type = ADD_NOTIFICATION;


  constructor(public payload: Notification) {}
}

/**
 * Action to remove a notification with given uuid from store
 *
 * @type {string}
 */
export const REMOVE_NOTIFICATION = '[NOTIFICATION] Remove';
export class RemoveNotificationAction implements ActionWithPayload<string> {
  readonly type = REMOVE_NOTIFICATION;


  constructor(public payload: string) {}
}

export type Actions = AddNotificationAction | RemoveNotificationAction;
