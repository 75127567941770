import {
  createAction,
  props
} from '@ngrx/store';

import {
  News
} from '../model/news';


export const fetchNews = createAction(
  '[NEWS] Fetch news'
);

export const fetchNewsSucceeded = createAction(
  '[NEWS] Fetch news succeeded',
  props<{news: News[]}>()
);

export const fetchNewsFailed = createAction(
  '[NEWS] Fetch news failed',
  props<{error: any}>()
);

export type Actions = ReturnType<typeof fetchNews> |
  ReturnType<typeof fetchNewsSucceeded> |
  ReturnType<typeof fetchNewsFailed>;
