import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { NewsCategoriesEnum } from '../enum/news-categories.enum';


@Component({
  selector: 'p1-news-navigation',
  templateUrl: './news-navigation.component.html',
  styleUrls: ['./news-navigation.component.scss']
})
export class NewsNavigationComponent {

  @Input()
  selectedNewsCategoryId: number;

  @Input()
  unreadNewsInCategories: any;

  @Output()
  newsCategoryIdSelected: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  addReadNews: EventEmitter<number> = new EventEmitter<number>();

  newsCategory = NewsCategoriesEnum;

  constructor() { }

  countArticles(id: number) {
    return this.unreadNewsInCategories.filter(category => category === id).length;
  }
}
