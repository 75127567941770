import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { TreeCategory } from '@p1/libs/catalog-service';

import { Breakpoint } from '../../../core/enum/breakpoint.enum';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[p1-category-tree-sublist]',
  templateUrl: './category-tree-sublist.component.html',
  styleUrls: ['./category-tree-sublist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryTreeSubListComponent {
  @Input()
  breakpoint: Breakpoint;

  @Input()
  categoryTree: TreeCategory[];

  @Input()
  selecedCategoryUuids: string[];

  @Output()
  subCategorySelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedCategory: TreeCategory;
  childIsActive: boolean;

  setActiveCategory(category) {
    if (this.selectedCategory === category) {
      this.selectedCategory = null;
      this.subCategorySelected.emit(false);
    } else {
      this.selectedCategory = category;
      this.subCategorySelected.emit(true);
    }
  }

  selectSubCategory(categorySelected) {
    this.childIsActive = categorySelected;
  }

  identifyCategory(index: number, category: TreeCategory) {
    return category ? category.uuid : index;
  }
}
