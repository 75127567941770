import {
  Component,
  Input,
  HostListener,
  HostBinding,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
  Renderer2,
  AfterViewInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  trigger,
  state,
  transition,
  animate,
  style
} from '@angular/animations';

import { WindowRef } from '@p1/libs/browser-api-wrapper';

@Component({
  selector: 'p1-lib-ui-elements-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('modalState', [
      state('in',
        style({
          opacity: '1',
          transform: 'scale(1)'
        })),
      transition(':enter', [
        style({
          opacity: '0',
          transform: 'scale(1.05)'
        }),
        animate(225)
      ])
    ])
  ]
})
/**
 * Component taking care of displaying modal dialogs
 */
export class ModalComponent implements OnDestroy, AfterViewInit {

  /**
   * Host binding for animations
   */
  @HostBinding('@modalState')
  modalState;

  /**
   * Defines if modal is closeable or not
   * if closable is true, the output "onClose" needs to be set
   *
   * @type {boolean}
   */
  @Input()
  closeable: boolean = false;

  /**
   * Defines if the modal should use a fixed height of 75%
   * this is useful for multi step modals
   *
   * @type {boolean}
   */
  @Input()
  fixedHeight: boolean = false;

  /**
   * Defines if the modal should use a fixed width of 80% regardless of the content
   * the height is defined by the modals content
   *
   * @type {boolean}
   */
  @Input()
  fixedWidth: boolean = false;

  /**
   * Sets the width to 80% and height to 90% regardless of the content
   *
   * @type {boolean}
   */
  @Input()
  fullscreen: boolean = false;

  /**
   * Positions the modal to the bottom left of the screen
   */
  @Input()
  leftBottom: boolean = false;

  /**
   * Sets a defined min-width
   *
   * @type {boolean}
   */
  @Input()
  useMinWidth: boolean = false;

  /**
   * Sets a defined max-width
   *
   * @type {boolean}
   */
  @Input()
  useMaxWidth: boolean = false;

  /**
   * Sets a defined max-width
   *
   * @type {boolean}
   */
  @Input()
  useSmallestMaxWidth: boolean = false;

  /**
   * Defines if the content is scrollable
   * If false the content that exceeds the modal size can not be seen
   *
   * @type {boolean}
   */
  @Input()
  contentIsScrollable: boolean = true;

  /**
   * Defines if the 'modal-actions' directive is displayed in modal
   * The directive has to be implemented within the modal to be able to show it
   * the directive is useful for 'cancel', 'safe', 'confirm' buttons
   *
   * @type {boolean}
   */
  @Input()
  showActions: boolean = true;

  /**
   * Defines if the first input element in the modal is focused automatically
   *
   * @type {boolean}
   */
  @Input()
  autoFocus: boolean = true;

  /**
   * Determines if default padding from the sides is used for each section and adds extra vertical spacing to content section
   *
   * @type {boolean}
   */
  @Input()
  useDefaultSpacings: boolean = true;

  /**
   * Determines if grid-gap is applied
   *
   * @type {boolean}
   */
  @Input()
  useDefaultGap: boolean = true;

  /**
   * Is used for adding the context to google analytics events.
   * Only for events that are defined in the modal component directly,
   * events that are defined in the component where the modal is used via directives need their own event tracking
   * Default context is 'P1Modal'
   *
   * @type {string}
   */
  @Input()
  context: string;

  /**
   * Is used for adding the action to google analytics events.
   * Only for actions that are defined in the modal component directly,
   * actions that are defined in the component where the modal is used via directives need their own event tracking
   *
   * @type {string}
   */
  @Input()
  trackingAction: string;

  /**
   * Event if modal is closed by overlay click or X icon
   */

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();

  scrollTop: number = 0;
  focusedBeforeOpened: any;

  focusableElements: any;
  firstFocusableElement: any;
  lastFocusableElement: any;

  /**
   * constructor
   * Adds body class for open modal
   *
   * @param _document
   * @param _element Reference to host element (injected)
   * @param _renderer ng Renderer2 (injected)
   * @param _windowRef
   */
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _element: ElementRef,
    private _renderer: Renderer2,
    private _windowRef: WindowRef) {


    if (this._windowRef.nativeWindow.document.body.scrollHeight > this._windowRef.nativeWindow.document.body.clientHeight) {
      this.scrollTop = this._windowRef.nativeWindow.pageYOffset;

      this._setBodyStyleTop(this.scrollTop * -1 + 'px');
      this._toggleBlockScroll(true);
    }
  }

  @HostBinding('class.left-bottom') get className() {
    return this.leftBottom;
  }

  /**
   * Close modal if user clicked on overlay and modal is closeable
   *
   * @param ev event target of click
   */
  @HostListener('click', ['$event.target'])
  onHostClick(ev) {
    if (this.closeable && ev === this._element.nativeElement) {
      this.close();
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (this._windowRef.nativeWindow.document.body.scrollHeight >= this._windowRef.nativeWindow.document.body.clientHeight) {
      this._toggleBlockScroll(true);
    } else {
      this._toggleBlockScroll(false);
    }
  }

  /**
   * OnKeyDown detect all focusable elements.
   * If the user press SHIFT+TAB or TAB, then identify next item and focus it.
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(ev) {

    switch (ev.keyCode) {
      case 9: // TAB key pressed
        if (this.focusableElements.length === 1) {
          ev.preventDefault();
          break;
        }

        if (ev.shiftKey) { // SHIFT + TAB => Move focus backwards
          if (this._document.activeElement === this.firstFocusableElement) {
            ev.preventDefault();
            this.lastFocusableElement.focus();
          }
        } else { // TAB => Move focus forwards
          if (this._document.activeElement === this.lastFocusableElement) {
            ev.preventDefault();
            this.firstFocusableElement.focus();
          }
        }
        break;
      case 27: // ESC Key
        if (this.closeable) {
          this.close();
        }
        break;
      default:
        break;
    }
  }

  /**
   * Updates focusable elements
   * (e.g. value of input field changes and (de)activates a button)
   */
  @HostListener('keyup')
  onKeyUp() {
    this.focusableElements = this.getFocusableElements();
  }

  /**
   * Focuses first element in modal view
   */
  ngAfterViewInit() {
    this.focusedBeforeOpened = this._document.activeElement;
    this.focusableElements = this.getFocusableElements();

    if (this.autoFocus && this.firstFocusableElement != null) {
      // focus event need timeout to get element
      setTimeout(() => {
        this.firstFocusableElement.focus();
      }, 200);
    }
  }

  /**
   * Removes body class for open modal
   */
  ngOnDestroy() {
    this._toggleBlockScroll(false);
    this._setBodyStyleTop('auto');
    this._windowRef.nativeWindow.scrollTo(0, this.scrollTop);
  }

  /**
   * Get all focusable elements in modal
   * Saves first and last focusable element in separated variables
   */
  getFocusableElements() {
    const elements = Array.from(
      this._element.nativeElement.querySelectorAll(
        '[href], button:not([disabled]):not(.modal-close__button),' +
        'input:not([disabled]), select:not([disabled]),' +
        'textarea:not([disabled]),' +
        '[tabindex]:not([tabindex="-1"])'
      )
    );
    this.firstFocusableElement = elements[0];
    this.lastFocusableElement = elements.length === 1 ? elements[0] : elements[elements.length - 1];

    return elements;
  }

  /**
   * Event emitting to close the modal
   */
  close() {
    this.focusedBeforeOpened.focus();
    this.closeModal.emit();
  }

  /**
   * Sets the body style top
   *
   * @param value
   * @private
   */
  private _setBodyStyleTop(value: string) {
    this._renderer.setStyle(this._windowRef.nativeWindow.document.body, 'top', value);
  }

  /**
   * Adds or removes block-scroll class @ documentElement
   *
   * @param blockScroll
   * @private
   */
  private _toggleBlockScroll(blockScroll: boolean) {
    if (blockScroll) {
      this._renderer.addClass(this._windowRef.nativeWindow.document.documentElement, 'block-scroll');
    } else {
      this._renderer.removeClass(this._windowRef.nativeWindow.document.documentElement, 'block-scroll');
    }
  }

}
