import { generateFileContainerFromBackendInput } from '@p1/libs/file';

import { ProductBackendInterface } from './product.backend-interface';
import { Product } from './product';
import { generateSupplierFromBackendInterface } from '../../supplier/model/supplier.generators';
import { generateProductCategoryFromBackendInput } from '../model-category/product-category.generators';
import { generateProductDownloadsFromBackendInput } from '../model-downloads';
import {
  generateProductPropertySetFromBackendInput,
  ProductPropertySet
} from '../model-property-set';
import { generateProductScoreFromBackendInput } from '../model-score';
import { additionalSectionNames } from './product-additional-section-names';


/**
 * Generates a Product based on backend api input
 *
 * @param input
 * @returns
 */
export const generateProductFromBackendInput = (input: ProductBackendInterface): Product => {
  try {
    const product: Product = {
      uuid: input.Uuid,
      name: input.Name,
      supplier: generateSupplierFromBackendInterface(input.Supplier),
      urlPathSegment: Array.isArray(input.Slugs) && input.Slugs.length > 0 ? input.Slugs[0] : input.Uuid
    };

    if (input.Category !== undefined) {
      product.category = generateProductCategoryFromBackendInput(input.Category);
    }

    if (Array.isArray(input.TreeCategories)) {
      product.treeCategories = input.TreeCategories;
    }

    if (input.Slugs !== undefined) {
      product.slugs = input.Slugs;
    }

    if (input.Image !== undefined) {
      product.primaryImage = generateFileContainerFromBackendInput(input.Image);
    }

    if (input.Type !== undefined) {
      product.type = input.Type;
    }

    if (input.Downloads !== undefined) {
      product.downloads = generateProductDownloadsFromBackendInput(input.Downloads);
    }

    if (input.Score !== undefined) {
      product.score = generateProductScoreFromBackendInput(input.Score);
    }

    if (input.PropertySets !== undefined && input.PropertySets.length > 0) {
      product.propertySets = Array.from(input.PropertySets
        .map(generateProductPropertySetFromBackendInput)
        .filter(item => !!item)
        .reduce((allSets, propertySet) => {
          // TODO: Remove reduce part when merging of property sets is done in the backend
          if (allSets.has(propertySet.name)) {
            const firstSet = allSets.get(propertySet.name);
            const mergedProperties = [...firstSet.properties, ...propertySet.properties];
            allSets.set(propertySet.name,
              {
                ...propertySet,
                properties: mergedProperties
              });
          } else {
            allSets.set(propertySet.name, propertySet);
          }
          return allSets;
        }, new Map<string, ProductPropertySet>()).values());
    }

    if (input.ImageGallery !== undefined && input.ImageGallery.length > 0) {
      product.imageGallery = input.ImageGallery.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (input.Videos !== undefined && input.Videos.length > 0) {
      product.videos = input.Videos.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (input.Details !== undefined && input.Details.length > 0) {
      input.Details.forEach(res => {
        if (res.Name === 'Produktvorteile' || res.Name === 'Beschreibung') {
          res.Name = additionalSectionNames[res.Name];
        }
      });
      product.details = input.Details.map(generateFileContainerFromBackendInput).filter(item => !!item);
    }

    if (input.Advertised) {
      product.advertised = input.Advertised;
    }

    if (input.OriginalUrl) {
      product.originalSource = input.OriginalUrl;
    }

    return product;
  } catch (e) {
    return null;
  }
};
