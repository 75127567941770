<main>
  <div class="container">
    <div class="row">
      <div class="gr-12 gr-6@m content-wrapper">
        <div>
          <h1 [innerHTML]="headline | safeHtml"></h1>
          <p>{{ copytext }}</p>
        </div>
        <div>
          <a p1Button
             [routerLink]="buttonLink">
            {{ buttonLabel }}
          </a>
        </div>
      </div>
      <div class="gr-12 gr-6@m illustration-wrapper">
        <p1-lib-icons-icon-wrapper name="illustrationNotFound"></p1-lib-icons-icon-wrapper>
      </div>
    </div>
  </div>
</main>
