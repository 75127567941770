import {
  Injectable,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { customErrorMarkup } from './custom-error-markup.constant';
import { serverErrorMarkup } from './server-error-markup.constant';
import { CustomErrorTypes } from './custom-error-types.enum';
import {
  serverErrorMessage,
  uncaughtErrorMessage
} from './custom-error-messages.constant';
import { isPlatformBrowser } from '@angular/common';


@Injectable()
export class ErrorModalCreatorService {

  private $errorModal: Node;
  private pageWrapperElement: HTMLCollectionOf<Element>;

  constructor(@Inject(PLATFORM_ID) private _platformId: Record<string, unknown>) {

  }


  /**
   * Displays error information as small page overlay
   *
   * @param error - error object
   */
  public showError(error: CustomErrorTypes) {
    if (!this.$errorModal && isPlatformBrowser(this._platformId)) {
      this.$errorModal = error === CustomErrorTypes.ServerError ? this.createServerErrorModalElement(error) : this.createErrorModalElement();
      if (this.$errorModal != null) {
        document.body.appendChild(this.$errorModal);
      }

      this.pageWrapperElement = document.getElementsByClassName('page__wrapper');

      if (this.pageWrapperElement.length > 0 &&
          typeof (this.pageWrapperElement[0]) !== 'undefined' &&
          this.pageWrapperElement[0] !== null) {
        this.pageWrapperElement[0].setAttribute('style', `
          filter: blur(3px);
          overflow: hidden
        `);
      }
    }
  }

  private createServerErrorModalElement(error: CustomErrorTypes) {
    const headline = serverErrorMessage.headline;
    const text = serverErrorMessage.text;
    const buttonText = serverErrorMessage.buttonText;
    const contact = serverErrorMessage.contact;
    return this.createModalElement(headline, text, buttonText, contact, error);
  }

  private createErrorModalElement() {
    const headline = uncaughtErrorMessage.headline;
    const text = uncaughtErrorMessage.text;
    const buttonText = uncaughtErrorMessage.buttonText;
    const contact = uncaughtErrorMessage.contact;
    return this.createModalElement(headline, text, buttonText, contact);
  }


  /**
   * @param headline
   * @param text
   * @param buttonText
   * @param contact
   */
  private createModalElement(headline: string, text: string, buttonText: string, contact: string, error?: CustomErrorTypes) {
    let domString;

    if (error === CustomErrorTypes.ServerError) {
      domString = serverErrorMarkup
        .replace('##headline##', headline)
        .replace('##text##', text)
        .replace('##buttontext##', buttonText)
        .replace('##contact##', contact);
    } else {
      domString = customErrorMarkup
        .replace('##headline##', headline)
        .replace('##text##', text)
        .replace('##buttontext##', buttonText)
        .replace('##contact##', contact);
    }

    const parser = new DOMParser();
    const errorModalHTML = parser.parseFromString(domString, 'text/html');
    if (errorModalHTML) {
      const el = errorModalHTML.body.firstChild;

      errorModalHTML.querySelector('button').addEventListener('click', () => {
        location.reload();
      });

      return el;
    }
    return null;
  }
}
