<form class="frm"
      [ngClass]="{'frm--big-search': largeMode}">
  <div class="search__fields-wrapper"
       [ngClass]="{
        'frm--inline-search': inlineMode,
        'frm--inline-search--opened': inlineMode && searchPage,
        'frm--inline-search--focused': inlineMode && searchFieldFocused
        }">
    <div class="frm__field search-field"
         [ngClass]="{
            'frm__field--search-bar-large': largeMode,
            'frm__field--search-bar': !largeMode,
            'frm__field--search-inline': inlineMode,
            'frm__field--search-inline--focused': inlineMode && searchFieldFocused
         }">
      <div class="search-field__inner">
        <input type="search"
               name="searchinput"
               maxlength="80"
               autocomplete="off"
               [placeholder]="(searchFieldPlaceholder$ | async)"
               [formControl]="searchTermFormControl"
               (keydown.enter)="onSearchBarEnter()"
               (focus)="onSearchFieldFocus($event)"
               ngDefaultControl
               #searchField>
        <button *ngIf="searchTermFormControl?.value?.length >= searchTermMinLength"
                class="frm__field__clear"
                (click)="onClearSearchClick()"
                i18n-title="title for search input clear button in search bar component // page: @@add-filter-dialog.component_placeholder-clear-button"
                title="Clear Search"
                tabindex="-1">
          <p1-lib-icons-icon-wrapper name="iconCrossCircledFilled"></p1-lib-icons-icon-wrapper>
        </button>
      </div>
      <button p1Button
              class="search-field__submit"
              (click)="onSearchBarSubmitClick()"
              tabindex="-1">
        <i class="icon">
          <p1-lib-icons-icon-wrapper name="iconSearch"></p1-lib-icons-icon-wrapper>
        </i>
      </button>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="searchResults"></ng-container>
</form>

<ng-container *ngIf="inlineMode && searchFieldFocused">
  <button p1Button
          buttonType="link-style"
          class="inline-search__close"
          (click)="closeSearchPreview()">
    <i class="icon">
      <p1-lib-icons-icon-wrapper name="iconCross"></p1-lib-icons-icon-wrapper>
    </i>
  </button>
</ng-container>

<ng-template #searchResults>
  <div *ngIf="showResultWrapper &&
              (searchTermFormControl.value?.length > this.searchTermMinLength)"
       class="search-results"
       [ngClass]="{'search-results--large': (largeMode)}">
    <div *ngIf="(searchBarSearchInProgress$ | async) !== true"
         class="search-results__inner">
      <div *ngIf="(searchBarResultsLength$ | async) !== 0"
           class="search-results__clusters">
        <ng-container *ngFor="let cluster of (searchBarResults$ | async) | keyvalue; trackBy: identifyCluster;">
          <div class="search-results__cluster"
               [ngClass]="{'search-results__cluster--no-results': cluster?.value.length === 0}">
            <span *ngIf="(searchBarResultsLength$ | async) !== 0 || cluster?.value.length === 0"
                  class="search-results__title">
              <b>{{ getSearchClusterLabelByKey(cluster?.key) }}</b>
              <ng-container *ngFor="let counter of (clusterCounts$ | async) | keyvalue; trackBy: identifyCounter;">
                <ng-container *ngIf="counter?.key === cluster?.key">
                  ({{counter?.value}})
                </ng-container>
              </ng-container>
            </span>
            <ng-container *ngIf="cluster?.value.length > 0">
              <ul class="list--without-dots search-results__list">
                <li *ngFor="let result of cluster.value | slice:0:searchBarResultsMaxPreviewElements; trackBy: identifyResult;"
                    class="search-results__list-item">
                  <a *ngIf="!featureFlags.originalSource && !result.originalSource"
                     class="search-results__link"
                     (click)="onSearchResultClick(result)"
                     [routerLink]="['/' + result?.urlTarget]"
                     [queryParams]="result?.urlParams"
                     [attr.data-type]="result?.urlTarget"
                     [attr.data-queryParams]="(result?.urlParams | json)"
                     [attr.data-uuid]="result?.uuid">
                    <span class="search-results__image-wrapper">
                      <img p1Img
                           [fileId]="result.primaryImage?.fileId || result.logo?.fileId || result.image?.fileId || result.image?.uuid"
                           width="48"
                           height="48"
                           class="search-results__image"
                           fallback="assets/images/placeholder-image/placeholder-product--small.svg">
                    </span>
                    <span class="search-results__info-wrapper">
                      <span class="search-results__name">
                        {{ result?.name }}
                      </span>
                    </span>
                  </a>
                  <a *ngIf="featureFlags.originalSource && result.originalSource"
                     class="search-results__link"
                     (click)="onSearchResultClick(result)"
                     [href]="result?.originalSource"
                     [attr.data-type]="result?.urlTarget"
                     [attr.data-queryParams]="(result?.urlParams | json)"
                     [attr.data-uuid]="result?.uuid">
                    <span class="search-results__image-wrapper">
                      <img p1Img
                           [fileId]="result.primaryImage?.fileId || result.logo?.fileId || result.image?.fileId"
                           width="48"
                           height="48"
                           class="search-results__image"
                           fallback="assets/images/placeholder-image/placeholder-product--small.svg">
                    </span>
                    <span class="search-results__info-wrapper">
                      <span class="search-results__name">
                        {{ result?.name }}
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="(searchBarResultsLength$ | async) === 0">
        <span class="search-results__title"
              i18n="headline for no results in search bar component // page: @@search-bar.component_no-results-headline">
          <b>Oh, no search result for <span class="search-results__term">{{ searchTerm }}&nbsp;</span></b>
        </span>
        <span class="search-results__title"
              i18n="sub headline for no results in search bar component // page: @@search-bar.component_no-results-sub-headline">
          <b>Don't give up!</b>
        </span>
        <ul>
          <li i18n="tip 1 for no results in search bar component // page: @@search-bar.component_no-results-tip-1">
            Try to search for products, manufacturers, categories or even property values
          </li>
          <li i18n="tip 2 for no results in search bar component // page: @@search-bar.component_no-results-tip-2">
            Generalize your search a little and then use our unique filter search
          </li>
          <li i18n="tip 3 for no results in search bar component // page: @@search-bar.component_no-results-tip-3">
            Check your spelling
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="(searchBarResultsLength$ | async) !== 0">
        <div class="search-results__more">
          <a (click)="onShowMoreClick()"
             [routerLink]="['/search']"
             [queryParams]="{searchTerm: searchTermFormControl.value}">
            <span i18n="show all results link in searchbar-results // page: @@search-bar.component_show-all-results-link">
              show all results
            </span>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
