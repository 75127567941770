import {
  Component,
  ElementRef,
  HostListener
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  select,
  Store
} from '@ngrx/store';
import { Observable } from 'rxjs';

import * as analyticsCompanyReducers from '../../supplier-analytics/reducer';
import { AnalyticsCompany } from '../../supplier-analytics/model';
import * as authReducer from '../../auth/reducer/auth.reducer';
import { SupplierAnalyticsRoles } from '../../supplier-analytics/enums/supplier-analytics-roles.enum';


/**
 * Component representing the supplier menu dropdown in header
 */
@Component({
  selector: 'p1-supplier-menu',
  templateUrl: './supplier-menu.component.html',
  styleUrls: ['./supplier-menu.component.scss'],
  animations: [
    trigger('suppliermenuContent', [
      state('is-open', style({
        height: '*',
        visibility: 'visible'
      })),
      state('*', style({
        height: '0px',
        visibility: 'hidden'
      })),
      transition('* <=> *', animate('.3s ease-in-out'))
    ])
  ]
})
export class SupplierMenuComponent {

  isOpen: boolean = false;
  analyticsCompany$: Observable<AnalyticsCompany>;
  SupplierAnalyticsRoles = SupplierAnalyticsRoles;
  analyticsRoles$: Observable<string[]>;

  private _ignoreToggle: boolean = false;
  private _ignoreToggleTimeout;

  constructor(
    private elementRef: ElementRef,
    private _store: Store<analyticsCompanyReducers.AnalyticsCompanyState>
  ) {
    this.analyticsCompany$ = this._store.pipe(
      select(analyticsCompanyReducers.getAnalyticsCompany)
    );
    this.analyticsRoles$ = this._store.pipe(
      select(authReducer.getAnalyticsRoles)
    );
  }

  /**
   * listener for click on document to close overlay if clicked outside
   */
  @HostListener('document:click', ['$event'])
  onMouseUp(event) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.toggleSupplierMenu();
    }
  }

  /**
   * Catches events within the component
   */
  @HostListener('click', ['$event'])
  catchEvent(event) {
    if (event.target['tagName'] && event.target.tagName === 'A') {
      this.toggleSupplierMenu();
    }
  }

  /**
   * Close search if user clicked on overlay or outside component
   */
  @HostListener('window:click', ['$event.target'])
  @HostListener('window:touchstart', ['$event.target'])
  onWindowClick() {
    if (this.isOpen) {
      window.addEventListener('searchFocused', () => {
        this.isOpen = false;
      });
    }
  }

  toggleSupplierMenu() {
    if (this._ignoreToggle) {
      return;
    }

    this.isOpen = !this.isOpen;
    this._ignoreToggle = true;

    clearTimeout(this._ignoreToggleTimeout);

    this._ignoreToggleTimeout = setTimeout(() => {
      this._ignoreToggle = false;
    }, 500);
  }
}
