import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Location } from '@angular/common';
import {
  Observable
} from 'rxjs';
import {
  select,
  Store
} from '@ngrx/store';
import {
  map
} from 'rxjs/operators';

import {
  FileService,
  ImageMode
} from '@p1/libs/file';

import { User } from '../../user/model';
import { environment } from '../../../environments/environment';
import { getWordpressPages } from '../../routing/wordpress-pages';
import * as authReducer from '../../auth/reducer/auth.reducer';
import * as catalogConfigReducer from '../../catalog-config/reducers';
import {
  CatalogConfig
} from '../../catalog-config/model/catalog-config';


@Component({
  selector: 'p1-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    Location
  ]
})
export class HeaderComponent implements OnChanges, OnDestroy {

  @Input()
  navigationOpen: boolean = false;

  @Input()
  unreadNews: number[];

  @Input()
  showNewsBell: boolean;

  @Output()
  navigationToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  newsToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  user$: Observable<User>;
  hasAnalyticsCompany$: Observable<boolean>;
  catalogConfig$: Observable<CatalogConfig>;
  catalogLogoUrl$: Observable<string>;
  catalogName$: Observable<string>;
  catalogLinkLogoUrl$: Observable<string>;

  ignoreNavClick: boolean = false;
  currentLanguage: string;
  featureFlags = environment.featureFlags;
  catalogIdentifier = environment.catalogIdentifier;
  wordpressPages: {[key: string]: string};

  private _navHoverTimeout: any;


  constructor(
    private _store: Store<authReducer.StateInterface>,
    private _fileService: FileService,
    @Inject(LOCALE_ID) private _locale: string
  ) {
    this.currentLanguage = this._locale;
    this.wordpressPages = getWordpressPages(this._locale);
    this.user$ = this._store.pipe(
      select(authReducer.getUser)
    );

    this.hasAnalyticsCompany$ = this._store.pipe(
      select(authReducer.getHasAnalyticsCompany)
    );

    this.catalogConfig$ = this._store.pipe(
      select(catalogConfigReducer.getCatalogConfig)
    );

    this.catalogLogoUrl$ = this.catalogConfig$.pipe(
      map(config => config && config.logo ? this._fileService.getImageUrl(
        config.logo.uuid,
        environment.catalogIdentifier === 'bim' ? 450 : 200,
        80,
        ImageMode.pad,
        '#f2f2f2') : null)
    );

    this.catalogName$ = this.catalogConfig$.pipe(
      map(config => config ? config.name : null)
    );

    this.catalogLinkLogoUrl$ = this.catalogConfig$.pipe(
      map(config => config ? config.linkLogoUrl : null)
    );
  }

  /**
   * If the navigationOpen state changes, ignore clicks on the button for 300ms
   * (otherwise, if the user clicks to slow, the navigation will be opened by the timeout and immediately be closed by the click)
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['navigationOpen']) {
      this.ignoreNavClick = true;
      setTimeout(() => {
        this.ignoreNavClick = false;
      }, 300);
    }
  }

  ngOnDestroy() {
    clearTimeout(this._navHoverTimeout);
  }

  /**
   * Starts a timeout to open the nav menu after the mouse enters the button
   */
  startNavTimer() {
    if (this.navigationOpen) {
      return;
    }

    this._navHoverTimeout = setTimeout(() => {
      this.toggleNavigation(true);
      this._resetTimeout();
    }, 300);
  }

  /**
   * Clears timeout for opening the menu after the mouse leaves the button
   */
  resetNavTimer() {
    this._resetTimeout();
  }

  /**
   * Emits event that tells the parent component to toggle the navigation open state
   *
   * @param newState - new navigation open state
   */
  toggleNavigation(newState: boolean) {
    this.navigationToggle.emit(newState);
    this._resetTimeout();
  }

  /**
   * Emits event that tells the parent component to toggle the navigation open state
   *
   * @param newState - new navigation open state
   */
  toggleNews() {
    this.newsToggle.emit();
  }

  private _resetTimeout() {
    clearTimeout(this._navHoverTimeout);
    this._navHoverTimeout = null;
  }
}
