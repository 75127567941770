import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import {
  select,
  Store
} from '@ngrx/store';
import {
  from,
  Observable,
  of,
  race,
  timer
} from 'rxjs';
import {
  map,
  take,
  switchMap,
  mapTo
} from 'rxjs/operators';

import { WindowRef } from '@p1/libs/browser-api-wrapper';

import * as authReducer from '../../../auth/reducer/auth.reducer';
import { GoAction } from '../../actions/router.actions';


@Injectable()
/**
 * Implements CanActivate abstract to allow Routes to be accessed when the user is logged in
 */
export class UserAuthenticatedGuard implements CanActivate, CanLoad {

  constructor(
    private _store: Store<authReducer.StateInterface>,
    private _windowRef: WindowRef,
    private _router: Router
  ) {}


  /**
   * Allows Route Access if the user is logged in EXCEPT the accessed Route is the login route
   *
   * @param state - The current Router State
   * @returns
   */
  canActivate(_, state: RouterStateSnapshot): Observable<boolean> {
    return this.delayedCheckForAuthentication().pipe(
      map(isAuthenticated => {
        const targetsLogin = state.url.indexOf('auth/login') > -1 || state.url.indexOf('auth/register') > -1 || state.url.indexOf('auth/lostPassword') > -1;
        if (isAuthenticated) {
          return targetsLogin ? !this._router.navigate(['']) : true;
        }

        return targetsLogin ? true : !this._router.navigate(['auth/login'], { queryParams: { reason: 'unauthorized', from: state.url } });
      })
    );
  }

  canLoad(_, segments: UrlSegment[]): Observable<boolean> {
    return this.delayedCheckForAuthentication().pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return true;
        }
        const fullPath = segments.reduce((path, currentSegment) => `${path}/${currentSegment.path}`, '');
        this._store.dispatch(new GoAction({ path: ['auth/login'], queryParams: { reason: 'unauthorized', from: fullPath} }));
        return false;
      })
    );
  }

  delayedCheckForAuthentication() {
    return this._store.pipe(
      select(authReducer.getIsAuthenticated),
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          return of(true);
        } else if (this._windowRef.nativeWindow['keycloakInit']) {
          return race(
            from(this._windowRef.nativeWindow['keycloakInit']),
            timer(5000).pipe(
              mapTo(false)
            )
          );
        } else {
          return of(false);
        }
      }),
      take(1)
    );
  }
}
