import {
  Component,
  HostBinding,
  Input
} from '@angular/core';
import {
  animate,
  style,
  transition,
  trigger
} from '@angular/animations';


@Component({
  selector: 'p1-lib-ui-elements-counter-badge',
  templateUrl: './counter-badge.component.html',
  styleUrls: ['./counter-badge.component.scss'],
  animations: [
    trigger('animate', [
      transition('* => void', animate('0.2s ease', style({
        transform: 'scale(0)'
      }))),
      transition('* => *', [
        animate('0.1s ease', style({
          transform: 'scale(1.3)'
        })),
        animate('0.1s ease', style({
          transform: 'scale(1)'
        }))
      ])
    ])
  ]
})
export class CounterBadgeComponent {
  @Input() @HostBinding('@animate')
  count: number;
}
