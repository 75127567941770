import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { News } from '../model/news';
import { NewsBackendInterface } from '../model/news.backend-interface';
import { generateNewsFromBackendInput } from '../model/news.generators';
import { NewsCategoriesEnum } from '../enum/news-categories.enum';

@Injectable()
export class NewsService {

  constructor(private _httpClient: HttpClient) {}

  fetchNews(): Observable<News[]> {
    return this._httpClient.get<NewsBackendInterface[]>(`${ environment.wordpress_api_url }posts?categories=${NewsCategoriesEnum.categories},${NewsCategoriesEnum.functions},${NewsCategoriesEnum.suppliers}`)
      .pipe(
        map(body => body.map(item => generateNewsFromBackendInput(item)))
      );
  }
}
